import React, { Component } from 'react';
import sprite from '../../img/icons/sprite.svg';
import usp from './usp.module.scss';
import { withTranslation } from 'react-i18next';

class Usp extends Component {
  render() {
    const {t} = this.props;

    return (
      <>
        <div className="grid">
          <div className="cell">
            <h2 className="title align--center margin--bottom-3:lg">{t('uspMainTitle')}</h2>
          </div>
        </div>
        <div className="grid gap">
          <div className="cell cell--12 cell--4:md align--center margin--bottom-2 margin--bottom-0:md">
            <div className={usp.usp}>
              <svg className="icon margin--bottom-24 color--primary" role="presentation" width="56" height="56">
                <use xlinkHref={sprite + '#sprite-bags'}/>
              </svg>
              <h3 className="margin--bottom-1">{t('uspTitle1')}</h3>
              <p className="color--primary-highdark margin--bottom-0">
                {t('uspDesc1')}
              </p>
            </div>
          </div>
          <div className="cell cell--12 cell--4:md align--center margin--bottom-2 margin--bottom-0:md">
            <div className={usp.usp}>
              <svg className="icon margin--bottom-24 color--primary" role="presentation" width="56" height="56">
                <use xlinkHref={sprite + '#sprite-travel'}/>
              </svg>
              <h3 className="margin--bottom-1" >{t('uspTitle2')}</h3>
              <p className="color--primary-highdark margin--bottom-0">
                {t('uspDesc2')}
              </p>
            </div>
          </div>
          <div className="cell cell--12 cell--4:md align--center margin--bottom-2 margin--bottom-0:md">
            <div className={usp.usp}>
              <svg className="icon margin--bottom-24 color--primary" role="presentation" width="56" height="56">
                <use xlinkHref={sprite + '#sprite-compass'}/>
              </svg>
              <h3 className="margin--bottom-1">{t('uspTitle3')}</h3>
              <p className="color--primary-highdark margin--bottom-0">
              {t('uspDesc3')}
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(Usp);