import constants from '../../../../../styles/constants.js';
import Radium from 'radium';
import { Link, hashHistory } from 'react-router';
import OrangeButton from '../../../../shared/OrangeButton';
import { zajezdyDetail } from '../../../../../utils/routeConfig';
import React, { Component } from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import formatCurrency from '../../../../shared/formatCurrency';
import { Swiper, SwiperSlide } from 'swiper/react';
import { withTranslation } from 'react-i18next';

class SimilarTour extends Component {
  render() {
    const { similarTour } = this.props;
    const { hovered } = this.state;
    const { t } = this.props;

    return (
      <>
        <div className="object" onClick={() => this.redirect(similarTour.id)}>
          <div className="object__header">
            <picture>
              <img src={similarTour.imgSrc} key={similarTour.id} alt={similarTour.hotel} />
            </picture>
          </div>
          <div className="object__body">
            <h3 className="title">{similarTour.hotel}</h3>
            <ul className="list list--breadcrumbs">
              <li className="text--14 color--primary-highdark">
                <strong className="text--semi">{similarTour.country}</strong>
              </li>
              <li className="text--14 color--primary-highdark">
                <strong className="text--semi">{similarTour.location}</strong>
              </li>
            </ul>
          </div>
          <div className="object__footer">
            <div className="button button--small button--primary">{t('availableOptions')}</div>
            <div className="object__price">
              <span className="text--16 color--primary-highdark">{t('od')}</span>
              <strong className="color--secondary">
                {similarTour.price && this.renderPrice(similarTour)}
              </strong>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderPrice(similarTour) {
    return <>{formatCurrency(similarTour.price)}</>;
  }

  redirect(idTour) {
    hashHistory.push(zajezdyDetail + idTour);
    window.location.reload();
  }
}

SimilarTour.propTypes = {
  similarTour: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
});

export default connect(mapStateToProps)(withTranslation()(Radium(SimilarTour)));
