import { Link, hashHistory } from 'react-router';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import Radium from 'radium';
import OrangeButton from '../../shared/OrangeButton';
import { withTranslation } from "react-i18next";
import { setComparemetTours, removeComparedTour } from '../../../actions/TourDetail/tourDetail';
import './ComparementSideBar.scss';
import styles from './compare.module.scss';
import sprite from '../../../img/icons/sprite.svg';

class ComparementSideBar extends Component {
  state = {
    opened: false,
    isEmpty: true,
  };

  componentDidMount() {
    const {
      tourComparement: { tours },
      setComparemetTours,
    } = this.props;

    if (!tours.length) {
      setComparemetTours();
    }
  }

  componentWillReceiveProps(props) {
    const {
      tourComparement: { tours },
    } = this.props;
    const { isEmpty } = this.state;

    if (props.tourComparement.tours.length === 0) {
      this.setState({ opened: false, isEmtpy: true, hasBeenOpened: false });
    }

    if (isEmpty && props.tourComparement.tours.length > 0) {
      setTimeout(() => {
        this.setState({ opened: true, isEmtpy: false });
      }, 1000);
    }
  }

  render() {
    const {
      tourComparement: { tours },
      showComparementSideBar,
    } = this.props;
    const { opened } = this.state;
    const canCompare = tours.length > 1;
    const { t } = this.props;

    if (!showComparementSideBar) {
      return null;
    }

    return (
      <>
        <div className={`${styles.compare} ${this.getClassForOpenState()}`}>
          <div className={styles.compare__header} onClick={() => this.setState({ opened: !opened, hasBeenOpened: true })}>
            <h2 className="title color--white">{t('compareTitle')}</h2>
          </div>
          <div className={styles.compare__body}>
            <ul className='list list--compare'>
              {tours.map((tour) => this.renderTourForComparement(tour))}
            </ul>
            <div className="margin--top-auto">
              {canCompare ? this.renderCompareButton() : this.renderCantCompareMessage()}
            </div>
          </div>
        </div>
        {/* <div className={'comparement-side-bar ' + this.getClassForOpenState()}>
          <div
            className="comparement-open-wrapper"
            onClick={() => this.setState({ opened: !opened, hasBeenOpened: true })}
          >
            <i className="material-icons open-comparement">
              {opened ? 'keyboard_arrow_right' : 'keyboard_arrow_left'}
            </i>

            <div className="comparement-open-title">Porovnání zájezdů</div>
          </div>

          <div className="comparement-info">
            <div className="comparement-tours">
              {tours.map((tour) => this.renderTourForComparement(tour))}
            </div>

            <div className="comparement-bottom-bar">
              {canCompare ? this.renderCompareButton() : this.renderCantCompareMessage()}
            </div>
          </div>
        </div>       */}
      </>
    );
  }

  renderCompareButton() {
    const { t } = this.props;

    return (
      <>
        <Link to={'/porovnani'} className="button button--small button--full button--primary hollow" onClick={() => this.setState({ opened: false })}>
          {t('compare')}
        </Link>
      </>
    );
  }

  renderCantCompareMessage() {
    const { t } = this.props;

    return (
      <>
        <hr className="margin--top-1 margin--bottom-1" />
        <p className="margin--bottom-0 text--14 color--primary-highdark align--center">{t('compareAlert')}</p>
      </>
    );
  }

  renderTourForComparement(tour) {
    const { removeComparedTour } = this.props;
    const { hotelTitle, dates, idTour } = tour;

    return (
      <>
        <li>
          <div className="text--14 color--primary-highdark">
            <strong>{hotelTitle}</strong>
            <div className="text--14">{dates}</div>
          </div>
          <button onClick={() => removeComparedTour(idTour)} className="button button--plain" type="button">
            <svg className="icon color--danger" role="presentation" width="20" height="20">
              <use xlinkHref={sprite + '#sprite-cross'}/>
            </svg>
          </button>        
        </li>
      </>
    );
  }

  getClassForOpenState() {
    const {
      tourComparement: { tours },
    } = this.props;
    const { opened, hasBeenOpened } = this.state;

    if (!opened && hasBeenOpened && tours.length) {
      return styles.compare__closed;
    } else if (opened) {
      return styles.compare__open;
    } else if (tours.length) {
      return styles.compare__shown;
    } else {
      return styles.compare__hidden;
    }
  }
}

const mapStateToProps = (state) => ({
  tourComparement: state.tourComparement,
});

const mapDispatchToProps = (dispatch) => ({
  setComparemetTours: () => dispatch(setComparemetTours()),
  removeComparedTour: (idTour) => dispatch(removeComparedTour(idTour)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Radium(ComparementSideBar)));
