import Radium from 'radium';
import cookie from 'react-cookie';
import { connect } from 'react-redux';
import { hashHistory } from 'react-router';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { search, zajezdyDetail } from '../../utils/routeConfig';
import constants from '../../styles/constants.js';
import searchIcon from '../../img/search-icon.png'; // eslint-disable-line
import formatCurrency from '../shared/formatCurrency';
import LoadingIndicator from 'react-loading-indicator';
import createSearchRequest from '../shared/RequestCreator/createSearchRequest';
import {
  saveSearchTerm,
  saveRequestSearchTerm,
  getAutocomplete,
  fetching,
  nonFetchable,
} from '../../actions/Search/search';

import styles from './autocomplete.module.scss';
import sprite from '../../img/icons/sprite.svg';

// const styles = {
//   dropDown: {
//     background: '#fff',
//     position: 'absolute',
//     top: 48,
//     left: 0,
//     width: 380,
//     zIndex: 4,
//     fontSize: '0.9em',
//     boxShadow: 'rgba(0, 0, 0, 0.31) 1px 2px 4px',
//     maxHeight: '530px',
//     overflow: 'auto',
//     padding: 10,

//     [constants.breakpoint1024]: {
//       left: 'inherit',
//       right: 0,
//       maxHeight: '450px',
//     },

//     [constants.breakpointMobile]: {
//       position: 'fixed',
//       left: '50%',
//       transform: 'translateX(-50%)',
//       width: '300px',
//       right: 'inherit',
//       top: '60px',
//       maxHeight: '350px',
//     },
//     item: {
//       padding: '5px 3px',
//       borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
//       cursor: 'pointer',
//       margin: '0',
//       minHeight: '65px',
//       position: 'relative',
//       background: 'rgba(0, 0, 0, 0)',
//       transition: '0.15s',
//       transform: 'scale(1)',
//       transformOrigin: 'center center',

//       imageBox: {
//         float: 'left',
//         display: 'inline-block',
//         width: '90px',
//         height: '65px',
//         overflow: 'hidden',
//         position: 'relative',

//         image: {
//           position: 'absolute',
//           top: '50%',
//           left: '50%',
//           transform: 'translate(-50%, -50%)',
//           height: '102%',
//           width: 'auto',
//         },
//       },

//       descBox: {
//         float: 'left',
//         display: 'inline-block',
//         width: 'calc(100% - 104px)',
//         marginLeft: '10px',
//         fontSize: '1.1em',
//         position: 'relative',
//         height: '65px',
//         lineHeight: '1.15em',
//         textTransform: 'uppercase',

//         [constants.breakpointMobile]: {
//           fontSize: '1em',
//         },

//         title: {
//           fontWeight: '600',
//           marginBottom: '3px',
//           color: [constants.mainBlue],
//         },

//         bottomLine: {
//           position: 'absolute',
//           bottom: '0',
//           left: '0',
//           width: '100%',

//           price: {
//             float: 'right',
//             display: 'inline-block',
//             width: '35%',
//             color: [constants.mainOrange],
//             fontWeight: '600',
//             textAlign: 'right',
//             textTransform: 'capitalize',
//           },

//           term: {
//             float: 'left',
//             display: 'inline-block',
//             width: '65%',
//           },
//         },
//       },

//       [':hover']: {
//         background: '#f6f6f6',
//         borderBottom: '1px solid #fff',
//       },
//     },

//     loader: {
//       width: 130,
//       marginTop: '3px',
//       height: 'auto',
//       position: 'relative',
//       left: '50%',
//       transform: 'translateX(-50%)',
//       display: 'inline-block',

//       text: {
//         float: 'right',
//         width: 'auto',
//         display: 'inline-block',
//         paddingTop: '5px',
//         color: '#7f7f7f',
//       },
//     },

//     checkbox: {
//       marginRight: '10px',
//       position: 'relative',
//       top: '1px',
//     },
//     caret: {
//       display: 'block',
//       position: 'absolute',
//       top: '-8px',
//       left: '7px',
//       width: '0',
//       height: '0',
//       content: '',
//       verticalAlign: 'middle',
//       borderBottom: '10px dashed' + [constants.dropDownBackground],
//       borderRight: '10px solid transparent',
//       borderLeft: '10px solid transparent',
//     },
//   },
//   hotel: {
//     fontSize: '0.9em',
//     marginTop: '4px',
//   },

//   wrapper: {
//     left: '220px',
//     top: '21px',
//     display: 'block',
//     position: 'absolute',

//     [constants.breakpointLargeTablet]: {
//       left: '190px',
//     },

//     [constants.breakpoint1024]: {
//       left: 'inherit',
//       right: '70px',
//       top: '39px',
//     },

//     [constants.breakpoint600]: {
//       top: 13,
//       right: 65,
//     },

//     [constants.breakpoint360]: {
//       right: 60,
//     },
//   },
//   searchInput: {
//     border: 'solid rgb(206, 206, 206) 1px',
//     color: '#000',
//     fontSize: '1em',
//     borderRadius: '4px',
//     outline: '0',
//     fontWeight: '300',
//     padding: '8px 5px 8px 10px',

//     [constants.breakpoint1024]: {
//       width: '120px',
//       padding: '5px 35px 6px 10px',
//     },

//     [constants.breakpointMobile]: {
//       width: '100px',
//     },

//     [constants.breakpoint360]: {
//       width: '70px',
//     },
//   },
//   groupTitle: {
//     textAlign: 'left',
//     padding: '4px 0 5px 0',
//     borderBottom: 'solid 1px #777',

//     unMargin: {
//       marginTop: '0',
//     },
//   },
//   notFound: {
//     textAlign: 'center',
//     marginTop: 0,
//     marginBottom: 0,
//     padding: '4px 0 5px 0',
//     color: '#999',
//   },
//   label: {
//     position: 'relative',
//     width: '100%',
//     right: '-3px',
//     top: '1px',
//   },
//   button: {
//     color: '#fff',
//     textTransform: 'uppercase',
//     marginRight: '0',
//     fontWeight: '700',
//     border: 'none',
//     position: 'relative',
//     display: 'none',
//     top: 0,
//     right: '1px',
//     borderRadius: '0 5px 5px 0',
//     width: '40px',
//   },
//   icon: {
//     position: 'absolute',
//     right: '2px',
//     color: '#386696',
//     cursor: 'pointer',
//     top: '2px',
//     padding: '9px',
//     width: '15px',
//     height: '15px',
//     background: "white",

//     [constants.breakpoint1024]: {

//       width: '11px',
//       height: '11px',
//     },
//   },
// };

const groupsTranslations = {
  country: 'Země',
  hotel: 'Hotel',
  location: 'Oblast',
};

class MainSearchInput extends Component {
  componentDidMount() {
    this.init();
  }

  render() {
    const {
      search: { searchTerm },
    } = this.props;

    return (
      <div className={styles.autocomplete}>
        <div onKeyUp={this.find.bind(this)}>
          <input
            className={styles.autocomplete__input}
            placeholder="Hledat zájez…"
            onChange={(event) => {
              this.save(event);
            }}
            value={searchTerm}
          />
          <button className={styles.autocomplete__submit} onClick={() => this.search()}>
            <svg className="icon" role="presentation" width="20" height="20">
              <use xlinkHref={sprite + '#sprite-search'}/>
            </svg>    
          </button>
        </div>

        {this.renderAutocomplete()}
      </div>
    );
  }

  renderAutocomplete() {
    const {
      autocomplete: { items },
    } = this.props;

    if (!items || items === nonFetchable) {
      return null;
    }

    if (items === fetching) {
      return this.loader();
    }

    return (
      <div className={styles.autocomplete__dropdown}>
        {Object.keys(items).map((key) => this.renderAutocompleteGroup(key))}
      </div>
    );
  }

  renderAutocompleteGroup(key) {
    const {
      autocomplete: { items },
    } = this.props;
    const groupItems = items[key];

    return (
      <div key={'autocompleteGroup-' + key}>
        <div className={styles.autocomplete__title}>
          <h3 className="title color--primary-highdark margin--bottom-0">{groupsTranslations[key]}</h3>
          <hr />
        </div>
        <ul className={styles.autocomplete__list}>
          {groupItems.length ? (
            groupItems.map((item) => this.renderAutocompleteItem(item, key))
          ) : (
            <li className={styles.autocomplete__item}>Nebyly nalezeny žádné výsledky</li>
          )}
        </ul>
      </div>
    );
  }

  renderAutocompleteItem(tour, key) {
    const { hotel, id, imgSrc, country, price, termFrom, termTo, location } = tour;

    return (
      <>
        <li className={styles.autocomplete__item} onClick={() => this.redirect(id)} key={hotel + key + Math.random().toString(36)}>
          <div className={styles.autocomplete__main}>
            <picture className="margin--right-1">
              <img width={100} src={imgSrc} />
            </picture>
            <ul className="list">
              <li>
                <h3 className="title color--primary-highdark" style={{marginBottom: '0.25rem'}}>{hotel}</h3>
              </li>
              <li className="text--14 color--primary-highdark">
                {termFrom} - {termTo}
              </li>
              <li className="text--14 color--primary-highdark">
                <ul className="list list--destination" style={{ marginBottom: 0}}>
                  <li className="text--14 color--black">
                    <strong className="text--semi">
                      {country}
                    </strong>
                  </li>
                  <li className="text--14 color--black">
                    <strong className="text--semi">
                      {location}
                    </strong>
                  </li>
                </ul>
              </li>              
            </ul>
          </div>
          <div className={styles.autocomplete__price}>
            {price === 'soldOut' ? 'Vyprodáno' : formatCurrency(price)}
          </div>
        </li>
        {/* <div
          onClick={() => this.redirect(id)}
          className="item"
          key={hotel + key + Math.random().toString(36)}
          style={styles.dropDown.item}
        >
          <div style={styles.dropDown.item.imageBox}>
            <img style={styles.dropDown.item.imageBox.image} src={imgSrc} />
          </div>

          <div style={styles.dropDown.item.descBox}>
            <div style={styles.dropDown.item.descBox.title}>
              {country}, <span>{location}</span>
            </div>

            <div style={styles.hotel}>{hotel}</div>

            <div style={styles.dropDown.item.descBox.bottomLine}>
              <div style={styles.dropDown.item.descBox.bottomLine.term}>
                {termFrom} - {termTo}
              </div>

              <div style={styles.dropDown.item.descBox.bottomLine.price}>
                {price === 'soldOut' ? 'Vyprodáno' : formatCurrency(price)}
              </div>
            </div>
          </div>
        </div>       */}
      </>
    );
  }

  loader() {
    return (
      <>
      <div className={styles.autocomplete__dropdown}>
        <ul className={styles.autocomplete__list}>
          <li><LoadingIndicator segmentWidth="3" /> Načítám zájezdy...</li>
        </ul>
      </div>      
      </>
      // <div style={styles.dropDown}>
      //   <div style={styles.dropDown.loader}>
      //     <LoadingIndicator segmentWidth="3" />
      //     <div style={styles.dropDown.loader.text}> Načítám zájezdy</div>
      //   </div>
      // </div>
    );
  }

  init() {
    this.setState({ user: cookie.load('user') });
  }

  redirect(idTour) {
    hashHistory.push(zajezdyDetail + idTour);
    window.location.reload();
  }

  save(event) {
    const { saveSearchTerm, getAutocomplete } = this.props;
    const searchTerm = event.target.value;

    if (searchTerm.length > 2) {
      const autocompleteParams = {
        user: this.state.user,
        word: searchTerm,
      };

      getAutocomplete(autocompleteParams);
    }

    saveSearchTerm(searchTerm);
  }

  find(event) {
    if (event.keyCode === 13) {
      this.search();
    }
  }

  search() {
    const {
      search: { searchTerm },
      saveRequestSearchTerm,
    } = this.props;

    saveRequestSearchTerm(searchTerm);
    hashHistory.push(search);
  }
}

MainSearchInput.propTypes = {
  autocomplete: PropTypes.any,
  getAutocomplete: PropTypes.any,
  search: PropTypes.any,
  saveSearchTerm: PropTypes.any,
  saveRequestSearchTerm: PropTypes.any,
};

const mapStateToProps = (state) => ({
  search: state.search,
  autocomplete: state.autocomplete,
});

const mapDispatchToProps = (dispatch) => ({
  saveSearchTerm: (searchTerm) => dispatch(saveSearchTerm(searchTerm)),
  getAutocomplete: (params) => dispatch(getAutocomplete(params)),
  saveRequestSearchTerm: (searchTerm) => dispatch(saveRequestSearchTerm(searchTerm)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(MainSearchInput));
