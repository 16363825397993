import headImage from '../../../../img/bcg-tours.jpg'; // eslint-disable-line
import { saveFilter } from '../../../../actions/TourList/filter';
// import style from '../../../../styles/slider.scss'; // eslint-disable-line
import checked from '../../../../img/checked.png'; // eslint-disable-line
// import ToursListHead from './ToursListHead.scss';
import formatCurrency from '../../../shared/formatCurrency';
import OrangeButton from '../../../shared/OrangeButton';
import { setCost, toggleTiles } from '../../../../actions/TourList/paginator';
import { fetchFilteredList } from '../../../../actions/TourList/fetchTourList';
import createFilterRequest from '../../../shared/RequestCreator/createFilterRequest';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import { withTranslation } from 'react-i18next';
import ToursFilter from './ToursFilter';
import { connect } from 'react-redux';
import ReactSlider from 'react-slider';
import cookie from 'react-cookie';
import { employee } from '../../../../actions/HP/defaultTourlist';
import { dateSorting } from './components/TourListSettings';
import styles from '../../../shared/TourListStyles/TourListHeadStyles';
import Radium from 'radium';
import hero from './hero.module.scss'; // eslint-disable-line
import filter from './filter.module.scss'; // eslint-disable-line
import slider from './slider.module.scss'; // eslint-disable-line
import { useTranslation } from 'react-i18next';
import heroPicture from '../../../../img/heros/hero-lists.webp';

const barClassNames = {
  'employee': 'employee-bar',
  'visitor': 'bar',
  'szEmployee': 'sz-employee-bar',
}
const lastminute = 'lastminute';

class TourListHead extends Component {
  state = {
    max: 0,
    min: 0,
    setted: false,
    fetched: false,
    actualMin: 0,
    actualMax: 0,
  };

  componentDidMount() {
    this.initialize();
  }

  componentWillReceiveProps(props) {
    const { tourList, type, saveFilter } = props;
    const { specialUrlFetched, normalStateRefetched, lastTypeState } = this.state;
    const sortObject = {
      value: dateSorting.value,
      code: dateSorting.code,
      type: 'ASC',
    };
    this.checkFetched(tourList);

    if (
      type === lastminute &&
      !specialUrlFetched &&
      !normalStateRefetched &&
      lastTypeState !== type
    ) {
      saveFilter(true, 'isLastMinuteOrCustom');
      saveFilter(true, 'showTermList');
      saveFilter(sortObject, 'sortBy');
      const optional = {
        changedShowTermList: true,
        changedLastMinute: true,
        changedSortBy: sortObject,
      };

      this.fetchFiltered(optional);
      this.setState({ specialUrlFetched: true, lastTypeState: type });
    } else if (specialUrlFetched && lastTypeState !== type) {
      this.setState({ specialUrlFetched: false, lastTypeState: undefined });
      saveFilter(false, 'isLastMinuteOrCustom');
      saveFilter(false, 'showTermList');
      saveFilter(sortObject, 'sortBy');
      const optional = {
        changedShowTermList: false,
        changedLastMinute: false,
        changedSortBy: sortObject,
      };

      this.fetchFiltered(optional);
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.max !== 0 && nextState.max !== this.state.max) {
      setTimeout(() => this.setState({ max: nextState.max }), 100);
    }
  }

  render() {
    const {
      setCost,
      paginator: { minCost, maxCost },
      fetchState: { filterFetched },
    } = this.props;
    const {
      filterSettings: { filterName, primaryColor, secondaryColor },
      filter: { isLastMinuteOrCustom },
    } = this.props;
    const { actualMin, actualMax, setted, max, min } = this.state;
    const user = cookie.load('user');

    if (filterFetched && !setted) {
      setCost(minCost, maxCost);
      this.setState({
        setted: true,
        max: maxCost,
        min: minCost,
        actualMax: maxCost,
        actualMin: minCost,
      });
    }

    this.setCustomColors(secondaryColor, primaryColor);
    const {t} = this.props;

    return (
      <>
        <section className={hero.hero}>
          <picture className={hero.hero__picture}>
            <img src={heroPicture} alt={t('homepageTitle')} width={1920} />
          </picture>
          <div className={hero.hero__content}>
            <div className="container">
              <div className="grid grid--middle gap">
                <div className="cell cell--12 cell--4:lg ">
                  <article className={hero.hero__article}>
                    <h1 className="title margin--bottom-1 color--white">{t('homepageTitle')}</h1>
                    <p className="lead margin--bottom-2 color--white" dangerouslySetInnerHTML={{__html: t('homepageDesc')}} />
                  </article>
                </div>
                <div className="cell cell--12 cell--8:lg cell--bottom">
                  <div className={filter.filter}>
                    <ToursFilter />
                    <label className={`${filter.filter__label} margin--bottom-1`}>
                      {t('priceOfStay')}
                    </label>
                    {max !== 0 && (
                      <ReactSlider
                        className={slider.slider}
                        barClassName={slider.slider__bar}
                        defaultValue={[actualMin, actualMax]}
                        min={min}
                        max={max}
                        withBars
                        pearling
                        onChange={this.changeRealtime.bind(this)}
                        onAfterChange={this.change.bind(this)}
                      />
                    )}   
                    <div className="grid grid--justify">
                      <div className="cell cell--shrink text--14 color--primary-highdark">{formatCurrency(actualMin) + ' '}</div>
                      <div className="cell cell--shrink text--14 color--primary-highdark">{formatCurrency(actualMax)}</div>
                    </div>
                  </div>               
                </div>
              </div>
            </div>
          </div>

        </section>
        {/* <div style={styles.sectionHead} className="filters-header">
          <div style={styles.wrapperBig}>
            <div style={styles.contentBox} className="tour-liset-box-wrapper">
              <div className="tour-filteres-wrapper">
                <ToursFilter />
                <div style={styles.centered}>

                </div>
                <h3 style={styles.value} className="tours-slider-value">
                  {formatCurrency(actualMin) + ' '}- {formatCurrency(actualMax)}
                </h3>

                {max !== 0 && (
                  <ReactSlider
                    barClassName={barClassNames[user]}
                    defaultValue={[actualMin, actualMax]}
                    min={min}
                    max={max}
                    withBars
                    pearling
                    onChange={this.changeRealtime.bind(this)}
                    onAfterChange={this.change.bind(this)}
                  />
                )}
              </div>
            </div>
          </div>
        </div> */}
      </>
    );
  }

  setCustomColors(secondaryColor, primaryColor) {
    const { hovered } = this.state;

    if (secondaryColor !== 'defaultColor' && primaryColor !== 'defaultColor') {
      styles.iconBox.icon.color = hovered ? primaryColor : secondaryColor;
      styles.buttonlastMinute.color = secondaryColor;
      styles.buttonlastMinute.borderColor = primaryColor;
      styles.buttonlastMinute.background = primaryColor;
      styles.buttonlastMinute[':hover'] = {
        background: secondaryColor,
        borderColor: secondaryColor,
        color: primaryColor,
      };
    }
  }

  toggleLastMinute() {
    const {
      saveFilter,
      toggleTiles,
      filter: { isLastMinuteOrCustom, showTermList },
      fetchFilteredList,
      paginator: { page, itemsPerPage },
      filter,
      filter: { dateFrom, dateTo },
    } = this.props;
    const { actualMax, actualMin } = this.state;
    const sortObject = {
      value: dateSorting.value,
      code: dateSorting.code,
      type: 'ASC',
    };

    saveFilter(!isLastMinuteOrCustom, 'isLastMinuteOrCustom');
    const optional = {
      changedLastMinute: !isLastMinuteOrCustom,
      changedSortBy: sortObject,
    };

    if (!isLastMinuteOrCustom && !showTermList) {
      saveFilter(true, 'showTermList');
      optional.changedShowTermList = true;
    } else if (isLastMinuteOrCustom && !dateFrom && !dateTo) {
      saveFilter(false, 'showTermList');
      optional.changedShowTermList = false;
    }

    saveFilter(sortObject, 'sortBy');
    toggleTiles(false);

    fetchFilteredList(
      createFilterRequest(filter, page, itemsPerPage, actualMin, actualMax, optional),
    );
  }

  initialize() {
    const { tourList } = this.props;

    this.setState({ setted: false });
    this.checkFetched(tourList);
  }

  changeRealtime(values) {
    const [onChangeMin, onChangeMax] = values;
    this.setState({ actualMax: onChangeMax, actualMin: onChangeMin });
  }

  change(values) {
    const {
      setCost,
      toggleTiles,
      fetchFilteredList,
      paginator: { page, itemsPerPage },
      filter,
    } = this.props;
    const [onChangeMin, onChangeMax] = values;
    this.setState({ actualMax: onChangeMax, actualMin: onChangeMin });
    setCost(onChangeMin, onChangeMax);
    toggleTiles(false);
    fetchFilteredList(createFilterRequest(filter, page, itemsPerPage, onChangeMin, onChangeMax));
  }

  checkFetched(tourList) {
    const { list } = tourList;

    if (list.length > 1) {
      this.setState({ fetched: true });
    }
  }

  fetchFiltered(optional) {
    const {
      fetchFilteredList,
      toggleTiles,
      filter,
      paginator: { itemsPerPage, minCost, maxCost, page },
    } = this.props;
    fetchFilteredList(createFilterRequest(filter, page, itemsPerPage, minCost, maxCost, optional));
    toggleTiles(false);
  }
}

TourListHead.propTypes = {
  setCost: PropTypes.any,
  paginator: PropTypes.any,
  fetchState: PropTypes.any,
  toggleTiles: PropTypes.any,
  fetchFilteredList: PropTypes.any,
  filter: PropTypes.any,
  filterSettings: PropTypes.any,
  saveFilter: PropTypes.any,
  type: PropTypes.any,
  tourList: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourList: state.tourList,
  tourFilter: state.tourFilter,
  filter: state.filter,
  filterSettings: state.filterSettings,
  paginator: state.paginator,
  fetchState: state.fetchState,
  defaultTourListFilter: state.defaultTourListFilter,
});

const mapDispatchToProps = (dispatch) => ({
  toggleTiles: (nextState) => dispatch(toggleTiles(nextState)),
  saveFilter: (filter, designation) => dispatch(saveFilter(filter, designation)),
  fetchFilteredList: (filters) => dispatch(fetchFilteredList(filters)),
  setCost: (min, max) => dispatch(setCost(min, max)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Radium(TourListHead)));
