import { getJSON } from '../../utils/fetch';
import { fetchLocations } from './fetchTourList';
import cookie from 'react-cookie';

export const SAVE_FILTER = 'SAVE_FILTER';
export const SET_TOUR_FILTER = 'SET_TOUR_FILTER';
export const SET_CATALOGUE_FILTER = 'SET_CATALOGUE_FILTER';
export const SELECT = 'SELECT';
export const CHECK_BOX = 'CHECK_BOX';
export const CHECK_BOX_CHECK = 'CHECK_BOX_CHECK';
export const LOCATIONS = 'LOCATIONS';
export const LOCATIONS_TEXT_BOX = 'LOCATIONS_TEXT_BOX';
export const SET_INCLUDED_FILTER = 'SET_INCLUDED_FILTER';
export const SET_INCLUDED_DEPENDENT_FILTER = 'SET_INCLUDED_DEPENDENT_FILTER';
export const RESET_LOCATIONS = 'RESET_LOCATIONS';
export const TOGGLE_RESETING_LOCATIONS = 'TOGGLE_RESETING_LOCATIONS';
export const SET_TILES = 'SET_TILES';
export const RESET_FILTER = 'RESET_FILTER';
export const SAVE_LOCATIONS = 'SAVE_LOCATIONS';

export const toggleSelected = (code, designation, checkbox) => {
  return (dispatch) => {
    if (!checkbox) {
      dispatch({
        type: SELECT,
        code,
        designation,
      });
    } else {
      dispatch({
        type: CHECK_BOX,
        code,
        designation,
      });
    }
  };
};

export const setSelected = (code, designation) => {
  return (dispatch) => {
    dispatch({
      type: CHECK_BOX_CHECK,
      code,
      designation,
    });
  };
};

export const resetLocations = (designation) => ({
  type: RESET_LOCATIONS,
  designation,
});

export const setTiles = (tiles) => ({
  type: SET_TILES,
  tiles,
});

export const toggleSelectedLocations = (code, designation, textBox) => {
  return (dispatch) => {
    if (textBox) {
      dispatch({
        type: LOCATIONS_TEXT_BOX,
        code,
        designation,
      });
    } else {
      dispatch({
        type: LOCATIONS,
        code,
        designation,
      });
    }
  };
};

export const saveIncludedSelectedLocations = (result) => ({
  type: SET_INCLUDED_DEPENDENT_FILTER,
  result,
});

export const toggleResetingLocations = (status) => ({
  type: TOGGLE_RESETING_LOCATIONS,
  status,
});

export const resetFilter = (designation) => ({
  type: RESET_FILTER,
  designation,
});

export const resetAllFilters = () => {
  const filters = ['countrySortedByName', 'season', 'transport', 'days', 'diet'];

  return (dispatch) => {
    fetchLocations(dispatch);

    filters.forEach((filter) => {
      dispatch({
        type: RESET_FILTER,
        designation: filter,
      });
    });
  };
};

export const setFilterFetched = () => ({
  type: RESET_FILTER,
});

export const saveFilter = (filter) => ({
  type: SAVE_FILTER,
  filter,
});

export const saveIncluded = (designation, filter) => ({
  type: SET_INCLUDED_FILTER,
  designation,
  filter,
});

export const fetchCatalogueFilter = () => {
  return (dispatch) => {
    getJSON('adapted-catalogue-filter/?user=' + cookie.load('user')).then((result) => {
      dispatch({
        type: SET_CATALOGUE_FILTER,
        result,
      });
    });
  };
};

export const fetchTourFilter = () => {
  return (dispatch) => {
    getJSON('adapted-filter/?user=' + cookie.load('user')).then((result) => {
      dispatch({
        type: SET_TOUR_FILTER,
        result: result.adaptedFilter,
      });
      dispatch({
        type: SAVE_LOCATIONS,
        result: result.locationsInCountries,
      });
      dispatch({
        type: SET_TILES,
        tiles: result.adaptedFilter.country,
      });
    });
  };
};
