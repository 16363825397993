import { setSettingsParameter } from '../../../../actions/Calculator/calculator';
import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import BaseSelect from '../../../shared/BaseSelect/BaseSelect';
import { discountParam } from '../../../../reducers/calculator';
import { handleSelect, itemArrayForSelet, selectedText } from './multiSelect';
import FormGroup from '../../../shared/forms/FormGroup';
import { withTranslation } from 'react-i18next';

class DiscountPicker extends Component {
  render() {
    const {
      calculator: {
        data: { discounts },
        settings: { discount },
      },
      setSettingsParameter,
    } = this.props;
    const { t } = this.props;

    if (discounts.length === 0) {
      return null;
    }

    return (
      <>
        <FormGroup title={t('discount')}>
          <BaseSelect
            isCheckBox={Boolean(true)}
            items={itemArrayForSelet(discounts, discount)}
            placeholder={'-'}
            text={discount && discount.length ? selectedText(discount) : null}
            onReset={() => setSettingsParameter([], discountParam)}
            onSelect={(id) =>
              handleSelect(id, discounts, discount, setSettingsParameter, discountParam)
            }
          />
        </FormGroup>      
        {/* <div className="picker picker-big">
          <label>Slevy</label>
          <BaseSelect
            isCheckBox={Boolean(true)}
            items={itemArrayForSelet(discounts, discount)}
            placeholder={'-'}
            text={discount && discount.length ? selectedText(discount) : null}
            onReset={() => setSettingsParameter([], discountParam)}
            onSelect={(id) =>
              handleSelect(id, discounts, discount, setSettingsParameter, discountParam)
            }
          />
        </div>       */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  calculator: state.calculator,
});

const mapDispatchToProps = (dispatch) => ({
  setSettingsParameter: (object, parameter) => dispatch(setSettingsParameter(object, parameter)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Radium(DiscountPicker)));
