import Caret from '../../shared/Caret';
import constants from '../../../styles/constants.js';
import DropDown from './DropDown';
import Radium from 'radium';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import styles from './menu.module.scss';
import sprite from '../../../img/icons/sprite.svg';

class DropdownMenuItem extends Component {
  state = {
    hovered: false,
  };

  render() {
    const { hovered } = this.state;
    const { type, title, link, key, parent } = this.props;

    return (
      <>
        {title ? <>
          <li
            key={key}
            onClick={this.handleItemClick.bind(this)}
            onMouseEnter={() => {
              if (window.innerWidth > 1024) {
                this.setState({ hovered: true })
              }
            }}
            onMouseLeave={() => {
              if (window.innerWidth > 1024) {
                this.setState({ hovered: false })
              }
            }}
          >
            <div className={styles.menu__link} key={2}>
              {title}
              <svg className="icon margin--left-1" role="presentation" width="12" height="12">
                <use xlinkHref={sprite + '#sprite-angle-down'}/>
              </svg>
            </div>
            {hovered && this.renderDropDownMenu(parent)}
          </li>        
        </> : null}
      </>
    );
  }

  handleItemClick() {
    this.setState({ hovered: true, opened: true });
  }

  renderDropDownMenu(parent) {
    const { type } = this.props;
    return <DropDown type={type} parent={parent} />;
  }
}

DropdownMenuItem.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  key: PropTypes.string.isRequired,
  parent: PropTypes.string.isRequired,
};

export default Radium(DropdownMenuItem);
