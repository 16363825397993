import React, { useState} from 'react';
import faq from './faq.module.scss';
import Accordion from './Accordion'


const App = () => {
	const accordionData = [
		{
			title: 'Když jste cestovní kancelář Českých drah, tak máte vždy vlakovou dopravu?',
			content: 'Ne, většinu zahraničních pobytových zájezdů nabízíme s leteckou dopravou. Do několika zemí pak s dopravou autobusovou. Vlakové pobytové zájezdy nabízíme (vedle leteckých) například do Bulharska. S vlakovou dopravou nabízíme většinu poznávacích zájezdů.'
		},
		{
			title: 'Mohu zasílat cestovní smlouvu i elektronicky?',
			content: 'Samozřejmě, nutností je ovšem abychom měli na cestovní smlouvě podpis klienta. Pak ji můžete naskenovat a originál si nechat. Obratem zašleme fakturu, kde jsou údaje o platbě.'
		},
		{
			title: 'Mohu platit bezhotovostně, převodem na účet?',
			content: 'Samozřejmě, tuto platbu preferujeme. Veškeré potřebné údaje budou uvedeny na námi vystaveném dokladu.'
		},
		{
			title: 'Zaplatili jsme fakturu, ale stále nepřišel poukaz na dovolenou. Co máme dělat?',
			content: 'Poukazy (Vouchery) rozesíláme zhruba týden před odletem na dovolenou. A to hlavně z důvodu možných změn u odletového času.'
		},
		{
			title: 'Co když se mění letový čas? Kdy se to dozvíme?',
			content: 'V případě, že je změna letového času včas nahlášena od letecké společnosti (24 a více hodin před odletem), pak všechny klienty informujeme formou SMS na telefonní číslo udané v cestovní smlouvě. Pokud nedostaneme potvrzení o přijetí, pak je informujeme telefonickým hovorem.'
		},
		{
			title: 'S jakými leteckými společnostmi spolupracujete?',
			content: 'Spolupracujeme pouze s českými leteckými společnostmi. Zveřejňujeme i přibližné odletové časy. Snažíme se zajišťovat lety v denních hodinách.'
		},
		{
			title: 'Když máte uvedeno, že zájezd je na 8 dní a já strávím první den na cestě a na hotel se dostanu až o půlnoci, není to zájezd na 7 dní?',
			content: 'Přesnější údaj by byl počet nocí. Ale vzhledem ke konkurenci raději udáváme počet dní. Do délky zájezdu se vždy počítá první i poslední den. Tj. den, kdy odlétáte a den, kdy se vracíte. Naprostá většina zájezdů je koncipována tak, že když letadlo přiveze klienty, zároveň obrátkou jiné odváží. Takže oč máte první den zkrácený, o to je poslední den delší.'
		},
		{
			title: 'Měli jsme problém s ubytováním na dovolené. Kam si můžeme stěžovat?',
			content: 'Ve všeobecných podmínkách máte přesný popis, jak reklamovat případné nedostatky. Nejdůležitější je, řešit problém okamžitě na místě s delegáty. Pozdní reklamace jsou vždy velice problematické.'
		},
		{
			title: 'Jak postupovat v případě zdravotních komplikací v zahraničí, na koho se obrátit a jak je to s platbou?',
			content: 'V tomto případě se můžete obrátit na svého delegáta, který vám doporučí lékaře, případně vás dovede - doveze na ambulanci nebo v akutních případech přivede lékaře přímo do hotelu. Léky a vyšetření se platí na místě a pojišťovna v ČR vám zpětně po předložení lékařských dokumentů a příslušných účtů tyto služby proplatí. Doporučujeme číst pečlivě „Pojistné podmínky“ zasílané společně s poukazy a Vouchery. '
		},
		{
			title: 'Mohu objednat zájezd (apartmán) společně s přáteli, kteří nejsou zaměstnanci skupiny ČD ?',
			content: ' Ano. Naše CK poskytuje stejné služby zaměstnancům skupiny ČD, SŽDC i běžným klientům, takže si můžete zájezd objednat společně. Jediné, co se bude lišit, je cena. Zaměstnanci skupiny ČD a SŽDC mohou čerpat příspěvek od zaměstnavatele.'
		},
	];

	const { title, content} = accordionData;

	return (
		<ul className={faq.accordion}>
			{accordionData.map(({ title, content}) => (
				<Accordion title={title} content={content} />
			))}
		</ul>
	);
};

export default App;