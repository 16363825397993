import footerLogo from '../../img/cd-travel_logo-white.svg'; // eslint-disable-line
import React, { Component } from 'react';
import footer from './footer.module.scss';
import Logo from '../Logo/Logo';
import {Link} from 'react-router';
import sprite from '../../img/icons/sprite.svg';
import { withTranslation } from 'react-i18next';

class Footer extends Component {
  render() {
    const {t} = this.props;

    return (
      <footer className={footer.footer}>
        <div className={footer.footer__section}>
          <div className="container">
            <div className="grid grid--center">
              <div className="cell cell--shrink:md">
                <div className={footer.footer__contact}>
                  <svg className="icon margin--right-24" role="presentation" width="38" height="38">
                    <use xlinkHref={sprite + '#sprite-phone'}/>
                  </svg>
                  <ul className='list'>
                    <li className='color--primary-200 text--14'>{t('footerCallTitle')}</li>
                    <li>
                      <a className="link link--large color--white" href="tel:+420972243051-5">
                        +420 972 243 051 - 5
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="cell cell--shrink:md">
                <div className={footer.footer__contact}>
                  <svg className="icon margin--right-24" role="presentation" width="38" height="38">
                    <use xlinkHref={sprite + '#sprite-mail'}/>
                  </svg>
                  <ul className='list'>
                    <li className='color--primary-200 text--14'>{t('footerEmailTitle')}</li>
                    <li>
                      <a className="link link--large color--white" href="mailto:CKInfo@cdtravel.cz">
                        CKInfo@cdtravel.cz
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={footer.footer__section}>
          <div className="container">
            <div className="grid grid--middle">
              <div className="cell cell--shrink:md align--center">
                <Logo isLight={true} />
              </div>
              <div className="cell cell--auto:md align--center">
                <p className='text--14 color--primary-200 margin--block-1 margin--block-0:md'>{t('copyright')}</p>
              </div>
              <div className="cell cell--shrink:md align--center">
                <a className="link color--primary-200" href="https://www.facebook.com/cdtravel.cz/" target="_blank" title="Facebook">
                  <svg className="icon" role="presentation" width="32" height="32">
                    <use xlinkHref={sprite + '#sprite-facebook'}/>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default withTranslation()(Footer);
