const getBaseUrl = () => {
    return 'https://cd82-be.involvedigital.cz/www/api/v1/';
    return 'https://staging.cdtravel.cz/www/api/v1/';
    return 'http://cdtravel/www/api/v1/';
    return 'http://cdtravel-old/www/api/v1/';
    return 'https://cd.incolorstudio.cz/www/api/v1/';
    return 'https://www.cdtravel.cz/www/api/v1/';

    if (window.location.hostname === 'localhost') {
        return 'http://cdtravel/www/api/v1/';
    } else if (window.location.hostname === 'cd.incolorstudio.cz') {
        return 'https://cd.incolorstudio.cz/www/api/v1/';
    } else {
        return 'https://www.cdtravel.cz/www/api/v1/';
    }
};

const baseUrl = getBaseUrl();

export default baseUrl;
export const apiUrl = baseUrl;

