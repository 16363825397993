import Radium from 'radium';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import Banner from '../../Banner/Banner';
import Usp from '../../Usp/Usp';
import hero from '../Tours/ToursList/hero.module.scss';
import heroPicture from '../../../img/heros/hero-lists.webp';
import cms from '../CMS/cms.module.scss';

{/*const styles = {
  sectionHead: {
    width: '100%',
  },
  contentWrapper: {
    overflow: 'hidden',
    background: '#fff',
    zIndex: 6,
    position: 'relative',
    padding: '50px 0',
  },
  contentBox: {
    margin: '0 auto',
    padding: '0 15px',
    maxWidth: '800px',
  },
  image: {
    marginTop: '25px',
    width: '100%',
  },
};*/}

class AboutUs extends Component {
  render() {
    return (
      <>
        <Helmet title="about" />
		  	<section className={hero.hero}>
		  		<picture className={hero.hero__picture}>
		  			<img src={heroPicture} alt="Pro vaše nejlepší zážitky z cestování"/>
		  		</picture>
		  		<div className={hero.hero__content}>
		  			<div className="container">
		  				<div className="grid gap">
		  					<div className="cell cell--12 cell--6:lg">
		  						<article className={hero.hero__article}>
		  							<h1 className="title margin--bottom-1 color--white">O naší společnosti</h1>
		  							<p className="lead margin--bottom-2 color--white">Lorem ipsum dolor sit amet, consectetuer elit dal adipiscing elit. Lorem ipsum dolor sit amet, consectetuer elit dal adipiscing elit.</p>
		  						</article>
		  					</div>
		  				</div>
		  			</div>
		  		</div>
		  	</section>
		  	<section className="section">
		  		<div className="container">
		  			<div className="grid">
	        		<div className="cell">
		  					<div className={cms.block}>
		  						<h2>ČD travel, s.r.o., cestovní kancelář</h2>
                  <ul className='list'>
                    <li><strong>Sídlo:</strong> 28.října 372/5, 110 00 Praha 1</li>
                    <li><strong>Zápis v obchodním rejstříku:</strong> MS v Praze, odd.C, vložka 108644</li>
                    <li><strong>Bankovní spojení:</strong> 883456/0300 ČSOB Praha</li>
                  </ul>
                  <a href="https://or.justice.cz/ias/ui/rejstrik-dotaz?dotaz=27364976" target="_blank" title="Výpis z Obchodního rejstříku">
                    Výpis z Obchodního rejstříku
                  </a>
                  <a href="/userfiles/file/Vyrocni zpravy/Vyrocni zprava CD travel 2015.pdf" target="_blank" title="Výroční zpráva za rok 2015">
                    Výroční zpráva za rok 2015
                  </a>
                  <p className='lead'>
                    ČD travel, s.r.o. je cestovní kanceláří NEJEN pro železničáře. Většinovým vlastníkem jsou České dráhy a.s.. Našich služeb využívají železničáři - proto se při sestavování nabídky orientujeme podle jejich požadavků, ale i ostatní široká klientela, která vyhledává kvalitní a cenově dostupné rekreace. Naše cestovní kancelář má již více než 20-letou tradici.
                  </p>
                  <h3>Nabízíme</h3>
                  <ul className='list'>
                    <li>
                      Rekreace tuzemské i zahraniční. Máme širokou nabídku wellness pobytů a pobytů pro
                      seniory (od 55 let). Zahraniční pobyty jsou s dopravou leteckou, vlakovou,
                      autobusovou i vlastní. Nabídka je široká zejména na zájezdy do Řecka, Bulharska,
                      Španělska, Turecka, Chorvatska i na Slovensko.
                    </li>
                    <li>
                      Nabízíme poznávací zájezdy do všech koutů Evropy (Evropské superexpresy, Anglické
                      hity tunelem, Švýcarské Alpy vlakem).
                    </li>
                    <li>
                      Zajišťujeme podniková školení v kvalitních a cenově dostupných zařízeních po celé
                      České i Slovenské republice.
                    </li>
                  </ul>
		  					</div>
	        		</div>
		  			</div>
	      	</div>
		  	</section>
		  	<section className="section section--global section--banner">
	        <div className="container">
	          <div className="grid">
	            <div className="cell">
	              <Banner />
	            </div>
	          </div>
	        </div>
	      </section>
	      <section className='section section--global' style={{paddingTop: 0}}>
	        <div className="container">
	          <div className="grid">
	            <div className="cell">
	              <Usp/>
	            </div>
	          </div>
	        </div>
	      </section>
      </>
    );
  }
}

export default Radium(AboutUs);
