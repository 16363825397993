import { setSettingsParameter } from '../../../../actions/Calculator/calculator';
import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import BaseSelect from '../../../shared/BaseSelect/BaseSelect';
import { eatingParam } from '../../../../reducers/calculator';
import FormGroup from '../../../shared/forms/FormGroup';
import { withTranslation } from 'react-i18next';

class EatingPicker extends Component {
  render() {
    const {
      calculator: {
        data: { eating },
        settings,
      },
      setSettingsParameter,
    } = this.props;
    const { t } = this.props;
    const selectItems = eating
      .map((eatingItem) => {
        if (eatingItem.type === 'children') {
          return null;
        }
        const price = this.getPrice(eating, eatingItem);

        return {
          id: eatingItem.id,
          title: `${eatingItem.title} ${price}`,
        };
      })
      .filter((x) => x != null);

    return (
      <>
        <FormGroup title={t('diet')}>
          <BaseSelect
            isCheckBox={Boolean(false)}
            items={selectItems}
            placeholder={'-'}
            text={
              settings.eating
                ? `${settings.eating.title} ${this.getPrice(eating, settings.eating)}`
                : null
            }
            onReset={() => setSettingsParameter(null, eatingParam)}
            onSelect={(id) => setSettingsParameter(this.eatingTitleById(id), eatingParam)}
          />
        </FormGroup>      
        {/* <div className="picker picker-medium">
          <label>Strava</label>

          <BaseSelect
            isCheckBox={Boolean(false)}
            items={selectItems}
            placeholder={'-'}
            text={
              settings.eating
                ? `${settings.eating.title} ${this.getPrice(eating, settings.eating)}`
                : null
            }
            onReset={() => setSettingsParameter(null, eatingParam)}
            onSelect={(id) => setSettingsParameter(this.eatingTitleById(id), eatingParam)}
          />
        </div>*/}
      </>

    );
  }

  getPrice(eating, eatingItem) {
    const children = eating.find((item) => {
      return item.baseCode === eatingItem.code && item.type === 'children';
    });

    const useChildrenPrice = children && eatingItem.price !== children.price;
    const price = `(${eatingItem.price}${useChildrenPrice ? ' / ' + children.price : ''})`;
    return price;
  }

  eatingTitleById(id) {
    const {
      calculator: {
        data: { eating },
        settings,
      },
    } = this.props;

    return eating.find((eating) => {
      return eating.id === id;
    });
  }
}

const mapStateToProps = (state) => ({
  calculator: state.calculator,
});

const mapDispatchToProps = (dispatch) => ({
  setSettingsParameter: (id, parameter) => dispatch(setSettingsParameter(id, parameter)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Radium(EatingPicker)));
