import AdList from './AdList';
import Filter from './Filter';
import greyBg from '../../../img/grey-bg.png'; // eslint-disable-line
import TogetherTravelingForm from './TogetherTravellingForm';
import OrangeButton from '../../shared/OrangeButton';

import SectionImage from '../../shared/SectionImage';
import sectionImg from '../../../img/section-headers/together.jpg'; // eslint-disable-line
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container } from '../../../libs/radium-grid'; // eslint-disable-line
import { togetherTitle } from '../../../utils/titleConfig';
import { fetchPassengers } from '../../../actions/TogetherTraveling/fetchPassengers';
import { postNewInsertion } from '../../../actions/TogetherTraveling/postPassengers';
import { fetchTourFilter } from '../../../actions/TourList/tourFilter';
import {
  setNewInsertionForm,
  toggleTogetherTravelingForm,
} from '../../../actions/TogetherTraveling/passengerFilter';
import Helmet from 'react-helmet';
import Radium from 'radium';
import cms from '../CMS/cms.module.scss';
import hero from '../Tours/ToursList/hero.module.scss';
import heroPicture from '../../../img/heros/hero-lists.webp';
import { withTranslation } from 'react-i18next';

// const styles = {
//   sectionHead: {
//     width: '100%',
//   },
//   contentWrapper: {
//     overflow: 'hidden',
//     zIndex: 6,
//     position: 'relative',
//     padding: '50px 0 50px 0',
//     backgroundImage: 'url(' + greyBg + ')',
//     height: '100%',
//   },
//   newAdBox: {
//     margin: '20px 0',
//   },
// };

export const newAdvertising = 'TogetherTravelling';

class TogetherTravelling extends Component {
  state = {
    toggle: false,
    opened: false,
  };

  componentDidMount() {
    const { fetchPassengers, fetchTourFilter } = this.props;
    fetchPassengers();
    fetchTourFilter();
  }

  render() {
    const {t} = this.props;

    return (
      <>
        <Helmet title={togetherTitle} />
        <section className={hero.hero}>
          <picture className={hero.hero__picture}>
						<img src={heroPicture} alt="Pro vaše nejlepší zážitky z cestování" />
					</picture>
          <div className={hero.hero__content}>
            <div className="container">
              <div className="grid gap">
                <div className="cell cell--12 cell--6:lg">
                  <article className={hero.hero__article}>
                    <h1 className="title margin--bottom-1 color--white">{t('travelTogetherTitle')}</h1>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
        {this.renderContent()}
      </>
    );
  }

  renderContent() {
    const {t} = this.props;
    const {
      togetherTravelingFormStatus: { newAdvertisingMessageOpened },
    } = this.props;

    return (
      <section className="margin--bottom-3" style={{paddingBottom:'2rem'}}>
        <div className="container">
          <div className={cms.block} style={{overflow:'hidden'}}>
            <Filter />
            <hr className="margin--bottom-24" />
            <div>
              {newAdvertisingMessageOpened && (
                <TogetherTravelingForm
                  opened={newAdvertisingMessageOpened}
                  sourceOfRequest={newAdvertising}
                />
              )}
            </div>
            <AdList />
            <div className="margin--top-1">
              <button className="button button--primary color--white" onClick={() => this.openTogetherTravelingForm()} type="centered" key="1">
                {t('addNewPost')}
              </button>
            </div>
          </div>
        </div>
      </section>
    );
  }

  openTogetherTravelingForm() {
    const { toggleTogetherTravelingForm } = this.props;

    this.setState({ opened: true });
    toggleTogetherTravelingForm(newAdvertising, true);
  }
}

TogetherTravelling.propTypes = {
  fetchPassengers: PropTypes.any,
  fetchTourFilter: PropTypes.any,
  togetherTravelingFormStatus: PropTypes.any,
  toggleTogetherTravelingForm: PropTypes.any,
};

const mapStateToProps = (state) => ({
  passengers: state.passengers,
  togetherTravelingFormStatus: state.togetherTravelingFormStatus,
  passengersFilter: state.passengersFilter,
});

const mapDispatchToProps = (dispatch) => ({
  postNewInsertion: (requestData) => dispatch(postNewInsertion(requestData)),
  fetchPassengers: () => dispatch(fetchPassengers()),
  fetchTourFilter: () => dispatch(fetchTourFilter()),
  toggleTogetherTravelingForm: (formType, isFormOpened) =>
    dispatch(toggleTogetherTravelingForm(formType, isFormOpened)),
  setNewInsertionForm: (status) => dispatch(setNewInsertionForm(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Radium(TogetherTravelling)));
