import constants from '../../../../../../styles/constants.js';
import crosLineImg from '../../../../../../img/crossLine.png'; // eslint-disable-line
import formatDateLong from '../../../../../shared/Moment/formatDateLong';
import cookie from 'react-cookie';
import formatDateShort from '../../../../../shared/Moment/formatDateShort';
import formatCurrency from '../../../../../shared/formatCurrency';
import getDataTip from '../../../../../shared/getDetailPriceListDataTip';

import { employee, szEmployee } from '../../../../../../actions/HP/defaultTourlist';
import TourTitle from './../../TourTitle';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Radium from 'radium';

const styles = {
  wrapper: {
    marginTop: '-12px',
    clear: 'both',
    overflow: 'hidden',
    position: 'relative',
    boxShadow: 'rgba(0, 0, 0, 0.13) 0px 0px 2px',
  },
  panelsContentWrapper: {
    width: '92%',
    padding: '0% 4% 2%',
    background: 'white',
  },
  leftAlign: {
    textAlign: 'left',
  },
  rightAlign: {
    textAlign: 'center',
  },
  sectionTitle: {
    marginTop: '0px',
    marginBottom: '40px',
    textTransform: 'uppercase',
    lineHeight: '23px',
    fontWeight: '700',
    padding: '1px 5px 1px 4px',
    color: [constants.yellow],

    display: 'inline-block',
    fontSize: '1.1em',
    borderBottom: '2px solid #ededed',
    width: '100%',
  },
  table: {
    borderCollapse: 'collapse',
    fontSize: '1em',
    maxWidth: '1000px',
    minWidth: '700px',
    width: '100%',

    [constants.breakpointMobile]: {
      fontSize: '0.8em',
    },
    tbody: {
      backgroundColor: 'white',
    },
    head: {
      textAlign: 'left',
    },
    subsidyHead: {
      textAlign: 'left',
      backgroundColor: [constants.green],
      color: '#fff',
      fontWeight: 'bold',
      paddingBottom: '10px',
    },
    subsidyTitleHead: {
      color: 'white',
      backgroundColor: [constants.green],
      fontSize: '1.1em',
      fontWeight: '500',
      position: 'fixed',

      top: '30px',
      left: '-10',
      zIndex: 999,
      textAlign: 'center',
      padding: '20px',
    },
    subsidyTitle: {
      fontSize: '13px',
      textTransform: 'uppercase',
      color: 'black',
    },
    line: {},
    crossLine: {
      backgroundImage: `url(${crosLineImg})`,
      backgroundPositionY: '50%',
      backgroundRepeat: 'repeat-x',
      color: '#999',
    },
    printingCrossLine: {
      backgroundPositionY: '50%',
      backgroundRepeat: 'repeat-x',
      color: 'rgba(216, 216, 216, 0.5)',
    },
    description: {},
    priceFor: {
      textAlign: 'right',
      minWidth: '60px',
    },
    price: {
      textAlign: 'right',
      minWidth: '80px',
    },
    infoBottom: {
      marginTop: '5px',
      width: '700px',
      margin: '0 auto',
    },
    infoBottomWrapper: {
      marginTop: '20px',
    },
  },
  priceList: {
    cell: {
      width: '50%',
      float: 'left',
      textAlign: 'right',
      display: 'inline-block',
      padding: '7px 0',
      borderBottom: '1px solid rgba(56, 102, 150, 0.2)',
      fontWeight: '600',
      color: [constants.mainOrange],
    },
    orange: {
      color: [constants.mainOrange],
    },
    leftBox: {
      fontWeight: '600',
      textAlign: 'left !important',
      color: [constants.mainBlue],
    },
    line: {
      padding: 0,
    },
  },
  tourNotices: {
    padding: ' 0 10px',
    color: [constants.mainBlue],
  },
  tourHeader: {
    justifyContent: 'center',
  },
  loading: {
    display: 'block',
    width: '200px',
    margin: '0 auto',
    justifyContent: 'center',
    marginTop: 120,
  },
  buttonSubsidyMarged: {
    margin: '20px 10px 0px 10px',
    background: [constants.green],
    borderColor: [constants.green],
    [constants.breakpoint360]: {
      position: 'relative',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    ':hover': {
      background: 'transparent',
      borderColor: [constants.green],
      color: [constants.green],
    },
  },
  buttonMarged: {
    margin: '20px 10px 0px 10px',
    [constants.breakpoint360]: {
      position: 'relative',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  buttons: {
    position: 'relative',
    left: '50%',
    float: 'left',
    transform: 'translateX(-50%)',
  },
  soldOutText: {
    position: 'absolute',
    transform: 'translate(-50%, -50%) rotate(-22deg)',
    left: '50%',
    top: '50%',
    fontSize: '4em',
    color: 'red',
    textTransform: 'upperca fse',
    opacity: '0.4',
    fontWeight: 'bold',
  },
};

export const subsidy = 'dotovany';

class SeparatedPriceList extends Component {
  state = {
    fetched: false,
  };

  render() {
    const { term } = this.props;
    const { isSoldOut, isCanceled } = term;

    return (
      <div className={'tour-price-list'} style={styles.wrapper}>
        <TourTitle>
          {term
            ? 'Termín ' + formatDateShort(term.dateFrom) + ' - ' + formatDateLong(term.dateTo)
            : 'Ceník zájezdu'}
        </TourTitle>
        <div style={styles.panelsContentWrapper}>
          {isSoldOut && <div style={styles.soldOutText}>Vyprodáno</div>}
          {isCanceled && <div style={styles.soldOutText}>Zrušeno</div>}

          <div className="pricelist-div-line" id="printed" ref="printed">
            {term && this.renderStaticPriceListRowItems(term)}
          </div>

          <div className="bottom-info-wrapper">
            {term && <p dangerouslySetInnerHTML={{ __html: getDataTip(term) }} id="printed" />}
          </div>
        </div>
      </div>
    );
  }

  renderStaticPriceListRowItems(term) {
    const user = cookie.load('user');
    const { prices } = term;

    return (
      <div>
        <p>{(user === employee || user === szEmployee) && 'Ceny po dotaci'}</p>
        {prices &&
          prices.map((priceObject) => this.renderDynamicPriceListRowItems(priceObject, user))}
      </div>
    );
  }

  renderDynamicPriceListRowItems(priceObject, user) {
    const { description, price, subsidy, szSubsidy, isHiddenForDetail } = priceObject;
    const realSubsidy = user === szEmployee ? szSubsidy : subsidy;

    if (isHiddenForDetail) {
      return null;
    }

    return (
      <ul style={styles.priceList.line}>
        <li style={[styles.priceList.cell, styles.priceList.leftBox]}>{description}</li>
        <li style={styles.priceList.cell}>{this.renderPrice(user, price, realSubsidy)}</li>
      </ul>
    );
  }

  renderPrice(user, price, subsidy) {
    if (!price) {
      return 'zdarma';
    }

    return user === employee || user === szEmployee
      ? [formatCurrency(price - subsidy)]
      : [formatCurrency(price)];
  }
}

SeparatedPriceList.propTypes = {
  params: PropTypes.any,
  generatePDF: PropTypes.any,
  type: PropTypes.any,
  location: PropTypes.any,
  pathname: PropTypes.any,
  tourDetail: PropTypes.any,
  fetchTourDetail: PropTypes.any,
  dataFetched: PropTypes.any,
  term: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
  filter: state.filter,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(SeparatedPriceList));
