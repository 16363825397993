import emailIcon from '../../../../../img/mail-icon-grey.png'; // eslint-disable-line
import constants from '../../../../../styles/constants.js';
import phoneIcon from '../../../../../img/phone-grey.png'; // eslint-disable-line
import phone2Icon from '../../../../../img/phone2.png'; // eslint-disable-line
import Radium from 'radium';
import React, { Component } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './guarantee.module.scss';

class Guarantee extends Component {
  render() {
    const {
      tourDetail: {
        guarantee: { name, email, phone, mobile, photo },
      },
    } = this.props;

    return (
      <>
        <div className={styles.guarantee}>
          <picture className={styles.guarantor__image}>
            <img src={photo} width={64} height={64} title={name} />
          </picture>
          <div className={styles.guarantor__content}>
            <h2 className="title margin--bottom-0 margin--top-0">{name}</h2>
            <p className="color--primary-highdark text--14" style={{marginBottom: '0.5rem'}}>garant zájezdu</p>
            <ul className="list">
              <li>
                <a href={`mailto:${email}`} className="link link--underline color--primary text--14 ">
                  {email}
                </a>                
              </li>
              <li>
                <a href={`tel:${mobile}`} className=" link color--primary text--14">
                  {phone + ',  ' + mobile}
                </a>
              </li>
            </ul>
          </div>
        </div>      
      </>
    );
  }
}

Guarantee.propTypes = {
  tourDetail: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
});

export default connect(mapStateToProps)(Radium(Guarantee));
