import OrangeButton from '../shared/OrangeButton.jsx';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { close, fetchAdvertising } from '../../actions/Advertising/fetchAdvertising';
import { fetchFilterSettings } from '../../actions/TourList/filterSettings';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import modal from './advertisingMessage.module.scss';
import { withTranslation } from 'react-i18next';

class AdvertisingMessage extends Component {
  componentDidMount() {
    const { fetchAdvertising, fetchFilterSettings } = this.props;

    fetchAdvertising();
    fetchFilterSettings();
  }

  render() {
    const {
      advertising: {
        advertisingMessage: { title, text },
        closed,
      },
    } = this.props;
    const cookieLoaded = cookie.load('adMessageOpened');
    const { t } = this.props;

    return (
      <>
        <Modal
          isOpen={!closed && title && (!cookieLoaded || typeof cookieLoaded === 'undefined')}
          className={modal.modal__content}
          overlayClassName={modal.overlay}
        >
          <div className="grid gap">
            <div className="cell cell--12">
              <h3 className="title h6"> {title}</h3>
            </div>
            <div className="cell cell--12 margin--bottom-3">
              <p className="color--primary-highdark" dangerouslySetInnerHTML={{ __html: text }} />
            </div>
          </div>
          <div className="grid">
            <div className="cell cell--12 align--right">
              <button
                className="button hollow"
                title={t('close')}
                onClick={() => this.handleForm()}
              >
                Zavřít
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }

  handleForm() {
    const { close } = this.props;
    cookie.save('adMessageOpened', false, { maxAge: 60 * 60 * 24 * 3 });
    close();
  }
}

AdvertisingMessage.propTypes = {
  fetchAdvertising: PropTypes.any,
  advertising: PropTypes.any,
  fetchFilterSettings: PropTypes.any,
  close: PropTypes.any,
};

const mapStateToProps = (state) => ({
  advertising: state.advertising,
});

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(close()),
  fetchAdvertising: () => dispatch(fetchAdvertising()),
  fetchFilterSettings: () => dispatch(fetchFilterSettings()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AdvertisingMessage));
