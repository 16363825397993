import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  cs: {
    translation: {
      "crossroadTitle1": "Jsem běžný uživatel",
      "crossroadDesc1": "Prozkoumejte naše nejlepší nabídky pro běžné uživatele a objevte skvělé výlety a dovolené za atraktivní ceny, které vám zpříjemní volný čas a poskytnou nezapomenutelné zážitky.",
      "crossroadTitle2": "Jsem zaměstnanec skupiny ČD",
      "crossroadDesc2": "Jako zaměstnanec skupiny ČD můžete využít exkluzivní výhody a slevy na naše železniční destinace, což vám umožní snadnější a cenově výhodnější cestování po České republice.",
      "crossroadTitle3": "Jsem zaměstnanec SŽ",
      "crossroadDesc3": "Pro zaměstnance SŽ přinášíme zvláštní benefity, včetně slev na vybrané trasy a možnosti lepšího plánování vašich služebních cest, abyste mohli efektivněji naplánovat své pracovní aktivity.",
      "withSubsidy": "a čerpám dotaci",
      "withoutSubsidy": "a nečerpám dotaci",
      "crossroadCta": "Pojďme cestovat",
      "homepageTitle": "Pro vaše nejlepší zážitky z cestování",
      "homepageDesc": "Přijďte si vytvořit nezapomenutelné vzpomínky s&nbsp;námi a prožijte svět s plnými smysly.",
      "homepageCta": "Hledat inspiraci",
      "priceOfStay": "Cena pobytu",
      "footerCallTitle": "Volejte po–pá od 9 do 17 hodin",
      "footerEmailTitle": "Nebo pište kdykoliv na",
      "copyright": "Copyright © 2023 cdtravel.cz",
      "uspMainTitle": "Proč cestovat s ČD Travel",
      "uspTitle1": "Cestujte s jistotou",
      "uspDesc1": "S naší cestovní kanceláří cestujete s absolutní jistotou. Dejte nám příležitost vás vést k nezapomenutelným zážitkům.",
      "uspTitle2": "Objevte svět bez starostí",
      "uspDesc2": "Zanechte starosti doma a objevte krásy světa. S naší cestovní kanceláří nemusíte řešit žádné starosti spojené s plánováním dovolené.",
      "uspTitle3": "Vaše cesta, vaše pravidla",
      "uspDesc3": "Připravili jsme pro vás luxusní dovolenou, která splní všechna vaše očekávání. Vaše sny se stanou skutečností s naší kanceláří.",
      "paymentInfo": "Platební údaje",
      "paymentIntroText": "S naší cestovní kanceláří můžete pohodlně zaplatit za svou dovolenou přímo z domova a to vše online, což vám ušetří cenný čas a zjednoduší celý proces rezervace.",
      "price": "Cena",
      "currency": "Kč",
      "pay": "Zaplatit",
      "paymentLead": "Po vyplnění a potvrzení formuláře budete přesměrováni na platební bránu <b>ČSOB</b>, kde proběhne platba kartou.",
      "vsHint": "Desetimístné číslo, které jste od nás obdrželi.",
      "pipAgreement": "Souhlasím se zpracováním osobních údajů v souladu s Nařízením EU 2016/679.",
      "reviewTitle": "Recenze",
      "specialOfferTitle": "Speciální nabídky",
      "specialOfferDesc": "Objevte exkluzivní slevy a jedinečné cestovní zážitky v naší sekci Speciální nabídky. Prozkoumejte nejlepší dovolenkové možnosti za výhodné ceny.",
      "ourDestination": "Naše destinace",
      "loadMore": "Načíst další",
      "close": "Zrušit",
      "submit": "Potvrdit",
      "transport": "Doprava",
      "noTransportationInfo": "Zájezd nemá uvedené informace o odletu",
      "noTransportationInfoBus": "Zájezd nemá uvedené odjezdy",
      "subjectToChange": "Změna letového řádu vyhrazena.",
      "payAttentionToInstructions": "prosíme, sledujte pokyny CK",
      "subjectToChangeBus": "Změna jízdního řádu vyhrazena.",
      "locationOfDepartureOutbound": "Místo odjezdu tam",
      "locationOfArrivalOutbound": "Místo příjezdu",
      "dateOutbound": "Datum tam",
      "timeOutbound": "Odjezd tam",
      "locationOfDepartureReturn": "Místo odjezdu zpět",
      "locationOfArrivalReturn": "Datum zpět",
      "timeReturn": "Odjezd zpět",
      "duration": "Doba Cesty",
      "travelTogetherTitle": "Najděte společníka na cesty",
      "addNewPost": "Přidat nový inzerát",
      "reply": "Odpovědět",
      "avialableDates": "Dostupné termíny",
      "similarTours": "Podobné zájezdy v této lokalitě",
      "sendQuestion": "Poslat dotaz",
      "bookingDesc": " Mám zájem o bližší informace k tomuto zájezdu. Souhlasím, aby mě kontaktoval zaměstnanec cestovní kanceláře. Zároveň beru na vědomí, že tento mail není závaznou rezervací ani objednávkou.",
      "inquiry": "Nezávazná poptávka",
      "compare": "Porovnat",
      "calculator": "Kalkulačka",
      "personsCount": "Počet osob",
      "diet": "Strava",
      "transportPlace": "Nástupní místo",
      "surcharges": "Příplatky",
      "discount": "Slevy",
      "errorCombination": "Tato kombinace neni povolena",
      "subsidyEntitlement": "Nárok na dotaci",
      "backToDetail": "Zpět na detail",
      "order": "Objednat",
      "totalPrice": "Celková cena zájezdu",
      "backToCalculator": "Zpět na kalkulačku",
      "next": "Pokračovat",
      "basicInfo": "Základní údaje",
      "passengers": "Cestující",
      "summary": "Souhrn",
      "backToCalculatorNote": "*Pokud se vrátíte zpět na kalkulačku, nepřijdete tím o vyplněné údaje",
      "backToPrevious": "Zpět na předchozí krok",
      "finishReservation": "Donkončit objednávku",
      "chooseTerm": "Zvolit termín",
      "declarationPensioners": "Čestné prohlášení (povinné pro důchodce)",
      "declarationWork": "Prohlašuji, že jsem ukončil/a pracovní poměr u společnosti",
      "day": "dne",
      "grant": "O příspěvek žádám z C-SF/C-FKSP",
      "cd": "ČD",
      "cdCargo": "ČD Cargo",
      "railwayAdministration": "Správa železnic",
      "iDeclare": "Prohlašuji, že",
      "inProbationPeriod": "ve zkušební době.",
      "infant": "Infant",
      "foreign": "Cizí",
      "firstOrLastMinuteTitle": "Nabídka first nebo last minute",
      "firstOrLastMinuteDesc": "Vyberte si z našich nejnovějších last minute nabídek a vydejte se na dovolenou bez zbytečného plánování.",
      "travelByBus": "K moři letecky/busem",
      "travelByBusDesc": "Naše nabídky vám umožňují vybrat si, jakým způsobem se dostanete k oblíbeným plážím a slunečným destinacím.",
      "travelByOwn": "Vlastní doprava",
      "travelByOwnDesc": "Pro ty, kteří preferují nezávislost, nabízíme možnost vlastní dopravy. Plánujte si dovolenou podle svého tempa.",
      "compareTitle": "Porovnání zájezdů",
      "compareDesc": "Porovnání zájezdů vám umožňuje snadno srovnat různé cestovní nabídky, abyste mohli najít tu nejvhodnější pro svou plánovanou dovolenou.",
      "compareAlert": "Porovnat můžete dva nebo tři zájezdy",
      "backToList": "Zpět na přehled zájezdů",
      "tableName": "Název",
      "tablePrice": "Cena",
      "tableDate": "Datum",
      "tableLastminute": "Lastminute",
      "tableTransport": "Doprava",
      "tableCapacity": "Kapacity",
      "tableFood": "Strava",
      "tableNote": "Poznámky",
      "tableRooms": "Pokoje",
      "title": "Titul",
      "name": "Jméno*",
      "surname": "Příjmení*",
      "birthDate": "Datum narození*",
      "specification": "Příslušnost",
      "backToTrip": "Zpátky na zájezd",
      "bookingSent": "Objednávka byla odeslána",
      "bookingSentText": " Na Váší e-mailovou adresu jsme odeslali potvrzovací e-mail a předvyplněnou objednávku. Zkontrolujte prosím",
      "incommingMessages": " doručenou poštu",
      "48Hours": "Rezervace má platnost 48 hodin.",
      "name2": "Jméno",
      "birth": "Narozen/a",
      "specifications": "Specifikace",
      "company": "Společnost",
      "note": "Poznámka",
      "zipCode": "PSČ",
      "street": "Ulice",
      "phone": "Telefon",
      "mail": "E-mail",
      "location": "Lokalita",
      "country": "Země",
      "hotel": "Místo",
      "term": "Termín",
      "contactTitle": "Ozvěte se nám, rádi vám pomůžeme",
      "contactDesc1": "Obchodně rezervační oddělení",
      "contactDesc2": "28. října 372/5, 110 00 Praha 1",
      "faq":"Často kladené otázky a odpovědi",
      "faqDesc": "Níže naleznete odpovědi na nejčastější otázky. Pokud nenajdete, co jste hledali, neváhejte nás kontaktovat.",
      "aboutDesc": "Lorem ipsum dolor sit amet, consectetuer elit dal adipiscing elit. Lorem ipsum dolor sit amet, consectetuer elit dal adipiscing elit.",
      "ticketsDesc": "Lorem ipsum dolor sit amet, consectetuer elit dal adipiscing elit. Lorem ipsum dolor sit amet, consectetuer elit dal adipiscing elit.",
      "previous": "Předchozí",
      "next2": "Další",
      "print": "Vytisknout",
      "tripDetails": "Popis zájezdu",
      "soldOut": "VYPRODÁNO",
      "availableOptions": "Dostupné možnosti",
      "from": "od",
      "favorableOffer": "Výhodná nabídka",
      "length": "Délka:",
      "loadingTrips": "Načítám zájezdy",
      "noResultsFound": "Nebyly nalezeny žádné výsledky",
      "searchResults": "Výpis vyhledávání:",
      "searchDesc": "Najděte si svou ideální dovolenou pomocí našeho pokročilého vyhledávacího nástroje a objevte širokou nabídku úžasných destinací a dobrodružství.",
    }
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "cs", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;
