import { fetchCalculatorSettings, resetCalculator } from "../../../actions/Calculator/calculator";
import React, { Component } from "react";
import Radium from "radium";
import { connect } from "react-redux";
import cookie from "react-cookie";
import leftArrow from "../../../img/icons/back-arrow.png";
import { Link } from "react-router";
import { visitor } from "../../../actions/HP/defaultTourlist";
import RoomPicker from "./Pickers/RoomPicker";
import EatingPicker from "./Pickers/EatingPicker";
import PeoplePicker from "./Pickers/PeoplePicker";
import SurchargesPicker from "./Pickers/SurchargesPicker";
import DiscountPicker from "./Pickers/DiscountPicker";
import OccupantSummary from "./Summary/OccupantSummary";
import Icons from "./Icons";
import TotalPrice from "./Summary/TotalPrice";
import InfantsPicker, { getPeopleInOrder } from "./Pickers/InfantsPicker";
// import "./Calculator.css";
import formatDateShort from "../../shared/Moment/formatDateShort";
import formatDateLong from "../../shared/Moment/formatDateLong";
import Reservation from "./Reservation/Reservation";
import ReactTooltip from "react-tooltip";
import TransportPicker, { getDeparturePlaces } from "./Pickers/TransportPicker";
import constants from "../../../styles/constants";
import hero from "../Tours/ToursList/hero.module.scss";
import heroPicture from "../../../img/heros/hero-lists.webp";
import cms from "../CMS/cms.module.scss";
import { withTranslation } from "react-i18next";
import Helmet from "react-helmet";
import FormGroup from "../../shared/forms/FormGroup";


class Calculator extends Component {
  componentDidMount() {
    const {
      params: { idDetail },
      fetchCalculatorSettings,
      resetCalculator
    } = this.props;
    const user = cookie.load("user");

    resetCalculator();
    fetchCalculatorSettings(idDetail, user !== visitor);
  }

  render() {
    const {
      calculator: { data }
    } = this.props;
    const { renderReservation } = this.state;
    const { t } = this.props;

    if (!Object.keys(data).length) {
      return null;
    }

    const {
      params,
      calculator: {
        data: {
          hotel: { hotel, country, location, from, till }
        },
        specialAdult
      }
    } = this.props;

    const maxOccupantsOk = getPeopleInOrder(this.props) <= 6;

    return (
      <>
        <Helmet title="Kalkulačka" />
        <section className={hero.hero}>
          <picture className={hero.hero__picture}>
            <img src={heroPicture} alt={t("paymentInfo")} />
          </picture>
          <div className={hero.hero__content}>
            <div className="container">
              <div className="grid gap">
                <div className="cell cell--12 cell--6:lg">
                  <article className={hero.hero__article}>
                    <h1 className="title margin--bottom-1 color--white">{hotel}</h1>
                    <p className="lead color--white">
                      {country} {location},{formatDateShort(from)} - {formatDateLong(till)}
                    </p>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          {this.renderReservation()}

          <ReactTooltip html={Boolean(true)} />
        </section>
      </>
    );
  }

  renderReservation(combinationAllowed, renderReservation) {
    const { t } = this.props;

    return (
      <>
        <Reservation
          render={true}
          onClose={() => this.setState({ renderReservation: false })}
          idDetail={this.props.params.idDetail}
          combinationAllowed={combinationAllowed}
        />
      </>
    );
  }

  transportSelected() {
    const {
      calculator: {
        settings: { departureFrom, transport }
      }
    } = this.props;
    const departures = getDeparturePlaces(this.props);

    if (!transport) {
      return false;
    }

    if (departures && departures.length && !departureFrom) {
      return false;
    }

    return true;
  }
}

const mapStateToProps = (state) => ({
  calculator: state.calculator,
  tourDetail: state.tourDetail,
  isSubsidy: state.calculator.settings.isSubsidy
});

const mapDispatchToProps = (dispatch) => ({
  resetCalculator: () => dispatch(resetCalculator()),
  fetchCalculatorSettings: (idTour, isSubsidy) =>
    dispatch(fetchCalculatorSettings(idTour, isSubsidy))
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Radium(Calculator)));
