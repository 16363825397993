//import constants from '../../../../../../styles/constants.js';
import Radium from 'radium';
import formatDateLong from '../../../../../shared/Moment/formatDateLong';
import getDayOfWeek from '../../../../../shared/Moment/getDayOfWeekFromDate';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './departure.module.scss';
import { withTranslation } from 'react-i18next';

// const styles = {
//   col: {
//     padding: '0',

//     [constants.breakpointTablet]: {
//       padding: 'inherit',
//     },
//   },
//   block: {
//     overflow: 'auto',
//   },
//   rightBox: {
//     position: 'relative',

//     [constants.breakpointTablet]: {
//       marginTop: '30px',
//     },
//   },
//   iconAvailability: {
//     height: '30px',
//     width: 'auto',
//     padding: '3px 25px 3px 0',
//   },
//   iconType: {
//     position: 'relative',
//     top: '8px',
//     height: '20px',
//     width: 'auto',
//     padding: '3px 10px 3px 0',
//   },
//   textCentered: {
//     textAlign: 'center',
//     marginTop: 30,
//   },
//   infoTable: {
//     margin: '0 auto',
//     maxWidth: 800,
//     overflow: 'auto',
//     marginBottom: 20,

//     wrapper: {
//       clear: 'both',
//       overflow: 'auto',
//       backgroundColor: 'transparent',
//       marginBottom: 20,
//     },

//     header: {
//      borderBottom: '1px solid rgb(204, 204, 204)',
//      overflow: 'auto',
//      color: [constants.mainBlue],
//     },

//     inlineTableWrapper: {
//      width: '50%',
//      float: 'left',
//      color: [constants.mainBlue],

//      [constants.breakpointTablet]: {
//        width: '98%',
//      },

//      headWrapper: {
//        border: 'none',

//        title: {
//          color: '#333',
//          fontWeight: '600',
//        },
//      },

//      departureTitle: {
//        color: [constants.mainBlue],
//      },

//      lineWrapper: {
//        borderBottom: '1px solid rgba(56, 102, 150, 0.2)',
//        backgroundColor: 'transparent',
//        overflow: 'auto',

//        title: {
//          fontWeight: '600',
//          padding: '9px 0',
//          textAlign: 'left',
//          float: 'left',
//          marginRight: 15,
//        },
//        valueWrapper: {
//          float: 'right',
//          value: {
//            color: [constants.mainOrange],
//            display: 'inline-block',
//            padding: '9px 0',
//            fontWeight: '600',
//            textAlign: 'right',
//          },
//        },
//      },

//      [constants.breakpointMobile]: {
//        fontSize: '0.9em',
//      },
//     },
//   },
//   availability: {
//     fontSize: '0.8em',
//     marginTop: '20px',

//     image: {
//       width: '35px',
//       height: 'auto',
//       paddingRight: '7px',
//     },
//     text: {
//       padding: '3px 0',
//       position: 'relative',
//       top: '-6px',
//     },
//   },
// };

class DepartureTable extends Component {
  render() {
    const { departures } = this.props;
    const {t} = this.props;

    if (!this.checkDeparturesIntegrity(departures).length) {
      return (
        <>
          <p>{t('noTransportationInfo')}</p>
        </>
      );
    }

    return (
      <>
        {departures.map((departure) => this.renderDepartures(departure))}
        <hr className="margin--top-2 margin--bottom-2" />
        <div className="grid">
          <div className="cell align--center">
            <p className="color--primary--highdark margin--bottom-0">
              {t('subjectToChange')}
              <br />
              <i>({t('payAttentionToInstructions')})</i>
            </p>
          </div>
        </div>
      </>
    );
  }

  renderDepartures(departure) {
    const {
      destinationTitle,
      destinationDefaultCity,
      destinationCode,
      arrivalCode,
      destinationDestinationCity,
      destinationDate,
      destinationDepartureTime,
      destinationArrivalTime,
      arrivalDefaultCity,
      arrivalDestinationCity,
      arrivalDate,
      arrivalDepartureTime,
      arrivalArrivalTime,
      destinationTravelTime,
      arrivalTravelTime,
      terminal,
    } = departure;

    const departuresTableRowHeader = [
      {
        title: 'Letecky ' + destinationDefaultCity + ' | ' + destinationTitle,
      },
    ];

    const departureTableRows = [
      {
        tableHeader: 'Let TAM',
        tableColumn2: getDayOfWeek(destinationDate) + ' | ',
        tableColumn3: formatDateLong(destinationDate),
      },
      {
        tableHeader: 'Číslo letu',
        tableColumn2: destinationCode,
        tableColumn3: '',
      },
      {
        tableHeader: 'Odlet',
        tableColumn2: destinationDefaultCity + ' | ',
        tableColumn3: destinationDepartureTime + ' h',
      },
      {
        tableHeader: 'Přílet',
        tableColumn2: destinationDestinationCity + ' | ',
        tableColumn3: destinationArrivalTime + ' h',
      },
      {
        tableHeader: 'Délka letu',
        tableColumn2: '',
        tableColumn3: destinationTravelTime + ' h',
      },
    ];

    if (terminal) {
      departureTableRows.push({
        tableHeader: 'Terminál',
        tableColumn2: '',
        tableColumn3: terminal,
      });
    }

    const arrivalTableRows = [
      {
        tableHeader: 'Let ZPĚT',
        tableColumn2: getDayOfWeek(arrivalDate) + ' | ',
        tableColumn3: formatDateLong(arrivalDate),
      },
      {
        tableHeader: 'Číslo letu',
        tableColumn2: arrivalCode,
        tableColumn3: '',
      },
      {
        tableHeader: 'Odlet',
        tableColumn2: arrivalDefaultCity + ' | ',
        tableColumn3: arrivalDepartureTime + ' h',
      },
      {
        tableHeader: 'Přílet',
        tableColumn2: arrivalDestinationCity + ' | ',
        tableColumn3: arrivalArrivalTime + ' h',
      },
      {
        tableHeader: 'Délka letu',
        tableColumn2: '',
        tableColumn3: arrivalTravelTime + ' h',
      },
    ];

    if (destinationTitle) {
      return (
        <>
          {departuresTableRowHeader.map((info) => this.renderTableHead(info.title))}

          <div className="grid gap">
            <div
              className="cell--12 cell--6:md"
              //style={styles.infoTable.inlineTableWrapper}
            >
              {departureTableRows.map((info) =>
                this.renderTableRow(info.tableHeader, info.tableColumn2, info.tableColumn3),
              )}
            </div>

            <div
              className="cell--12 cell--6:md"
              //style={styles.infoTable.inlineTableWrapper}
              >
              {arrivalTableRows.map((info) =>
                this.renderTableRow(info.tableHeader, info.tableColumn2, info.tableColumn3),
              )}
            </div>
          </div>
        </>
      );
    }

    return null;
  }

  checkDeparturesIntegrity(departures) {
    return departures.filter(({ destinationTitle }) => destinationTitle);
  }

  renderTableHead(tableHeader, tableColumn2, tableColumn3) {
    return (
      <>
        <div className="grid" key={tableHeader}>
          <div className="cell">
            <h3 className="title color--primary-highdark">{tableHeader}</h3>
          </div>
        </div>
        {/* <div className="" key={tableHeader}>
          <div
            style={{
            ...styles.infoTable.inlineTableWrapper.headWrapper.title,
            ...styles.infoTable.inlineTableWrapper.lineWrapper.title,
            }}
          >
            <span className="">{tableHeader}</span>
          </div>

          <div
            style={{
            ...styles.infoTable.inlineTableWrapper.headWrapper.value,
            ...styles.infoTable.inlineTableWrapper.lineWrapper.value,
            }}
          >
            {tableColumn2}
            <div>{tableColumn3}</div>
          </div>
        </div>       */}
      </>
    );
  }

  renderTableRow(tableHeader, tableColumn2, tableColumn3) {
    //const lineWrapperStyle = styles.infoTable.inlineTableWrapper.lineWrapper;

    return (
      <div className={styles.table__row} key={tableHeader}>
        <div className="color--primary-highdark">{tableHeader}:</div>
        <div className="align--right">
          <strong className="color--primary">{tableColumn2}</strong>
          <strong className="color--primary">{tableColumn3}</strong>
        </div>
      </div>
    );
  }
}

DepartureTable.propTypes = {
  departures: PropTypes.any,
};

export default (withTranslation()(Radium(DepartureTable)));
