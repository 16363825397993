import {
  SET_RESERVATION_PARAM,
  SET_CUSTOMER_PARAM
} from "../actions/Calculator/reservation";
import {RESET_CALCULATOR} from "../actions/Calculator/calculator";

const initialState = {
  customerSettings: {},
  requiredSettings: ["name", "surname", "birthDate"],
  settingsValidations: {
    birthDate: '^\\s*(3[01]|[12][0-9]|0?[1-9])\\.(1[012]|0?[1-9])\\.((?:19|20)\\d{2})\\s*$',
    pensionerCompanyEnd: '^\\s*(3[01]|[12][0-9]|0?[1-9])\\.(1[012]|0?[1-9])\\.((?:19|20)\\d{2})\\s*$',
  },
  requiredPensionerSettings: [
    "name",
    "surname",
    "birthDate",
    "specification",
    "pensionerCompany",
    "pensionerCompanyEnd"
  ],
  requiredSecondStep: [
    "agreesPolicy"
  ],
  required: [
    "name",
    "surname",
    "street",
    "city",
    "birthDate",
    "email",
    "streetNum",
    "phone",
    "agrees",
    "zipCode"
  ],
  requiredValidation: {
    email: '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    birthDate: '^\\s*(3[01]|[12][0-9]|0?[1-9])\\.(1[012]|0?[1-9])\\.((?:19|20)\\d{2})\\s*$',
    phone: '([+]?\\d{1,3}[. \\s]?)?(\\d{9}?)',
    zipCode: '\\d{3} ?\\d{2}',
  },
  trans: {
    name: "Jméno",
    title: "Titul",
    surname: "Příjmení",
    street: "Ulice",
    city: "Město",
    birthDate: "Datum narození",
    email: "Email",
    streetNum: "Číslo popisné",
    phone: "Telefon",
    zipCode: "PSČ",
    note: "Poznámka",
    specification: "Specifikace",
    agrees:
      "Pokračováním v rezervaci vyjadřujete souhlas se zpracováním osobních údajů v souladu s Nařízením EU 2016/679.",
    agreesPolicy: "Souhlasím se Všeobecnými a záručními podmínkami cestovní kanceláře ČD travel a Pojistnými podmínkami ERV Evropské Pojišťovny."
  },
  values: {
    name: "",
    company: "cd",
    inTestTime: false,
    title: '',
    surname: "",
    street: "",
    city: "",
    birthDate: "",
    email: "",
    streetNum: "",
    phone: "",
    zipCode: "",
    note: "",
    agrees: false,
    agreesPolicy: false
  }
};

const filter = (state = initialState, action) => {
  switch (action.type) {
    case SET_RESERVATION_PARAM:
      state.values[action.param] = action.value;

      return {...state};

    case RESET_CALCULATOR:
      return {
        ...state,
        customerSettings: {},
        values: {
          company: "cd",
          inTestTime: false,
          name: "",
          surname: "",
          street: "",
          city: "",
          birthDate: "",
          email: "",
          streetNum: "",
          phone: "",
          zipCode: "",
          note: "",
          agrees: false,
        }
      };

    case SET_CUSTOMER_PARAM:
      let customer = state.customerSettings[action.id];

      if (!customer) {
        customer = {
          name: "",
          surname: "",
          birthDate: "",
          specification: "employee",
          pensionerCompany: "cd",
          pensionerCompanyEnd: null
        };
      }

      customer[action.param] = action.value;
      state.customerSettings[action.id] = customer;

      return {...state};

    default:
      return state;
  }
};

export default filter;
