import Radium from 'radium';
import Helmet from 'react-helmet';
import cookie from 'react-cookie';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { hashHistory, Link } from 'react-router';
import { zajezdyDetail } from '../../../utils/routeConfig';
import LoadingIndicator from 'react-loading-indicator';
import { Container } from '../../../libs/radium-grid';
import OrangeButton from '../../shared/OrangeButton';
import constants from '../../../styles/constants.js';
import { searchTitle } from '../../../utils/titleConfig'; // eslint-disable-line
import { fetchCMS } from '../../../actions/CMS/fetchCMS';
import formatCurrency from '../../shared/formatCurrency';
import { fetchSearchList, fetching } from '../../../actions/Search/search';
import { fetchTourList, fetchLocations } from '../../../actions/TourList/fetchTourList';
import heroPicture from '../../../img/heros/hero-lists.webp';
import hero from '../../Pages/Tours/ToursList/hero.module.scss';
import { withTranslation } from 'react-i18next';
import { soldOut } from '../Tours/ToursList/items/ToursListItem.jsx';

const customs = {
  loader: {
    width: 140,
    marginTop: '3px',
    height: 'auto',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'inline-block',
    padding: '10px 15px 7px 15px',
    borderRadius: '10px',
    background: '#fff',

    text: {
      float: 'right',
      width: 'auto',
      display: 'inline-block',
      paddingTop: '5px',
      paddingLeft: '10px',
      color: '#7f7f7f',
    },
  },
  bottomLoading: {
    clear: 'both',
    paddingTop: '20px',
  },
};

const groupsTranslations = {
  country: 'Země',
  hotel: 'Hotel',
  location: 'Oblast',
};

class Search extends Component {
  componentDidMount() {
    this.init();
  }

  render() {
    const { searchTerm } = this.state;
    const { t } = this.props;

    return (
      <>
        <Helmet title={searchTitle} />
        <section className={hero.hero}>
          <picture className={hero.hero__picture}>
            <img src={heroPicture} alt="Pro vaše nejlepší zážitky z cestování" />
          </picture>
          <div className={hero.hero__content}>
            <div className="container">
              <div className="grid gap">
                <div className="cell cell--12 cell--6:lg">
                  <article className={hero.hero__article}>
                    <h1 className="title margin--bottom-1 color--white">
                      {t('searchResults')} {searchTerm}
                    </h1>
                    <p className="lead margin--bottom-2 color--white" style={{ marginBottom: '0' }}>
                      {t('searchDesc')}
                    </p>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section section--medium" id="ubytovani">
          <div className="container">{this.renderSearch()}</div>
        </section>
      </>
    );
  }

  renderSearch() {
    const {
      search: { items, state },
    } = this.props;

    if (!items) {
      <div>{state === fetching && this.loader()}</div>;
    }

    return <div>{Object.keys(items).map((key) => this.renderSearchGroup(key))}</div>;
  }

  renderSearchGroup(key) {
    const {
      search: { items },
    } = this.props;
    const groupItems = items[key];
    const { t } = this.props;

    if (!Array.isArray(groupItems)) {
      return null;
    }

    return (
      <div className="margin--bottom-2 margin--bottom-3:md" key={'SearchGroup-' + key}>
        <h2 className="title color--primary-highdark margin--bottom-1">
          {groupsTranslations[key]}
        </h2>
        <hr className="margin--bottom-3" />
        <div className="grid gap">
          {groupItems.length ? (
            this.renderItems(groupItems, key, items.nextIndex[key])
          ) : (
            <div className="cell cell--12">
              <p className="color--primary-highdark margin--bottom-0">{t('noResultsFound')}</p>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderItems(groupItems, key, nextIndex) {
    const { searchTerm } = this.state;

    return (
      <>
        {groupItems.map((item) => this.renderSearchItem(item, key))}
        {nextIndex > 0 && (
          <div onClick={() => this.handleFetch(searchTerm)}>Načíst další zájezdy</div>
        )}
      </>
    );
  }

  renderSearchItem(tour, key) {
    const { hotel, id, imgSrc, country, price, termFrom, termTo, location } = tour;
    const { t } = this.props;

    return (
      <>
        <div
          className="cell cell--12 cell--6:md cell--4:lg margin--bottom-24"
          key={hotel + key + Math.random().toString(36)}
        >
          <Link className="object" onClick={() => this.redirect(id)}>
            <div className="object__header">
              <picture>
                <img src={imgSrc} alt={hotel} />
              </picture>
            </div>
            <div className="object__body">
              <h3 className="title">{hotel}</h3>
              <ul className="list list--destination">
                <li className="text--14 color--black">
                  <strong className="text--semi">{country}</strong>
                </li>
                <li className="text--14 color--black">
                  <strong className="text--semi">{location}</strong>
                </li>
              </ul>
              <p className="text--14 color--primary-highdark">
                {termFrom} - {termTo}
              </p>
            </div>
            <div className="object__footer">
              <div className="button button--small button--primary">{t('chooseTerm')}</div>
              <div className="object__price">
                {price !== soldOut ? (
                  <>
                    <span className="text--16 color--primary-highdark">{t('od')}</span>
                    <strong className="color--secondary">{formatCurrency(price)}</strong>
                  </>
                ) : (
                  <>
                    <strong className="color--secondary">{t('soldOut')}</strong>
                  </>
                )}
              </div>
            </div>
          </Link>
        </div>
      </>
    );
  }

  redirect(idTour) {
    hashHistory.push(zajezdyDetail + idTour);
    window.location.reload();
  }

  loader() {
    const { t } = this.props;

    return (
      <div className="cell cell--12">
        <div style={customs.loader}>
          <LoadingIndicator segmentWidth="3" />
          <div style={customs.loader.text}> {t('loadingTrips')}</div>
        </div>
      </div>
    );
  }

  init() {
    const {
      search: { searchTerm },
    } = this.props;

    if (searchTerm.length > 2) {
      this.setState({ searchTerm });
      this.handleFetch(searchTerm);
    }
  }

  save(event) {
    const searchTerm = event.target.value;

    if (searchTerm.length > 2) {
      this.handleFetch(searchTerm);
    }

    this.setState({ searchTerm });
  }

  handleFetch(searchTerm) {
    const {
      fetchSearchList,
      search: {
        items: { nextIndex },
      },
    } = this.props;

    const searchParams = {
      user: cookie.load('user'),
      word: searchTerm,
      count: {
        country: nextIndex && nextIndex.country > 0 ? nextIndex.country : 0,
        hotel: nextIndex && nextIndex.hotel > 0 ? nextIndex.hotel : 0,
        location: nextIndex && nextIndex.location > 0 ? nextIndex.location : 0,
      },
    };

    fetchSearchList(searchParams);
  }
}

Search.propTypes = {
  fetchCMS: PropTypes.any,
  fetchSearchList: PropTypes.any,
  search: PropTypes.any,
  fetchState: PropTypes.any,
  saveSearchTerm: PropTypes.any,
  saveRequestSearchTerm: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourList: state.tourList,
  search: state.search,
  fetchState: state.fetchState,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSearchList: (searchParams) => dispatch(fetchSearchList(searchParams)),
  fetchLocations: () => dispatch(fetchLocations()),
  fetchCMS: () => dispatch(fetchCMS()),
  fetchTourList: () => dispatch(fetchTourList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Radium(Search)));
