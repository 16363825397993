import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './input.module.scss'


export default class TextInput extends Component {
  render() {
    const { name, label, onChange, value} = this.props;

    return (
      <div className={styles.form__group}>
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>

        <input
          type="text"
          name={name}
          id={name}
          value={value}
          onChange={({ target: { value } }) => onChange(value)}
          className={styles.input}
        />
      </div>
    );
  }
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
