import constants from '../../../styles/constants.js';
import DropdownMenuItem from './DropdownMenuItem';
import Radium from 'radium';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import styles from './menu.module.scss';

class MainMenuItems extends Component {
  state = {
    hovered: false,
    opened: true,
  };

  render() {
    const {
      fetchState: { headerFetched },
      header,
    } = this.props;

    return (
      <>
        <ul className={styles.menu}>
          {headerFetched ? header.header.map((headerItem) => this.renderHead(headerItem)) : ''}
        </ul>
        {/* <ul style={styles.wrapper}>
          {headerFetched ? header.header.map((headerItem) => this.renderHead(headerItem)) : ''}
        </ul>       */}
      </>
    );
  }

  renderHead(headerItem) {
    const { type } = this.props;
    const { id, newWindow, title, link } = headerItem;

    switch (newWindow) {
      case null:
        return <DropdownMenuItem title={title} key={id} parent={id} type={type} />;

      case 1:
        return this.renderExternalItem(title, link, id);

      case 0:
        return this.renderItem(title, link, id);

      default:
        return '';
    }
  }

  renderItem(title, url, key) {
    const { type } = this.props;

    return (
      <>
        <li key={key}>
          <Link className={styles.menu__link} title={title} key={4} to={url} target="_blank">
            {title}
          </Link>
        </li>        
        {/* <span>
          <li style={{ ...styles.item, ...styles.item.types[type] }} key={key}>
            <Link style={styles.link.types[type]} key={4} to={url}>
              {title}
            </Link>
          </li>
        </span>       */}
      </>
    );
  }

  renderExternalItem(title, url, key) {
    const { type } = this.props;

    return (
      <>
        <li key={key}>
          <a className={styles.menu__link} title={title} key={4} href={url} target="_blank">
            {title}
          </a>
        </li>      
        {/* <span>
          <li style={[styles.item, styles.item.types[type]]} key={key}>
            <a style={styles.link.types[type]} key={4} href={url} target="_blank">
              {title}
            </a>
          </li>
        </span>       */}
      </>
    );
  }
}

MainMenuItems.propTypes = {
  type: PropTypes.string,
  closeMenu: PropTypes.func,
  fetchState: PropTypes.any,
  header: PropTypes.any,
};

const mapStateToProps = (state) => ({
  fetchState: state.fetchState,
  header: state.header,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(MainMenuItems));
