import constants from '../../../../styles/constants';
import projectColors from '../../../../styles/projectColors';
import { connect } from 'react-redux';
import { selecting } from '../../../../actions/TourList/filterOpenStatus';
import { toggleSelected } from '../../../../actions/TourList/tourFilter';
import { toggleTiles } from '../../../../actions/TourList/paginator';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import Radium from 'radium';
import Widget from '../../../Widget/Widget';

const styles = {
  backLink: {
    textDecoration: 'none',
    color: '#444',
    marginBottom: '10px',

    ':hover': {
      color: [constants.mainBlue],
    },
  },
  tileBox: {
    padding: 0,
    height: '230px',
    margin: '3px',
    float: 'left',
    position: 'relative',
    cursor: 'pointer',
    transition: [constants.transition],

    [constants.breakpointMobile]: {
      height: '240px',
    },

    link: {
      height: '100%',
      overflow: 'hidden',
      width: '100%',
      position: 'relative',
      color: '#fff',
      display: 'block',
    },

    imgTypes: {
      small: {
        width: 'auto',
        height: '115%',
        zIndex: 0,
        position: 'relative',
        top: '50%',
        left: '50%',
        transition: '0.5s',
        transform: 'scale(1) translate(-50%, -50%)',
        transformOrigin: 'top left',

        hovered: {
          transform: 'scale(1.5) translate(-50%, -50%)',
          transformOrigin: 'top left',
        },

        [constants.breakpointLargeTablet]: {
          height: '135%',
        },

        [constants.breakpointMobile]: {
          height: 'auto',
          width: '150%',
        },

        [constants.breakpoint360]: {
          height: '150%',
          width: 'auto',
        },
      },
      big: {
        width: '100%',
        height: 'auto%',
        zIndex: 0,
        position: 'relative',
        top: '50%',
        left: '50%',
        transition: '0.5s',
        transform: 'scale(1) translate(-50%, -50%)',
        transformOrigin: 'top left',

        hovered: {
          transform: 'scale(1.5) translate(-50%, -50%)',
          transformOrigin: 'top left',
        },
      },
    },
    titleBox: {
      background: [projectColors.halfTransparentOrange],
      position: 'absolute',
      textAlign: 'center',
      marginTop: 0,
      minHeight: '10%',
      color: 'white',
      bottom: 0,
      height: 'auto',
      left: 0,
      width: 'calc(100% - 30px)',
      padding: '15px',
      zIndex: 1,
      transition: '0.2s',
      opacity: 1,

      titleWrapper: {
        position: 'relative',
        width: '100%',
        height: '100%',
      },

      title: {
        position: 'relative',
        fontSize: '1.2em',
        textTransform: 'uppercase',
        top: 'inherit',
        fontWeight: '600',
        transform: 'none',
        color: '#fff',

        hovered: {
          transform: 'translateY(-50%)',
          display: 'block',
        },
      },

      hovered: {
        padding: '50% 15px 15px 15px',
        minHeight: 'calc(50% - 15px)',
      },
    },
  },

  types: {
    small: {
      width: 'calc(20% - 6px)',

      [constants.breakpointLargeTablet]: {
        width: 'calc(33.3% - 6px)',
      },
      [constants.breakpointTablet]: {
        width: 'calc(50% - 6px)',
      },
      [constants.breakpointMobile]: {
        width: 'calc(100% - 6px)',
      },
    },
    big: {
      width: 'calc(50% - 6px)',

      [constants.breakpointLargeTablet]: {
        width: 'calc(66.7% - 6px)',
      },
      [constants.breakpointTablet]: {
        width: 'calc(100% - 6px)',
      },
      [constants.breakpointMobile]: {
        width: 'calc(100% - 6px)',
      },
    },
  },
};

class Tile extends Component {
  state = {
    hovered: false,
  };

  render() {
    const { title, image, size, code, link, index } = this.props;
    const { hovered } = this.state;

    return (
      <div className="cell cell--6:md cell--4:lg margin--bottom-24">
      
        <Widget 
          key={'tile' + title + index} 
          title={title} 
          desc={null} 
          image={image}
          onClick={() => this.toggleCheckbox(code, link)}
        />
        {/* <div
          style={{ ...styles.tileBox, ...styles.types[size] }}
          className="tour-tile"
          key={'tile' + title + index}
          onMouseEnter={() => this.setState({ hovered: true })}
          onMouseLeave={() => this.setState({ hovered: false })}
        >
          <a
            style={styles.tileBox.link}
            onClick={() => this.toggleCheckbox(code, false, link)}
            href={link && link}
          >
            <img
              src={image}
              style={
                !hovered
                  ? styles.tileBox.imgTypes[size]
                  : {
                      ...styles.tileBox.imgTypes[size],
                      ...styles.tileBox.imgTypes[size].hovered,
                    }
              }
            />
            <span
              style={
                !hovered
                  ? styles.tileBox.titleBox
                  : {
                      ...styles.tileBox.titleBox,
                      ...styles.tileBox.titleBox.hovered,
                    }
              }
            >
              <span style={styles.tileBox.titleBox.titleWrapper}>
                <span
                  style={
                    !hovered
                      ? styles.tileBox.titleBox.title
                      : {
                          ...styles.tileBox.titleBox.title,
                          ...styles.tileBox.titleBox.title.hovered,
                        }
                  }
                >
                  {title}
                </span>
              </span>
            </span>
          </a>
        </div>       */}
      </div>

    );
  }

  toggleCheckbox(code, link) {
    const { toggleSelected, selecting, toggleTiles } = this.props;
    if (!link) {
      toggleSelected(code, 'countrySortedByName', false);
      selecting();
      toggleTiles(false);
    } else {
        window.open(link, '_blank');
    }
  }
}

Tile.propTypes = {
  title: PropTypes.any,
  link: PropTypes.string,
  image: PropTypes.string,
  size: PropTypes.string,
  code: PropTypes.any,
  toggleSelected: PropTypes.any,
  selecting: PropTypes.any,
  toggleTiles: PropTypes.any,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  selecting: () => dispatch(selecting()),
  toggleTiles: (nextState) => dispatch(toggleTiles(nextState)),
  toggleSelected: (code, designation, checkbox) =>
    dispatch(toggleSelected(code, designation, checkbox)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(Tile));
