//import constants from '../../../../../../styles/constants.js';
import PropTypes from 'prop-types';
import formatDateLong from '../../../../../shared/Moment/formatDateLong';
import React, { Component } from 'react';
import Radium from 'radium';
import { withTranslation } from 'react-i18next';

//const styles = {
//  wrapper: {
//    marginTop: '25px',
//    clear: 'both',
//    paddingBottom: '15px',
//  },
//  contentWrapper: {
//    width: '92%',
//    padding: '10px 4%',
//  },
//  link: {
//    color: '#444',
//    textDecoration: 'none',
//  },
//  textCentered: {
//    textAlign: 'center',
//    marginTop: 30,
//  },
//  table: {
//    borderCollapse: 'collapse',
//    fontSize: '0.9em',
//    maxWidth: '1000px',
//    margin: '0 auto',
//    minWidth: '500px',
//
//    [constants.breakpointMobile]: {
//      fontSize: '0.8em',
//    },
//    tbody: {
//      backgroundColor: 'white',
//    },
//    head: {
//      textAlign: 'left',
//      backgroundColor: [constants.yellow],
//      color: '#fff',
//    },
//    subsidyHead: {
//      textAlign: 'left',
//      backgroundColor: [constants.green],
//      color: '#fff',
//    },
//    line: {
//      width: '780',
//      borderBottom: '1px solid rgba(194, 194, 194, .17)',
//    },
//    description: {
//      fontWeight: '600',
//    },
//    priceFor: {
//      textAlign: 'right',
//      fontWeight: '600',
//      minWidth: '60px',
//    },
//    price: {
//      textAlign: 'right',
//      fontWeight: '600',
//      minWidth: '80px',
//    },
//    infoBottom: {
//      marginTop: '5px',
//      width: '700px',
//      margin: '0 auto',
//    },
//    infoBottomWrapper: {
//      marginTop: '20px',
//    },
//  },
//  termLine: {
//    color: [constants.mainBlue],
//    fontWeight: 600,
//  },
//  bigPhoto: {
//    width: '99%',
//    margin: '0 0.5% 0.5% 0.5%',
//    height: '380px',
//    overflow: 'hidden',
//    cursor: 'pointer',
//
//    [constants.breakpointTablet]: {
//      height: '500px',
//    },
//    [constants.breakpoint600]: {
//      height: '380px',
//    },
//    bigImg: {
//      width: '100%',
//      height: 'auto',
//      position: 'relative',
//      left: '50%',
//      top: '50%',
//      transform: 'translate(-50%, -50%)',
//
//      [constants.breakpointLargeTablet]: {
//        width: 'auto',
//        height: '100%',
//      },
//    },
//  },
//};

class DepartureTable extends Component {
  render() {
    const { departures } = this.props;
    const {t} = this.props;

    if (!this.checkDeparturesIntegrity(departures).length) {
      return (
        <>
          <p>{t('noTransportationInfoBus')}</p>
        </>
      );
    }

    return (
      <>
        {departures.map((departure) => this.renderDepartures(departure))}
        <hr className="margin--top-2 margin--bottom-2" />
        <div className="grid">
          <div className="cell align--center">
            <p className="color--primary--highdark margin--bottom-0">
              {t('subjectToChangeBus')}
              <br />
              <i>({t('payAttentionToInstructions')})</i>
            </p>
          </div>
        </div>
      </>
    );
  }

  renderDepartures(departure) {
    const {
      destinationTitle,
      destinationDefaultCity,
      destinationDestinationCity,
      destinationDate,
      destinationDepartureTime,
      destinationArrivalTime,
      arrivalDefaultCity,
      arrivalDestinationCity,
      arrivalDate,
      arrivalDepartureTime,
      arrivalArrivalTime,
      destinationTravelTime,
      arrivalTravelTime,
    } = departure;

    const departuresTableRowHeader = [
      {
        title: 'Autobusem ' + destinationDefaultCity + ' | ' + destinationTitle,
      },
    ]

    const departureTableRows = [
      {
        tableHeader: 'Datum TAM',
        tableColumn2: getDayOfWeek(destinationDate) + ' | ',
        tableColumn3: formatDateLong(destinationDate),
      },
      {
        tableHeader: 'Odjezd tam',
        tableColumn2: destinationDefaultCity + ' | ',
        tableColumn3: destinationDepartureTime + ' h',
      },
      {
        tableHeader: 'Příjezd',
        tableColumn2: destinationDestinationCity + ' | ',
        tableColumn3: destinationArrivalTime + ' h',
      },
      {
        tableHeader: 'Doba cesty',
        tableColumn2: '',
        tableColumn3: destinationTravelTime + ' h',
      },
    ];

    const arrivalTableRows = [
      {
        tableHeader: 'Datum ZPĚT',
        tableColumn2: getDayOfWeek(arrivalDate) + ' | ',
        tableColumn3: formatDateLong(arrivalDate),
      },
      {
        tableHeader: 'Odjezd zpět',
        tableColumn2: arrivalDefaultCity + ' | ',
        tableColumn3: arrivalDepartureTime + ' h',
      },
      {
        tableHeader: 'Příjezd zpět',
        tableColumn2: arrivalDestinationCity + ' | ',
        tableColumn3: arrivalArrivalTime + ' h',
      },
      {
        tableHeader: 'Doba cesty',
        tableColumn2: '',
        tableColumn3: arrivalTravelTime + ' h',
      },
    ];

    if (destinationTitle) {
      return (
        <>
          {departuresTableRowHeader.map((info) => this.renderTableHead(info.title))}

          <div className="grid gap">
            <div
              className="cell--12 cell--6:md"
            >
              {departureTableRows.map((info) =>
                this.renderTableRow(info.tableHeader, info.tableColumn2, info.tableColumn3),
              )}
            </div>

            <div
              className="cell--12 cell--6:md"
              >
              {arrivalTableRows.map((info) =>
                this.renderTableRow(info.tableHeader, info.tableColumn2, info.tableColumn3),
              )}
            </div>
          </div>
        </>
      );
    }

    return null;
  }

  checkDeparturesIntegrity(departures) {
    return departures.filter(({ destinationTitle }) => destinationTitle);
  }

  renderTableHead(tableHeader) {
    return (
      <>
        <div className="grid" key={tableHeader}>
          <div className="cell">
            <h3 className="title color--primary-highdark">{tableHeader}</h3>
          </div>
        </div>
      </>
    );
  }

  renderTableRow(tableHeader, tableColumn2, tableColumn3) {

    return (
      <div className={styles.table__row} key={tableHeader}>
        <div className="color--primary-highdark">{tableHeader}:</div>
        <div className="align--right">
          <strong className="color--primary">{tableColumn2}</strong>
          <strong className="color--primary">{tableColumn3}</strong>
        </div>
      </div>
    );
  }

  //renderPriceListHead() {
  //  const {t} = this.props;

  //  return (
  //    <tr >
  //      <th>{t('locationOfDepartureOutbound')}</th>
  //      <th>{t('locationOfArrivalOutbound')}</th>
  //      <th>{t('dateOutbound')}</th>
  //      <th>{t('timeOutbound')}</th>
  //      <th>{t('locationOfDepartureReturn')}</th>
  //      <th>{t('locationOfArrivalReturn')}</th>
  //      <th>{t('timeReturn')}</th>
  //      <th>{t('duration')}</th>
  //    </tr>
  //  );
  //}
}

DepartureTable.propTypes = {
  departures: PropTypes.any,
};

export default (withTranslation()(Radium(DepartureTable)));
