import constants from '../../../../../../styles/constants.js';
import Radium from 'radium';
import BusDepartureTable from './BusDepartureTable';
import PlaneDepartureTable from './PlaneDepartureTable';
import TourTitle from '../../TourTitle';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './departure.module.scss';
import { withTranslation } from 'react-i18next';

// const styles = {
//   wrapper: {
//     clear: 'both',
//     marginTop: '20px',
//     boxShadow: 'rgba(0, 0, 0, 0.13) 0px 0px 2px',
//   },
//   panelsContentWrapper: {
//    width: '92%',
//    padding: '2% 4% 0',
//    background: 'white',
//   },
//   link: {
//     color: '#444',
//     textDecoration: 'none',
//   },
//   table: {
//     borderCollapse: 'collapse',
//     fontSize: '0.9em',
//     maxWidth: '1000px',
//     margin: '0 auto',

//     [constants.breakpointMobile]: {
//       fontSize: '0.8em',
//     },
//     tbody: {
//       backgroundColor: 'white',
//     },
//     head: {
//       textAlign: 'left',
//       backgroundColor: [constants.yellow],
//       color: '#fff',
//     },
//     subsidyHead: {
//       textAlign: 'left',
//       backgroundColor: [constants.green],
//       color: '#fff',
//     },
//     line: {
//       width: '780',
//       borderBottom: '1px solid rgb(51, 51, 51)',
//     },
//     description: {
//       fontWeight: '600',
//     },
//     priceFor: {
//       textAlign: 'right',
//       fontWeight: '600',
//       minWidth: '60px',
//     },
//     price: {
//       textAlign: 'right',
//       fontWeight: '600',
//       minWidth: '80px',
//     },
//     infoBottom: {
//       marginTop: '5px',
//       width: '700px',
//       margin: '0 auto',
//     },
//     infoBottomWrapper: {
//       marginTop: '20px',
//     },
//   },
//   termLine: {
//     color: [constants.mainBlue],
//     fontWeight: 600,
//   },

//   bigPhotoBox: {
//     width: '100%',
//     maxWidth: '800px',
//     height: 'auto',
//     overflow: 'hidden',
//     margin: '0 auto',

//     bigPhoto: {
//       width: '99%',
//       margin: '0 0.5% 0.5% 0.5%',
//       height: '380px',
//       overflow: 'hidden',
//       cursor: 'pointer',

//       [constants.breakpointTablet]: {
//         height: '500px',
//       },
//       [constants.breakpoint600]: {
//         height: '380px',
//       },
//       bigImg: {
//         height: 'auto',
//         position: 'relative',
//         left: '50%',
//         transform: 'translateX(-50%)',
//         marginBottom: '40px',

//         [constants.breakpointTablet]: {
//           width: 'auto',
//           height: '100%',
//         },
//       },

//       planeImg: {
//         width: '100%',
//         maxWidth: '800px',
//         height: 'auto',
//         marginBottom: '20px',
//       },
//     },
//   },
// };
export const typeBus = 'bus';
export const typePlane = 'plane';

const sortedBus = ['Praha', 'Humpolec', 'Brno', 'Mikulov'];

class Departure extends Component {
  render() {
    const {t} = this.props;
    const {
      term: { tourDepartures },
    } = this.props;

    if (!tourDepartures) {
      return null;
    }

    const { departures, departureType } = tourDepartures;

    return (
      <div className={styles.departure}>
        <TourTitle>{t('transport')}</TourTitle>
        <div className={styles.table}>
          {this.renderContentByDepartureType(departureType, this.sortedDepartures(departures))}
        </div>
      </div>
    );
  }

  sortedDepartures(departures) {
    const sortedArray = sortedBus
      .map((title) => {
        return departures.find((departure) => departure.title === title);
      })
      .filter((x) => x != null);

    const otherDepartures = departures
      .map((departure) => {
        if (sortedArray.indexOf(departure) < 0) {
          return departure;
        }
      })
      .filter((x) => x != null);

    return [...sortedArray, ...otherDepartures];
  }

  renderContentByDepartureType(departureType, departures) {
    if (departureType === typePlane) {
      return (
        <>
          <PlaneDepartureTable departures={departures} />
        </>
      );
    }

    return (
      <>
        <BusDepartureTable departures={departures} />
      </>
    );
  }
}

Departure.propTypes = {
  term: PropTypes.any,
};

const mapStateToProps = () => ({});

export default (connect(mapStateToProps)(withTranslation()(Radium(Departure))));
