import React, { Component } from "react";
import Radium from "radium";
import { connect } from "react-redux";
import Eating from "./rowItems/Eating";
import AdditionalPrices from "./rowItems/AdditionalPrices";
import {
  formatCurrency,
} from "../calculations";
import {
  toggleGrant,
  setChildrenAgeByRenderIndex
} from "../../../../actions/Calculator/calculator";

import styles from './summary.module.scss';
import input from '../../../shared/forms/input.module.scss';
import { withTranslation } from 'react-i18next';

class Adults extends Component {
  render() {
    const { calculator: { settings: { occupation } } } = this.props;
    const { t } = this.props;

    const adults = [];

    for (let i = 0; i < occupation["adult"]; i++) {
      adults.push(this.renderAdultRow(i));
    }

    return <div>{adults}</div>;
  }

  renderAdultRow(index) {
    const { calculator: { occupation: { adult }, specialAdult } } = this.props;
    const { t } = this.props;

    return (
      <>
        <div className={styles.summary__item}>
          <div className="grid grid--middle gap">
            <div className="cell cell--12 cell--auto:md color--primary-highdark text--14">
              <strong>{index + 1}. {specialAdult ? "Osoba" : "Dospělá osoba"}</strong>
            </div>
            <div className="cell cell--12 cell--shrink:md">
              {this.renderDiscountPrice(
                adult[index].price,
                adult[index].discountedPrice
              )}
            </div>
          </div>
          <hr className="margin--top-1 margin--bottom-1" />
          <div className="grid">
            <div className="cell">
              {this.hiddingSubsidyCheckbox(index)}
              <AdditionalPrices type="adult" index={index} />
            </div>
          </div>
          <hr className="margin--top-1 margin--bottom-1" />
          <div className="grid">
            <div className="cell">
              <Eating type="adult" index={index} />
            </div>
          </div>
        </div>
      </>
    );
  }

  hiddingSubsidyCheckbox(index) {
    const {
      calculator: { occupation: { adult } },
      toggleGrant,
      isSubsidy
    } = this.props;
    const { t } = this.props;

    if (isSubsidy) {
      return (
        <>
        <div className={`${input.form__group} ${input['form__group--inline']}`}>
          <input
            type="checkbox"
            className={input.checkbox}
            checked={adult[index].grantUsed}
            onClick={() => toggleGrant("adult", index)}
            id="adultSubsidyEntitlement"
          />
          <label className={input.label} for="adultSubsidyEntitlement">
            {t('subsidyEntitlement')}
          </label>          
        </div>
        {/* <div className="subsidy-claim-wrapper">
          <label className="custom-checkbox subsidy">
            nárok na dotaci
            <input
              type="checkbox"
              checked={adult[index].grantUsed}
              onClick={() => toggleGrant("adult", index)}
            />
            <span className="checkmark" />
          </label>
        </div>         */}
        </>
      );
    }

    return null;
  }

  renderDiscountPrice(price, discountPrice) {
    if (!discountPrice) {
      return (
        <>        
          <div className="text--price color--secondary text--18">
            <strong>
              {formatCurrency(price)}
            </strong>
          </div>        
          {/* <div className="person-price-wrapper">
            <span className="label">cena</span>
            <span className="price">{formatCurrency(price)}</span>
          </div>           */}
        </>
      );
    }

    return (
      <div className="person-price-wrapper">
        <span className="label">cena</span>

        <span className="row">
          <span className="crossed">{formatCurrency(price)}</span>
          <span className="price"> {formatCurrency(discountPrice)}</span>
        </span>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  calculator: state.calculator,
  isSubsidy: state.calculator.settings.isSubsidy
});

const mapDispatchToProps = dispatch => ({
  toggleGrant: (occupation, index) => dispatch(toggleGrant(occupation, index)),
  setChildrenAgeByRenderIndex: (index, age) =>
    dispatch(setChildrenAgeByRenderIndex(index, age))
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Radium(Adults)));
