import {
  SET_INCLUDED_FILTER,
  SET_TOUR_FILTER,
  SELECT,
  CHECK_BOX,
  RESET_FILTER,
  CHECK_BOX_CHECK,
} from '../actions/TourList/tourFilter';

export const COUNTRIES_SORTED_BY_NAME = 'countrySortedByName';

const initialState = {
  apartment: [],
  countrySortedByName: [],
  season: [],
  transport: [],
  days: [],
  diet: [
    {
      code: '',
      selected: '',
      value: '',
    },
  ],
};

const filter = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOUR_FILTER:
      return action.result;

    case SET_INCLUDED_FILTER:
      return {
        ...state,
        [action.designation]: action.filter,
        filterFetched: true,
      };

    case CHECK_BOX:
      return {
        ...state,
        [action.designation]: [
          ...state[action.designation].map((toggled) => {
            if (toggled.code.toString() === action.code.toString()) {
              return { ...toggled, selected: !toggled.selected };
            }
            return toggled;
          }),
        ],
      };
    case CHECK_BOX_CHECK:
      return {
        ...state,
        [action.designation]: [
          ...state[action.designation].map((toggled) => {
            if (toggled.code.toString() === action.code.toString()) {
              return { ...toggled, selected: true };
            }else {
              return { ...toggled, selected: false };
            }
          }),
        ],
      };

    case SELECT:
      return {
        ...state,
        [action.designation]: [
          ...state[action.designation].map((toggled) => {
            if (toggled.code === action.code) {
              return { ...toggled, selected: !toggled.selected };
            }

            return { ...toggled, selected: false };
          }),
        ],
      };

    case RESET_FILTER:
      return {
        ...state,
        [action.designation]: [
          ...state[action.designation].map((toggled) => {
            return { ...toggled, selected: false };
          }),
        ],
      };

    default:
      return state;
  }
};

export default filter;
