import ToursListItem from '../ToursList/items/ToursListItem';
import ToursPaginator from '../ToursList/ToursPaginator';
import Radium from 'radium';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import constants from '../../../../styles/constants.js';
import getPages from '../../../shared/getPages';
import { loading } from '../../../../reducers/filteredTours';
import StyledCircleLoader from '../../../shared/Loading/StyledCircleLoader';
import { connect } from 'react-redux';
import TourListSettings from '../ToursList/components/TourListSettings';

const customs = {
  loading: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 120,
  },
};

class ToursList extends Component {
  state = {
    itemCount: 0,
    tripCount: 0,
    tripPages: [],
    items: [],
    fetched: false,
  };

  render() {
    const {
      paginator: { offerPage },
      specialTourList: { tours, toursCount, dataLoading },
      type,
    } = this.props;
    const { pages } = getPages(toursCount);

    return (
      <>
        <section className="section section--medium">
          <div className="container">
            <div className="grid margin--bottom-2 margin--bottom-3:md">
              <div className="cell--12 cell--auto:md">
                <h2 className="title margin--bottom-0:md">
                  {tours.length} možností ubytování
                </h2>
              </div>
              <div className="cell--12 cell--shrink:md">
                <div style={{display:'flex', flexDirection: 'row-reverse', justifyContent: 'flex-end'}}>
                  <TourListSettings/>
                </div>
              </div>
            </div>
            <div className="grid gap">
              {dataLoading === loading ? (
                <>
                {/* <div style={customs.loadingWrapper}>
                  <StyledCircleLoader />
                </div>                 */}
                </>
              ) : (
                <>
                  {tours.map((item) => this.renderTour(item))}
                </>
              )}
            </div>
            <ToursPaginator
              tripCount={toursCount}
              pages={pages}
              type={type}
              actualPage={offerPage}
              paginatorType={'offer'}
              specialOffer={1}
            />
          </div>
        </section>      
        {/* <ToursPaginator
          tripCount={toursCount}
          pages={pages}
          type={type}
          actualPage={offerPage}
          paginatorType={'offer'}
          specialOffer={1}
        />

        {dataLoading === loading ? (
          <div style={styles.loading}>
            <StyledCircleLoader />
          </div>
        ) : (
          tours.map((item) => this.renderTour(item))
        )}

        <ToursPaginator
          tripCount={toursCount}
          pages={pages}
          type={type}
          actualPage={offerPage}
          paginatorType={'offer'}
          specialOffer={1}
        /> */}
      </>
    );
  }

  renderTour(item) {
    const { title, location, description, imgSrc, terms, idTour } = item;
    const { type } = this.props;

    return (
      <ToursListItem
        id={idTour}
        title={title}
        destination={location}
        description={description}
        image={imgSrc}
        tour={item}
        termList={terms}
        stickerPriority={type}
      />
    );
  }
}

ToursList.propTypes = {
  paginator: PropTypes.any,
  type: PropTypes.any,
  specialTourList: PropTypes.any,
};

const mapStateToProps = (state) => ({
  filter: state.filter,
  paginator: state.paginator,
  specialTourList: state.specialTourList,
});

export default connect(mapStateToProps)(Radium(ToursList));
