import constants from '../../../../../styles/constants.js';
import DatePicker from 'material-ui/DatePicker';
import Radium from 'radium';
import { connect } from 'react-redux';
import { saveFilter } from '../../../../../actions/TourList/filter';
import { toggleTiles } from '../../../../../actions/TourList/paginator';
import createFilterRequest from '../../../../shared/RequestCreator/createFilterRequest';
import { fetchFilteredList } from '../../../../../actions/TourList/fetchTourList';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import arrowDown from '../../../../../img/down-arrow.svg';
import components from './components.module.scss';
import input from '../../../../shared/forms/input.module.scss';
import sprite from '../../../../../img/icons/sprite.svg';

const styles = {
  datepicker: {
    text: {
      color: '#000816',
      fontSize: '0.875rem',
      cursor: 'pointer',
      width: '100%',
      maxWidth: '100%',
    },
  },
};

class FromToDatePicker extends Component {
  componentDidMount() {
    const {
      filter: { dateTo },
    } = this.props;

    if (dateTo) {
      this.setState({ dateTo });
    }
  }

  componentWillReceiveProps(props) {
    const {
      filter: { dateTo, dateFrom },
    } = props;

    if (dateTo && dateFrom > dateTo) {
      const {
        saveFilter,
        fetchFilteredList,
        filter,
        paginator: { itemsPerPage, minCost, maxCost, page },
      } = this.props;
      const optional = { changedDateFrom: dateTo };

      saveFilter(dateTo, 'dateFrom');
      fetchFilteredList(
        createFilterRequest(filter, page, itemsPerPage, minCost, maxCost, optional),
      );
    }
  }

  render() {
    const {
      filter: { dateFrom },
    } = this.props;
    const { dateTo } = this.state;

    return (
      <>
        <div className={input.datepicker}>
          <div className={input.datepicker__field}>
            <DatePicker
              value={dateFrom}
              minDate={new Date()}
              onChange={(x, event) => this.setDate(event, 'from')}
              hintText="Odjezd"
              mode="portrait"
              key="from"
              className={input.datepicker__input}
              cancelLabel="Zrušit"
              okLabel="Potvrdit"
              DateTimeFormat={Intl.DateTimeFormat}
              formatDate={this.formatDateLong}
              locale="cs"
              autoOk="true"
              textFieldStyle={styles.datepicker.text}
            />
            {dateFrom && (
              <i
                className={input.datepicker__reset}
                onClick={() => this.setDate(null, 'from')}
              >
                <svg className="icon" role="presentation" width="16" height="16">
                  <use xlinkHref={sprite + '#sprite-cross'}/>
                </svg>        
              </i>
            )}
          </div>
          <div className={input.datepicker__field} onClick={() => this.setDateToValue()}>
            <DatePicker
              value={dateTo}
              minDate={dateFrom || new Date()}
              onChange={(x, event) => this.setDate(event, 'to')}
              onDismiss={() => this.setDateToValue(false)}
              hintText="Návrat"
              mode="portrait"
              key="from"
              cancelLabel="Zrušit"
              className={input.datepicker__input}
              okLabel="Potvrdit"
              DateTimeFormat={Intl.DateTimeFormat}
              formatDate={this.formatDateLong}
              locale="cs"
              autoOk="true"
              textFieldStyle={styles.datepicker.text}
            />
            {dateTo && (
              <i
                className={input.datepicker__reset}
                onClick={() => this.setDate(null, 'to')}
              >
                <svg className="icon" role="presentation" width="16" height="16">
                  <use xlinkHref={sprite + '#sprite-cross'}/>
                </svg>        
              </i>
            )}            
          </div>
        </div>
      </>
    );
  }

  setDateToValue(setNew = true) {
    const {
      filter: { dateFrom, dateTo },
    } = this.props;

    if (dateFrom && !dateTo) {
      this.setState({ dateTo: setNew ? dateFrom : null });
    }
  }

  setDate(event, state) {
    const {
      saveFilter,
      fetchFilteredList,
      toggleTiles,
      filter,
      paginator: { itemsPerPage, minCost, maxCost, page },
    } = this.props;
    const optional = {};
    if (state === 'to') {
      this.setState({ dateTo: event });
      saveFilter(event, 'dateTo');
      optional.changedDateTo = event;
    } else {
      saveFilter(event, 'dateFrom');
      optional.changedDateFrom = event;
    }

    const sortByDate = { value: 'dle datumu', code: 'date', type: 'ASC' };

    if (!filter.sortBy) {
      optional.changedSortBy = sortByDate;
      optional.changedShowTermList = true;
      saveFilter(sortByDate, 'sortBy');
      saveFilter(true, 'showTermList');
    }

    fetchFilteredList(createFilterRequest(filter, page, itemsPerPage, minCost, maxCost, optional));
    toggleTiles(false);
  }
}

FromToDatePicker.propTypes = {
  saveFilter: PropTypes.any,
  fetchFilteredList: PropTypes.any,
  toggleTiles: PropTypes.any,
  filter: PropTypes.any,
  paginator: PropTypes.any,
};

const mapStateToProps = (state) => ({
  filter: state.filter,
  filteredTours: state.filteredTours,
  paginator: state.paginator,
});

const mapDispatchToProps = (dispatch) => ({
  toggleTiles: (nextState) => dispatch(toggleTiles(nextState)),
  saveFilter: (filter, designation) => dispatch(saveFilter(filter, designation)),
  fetchFilteredList: (filters) => dispatch(fetchFilteredList(filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(FromToDatePicker));
