import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import Infants from './Infants';
import Adults from './Adults';
import Children from './Children';
import RoomAdditionalPrices from './RoomAdditionalPrices';
import {
  toggleGrant,
  setChildrenAgeByRenderIndex,
} from '../../../../actions/Calculator/calculator';

import styles from './summary.module.scss';

class OccupantSum extends Component {
  render() {
    const {
      calculator: {
        settings: {
          occupation: { adult, children },
        },
      },
    } = this.props;

    if (!adult && !children) {
      return null;
    }

    return (
      <div className={styles.summary}>
        <Adults />

        <Children />

        <Infants />

        <RoomAdditionalPrices />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  calculator: state.calculator,
});

const mapDispatchToProps = (dispatch) => ({
  toggleGrant: (occupation, index) => dispatch(toggleGrant(occupation, index)),
  setChildrenAgeByRenderIndex: (index, age) => dispatch(setChildrenAgeByRenderIndex(index, age)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Radium(OccupantSum));
