import cookie from 'react-cookie';
import { employee, szEmployee, visitor } from '../../actions/HP/defaultTourlist';
import { hashHistory } from 'react-router';
import styles from '../Header/header.module.scss';

import React, { Component } from 'react';
import Radium from 'radium';

const titlesBelowMedium = {
    employee: 'Zaměstnanec ČD',
    szEmployee: 'Zaměstnanec SZ',
    visitor: 'Běžný uživatel',
};

const titlesRegular = {
    employee: 'Zaměstnanec skupiny ČD',
    szEmployee: 'Zaměstnanec skupiny SZ',
    visitor: 'Běžný uživatel',
};

const userTypes = [employee, szEmployee, visitor];

class SectionType extends Component {
    render() {
        return (
            <div className={styles.header__switch}>
                {userTypes.map((type, index) => (
                    <a
                        onClick={() => this.changeUser(type)}
                        className={'link color--white text--semi ' + this.getClassByActiveState(type)}
                    >
                        {this.getTitlesByWidth(type)}
                    </a>
                ))}
            </div>
        );
    }

    changeUser(user) {
        const {
            location: { pathname },
        } = this.props;

        const isCatalogue = pathname.indexOf('katalog') > 0;
        const isDetail = pathname.indexOf('zajezdy/detail/') > 0;

        cookie.save('userChangedFromMenu', false);
        cookie.save('user', user);
        cookie.save('ignoreUrlUser', true);

        if (isCatalogue || isDetail) {
            isCatalogue && hashHistory.push('/katalog');
        }

        window.location.reload();
    }

    getClassByActiveState(type) {
        if (cookie.load('user') === type) {
            return styles['is-active'];
        }

        return '';
    }

    getTitlesByWidth(type) {
        if (window.innerWidth < 760) {
            return titlesBelowMedium[type];
        }

        return titlesRegular[type];
    }
}

export default Radium(SectionType);
