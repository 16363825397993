//import OrangeButton from '../../shared/OrangeButton';
import PropTypes from 'prop-types';
import TogetherTravelingForm from './TogetherTravellingForm';
import { connect } from 'react-redux';
import { toggleTogetherTravelingForm } from '../../../actions/TogetherTraveling/passengerFilter';
import React, { Component } from 'react';
import Radium from 'radium';
import { withTranslation } from 'react-i18next';

const styles = {
  table: {
    width: '100%',
    fontSize: '0.85em',

    line: {
      boxShadow: '0px 1px 0px #e7e7e7',
    },

    head: {
      item: {
        fontWeight: '500',
        padding: '10px',
        textAlign: 'left',
      },
    },
    body: {
      item: {
        padding: '10px',
        textAlign: 'left',
      },
    },
  },
};

export const togetherTravelingReply = 'adline';

class AdLine extends Component {
  state = {
    toggle: false,
    opened: false,
    fetched: false,
  };

  render() {
    const {t} = this.props
    const {
      dateFrom,
      dateTo,
      item: { country, sex, age, email, note },
    } = this.props;
    const {
      togetherTravelingFormStatus: { advertisingMessageReplyOpened },
    } = this.props;
    const { opened } = this.state;

    if (!advertisingMessageReplyOpened && opened) {
      this.setState({ opened: false });
    }

    return (
      <tr style={styles.table.line}>
        <td style={styles.table.body.item}>
          {dateFrom}
          <br />
          {dateTo}
        </td>
        {this.renderBodyItem(country)}
        {this.renderBodyItem(sex)}
        {this.renderBodyItem(age)}
        {this.renderBodyItem(note)}
        <td style={styles.table.body.item}>
          <button className="button button--small hollow" onClick={() => this.open()} type="normal" key={email}>
            {t('reply')}
          </button>

          {this.renderTravelingForm(advertisingMessageReplyOpened)}
        </td>
      </tr>
    );
  }

  renderTravelingForm(propsOpenState) {
    const {
      dateFrom,
      dateTo,
      item: { country, email, id },
    } = this.props;
    const { from, opened } = this.state;

    if (propsOpenState && opened) {
      return (
        <TogetherTravelingForm
          id={id}
          dateFrom={dateFrom}
          dateTo={dateTo}
          country={country}
          email={email}
          opened={opened}
          toggle={from}
          sourceOfRequest={togetherTravelingReply}
        />
      );
    }
    return null;
  }

  open() {
    const { toggleTogetherTravelingForm } = this.props;

    this.setState({ opened: true });
    toggleTogetherTravelingForm(togetherTravelingReply, true);
  }

  renderBodyItem(title) {
    return <td style={styles.table.body.item}>{title}</td>;
  }
}

AdLine.propTypes = {
  item: PropTypes.object.isRequired,
  dateFrom: PropTypes.string.isRequired,
  dateTo: PropTypes.string.isRequired,
  togetherTravelingFormStatus: PropTypes.any,
  toggleTogetherTravelingForm: PropTypes.any,
};

const mapStateToProps = (state) => ({
  togetherTravelingFormStatus: state.togetherTravelingFormStatus,
});

const mapDispatchToProps = (dispatch) => ({
  toggleTogetherTravelingForm: (formType, isFormOpened) =>
    dispatch(toggleTogetherTravelingForm(formType, isFormOpened)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Radium(AdLine)));
