import MultiCheckbox from './components/MultiCheckbox';
import FromToDatePicker from './components/FromToDatePicker';
import CountryDependentCheckboxfrom from './components/CountryDependentCheckbox';
import Radium from 'radium';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { COUNTRIES_SORTED_BY_NAME } from '../../../../reducers/tourFilter';
import { closeTabs, selecting, toggle } from '../../../../actions/TourList/filterOpenStatus';
import { saveFilter } from '../../../../actions/TourList/filter';
import PropTypes from 'prop-types';

class ToursFilter extends Component {
  componentDidMount() {
    const { closeTabs } = this.props;

    closeTabs();
  }

  componentWillReceiveProps(props) {
    const { selectedLocations, tourFilter } = props;
    if (tourFilter && selectedLocations) {
      this.setState({ fetched: true });
    }
  }

  render() {
    const { selectedLocations } = this.props;
    const {
      tourFilter: { countrySortedByName, transport, diet, days, capacity },
      fetchState: { filterFetched },
    } = this.props;

    const { fetched } = this.state;

    if (filterFetched && countrySortedByName[0].code !== '') {
      countrySortedByName.unshift({ code: '', value: '---' })
    }

    return (
      <div className="grid gap margin--bottom-2">
        {fetched && (
          <>
          <div className="cell cell--12 cell--6:md">
            <MultiCheckbox
              data={filterFetched ? countrySortedByName : []}
              type={COUNTRIES_SORTED_BY_NAME}
              box="textbox"
              defaultText="Kam pojedete?"
              selectIcon="marker"
            />
          </div>
          <div className="cell cell--12 cell--6:md">
            <CountryDependentCheckboxfrom
              data={filterFetched ? selectedLocations : []}
              type="location"
              defaultText="Oblast"
              selectIcon="marker"
            />
          </div>    
          </>      
        )}
        {fetched && (
          <div className="cell cell--12 cell--6:md">
            <MultiCheckbox
              data={filterFetched ? transport : []}
              type="transport"
              box="checkbox"
              defaultText="Jak pojedete?"
              selectIcon="plane"
            />
          </div>
        )}   
        {fetched && (
          <div className="cell cell--12 cell--6:md">
            <MultiCheckbox
              data={filterFetched ? diet : []}
              type="diet"
              box="checkbox"
              defaultText="Stravování"
              selectIcon="food"
            />
          </div>
        )}                 
        <div className="cell cell--12">
          <hr className='margin--bottom-2' />
          {fetched && <FromToDatePicker style="inputBoxShorter" />}
        </div>


        {/* {fetched && (
          <div className="col-4">
            <CountryDependentCheckboxfrom
              data={filterFetched ? selectedLocations : []}
              type="location"
              defaultText="oblast"
            />
          </div>
        )} */}

        {/* {fetched && (
          <div className="col-3">
            <MultiCheckbox
              data={filterFetched ? days : []}
              type="days"
              box="checkbox"
              defaultText="doba zájezdu"
              style="inputBoxShorter"
            />
          </div>
        )} */}

        {/* {fetched && (
          <div className="col-3">
            <MultiCheckbox
              data={filterFetched ? capacity : []}
              type="capacity"
              box="checkbox"
              defaultText="kapacita"
              style="inputBoxShorter"
            />
          </div>
        )} */}
      </div>
    );
  }
}

ToursFilter.propTypes = {
  closeTabs: PropTypes.any,
  selectedLocations: PropTypes.any,
  fetchState: PropTypes.any,
  tourFilter: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => ({
  toggle: (designation) => dispatch(toggle(designation)),
  selecting: () => dispatch(selecting()),
  saveFilter: (filter) => dispatch(saveFilter(filter)),
  closeTabs: () => dispatch(closeTabs()),
});

const mapStateToProps = (state) => ({
  filter: state.filter,
  defaultTourListFilter: state.defaultTourListFilter,
  selectedLocations: state.selectedLocations,
  filterOpen: state.filterOpen,
  tourList: state.tourList,
  fetchState: state.fetchState,
  tourFilter: state.tourFilter,
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(ToursFilter));
