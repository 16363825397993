import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import './Reservation.css';
import { hashHistory, Link } from 'react-router';
import OrangeButton from '../../../shared/OrangeButton';
import { zajezdyDetail } from '../../../../utils/routeConfig';
import { sendReservation, getDataForRequest } from '../../../../actions/Calculator/calculator';
import { getRequestData } from '../dummyReservation';
import { formatCurrency } from '../calculations';
import formatDateShort from '../../../shared/Moment/formatDateShort';
import formatDateLong from '../../../shared/Moment/formatDateLong';
import TotalPrice from '../Summary/TotalPrice';
import BottomText from './BottomText';
import { getIsSubsidy } from './UsersDetail';
import StyledCircleLoader from '../../../shared/Loading/StyledCircleLoader';
import Stepper from './Stepper.jsx';
import sprite from '../../../../img/icons/sprite.svg';
import { withTranslation } from 'react-i18next';
import cms from '../../CMS/cms.module.scss';

const companies = {
  cdCargo: 'ČD Cargo',
  cd: 'ČD',
  railwayAdministration: 'Správa železnic',
};

const specifications = {
  employee: 'Zaměstnanec',
  familyMember: 'Rodinný příslušník',
  children: 'Dítě',
  pensioner: 'Důchodce',
  other: 'Cizí',
};

class ReservationSummary extends Component {
  state = {};

  render() {
    const { idDetail, goSecond, goFirst } = this.props;
    const {
      customer,
      reservation: { settings, occupation },
    } = getDataForRequest(this.props, idDetail);
    const { t } = this.props;
    const { step } = this.props;

    return (
      <>
        <section className="section">
          <div className="container">
            <div className="grid">
              <div className="cell">
                <div className={cms.block}>
                <div className="grid grid--middle margin--bottom-2 margin--bottom-3:md">
                    <div className="cell cell--auto:md">
                      <h2 className="title color--primary-highdark margin--bottom-0:md">{t('summary')}</h2>
                    </div>
                    <div className="cell cell--shrink:md align--center">
                      <Stepper activeStep={step} />
                    </div>
                  </div>                      

                  {this.renderLoader()}
                  <div className="grid">
                    <div className="cell">
                      <div className="table-scroll margin--bottom-2">
                        <table className="table table--compare">
                          <tbody>
                            {this.renderHotelInfo()}

                            {this.renderSettings(settings)}

                            {this.renderCustomer(customer)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="cell cell--12  margin--bottom-24">
                      {this.renderOccupation(occupation)}
                    </div>
                    <div className="cell cell--12  margin--bottom-24">{this.renderButtons()}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  renderLoader() {
    const { sendingReservation } = this.state;

    if (!sendingReservation) {
      return null;
    }

    return <StyledCircleLoader />;
  }

  renderOccupation(occupation) {
    const { adult, children, infantsSettings, infants } = occupation;
    const { isSubsidyLocal } = this.props;
    const { t } = this.props;

    return (
      <div style={{ maxWidth: '80rem' }} className="align--center">
        <div className="table-scroll">
          <table className="table--reservation">
            <thead className="color--primary">
              <tr>
                <th>{t('name2')}</th>
                <th>{t('birth')}</th>
                {isSubsidyLocal && <th>{t('specification')}</th>}
                <th>{t('discount')}</th>
                <th>{t('surcharges')}</th>
                <th className="align--right">{t('price')}</th>
              </tr>
            </thead>

            <tbody>
              {adult.concat(children).map((entity) => this.renderCustomerRow(entity))}
              {infantsSettings.map((entity) => this.renderInfantRow(entity, infants[0].price))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  renderInfantRow(customer, price) {
    const { isSubsidyLocal } = this.props;
    const { t } = this.props;

    return (
      <tr>
        <td>
          {customer.title ? customer.title + '.' : ''} {customer.name} {customer.surname}
        </td>
        <td>{customer.birthDate}</td>
        {isSubsidyLocal && <td>{t('infant')}</td>}
        <td></td>
        <td></td>
        <td className="align--right">{formatCurrency(price)}</td>
      </tr>
    );
  }

  renderCustomerRow(customer) {
    const { basicInfo, discount, surcharge } = customer;
    const { isSubsidyLocal } = this.props;
    const { discounts, surcharges } = this.getAdditionalPrices(discount, surcharge, true);

    return (
      <tr>
        <td>
          {basicInfo.title ? basicInfo.title + '.' : ''} {basicInfo.name} {basicInfo.surname}
        </td>
        <td>{basicInfo.birthDate}</td>
        {isSubsidyLocal && <td>{specifications[basicInfo.specification]}</td>}
        <td>{discounts}</td>
        <td>{surcharges}</td>
        <td className="text--price color--secondary align--right">
          <strong>{formatCurrency(customer.finalPrice)}</strong>
        </td>
      </tr>
    );
  }

  renderSettings(settings) {
    const { discount, surcharge, eating } = settings;
    const { discounts, surcharges } = this.getAdditionalPrices(discount, surcharge, false, true);
    const { t } = this.props;

    return (
      <>
        <tr>
          <td className="color--white">{t('diet')}</td>
          <td>{eating ? eating.title + ' (' + formatCurrency(eating.price) + ')' : '-'}</td>
        </tr>
        <tr>
          <td className="color--white">{t('discount')}</td>
          <td>{discounts}</td>
        </tr>
        <tr>
          <td className="color--white">{t('surcharges')}</td>
          <td>{surcharges}</td>
        </tr>
      </>
    );
  }

  renderHotelInfo() {
    const {
      calculator: {
        data: {
          hotel: { hotel, country, location, from, till },
        },
      },
    } = this.props;
    const { t } = this.props;

    return (
      <>
        <tr>
          <td className="color--white">{t('hotel')}</td>
          <td>{hotel}</td>
        </tr>
        <tr>
          <td className="color--white">{t('country')}</td>
          <td>{country}</td>
        </tr>
        <tr>
          <td className="color--white">{t('location')}</td>
          <td>{location}</td>
        </tr>
        <tr>
          <td className="color--white">{t('term')}</td>
          <td>
            <span>{formatDateShort(from)} - </span>
            <span>{formatDateLong(till)}</span>
          </td>
        </tr>
      </>
    );
  }

  getAdditionalPrices(discount, surcharge, hasToBeChecked = false, forRoom = false) {
    const discounts = this.getPriceFormatted(discount, hasToBeChecked, forRoom);
    const surcharges = this.getPriceFormatted(surcharge, hasToBeChecked, forRoom);

    return { discounts, surcharges };
  }

  getPriceFormatted(discount, hasToBeChecked, forRoom) {
    return discount
      .map((discountEntity) => {
        if (!discountEntity) {
          return null;
        }

        if (hasToBeChecked && !discountEntity.checked) {
          return null;
        }

        if (forRoom && discountEntity.type !== 1) {
          return null;
        }

        return discountEntity.title + ' (' + formatCurrency(discountEntity.price) + ')';
      })
      .filter((x) => x != null)
      .join(', ');
  }

  renderCustomer(customer) {
    const { t } = this.props;

    return (
      <>

        <tr>
          <td className="color--white">
            {t('company')}
          </td>

          <td className="color--white">
            {companies[customer.company]}
          </td>
        </tr>

        <tr>
          <td className="color--white">
            {t('name2')}
          </td>

          <td>
              {customer.title ? customer.title + '.' : ''} {customer.name} {customer.surname}
          </td>
        </tr>
        <tr>
          <td className="color--white">
            {t('mail')}
          </td>
          <td>
            {customer.email}
          </td>
        </tr>
        <tr>
          <td className="color--white">
            {t('phone')}
          </td>
          <td>
            {customer.phone}
          </td>
        </tr>
        <tr>
          <td className="color--white">
            {t('street')}
          </td>
          <td>
            {customer.street} {customer.streetNum}
          </td>
        </tr>
        <tr>
          <td className="color--white">
            {t('zipCode')}
          </td>
          <td>
            {customer.zipCode}
          </td>
        </tr>
        <tr>
          <td className="color--white">
            {t('note')}
          </td>
          <td >
            {customer.note}
          </td>
        </tr>
      </>
    );
  }

  renderButtons() {
    const { sendingReservation } = this.state;
    const { closeModal } = this.props;
    const { t } = this.props;

    return (
      <>
        <TotalPrice />

        <hr className="margin--top-2 margin--bottom-2" />

        <div className="grid gap">
          <div className="cell cell--12 cell--6:md margin--bottom-24 align--center align--left:md">
            <div
              className=" link link--underline text--14 color--primary-highdark"
              onClick={() => goSecond()}
            >
              <strong>{t('backToPrevious')}</strong>
            </div>
          </div>
          <div className="cell cell--12 cell--6:md align--center align--right:md margin--bottom-24">
            <button
              disabled={sendingReservation}
              dataTooltip="Vyplňte všechna pole označná znakem * a zaškrtněte souhlas s podmínkami"
              onClick={() => this.handleSendReservation()}
              className="button button--primary"
            >
              {t('finishReservation')}
            </button>
          </div>
          <div className="cell cell--12">
            <p className="color--primary-highdark text--14 margin--bottom-0">
              <i>{t('backToCalculatorNote')}</i>
            </p>
          </div>
        </div>
      </>
    );
  }

  handleSendReservation() {
    const { idDetail, sendReservation } = this.props;

    this.setState({ sendingReservation: true });
    sendReservation(this.props, idDetail);
  }
}

const mapStateToProps = (state) => {
  const {
    calculator: {
      occupation: { adult, children },
    },
  } = state;

  return {
    reservation: state.reservation,
    calculator: state.calculator,
    isSubsidy: state.calculator.settings.isSubsidy,
    isSubsidyLocal: getIsSubsidy(adult, children),
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendReservation: (props, idDetail) => dispatch(sendReservation(props, idDetail)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Radium(ReservationSummary)));
