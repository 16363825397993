import constants from '../../../../../styles/constants.js';
import formatDateLong from '../../../../shared/Moment/formatDateLong';
import formatDateShort from '../../../../shared/Moment/formatDateShort';
import getInstructionsWithImg from '../../../../shared/TourDetail/getInstructionsWithImg';
import { fetchTourDetail } from '../../../../../actions/TourDetail/fetchTourDetail';
import { generatePDF } from '../../../../../actions/PDF/generatePDF';
import { printInstructions } from '../../../../../utils/routeConfig';
import instructionsTemplate from '../../../../shared/TourDetail/tourInstructionsTemplate';
import base64 from 'base-64';
import utf8 from 'utf8';
import OrangeButton from '../../../../shared/OrangeButton';
import Radium from 'radium';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StyledCircleLoader from '../../../../shared/Loading/StyledCircleLoader';
import { connect } from 'react-redux';
import { hashHistory } from "react-router";
import styles from './Informations/description.module.scss';

const customs = {
  wrapperPrint: {
    marginTop: '100px',
  },
  printingHeader: {
    marginBottom: '40px',
    borderBottom: 'solid 1px #d6d6d6',
    paddingBottom: '10px',
    position: 'relative',
  },
  link: {
    color: '#444',
    textDecoration: 'none',
  },
  loading: {
    display: 'block',
    width: '200px',
    margin: '0 auto',
    justifyContent: 'center',
    marginTop: '120px',
  },
  logo: {
    textAlign: 'center',
    width: '200px',
    position: 'absolute',
    right: '0',
    top: '-80px',
  },
};
const printing = 'printing';

class Instructions extends Component {
  state = {
    fetched: false,
  };

  componentDidMount() {
    this.initialize();
  }

  componentWillReceiveProps(props) {
    const { tourDetail, fetchTourDetail } = props;
    const { params } = this.props;
    const { idDetail, fetched } = this.state;
    const currentLocation = hashHistory.getCurrentLocation();
    const tab = currentLocation.query.tab;

    let loaded = fetched;

    if (idDetail !== params.idDetail && typeof paramsIdDetail !== 'undefined' && !fetched) {
      fetchTourDetail(props.params.idDetail);
    }

    if (window.location.href.indexOf('instructions') >= 0 && tab !== "instructions") {
      this.setState({ generating: true });
    }

    if (JSON.stringify(tourDetail) !== JSON.stringify({})) {
      this.setState({ fetched: true });
      loaded = true;
    }

    if (loaded && params.status === printing) {
      setTimeout(() => window.print(), 1000);
    }
  }

  render() {
    const {
      tourDetail: { instructions, id, hotelTitle },
    } = this.props;
    const { generating, fetched } = this.state;

    if (!fetched) {
      return (
        <>
          {/* <div style={customs.loading}>
            <StyledCircleLoader />
          </div>         */}
        </>
      );
    }

    const htmlInstructions = getInstructionsWithImg(instructions, false);

    if (instructions) {
      if (generating) {
        return (
          <div className="instructions-wrapper" id="printed" style={customs.wrapperPrint}>
            {this.renderHeader()}
            <p
              className="instructions-print-info"
              dangerouslySetInnerHTML={{ __html: htmlInstructions }}
            />
          </div>
        );
      } else {
        return (
          <div className={styles.description}>
            <div className="margin--bottom-24">
              {this.renderPrintButton(id, hotelTitle)}
            </div>
            <div dangerouslySetInnerHTML={{ __html: htmlInstructions }} id="printed" />
          </div>

        );
      }
    }

    return this.noInstructionsMessage();
  }

  noInstructionsMessage() {
    return <p><strong className="color--primary">Zájezd nemá uvedené pokyny</strong></p>;
  }

  renderHeader() {
    const {
      tourDetail: { country, title, location, dateFrom, dateTo },
    } = this.props;
    return (
      <div style={customs.printingHeader}>
        <img style={customs.logo} src={'//www.cdtravel.cz/www/images/cd-travel_logo.svg'} />

        <span>
          <b>Destinace:</b> {country + ' ' + location + ' ' + title}
        </span>
      </div>
    );
  }

  renderPrintButton(id) {
    return (
      <OrangeButton
        onClick={() => this.generatePDF(id)}
        target="_blank"
        icon={true}
      >
        PDF ke stažení zde
      </OrangeButton>
    );
  }

  strip(html) {
    var tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  generatePDF(id) {
    const {
    } = this.props;
    const {
      generatePDF,
      tourDetail: { hotelTitle },
    } = this.props;

    generatePDF(id, hotelTitle + ' instrukce');
    this.setState({ generated: true });
  }

  initialize() {
    const { fetchTourDetail, dataFetched } = this.props;

    if (!dataFetched) {
      fetchTourDetail(this.props.params.idDetail);
    } else {
      this.setState({ fetched: true });
    }
  }
}

Instructions.propTypes = {
  tourDetail: PropTypes.any,
  generatePDF: PropTypes.any,
  params: PropTypes.any,
  fetchTourDetail: PropTypes.any,
  dataFetched: PropTypes.any,
  location: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTourDetail: (id) => dispatch(fetchTourDetail(id)),
  generatePDF: (params, name) => dispatch(generatePDF(params, name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(Instructions));
