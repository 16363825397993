import {
  fetchCalculatorSettings,
  resetCalculator,
} from '../../../../actions/Calculator/calculator';
import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import leftArrow from '../../../../img/icons/back-arrow.png';
import { Link } from 'react-router';
import { visitor } from '../../../../actions/HP/defaultTourlist';
import RoomPicker from './../Pickers/RoomPicker';
import EatingPicker from './../Pickers/EatingPicker';
import PeoplePicker from './../Pickers/PeoplePicker';
import SurchargesPicker from './../Pickers/SurchargesPicker';
import DiscountPicker from './../Pickers/DiscountPicker';
import OccupantSummary from './../Summary/OccupantSummary';
import Icons from './../Icons';
import TotalPrice from './../Summary/TotalPrice';
import InfantsPicker, { getPeopleInOrder } from './../Pickers/InfantsPicker';
// import './../Calculator.css';
import formatDateShort from '../../../shared/Moment/formatDateShort';
import formatDateLong from '../../../shared/Moment/formatDateLong';
import ReactTooltip from 'react-tooltip';
import TransportPicker, { getDeparturePlaces } from './../Pickers/TransportPicker';
import constants from '../../../../styles/constants';
import hero from '../../Tours/ToursList/hero.module.scss';
import heroPicture from '../../../../img/heros/hero-lists.webp';
import cms from '../../CMS/cms.module.scss';
import { withTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import FormGroup from '../../../shared/forms/FormGroup';
import Stepper from './Stepper.jsx';

const isSelectedCustomersOkByCombinations = (props) => {
  const {
    calculator: {
      settings: { customerCombination, occupation },
      data: { customerCombinations },
    },
  } = props;

  if (!customerCombination) {
    return null;
  }

  const combinationExist = customerCombinations[customerCombination].findIndex((combination) => {
    return (
      combination.childCount === (occupation['children'] || 0) &&
      combination.adultsCount === (occupation['adult'] || 0)
    );
  });

  return combinationExist > -1;
};

class CalculatorStep1 extends Component {
  componentDidMount() {}

  render() {
    const {
      calculator: { data },
    } = this.props;
    const { renderReservation } = this.state;
    const { t } = this.props;

    if (!Object.keys(data).length) {
      return null;
    }

    const {
      params,
      calculator: {
        data: {
          hotel: { hotel, country, location, from, till },
        },
        specialAdult,
      },
    } = this.props;
    const combinationAllowed = isSelectedCustomersOkByCombinations(this.props);
    const maxOccupantsOk = getPeopleInOrder(this.props) <= 6;
    const { step } = this.state;

    return (
      <>
        <div className="container">
          <div className="grid">
            <div className="cell margin--bottom-3">
              <div className={cms.block}>
                  <div className="grid grid--middle margin--bottom-2 margin--bottom-3:md">
                    <div className="cell cell--auto:md">
                      <h2 className="title color--primary-highdark margin--bottom-0:md">
                        {t('calculator')}
                      </h2>
                    </div>
                    <div className="cell cell--shrink:md align--center">
                      <Stepper activeStep={step} />
                    </div>
                  </div>
                  <div className="grid gap">
                    <div className="cell cell--12 margin--bottom-24">
                      <FormGroup title={t('personsCount')}>
                        <RoomPicker />
                      </FormGroup>
                    </div>
                    {!specialAdult && <PeoplePicker combinationAllowed={combinationAllowed} />}

                    <div className="cell cell--12 cell--6:md margin--bottom-24">
                      <InfantsPicker />
                    </div>
                  </div>

                  <div className="grid gap">
                    <div className="cell cell--12 cell--6:md margin--bottom-24">
                      <EatingPicker />
                    </div>

                    <div className="cell cell--12 cell--6:md margin--bottom-24">
                      <TransportPicker />
                    </div>

                    <div className="cell cell--12 margin--bottom-24">
                      <SurchargesPicker />
                    </div>

                    <div className="cell cell--12 margin--bottom-24">
                      <DiscountPicker />
                    </div>

                    <div className="cell cell--12 margin--bottom-24">
                      <OccupantSummary />
                    </div>
                  </div>

                  {this.renderReservation(combinationAllowed && maxOccupantsOk, renderReservation)}

                  <ReactTooltip html={Boolean(true)} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderReservation(combinationAllowed, renderReservation) {
    const { t, goNext } = this.props;
    return (
      <>
        <TotalPrice />
        <hr className="margin--top-2 margin--bottom-2" />
        <div className="grid grid--middle">
          <div className="cell cell--12 cell--6:md">
            <Link
              className="link link--underline text--14 color--primary-highdark"
              to={'/zajezdy/detail/' + this.props.idDetail}
            >
              <strong>{t('backToDetail')}</strong>
            </Link>
          </div>
          <div className="cell cell--12 cell--6:md">
            <button
              dataTooltip="Zadejte všechny povinné položky"
              className="button button--primary button--full"
              disabled={!combinationAllowed || !this.transportSelected()}
              onClick={() => goNext()}
            >
              {t('order')}
            </button>
          </div>
        </div>
      </>
    );
  }

  transportSelected() {
    const {
      calculator: {
        settings: { departureFrom, transport },
      },
    } = this.props;
    const departures = getDeparturePlaces(this.props);

    if (!transport) {
      return false;
    }

    if (departures && departures.length && !departureFrom) {
      return false;
    }

    return true;
  }
}

const mapStateToProps = (state) => ({
  calculator: state.calculator,
  tourDetail: state.tourDetail,
  isSubsidy: state.calculator.settings.isSubsidy,
});

const mapDispatchToProps = (dispatch) => ({
  resetCalculator: () => dispatch(resetCalculator()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Radium(CalculatorStep1)));
