import styles from '../../../../shared/TourListStyles/TourListItemStyles';
import formatDateLong from '../../../../shared/Moment/formatDateLong';
import formatDateShort from '../../../../shared/Moment/formatDateShort';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import getLengthInDaysAndNights from '../../../../shared/getLengthInDaysAndNights';
import TourSticker from '../components/TourSticker';
import { zajezdyDetail } from '../../../../../utils/routeConfig';
import { connect } from 'react-redux';
import { hashHistory, Link } from 'react-router';
import { fetchTourDetail } from '../../../../../actions/TourDetail/fetchTourDetail';
import cookie from 'react-cookie';
import { employee, szEmployee } from '../../../../../actions/HP/defaultTourlist';
import { adultType } from '../components/PickerPriceList';
import formatCurrency from '../../../../shared/formatCurrency';
import { soldOut } from './ToursListItem';
import { withTranslation } from 'react-i18next';

const byPlane = 'Letecky';

class ToursTermItem extends Component {
  state = {
    openModalTerms: false,
    pickerOpened: false,
  };

  render() {
    const {
      term,
      term: {
        isSoldOut,
        idTour,
        title,
        image,
        country,
        location,
        prices,
        dateFrom,
        dateTo,
        lengthInDays,
        lengthInNights,
        transport,
      },
    } = this.props;
    const {
      filterSettings: { primaryColor, secondaryColor },
    } = this.props;

    const { type, value } = this.getSticker(term);
    const user = cookie.load('user');
    let adultPrice;
    const { t } = this.props;

    if (!prices) {
      return null;
    }

    if (primaryColor !== 'defaultColor') {
      styles.stickerTypes.term.background = primaryColor;
    }

    const adultPrices =
      !isSoldOut &&
      prices.filter((price) => {
        if (price.code === adultType) {
          return price;
        }
        return null;
      });

    if (adultPrices.length) {
      const price = adultPrices[0];
      if (user === szEmployee) {
        adultPrice = price.price - price.szSubsidy;
      } else {
        adultPrice = user === employee ? price.price - price.subsidy : price.price;
      }
    }

    return (
      <>
        <div className="cell cell--12 cell--6:md cell--4:lg margin--bottom-24">
          <Link className="object" onClick={() => this.redirect(idTour)}>
            {type && (
              <TourSticker
                type={styles.stickerTypes.hotel}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
              >
                {value}
              </TourSticker>
            )}
            <div className="object__header">
              <picture>
                <img src={image ? image : term.imgSrc} alt={title} />
              </picture>
            </div>
            <div className="object__body">
              <h3 className="title">{title}</h3>
              <ul className="list list--destination">
                <li className="text--14 color--black">
                  <strong className="text--semi">{country}</strong>
                </li>
                <li className="text--14 color--black">
                  <strong className="text--semi">{location}</strong>
                </li>
              </ul>
              <p className="text--14 color--primary-highdark">
                {formatDateShort(dateFrom) + ' - ' + formatDateLong(dateTo)},{' '}
                {this.getFormatedTransports(transport)}
              </p>
            </div>
            <div className="object__footer">
              <div className="button button--small button--primary">{t('chooseTerm')}</div>
              <div className="object__price">
                {!isSoldOut ? (
                  <>
                    <span className="text--16 color--primary-highdark">{t('od')}</span>
                    <strong className="color--secondary">{formatCurrency(adultPrice)}</strong>
                  </>
                ) : (
                  <>
                    <strong className="color--secondary">{t('soldOut')}</strong>
                  </>
                )}
              </div>
            </div>
          </Link>
        </div>
      </>
    );
  }

  redirect(idTour) {
    hashHistory.push(zajezdyDetail + idTour);
  }

  getSticker(term) {
    const {
      filterSettings: { filterName },
    } = this.props;

    if (term.isLastMinuteOrCustom) {
      return { type: 'lastminute', value: filterName };
    } else if (term.isSpecialOffer) {
      return { type: 'offer', value: 'Výhodná nabídka' };
    }

    return { type: null, value: null };
  }

  getFormatedTransports(transport) {
    let transportMessage = '';

    transport.forEach((transportItem, i) => {
      transportMessage += transportItem.title + (i !== transport.length - 1 ? ', ' : '');
    });

    transportMessage = transportMessage.split(byPlane);
    transportMessage =
      transportMessage.shift() +
      (transportMessage.length ? byPlane + transportMessage.join('') : '');

    return transportMessage;
  }
}

ToursTermItem.propTypes = {
  term: PropTypes.any.isRequired,
  filterSettings: PropTypes.any.isRequired,
  image: PropTypes.string,
};

const mapStateToProps = (state) => ({
  filter: state.filter,
  filterSettings: state.filterSettings,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTourDetail: (id) => dispatch(fetchTourDetail(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Radium(ToursTermItem)));
