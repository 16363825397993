import { setSettingsParameter } from '../../../../actions/Calculator/calculator';
import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import BaseSelect from '../../../shared/BaseSelect/BaseSelect';
import { itemArrayForSelet, handleSelect, selectedText } from './multiSelect';
import { surchargeParam } from '../../../../reducers/calculator';
import FormGroup from '../../../shared/forms/FormGroup';
import { withTranslation } from 'react-i18next';

class SurchargesPicker extends Component {
  render() {
    const {
      calculator: {
        data: { surcharges },
        settings: { surcharge },
      },
      setSettingsParameter,
    } = this.props;
    const { t } = this.props;

    if (surcharges.length === 0) {
      return null;
    }

    return (
      <>
        <FormGroup title={t('surcharges')}>
          <BaseSelect
            isCheckBox={Boolean(true)}
            items={itemArrayForSelet(surcharges, surcharge)}
            placeholder={'-'}
            text={surcharge && surcharge.length ? selectedText(surcharge) : null}
            onReset={() => setSettingsParameter([], surchargeParam)}
            onSelect={(id) =>
              handleSelect(id, surcharges, surcharge, setSettingsParameter, surchargeParam)
            }
          />
        </FormGroup>       
        {/* <div className="picker picker-big">
          <label>Příplatky</label>
          <BaseSelect
            isCheckBox={Boolean(true)}
            items={itemArrayForSelet(surcharges, surcharge)}
            placeholder={'-'}
            text={surcharge && surcharge.length ? selectedText(surcharge) : null}
            onReset={() => setSettingsParameter([], surchargeParam)}
            onSelect={(id) =>
              handleSelect(id, surcharges, surcharge, setSettingsParameter, surchargeParam)
            }
          />
        </div>*/}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  calculator: state.calculator,
});

const mapDispatchToProps = (dispatch) => ({
  setSettingsParameter: (object, parameter) => dispatch(setSettingsParameter(object, parameter)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Radium(SurchargesPicker)));
