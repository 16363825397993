import { setSettingsParameter } from '../../../../actions/Calculator/calculator';
import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import BaseSelect from '../../../shared/BaseSelect/BaseSelect';
import { departureParam, transportParam } from '../../../../reducers/calculator';
import { entityById } from './multiSelect';
import FormGroup from '../../../shared/forms/FormGroup';
import { withTranslation } from 'react-i18next';

class TransportPicker extends Component {
  render() {
    const {
      calculator: {
        data: { transports },
        settings: { transport },
      },
      setSettingsParameter,
    } = this.props;
    const { t } = this.props;

    return (
      <>
        <FormGroup title={t('transport')}>
          <BaseSelect
            isCheckBox={Boolean(false)}
            items={this.getTransport()}
            placeholder={'-'}
            text={transport ? transport.title : null}
            onReset={() => setSettingsParameter(null, transportParam)}
            onSelect={(id) => setSettingsParameter(entityById(id, transports), transportParam)}
          />
        </FormGroup>
        {/* <div className="picker-row">
          <div className="picker picker-big">
            <label>Doprava</label>

            <BaseSelect
              isCheckBox={Boolean(false)}
              items={this.getTransport()}
              placeholder={'-'}
              text={transport ? transport.title : null}
              onReset={() => setSettingsParameter(null, transportParam)}
              onSelect={(id) => setSettingsParameter(entityById(id, transports), transportParam)}
            />
          </div>

          {this.renderDeparturePlace()}
        </div>         */}
      </>
    );
  }

  renderDeparturePlace() {
    const {
      calculator: {
        settings: { departureFrom },
      },
      setSettingsParameter,
    } = this.props;
    const departures = getDeparturePlaces(this.props);

    if (!departures || !departures.length) {
      return null;
    }

    return (
      <>
        <FormGroup title={t('transportPlace')}>
          <BaseSelect
            isCheckBox={Boolean(false)}
            items={departures}
            placeholder={'Vyberte nástupní místo'}
            text={departureFrom ? departureFrom.title : null}
            onReset={() => setSettingsParameter(null, departureParam)}
            onSelect={(id) => setSettingsParameter(entityById(id, departures), departureParam)}
          />
        </FormGroup>     
        {/* <div className="picker picker-big">
          <label>Nástupní místo</label>

          <BaseSelect
            isCheckBox={Boolean(false)}
            items={departures}
            placeholder={'Vyberte nástupní místo'}
            text={departureFrom ? departureFrom.title : null}
            onReset={() => setSettingsParameter(null, departureParam)}
            onSelect={(id) => setSettingsParameter(entityById(id, departures), departureParam)}
          />
        </div> */}
      </>

    );
  }

  getTransport() {
    const {
      calculator: {
        data: { transports },
      },
    } = this.props;

    return transports.map((transport) => {
      return {
        id: transport.id,
        title: transport.title,
      };
    });
  }
}

export const getDeparturePlaces = (props) => {
  const {
    calculator: {
      settings: { transport },
    },
  } = props;

  if (!transport) {
    return null;
  }

  return transport.departureFrom.map((departure) => {
    return {
      id: departure.id,
      title: departure.title,
    };
  });
};

const mapStateToProps = (state) => ({
  calculator: state.calculator,
});

const mapDispatchToProps = (dispatch) => ({
  setSettingsParameter: (object, parameter) => dispatch(setSettingsParameter(object, parameter)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Radium(TransportPicker)));
