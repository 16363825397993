import backgroundImg from '../../../../img/tours-detail/bg.jpg'; // eslint-disable-line
import placeIcon from '../../../../img/tours-detail/icons/place.png'; // eslint-disable-line
import dateIcon from '../../../../img/tours-detail/icons/date.png'; // eslint-disable-line
import constants from '../../../../styles/constants';
import { connect } from 'react-redux';
import projectColors from '../../../../styles/projectColors';

import formatDateLong from '../../../shared/Moment/formatDateLong';
import formatDateShort from '../../../shared/Moment/formatDateShort';
import daySuffix from '../../../shared/daySuffix';
import nightSuffix from '../../../shared/nightSuffix';
import checkZero from '../../../shared/checkZero';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container } from '../../../../libs/radium-grid'; // eslint-disable-line
import Radium from 'radium';
import TourPicker from './Panels/TourPicker';
import sprite from '../../../../img/icons/sprite.svg';
import detail from './detail.module.scss';
import { hashHistory, Link } from 'react-router';
import { withTranslation } from 'react-i18next';
import {
  fetchReviewOptions,
  fetchSimilarTours,
  fetchTourDetail,
  fetchTourDetailWithFilters,
  fetchWeather,
} from '../../../../actions/TourDetail/fetchTourDetail';
import { addTourForComparement } from '../../../../actions/TourDetail/tourDetail';
import { fetchTerms } from '../../../../actions/TourList/fetchTourList';

// const styles = {
//   wrapper: {
//     width: "100%",
//     height: "170px",
//     overflow: "hidden",
//     backgroundImage: "url(" + backgroundImg + ")",
//     backgroundSize: "100%",
//     fontSize: "1.3em",
//     position: "relative",

//     [constants.breakpoint768Min]: {
//       position: "fixed",
//       zIndex: "8"
//     },

//     [constants.breakpointTablet]: {
//       height: "auto"
//     },

//     [constants.breakpointDesktop]: {
//       backgroundSize: "1500px"
//     }
//   },
//   text: {
//     color: "#fff"
//   },
//   tourInfo: {
//     color: "#fff",
//     fontWeight: "500",
//     fontSize: "0.7em",
//     margin: "60px 0 0 130px",
//     textTransform: "uppercase",

//     [constants.breakpointTablet]: {
//       fontSize: "0.5em"
//     }
//   },

//   icon: {
//     position: "relative",
//     top: "9px",
//     width: "37px",
//     height: "37px",
//     marginRight: "3px",
//     marginLeft: 0,
//     display: "inline-block",

//     [constants.breakpointTablet]: {
//       width: "28px",
//       height: "auto",
//       top: "8px"
//     },

//     [constants.breakpointMobile]: {
//       display: "none"
//     }
//   },

//   title: {
//     color: "#fff",
//     fontWeight: "700",
//     fontSize: "1.6em",
//     margin: "40px 0 0 0",
//     textTransform: "uppercase",

//     [constants.breakpointTablet]: {
//       fontSize: "1.1em",
//       margin: "40px 0 0 0"
//     }
//   },
//   tourDetailTitle: {
//     color: [constants.darkBlue],
//     fontWeight: "700",
//     fontSize: "1.6em",
//     padding: "8px 0",
//     margin: "0",
//     background: "#f2f2f2",
//     textTransform: "uppercase",

//     [constants.breakpointTablet]: {
//       fontSize: "1.1em",
//       margin: "0 0 5px 0",
//       paddingBottom: "5px",
//       borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
//       textAlign: "center"
//     }
//   },
//   tourDays: {
//     color: "#fff",
//     fontWeight: "600",
//     fontSize: "1.2em",
//     margin: "5px 0 0 0",
//     position: "relative",
//     textTransform: "uppercase",

//     [constants.breakpointTablet]: {
//       fontSize: "0.9em",
//       margin: "0"
//     },

//     [constants.breakpointMobile]: {
//       lineHeight: "1.3em",
//       textAlign: "center",
//       margin: "10px 0 0 0"
//     }
//   },
//   orange: {
//     color: [constants.mainOrange]
//   },
//   tourSticker: {
//     color: "#fff",
//     fontWeight: "700",
//     fontSize: "0.9em",
//     margin: "0px 0 0 0",
//     textTransform: "uppercase",
//     background: "#2DA0E6",
//     display: "inline-block",
//     padding: 7,
//     transform: "rotate(4deg)",
//     position: "absolute",
//     right: "0",
//     top: "33px",

//     [constants.breakpointTablet]: {
//       fontSize: "0.8em",
//       textAlign: "center",
//       position: "relative",
//       transform: "none",
//       width: "calc(100%  + 30px)",
//       top: "inherit",
//       left: "-15px",
//       marginTop: "13px"
//     },

//     soldOut: {
//       background: "red"
//     }
//   },

//   smallerDays: {
//     fontSize: "0.8em",
//     color: "#fff",
//     paddingLeft: "5px",

//     [constants.breakpointMobile]: {
//       display: "block",
//       width: "100%"
//     }
//   },

//   relative: {
//     position: "relative"
//   },

//   headerBox: {
//     paddingBottom: "15px"
//   },

//   countryLine: {
//     width: "auto",
//     transform: "rotate(4deg)",
//     left: "-17px",
//     padding: "6px 5px 5px 7px",
//     position: "relative",
//     textAlign: "left",
//     background: [constants.yellow],
//     display: "inline-block",
//     textTransform: "uppercase",
//     color: "#fff",
//     top: "3px",
//     fontWeight: "600",
//     fontSize: "0.9em",

//     [constants.breakpointTablet]: {
//       left: "-5px"
//     }
//   }
// };

class TourHeader extends Component {
  render() {
    return <>{this.getHeaderData()}</>;
  }

  getHeaderData() {
    const {
      term,
      term: {
        isSoldOut,
        isCanceled,
        dateFrom,
        dateTo,
        lengthInDays,
        lengthInNights,
        dayStartName,
        dayEndName,
      },
    } = this.props;
    const { type } = this.getSticker(term);
    const {
      onlyHotel,
      tourDetail: { title, country, location, pickedTour },
    } = this.props;

    const visit = dayStartName
      ? checkZero(dayStartName) + ' - ' + checkZero(dayEndName) + ','
      : 'Délka zájezdu dle vlastního výběru';
    const tourLength = lengthInNights
      ? checkZero(lengthInNights) +
        nightSuffix(lengthInNights) +
        '; ' +
        checkZero(lengthInDays) +
        daySuffix(lengthInDays)
      : '';

    if (pickedTour) {
      return (
        <>
          <div className="grid">
            <div className="cell cell--12">
              <ul className="list list--breadcrumbs">
                <li>
                  <button
                    onClick={() => {
                      sessionStorage.setItem('country', country);
                      hashHistory.push('/zajezdy/vypis');
                    }}
                    className="link link--small link--underline text--14 color--primary-highdark"
                  >
                    <strong className="text--semi">{country}</strong>
                  </button>
                </li>
                <li className="color--primary-highdark">
                  <button className="color--primary-highdark text--14">
                    <strong className="text--semi">{location}</strong>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="grid grid--middle margin--bottom-2 margin--bottom-3:lg">
            <div className="cell cell--12 cell--shrink:lg margin--bottom-2 margin--bottom-0:lg">
              <h1 className="title margin--bottom-0 color--primary-highdark">{title}</h1>
            </div>
            {!onlyHotel && this.renderHeaderButtons(tourLength, visit)}
            {(type || isSoldOut) && !onlyHotel ? (
              <div className="cell cell--12 cell--shrink:lg">
                <div className={`${detail.intro__label} text--14`}>
                  {isSoldOut ? 'Zájezd vyprodán' : this.termSpecialStateText(term)}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>

          {/* <div className="tour-header-box" style={styles.headerBox}>
            <h1 style={styles.tourDetailTitle}>
              <Container>{"Ubytování: " + title}</Container>
            </h1>
            <Container>
              <div style={styles.relative}>
                <h2 style={styles.tourDays}>
                  <img style={styles.icon} src={placeIcon} /> {country + ", " + location}
                </h2>
                <h2 style={[styles.tourDays, styles.orange]}>
                  {this.renderDateOrPickButton(dateFrom, dateTo, tourLength, visit)}
                </h2>

                {(type || isSoldOut) && !onlyHotel ? (
                  <h2
                    style={[
                      styles.tourSticker,
                      ((isSoldOut || isCanceled)) && styles.tourSticker.soldOut
                    ]}
                    className="sticker"
                  >
                    <span style={styles.text}>
                      {isSoldOut ? "Zájezd vyprodán" : this.termSpecialStateText(term)}
                    </span>
                  </h2>
                ) : (
                  ""
                )}
              </div>
            </Container>
          </div>         */}
        </>
      );
    }

    return (
      <>
        <div className="grid">
          <div className="cell cell--12">
            <ul className="list list--breadcrumbs">
              <li className="text--14 color--primary-highdark">
                <strong className="text--semi">{country}</strong>
              </li>
              <li className="text--14 color--primary-highdark">
                <strong className="text--semi">{location}</strong>
              </li>
            </ul>
          </div>
        </div>
        <div className="grid grid--middle margin--bottom-2 margin--bottom-3:lg">
          <div className="cell">
            <h1 className="title margin--bottom-0 color--primary-highdark">{title}</h1>
          </div>
        </div>
      </>
    );
  }

  filterDestination() {
    hashHistory.push(`/zajezdy/vypis`);
    window.location.reload();
  }

  renderDate(tourLength, visit) {
    const {
      term: { dateTo, dateFrom },
    } = this.props;

    return <>{formatDateShort(dateFrom) + ' - ' + formatDateLong(dateTo)}</>;
  }

  termSpecialStateText(term) {
    const { isCanceled, isLastMinuteOrCustom, isSpecialOffer } = term;
    const {
      filterSettings: { filterName },
    } = this.props;

    if (isCanceled) {
      return 'Zrušeno';
    } else if (isLastMinuteOrCustom) {
      return filterName;
    } else if (isSpecialOffer) {
      return 'Výhodná nabídka';
    }

    return null;
  }

  renderHeaderButtons(tourLength, visit) {
    const {
      tourDetail,
      tourDetail: { isSoldOut },
    } = this.props;
    const {
      addTourForComparement,
      tourComparement: { tours },
    } = this.props;
    const { onlyHotel } = this.state;
    const { t, term } = this.props;

    if (onlyHotel) {
      return null;
    }

    return (
      <>
        <div className="cell cell--12 cell--shrink:md margin--bottom-2 margin--bottom-0:md margin--left-1:lg">
          <div className="button button--primary button--date">
            <svg className="icon margin--right-1" role="presentation" width="16" height="16">
              <use xlinkHref={sprite + '#sprite-calendar'} />
            </svg>
            {this.renderDate(tourLength, visit)}
          </div>
        </div>
        <div className="cell cell--12 cell--auto:md">
          <div
            className="grid grid--middle grid--end:md gap"
            data-tip={tours.length >= 3 ? 'V porovnání máte vybraný maximální počet zájezdů' : ''}
          >
            <div className="cell cell--shrink">
              <button
                onClick={() => addTourForComparement(term, tourDetail)}
                disabled={tours.length >= 3}
                className="button button--underline button--plain color--primary-highdark"
              >
                <svg className="icon margin--right-1" role="presentation" width="20" height="20">
                  <use xlinkHref={sprite + '#sprite-compare'} />
                </svg>
                {t('compare')}
              </button>
            </div>
            {/* <div className="cell cell--shrink">
             <button
                disabled={isSoldOut}
                className="button button--underline button--plain color--primary-highdark"
                onClick={() => hashHistory.push(`/zajezdy/kalkulacka/${term.idTour}`)}
            >
               <svg className="icon margin--right-1" role="presentation" width="20" height="20">
                 <use xlinkHref={sprite + '#sprite-calc'} />
               </svg>
               {t('calculator')}
             </button>
            </div> */}
          </div>
        </div>
      </>
    );
  }

  getSticker(term) {
    const {
      filterSettings: { filterName },
    } = this.props;
    if (term.isCanceled) {
      return { type: 'isCanceled', value: 'Zrušeno' };
    } else if (term.isLastMinuteOrCustom) {
      return { type: 'lastminute', value: filterName };
    } else if (term.isSpecialOffer) {
      return { type: 'offer', value: 'Výhodná nabídka' };
    }

    return { type: null, value: null };
  }
}

TourHeader.propTypes = {
  tourDetail: PropTypes.any,
  term: PropTypes.any,
  filterSettings: PropTypes.any,
  filter: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
  fetchState: state.fetchState,
  tourComparement: state.tourComparement,
  termList: state.termList,
  filter: state.filter,
  filterSettings: state.filterSettings,
  tourDetailReview: state.tourDetailReview,
});

const mapDispatchToProps = (dispatch) => ({
  addTourForComparement: (tour, tourDetail) => dispatch(addTourForComparement(tour, tourDetail)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Radium(TourHeader)));
