import ToursListItem from '../items/ToursListItem';
import ToursTermItem from '../items/ToursTermItem';
import ToursPaginator from '../ToursPaginator';

import constants from '../../../../../styles/constants.js';
import leftArrow from '../../../../../img/icons/back-arrow.png'; // eslint-disable-line;
import ToursListBody from './TourListBody.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { loading } from '../../../../../reducers/filteredTours';
import { toggleTiles } from '../../../../../actions/TourList/paginator';
import StyledCircleLoader from '../../../../shared/Loading/StyledCircleLoader';
import getPages from '../../../../shared/getPages';
import { connect } from 'react-redux';
import Radium from 'radium';
import TourListSettings from "./TourListSettings";

const styles = {
  wrapper: {},
  backLink: {
    position: 'fixed',
    top: 430,
    left: 30,
    cursor: 'pointer',

    [constants.breakpointDesktop]: {
      display: 'none',
    },

    text: {
      textDecoration: 'none',
      color: [constants.mainBlue],
      fontSize: '1.1em',
      fontWeight: '600',

      [constants.breakpointDesktop]: {
        padding: '10px',
        backgroundColor: '#fff',
      },

      ':hover': {
        textDecoration: 'underline',
      },
    },

    [constants.breakpoint850]: {
      display: 'none',
    },
  },
  loadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 80,
    marginBottom: 80,
  },
  tilesWrapper: {
    overflow: 'auto',
    margin: '20px 0',
  },
  arrow: {
    width: '11px',
    height: 'auto',
    marginRight: '7px',
    position: 'relative',
    top: '6px',
  },
};

class ToursList extends Component {
  state = {
    itemCount: 0,
    tripCount: 0,
    tripPages: [],
    items: [],
    fetching: false,
  };

  componentWillReceiveProps() {
    this.setState({ fetching: false });
  }

  render() {
    const {
      paginator: { page, itemsPerPage },
      filteredTours: { tours, toursCount, dataLoading, filterLoading },
    } = this.props;
    const { pages } = getPages(parseInt(toursCount, 10), itemsPerPage);

    return (
      <>
        <section className="section section--medium">
          <div className="container">
            <div className="grid margin--bottom-2 margin--bottom-3:md">
              <div className="cell--12 cell--auto:md">
                <h2 className="title margin--bottom-0:md">
                  {toursCount === 0
                      ? 'V tuto chvíli zde nejsou žádné zájezdy v nabídce, které by odpovídaly Vaší filtraci.'
                      : toursCount + ' možností ubytování'
                  }
                </h2>
              </div>
              <div className="cell--12 cell--shrink:md">
                {this.renderTourListSettings(tours.length)}
              </div>
            </div>
            <div className="grid gap">
              {dataLoading === loading ? (
                <>
                <div className="cell cell--12 align--center">
                  <div style={styles.loadingWrapper}>
                    <StyledCircleLoader />
                  </div>
                </div>
                </>
              ) : (
                <>
                  {tours.map((item) => this.renderTour(item))}
                </>
              )}
            </div>
              {this.renderPaginator(tours.length, filterLoading, toursCount, pages, page, Boolean(false))}
          </div>
        </section>

        {/*<div style={styles.wrapper} className="tour-list-wrapper">
          { {dataLoading === loading ? (
            <div style={styles.loadingWrapper}>
              <StyledCircleLoader />
            </div>
          ) : (
            tours.map((item) => this.renderTour(item))
          )} }

          <ToursPaginator
            filterLoading={filterLoading}
            tripCount={toursCount}
            pages={pages}
            showTourSettings={Boolean(false)}
            actualPage={page}
            paginatorType={'list'}
          />
        </div>*/}
      </>

    );
  }

  renderTourListSettings(toursCount) {
    if (toursCount !== 0) {
      return (
          <div style={{display:'flex', flexDirection: 'row-reverse', justifyContent: 'flex-end'}}>
            <TourListSettings/>
          </div>
      )
    }

    return null;
  }

  renderPaginator(toursLength, filterLoading, toursCount, pages, page, showTourSettings) {
    if (toursLength !== 0) {
      return (
          <ToursPaginator
              filterLoading={filterLoading}
              tripCount={toursCount}
              pages={pages}
              showTourSettings={showTourSettings}
              actualPage={page}
              paginatorType={'list'}
          />
      )
    }

    return null;
  }

  showTiles() {
    const { toggleTiles } = this.props;

    toggleTiles(true);
  }

  renderTour(item) {
    const {
      filter: { showTermList },
    } = this.props;

    if (showTermList) {
      return <ToursTermItem term={item} />;
    } else {
      const { title, location, description, imgSrc, idTour } = item;

      return (
        <ToursListItem
          id={idTour}
          tour={item}
          title={title}
          destination={location}
          description={description}
          image={imgSrc}
        />
      );
    }
  }
}

ToursList.propTypes = {
  paginator: PropTypes.any,
  filteredTours: PropTypes.any,
  filter: PropTypes.any,
  toggleTiles: PropTypes.any,
};

const mapStateToProps = (state) => ({
  filter: state.filter,
  filteredTours: state.filteredTours,
  paginator: state.paginator,
});

const mapDispatchToProps = (dispatch) => ({
  toggleTiles: (nextState) => dispatch(toggleTiles(nextState)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(ToursList));
