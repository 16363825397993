import formatDateLong from '../../../../../shared/Moment/formatDateLong';
import ReviewForm from './ReviewForm';
import Radium from 'radium';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './review.module.scss';
import sprite from '../../../../../../img/icons/sprite.svg';

class Reviews extends Component {
  state = {
    opened: false,
    toggle: false,
  };

  render() {
    const {
      tourDetail: { reviews },
    } = this.props;
    console.log(reviews)
    const { opened, toggle } = this.state;

    return (
      <div className={styles.review}>
        <ReviewForm opened={opened} toggle={toggle} />
        {!reviews.length && this.renderNoReviewMessage()}
        {reviews.map((item) => this.renderReviewsBox(item))}
        <div onClick={() => this.setState({ opened: !opened })} className="margin--top-2">
          <button
            className="button button--primary hollow"
            onClick={() => {
              this.setState(
                toggle ? { opened: true, toggle: false } : { opened: true, toggle: true },
              );
            }}
            key="1"
          >
            Přidat vlastní recenzi
          </button>
        </div>
      </div>
    );
  }

  renderNoReviewMessage() {
    return <p><strong>Zájezd nemá žádné recenze</strong></p>;
  }

  renderReviewsBox(item) {
    const {
      date,
      name,
      rating: { accommodationIndex, dietIndex, locationIndex, serviceIndex },
      description,
    } = item;
    const renderRating = [
      {
        title: 'Ubytování',
        value: accommodationIndex,
      },
      {
        title: 'Strava',
        value: dietIndex,
      },
      {
        title: 'Poloha',
        value: locationIndex,
      },
      {
        title: 'Služby',
        value: serviceIndex,
      },
    ];

    return (
      <div className={styles.review__item}>
        <div className="text--14 color--primary-highdark">Přidáno: {formatDateLong(date)}</div>
        <ul className="list list--rating">
          {renderRating.map((rate) => this.renderStarsBox(rate.title, rate.value))}
        </ul>
        <div>
          <p className='color--primary-highdark'>
            <span className='color--primary'>{name}</span> napsal/a:
          </p>
          <p className="color--primary-highdark margin--bottom-0">
            {description}
          </p>
        </div>
      </div>
    );
  }

  renderStarsBox(title, value) {
    let stars=[]

    for (let i = 0; i < value; i++) {
      stars.push(
        <svg class="icon" role="presentation" width="27" height="27">
          <use xlinkHref={sprite + '#sprite-fullstar'}/>
        </svg>
      )
    }

    return (
      <li className="list__item align--center">
        <strong className="color--primary">{title}</strong>
        <div className="list__container">
          <div className="list__stars">
            <svg class="icon" role="presentation" width="27" height="27">
              <use xlinkHref={sprite + '#sprite-star'}/>
            </svg>
            <svg class="icon" role="presentation" width="27" height="27">
              <use xlinkHref={sprite + '#sprite-star'}/>
            </svg>
            <svg class="icon" role="presentation" width="27" height="27">
              <use xlinkHref={sprite + '#sprite-star'}/>
            </svg>
            <svg class="icon" role="presentation" width="27" height="27">
              <use xlinkHref={sprite + '#sprite-star'}/>
            </svg>
            <svg class="icon" role="presentation" width="27" height="27">
              <use xlinkHref={sprite + '#sprite-star'}/>
            </svg>
          </div>
          <div className="list__full">
            {stars}
          </div>
        </div>
      </li>
    );
  }
}

Reviews.propTypes = {
  tourDetail: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
});

export default connect(mapStateToProps)(Radium(Reviews));
