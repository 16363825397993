import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { setParam, setCustomerSettings } from '../../../../actions/Calculator/reservation';
import { sendReservation } from '../../../../actions/Calculator/calculator';
import './Reservation.css';
import TotalPrice from '../Summary/TotalPrice';
import StyledCircleLoader from '../../../shared/Loading/StyledCircleLoader';
import OrangeButton from '../../../shared/OrangeButton';
import Agreement from './Agreement';
// import {customStyles} from "../../../shared/BaseSelect/BaseSelect";
import input from '../../../shared/forms/input.module.scss';
import ReactTooltip from 'react-tooltip';
import cookie from 'react-cookie';
import { visitor } from '../../../../actions/HP/defaultTourlist';
import BottomText from './BottomText';
import { withTranslation } from 'react-i18next';
import Stepper from './Stepper.jsx';
import sprite from '../../../../img/icons/sprite.svg';
import cms from '../../CMS/cms.module.scss';
import { Link } from 'react-router';
import FormGroup from '../../../shared/forms/FormGroup';
import styles from '../Summary/summary.module.scss';

export const getIsSubsidy = (adult, children) => {
  const subsidyAdultIndex = adult.findIndex((customer) => {
    return customer.grantUsed;
  });

  const subsidyChildrenIndex = children.findIndex((customer) => {
    return customer.grantUsed;
  });

  return subsidyAdultIndex > -1 || subsidyChildrenIndex > -1;
};

const titles = {
  adult: 'Dospělá osoba',
  children: 'Dítě',
  infant: 'Infant',
};

class Reservation extends Component {
  state = {
    specifications: {
      employee: 'Zaměstnanec',
      familyMember: 'Rodinný příslušník',
      children: 'Dítě',
      pensioner: 'Důchodce',
    },
  };

  componentDidMount() {
    const {
      setCustomerSettings,
      calculator: {
        occupation: { adult, children },
      },
      reservation: {
        customerSettings,
        values: { name, surname, birthDate, title },
      },
    } = this.props;

    adult.concat(children).forEach((customer) => {
      if (!customerSettings[customer.id]) {
        setCustomerSettings('name', '', customer.id);
      }
    });

    const firstAdult = adult[0];

    setCustomerSettings('title', title, firstAdult.id);
    setCustomerSettings('name', name, firstAdult.id);
    setCustomerSettings('surname', surname, firstAdult.id);
    setCustomerSettings('birthDate', birthDate, firstAdult.id);
    this.handleInfantsCreation();
  }

  componentWillReceiveProps(props) {
    const {
      setCustomerSettings,
      isSubsidyLocal,
      calculator: {
        occupation: { adult, children },
      },
      reservation: { customerSettings },
    } = props;

    if (isSubsidyLocal) {
      adult.concat(children).forEach((customer) => {
        if (!customer.isDiscounted && customerSettings[customer.id].specification !== 'other') {
          console.log(customer.id);
          setCustomerSettings('specification', 'other', customer.id);
        }
      });
    }
  }

  handleInfantsCreation() {
    const {
      setCustomerSettings,
      calculator: {
        occupation: { infants },
      },
      reservation: { customerSettings },
    } = this.props;

    let infantExistingCount = 0;

    Object.keys(customerSettings).forEach((key) => {
      const customerSetting = customerSettings[key];

      if (customerSetting.isInfant) {
        infantExistingCount++;
      }
    });

    const totalInfantsToRender = infants.reduce((a, b) => a + (parseInt(b.timeUsed) || 0), 0);
    const infantsToAdd = totalInfantsToRender - infantExistingCount;

    for (let i = 0; i < infantsToAdd; i++) {
      let id = btoa(`infant-${i}`);

      setCustomerSettings('isInfant', true, id);
      setCustomerSettings('id', id, id);
    }
  }

  render() {
    const {
      calculator: {
        occupation: { adult, children },
        settings: { occupation },
        sendingReservation,
        reservationSent,
      },
      reservation: { trans, values },
      isSubsidyLocal,
      goFirst,
      isSubsidy,
      setParam,
    } = this.props;
    const { t } = this.props;
    const { step } = this.props;

    return (
      <>
        <div className="container">
          <div className="grid">
            <div className="cell margin--bottom-3">
              <div className={cms.block}>
                <div style={{maxWidth: "100%", marginInline: "auto"}}>
                  <div className="grid grid--middle margin--bottom-2 margin--bottom-3:md">
                    <div className="cell cell--auto:md">
                      <h2 className="title color--primary-highdark margin--bottom-0:md">{t('passengers')}</h2>
                    </div>
                    <div className="cell cell--shrink:md align--center">
                      <Stepper activeStep={step} />
                    </div>
                  </div>                  

                  {this.renderDiscountApliance()}

                  {adult.map((adult, index) => this.renderCustomer(adult, index + 1, 'adult'))}
                  {children.map((child, index) =>
                    this.renderCustomer(child, index + occupation.adult + 1, 'children'),
                  )}

                  {this.getInfants().map((child) => this.renderCustomer(child, null, 'infant'))}
                  <div className="grid gap">
                    <div className="cell cell--12 margin--bottom-24">
                      <Agreement
                        onChange={(value) => setParam('agreesPolicy', value)}
                        values={values}
                        param={'agreesPolicy'}
                        trans={this.getAgreesPolicy()}
                        isSubsidy={isSubsidy}
                      />                      
                    </div>
                  </div>
                
                  {this.renderButtons()}

                  {reservationSent === 'error' && this.renderError()}

                  {sendingReservation && this.renderLoader()}

                  <ReactTooltip html={Boolean(true)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  getInfants() {
    const {
      reservation: { customerSettings },
    } = this.props;
    const infants = [];

    Object.keys(customerSettings).forEach((key) => {
      const customerSetting = customerSettings[key];

      if (customerSetting.isInfant) {
        infants.push(customerSetting);
      }
    });

    return infants;
  }

  getAgreesPolicy() {
    const user = cookie.load('user');

    if (user !== visitor) {
      return {
        agreesPolicy: (
          <>
            *Souhlasím s{' '}
            <a
              className="link link--underline color--primary-highdark"
              href="http://cdtravel-2.s3-website.eu-central-1.amazonaws.com/documentsUpload/a8f978b7Vseobecne_podminky.pdf"
              target="_blank"
            >
              Všeobecnými a záručními podmínkami
            </a>{' '}
            cestovní kanceláře ČD travel (případně pořádající CK) a
            <a
              className="link link--underline color--primary-highdark"
              href="http://cdtravel-2.s3-website.eu-central-1.amazonaws.com/documentsUpload/71f76c5eERV2018.pdf"
              target="_blank"
            >
              {' '}
              Pojistnými podmínkami
            </a>{' '}
            ERV Evropské Pojišťovny.
          </>
        ),
      };
    }

    return {
      agreesPolicy: (
        <>

          *Souhlasím se{' '}
          <a
            className="link link--underline color--primary-highdark"
            href="http://cdtravel-2.s3-website.eu-central-1.amazonaws.com/documentsUpload/a8f978b7Vseobecne_podminky.pdf"
            target="_blank"
          >
            Všeobecnými a záručními podmínkami
          </a>{' '}
          cestovní kanceláře ČD travel a
          <a
            className="link link--underline color--primary-highdark"
            href="http://cdtravel-2.s3-website.eu-central-1.amazonaws.com/documentsUpload/71f76c5eERV2018.pdf"
            target="_blank"
          >
            {' '}
            Pojistnými podmínkami ERV
          </a>{' '}
          Evropské Pojišťovny.

        </>
      ),
    };
  }

  renderError() {
    return <div className="sendingError">rezervaci nebylo možné odeslat</div>;
  }

  renderLoader() {
    return (
      <>
      <div className="sending-loader">
        <StyledCircleLoader />
      </div>      
      </>
    );
  }

  renderButtons() {
    const { closeModal, isReadyToSubmit, goNext, goFirst } = this.props;
    const { t } = this.props;

    return (
      <>
        <TotalPrice />
        <hr className="margin--top-2 margin--bottom-2" />
        <div className="grid gap grid--middle">
          <div className="cell cell--12 cell--6:md margin--bottom-24">
            <div
              className="link link--underline text--14 color--primary-highdark"
              onClick={() => goFirst()}
            >
              <strong>{t('backToPrevious')}</strong>
            </div>
          </div>
          <div className="cell cell--12 cell--6:md margin--bottom-24 align--right">
            <button
              dataTooltip="Vyplňte všechna pole označná znakem * a zaškrtněte souhlas s podmínkami"
              disabled={!isReadyToSubmit}
              onClick={() => goNext()}
              className="button button--primary"
            >
              {t('next')}
            </button>
          </div>
        </div>
        <div className="grid">
          <div className="cell cell--12">
            <p className="color--primary-highdark text--14 margin--bottom-0">
              <i>{t('backToCalculatorNote')}</i>
            </p>
          </div>          
        </div>
      </>
    );
  }

  renderDiscountApliance() {
    const {
      isSubsidyLocal,
      isSubsidy,
      reservation: {
        values: { company },
      },
      setParam,
    } = this.props;
    const { t } = this.props;

    if (!isSubsidyLocal) {
      return null;
    }

    return (
      <>
        <div className="grid margin--bottom-24">
          <div className="cell">
            <p className="text--14 color--primary-highdark">
              <strong>
                {t('grant')}
              </strong>
            </p>
            <div className={`${input.form__group} ${input['form__group--inline']}`}>
              <input
                className={input.checkbox}
                type="radio"
                onClick={() => setParam('company', 'cd')}
                checked={company === 'cd'}
                id="cd"
              />
              <label className={input.label} for="cd">
                {t('cd')}
              </label>          
            </div>
            <div className={`${input.form__group} ${input['form__group--inline']}`}>
              <input
                className={input.checkbox}
                type="radio"
                onClick={() => setParam('company', 'cdCargo')}
                checked={company === 'cdCargo'}
                id="cdCargo"
              />
              <label className={input.label} for="cdCargo">
              {t('cdCargo')}
              </label>          
            </div> 
            <div className={`${input.form__group} ${input['form__group--inline']}`}>
              <input
                className={input.checkbox}
                type="radio"
                onClick={() => setParam('company', 'railwayAdministration')}
                checked={company === 'railwayAdministration'}
                id="railwayAdministration"
              />
              <label className={input.label} for="railwayAdministration">
                {t('railwayAdministration')}
              </label>          
            </div>                        
          </div>
        </div>

        <div className="grid margin--bottom-24">
          <div className="cell">
            <div className="text--inline text--14 color--primary-highdark">
              <span className="margin--right-1">{t('iDeclare')}</span>
              {this.simpleSelect({ Nejsem: false, Jsem: true })}
              <span className="margin--left-1">{t('inProbationPeriod')}</span>
            </div>
          </div>
        </div>
      </>
    );
  }

  simpleSelect(options) {
    const {
      reservation: {
        values: { inTestTime },
      },
      setParam,
    } = this.props;
    const { t } = this.props;

    return (
      <>
        <FormGroup>
          <select
            className={input.select}
            onChange={(event) => setParam('inTestTime', event.target.value === 'true')}
          >
            {Object.keys(options).map((key) => {
              return (
                <option value={options[key]} selected={inTestTime === options[key]}>
                  {key}
                </option>
              );
            })}
          </select>                   
        </FormGroup>
        {/* <div>
          <select
            className="formControl text-select-input"
            onChange={(event) => setParam('inTestTime', event.target.value === 'true')}
          >
            {Object.keys(options).map((key) => {
              return (
                <option value={options[key]} selected={inTestTime === options[key]}>
                  {key}
                </option>
              );
            })}
          </select>
        </div>       */}
      </>
    );
  }

  pensionerSelect(customer) {
    const options = { ČD: 'cd', 'ČD Cargo': 'cdCargo' };
    const { setCustomerSettings } = this.props;

    return (
      <div>
        <select
          className="formControl text-select-input"
          onChange={(event) =>
            setCustomerSettings('pensionerCompany', event.target.value, customer.id)
          }
        >
          {Object.keys(options).map((key) => {
            return <option value={options[key]}>{key}</option>;
          })}
        </select>
      </div>
    );
  }

  renderCustomer(customer, index, type) {
    const {
      reservation: { customerSettings },
    } = this.props;

    const settings = customerSettings[customer.id];
    const isFirstAdult = index === 1 && type === 'adult';

    return (
      <>
        <div className={styles.summary__item}>
          <div className="grid">
            <div className="cell">
              <h3 className="title h4 color--primary-highdark margin--bottom-1">
                {index}. {titles[type]}
              </h3>
            </div>
          </div>
          <div className="grid gap">
            <div className="cell cell--12 cell--6:md cell--3:lg margin--bottom-24">
              {this.renderDataInput(
                'title',
                customer,
                type === 'children' || type === 'infant' || isFirstAdult,
              )}
            </div>
          </div>
          <div className="grid gap">
            <div className="cell cell--12 cell--6:md cell--3:lg margin--bottom-24">
              {this.renderDataInput('name', customer, isFirstAdult)}
            </div>
            <div className="cell cell--12 cell--6:md cell--3:lg margin--bottom-24">
              {this.renderDataInput('surname', customer, isFirstAdult)}
            </div>
            <div className="cell cell--12 cell--6:md cell--3:lg margin--bottom-24">
              {this.renderDataInput('birthDate', customer, isFirstAdult)}
            </div>
            <div className="cell cell--12 cell--6:md cell--3:lg margin--bottom-24">
              {this.renderSpecificationSelect(customer, settings)}
            </div>
          </div>
          {this.renderPensionerApliance(customer, settings, isFirstAdult)}
        </div>
      </>
    );
  }

  renderSpecificationSelect(customer, settings) {
    const { setCustomerSettings, isSubsidyLocal } = this.props;
    const { t } = this.props;

    if (!settings || !isSubsidyLocal) {
      return;
    }

    return (
      <>
        <FormGroup title={t('specification')}>
          <select
            disabled={!customer.isDiscounted || settings.isInfant}
            className={input.select}
            onChange={(event) =>
              setCustomerSettings('specification', event.target.value, customer.id)
            }
          >
            {this.renderOptions(customer, settings)}
          </select>          
        </FormGroup>
        {/* <div>
          <select
            disabled={!customer.isDiscounted || settings.isInfant}
            className={input.select}
            onChange={(event) =>
              setCustomerSettings('specification', event.target.value, customer.id)
            }
          >
            {this.renderOptions(customer, settings)}
          </select>
        </div>       */}
      </>
    );
  }

  renderOptions(customer, settings) {
    const { specifications } = this.state;
    const { t } = this.props;

    if (settings.isInfant) {
      return <option>{t('infant')}</option>;
    }

    if (!customer.isDiscounted) {
      return <option value={'other'}>{t('foreign')}</option>;
    }

    return Object.keys(specifications).map((key) => {
      return <option value={key}>{specifications[key]}</option>;
    });
  }

  renderPensionerApliance(customer, settings, render) {
    const {
      setCustomerSettings,
      reservation: { settingsValidations },
    } = this.props;
    const { t } = this.props;

    if (!settings || settings.specification !== 'pensioner' || !render) {
      return null;
    }

    const value = settings.pensionerCompanyEnd;
    const regex = settingsValidations.pensionerCompanyEnd;
    const isInvalid = value && regex && !new RegExp(regex).test(value);

    return (
      <div className="pensior-apliance">
        <span>
          <strong>{t('declarationPensioners')}</strong>
        </span>

        <div className="row">
          <span>{t('declarationWork')}</span>

          {this.pensionerSelect(customer)}

          <span>{t('day')}</span>

          <div className={isInvalid ? 'error' : ''}>
            <input
              className={'text-select-input'}
              name={'pensionerCompanyEnd'}
              autocomplete="on"
              value={settings.pensionerCompanyEnd}
              onChange={(event) =>
                setCustomerSettings('pensionerCompanyEnd', event.target.value, customer.id)
              }
            />
          </div>
        </div>
      </div>
    );
  }

  renderDataInput(param, customer, disabled = false) {
    const {
      reservation: { customerSettings, settingsValidations },
      setCustomerSettings,
    } = this.props;
    const { t } = this.props;
    const customerSetting = customerSettings[customer.id];
    const regex = settingsValidations[param];
    const isInvalid =
      customerSetting &&
      customerSetting[param] &&
      regex &&
      !new RegExp(regex).test(customerSetting[param]);

    return (
      <>
        <FormGroup title={t(param)}>
          <input
            className={input.input}
            value={customerSetting ? customerSetting[param] : ''}
            disabled={disabled}
            onChange={(event) => setCustomerSettings(param, event.target.value, customer.id)}
          />
        </FormGroup>
        {/* <div
          className={
            'reservation-input ' +
            (disabled ? 'disabled ' : '') +
            (param === 'title' ? 'title ' : '') +
            (isInvalid ? 'validation-error' : '')
          }
        >
          <input
            value={customerSetting ? customerSetting[param] : ''}
            disabled={disabled}
            onChange={(event) => setCustomerSettings(param, event.target.value, customer.id)}
          />
        </div>       */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    calculator: {
      occupation: { adult, children },
    },
    reservation: {
      customerSettings,
      requiredSettings,
      settingsValidations,
      requiredPensionerSettings,
    },
  } = state;
  let isReadyToSubmit = true;

  Object.keys(customerSettings).forEach((key) => {
    const isPensioner = customerSettings[key].specification === 'pensioner';

    const allFilled = (isPensioner && Object.keys(customerSettings)[0] === key
      ? requiredPensionerSettings
      : requiredSettings
    ).findIndex((required) => {
      const regex = settingsValidations[required];
      const value = customerSettings[key][required];

      if (value && regex && !new RegExp(regex).test(value)) {
        return key;
      }

      return !value;
    });

    if (allFilled !== -1) {
      isReadyToSubmit = false;
    }
  });

  const filledSecondStep =
    state.reservation.requiredSecondStep.findIndex((key) => {
      return !state.reservation.values[key];
    }) === -1;

  return {
    isReadyToSubmit: isReadyToSubmit && filledSecondStep,
    reservation: state.reservation,
    calculator: state.calculator,
    isSubsidy: state.calculator.settings.isSubsidy,
    isSubsidyLocal: getIsSubsidy(adult, children),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setParam: (param, value) => dispatch(setParam(param, value)),
  setCustomerSettings: (param, value, id) => dispatch(setCustomerSettings(param, value, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Radium(Reservation)));
