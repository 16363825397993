import Radium from "radium";
import ImageGallery from "react-image-gallery";
import React, { Component, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import gallery from "./gallery.module.scss";
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";

class RightDetailRow extends Component {

  state = {
    fixed: false,
    isGalleryOpen: false,
    selectedImageIndex: 0
  };

  render() {
    const {
      tourDetail: { photos },
      term
    } = this.props;
    const { isGalleryOpen, selectedImageIndex } = this.state;
    const formattedPhotos = this.getFormattedPhotos(photos);

    let mainPhoto;
    let sidePhotos = [];
    let otherPhotos = [];

    if (formattedPhotos) {
      mainPhoto = formattedPhotos[0];
      sidePhotos = formattedPhotos.slice(1, 3);
      otherPhotos = formattedPhotos.slice(3);
    }

    if (!mainPhoto) {
      return null;
    }

    return (
      <>
        <LightGallery
          elementClassNames={gallery.gallery}
          selector={".gallery-image"}
        >
          <div className={gallery.gallery__side}>
            {sidePhotos.map((photo, index) => (
              <a href={photo.original}
                 className={'gallery-image'}
              >
                <img alt="hotelPhoto" src={photo.original} width={200} />
              </a>
            ))}
          </div>
          <div className={gallery.gallery__main}>
            <a href={mainPhoto.original}
               className={'gallery-image'}
            >
              <img alt="hotelPhoto" src={mainPhoto.original} />
            </a>
          </div>
          <div className={gallery.gallery__thumbs} >
            {otherPhotos.map((photo, index) => (
              <a href={photo.original}
                 className={'gallery-image'}
                key={index}
              >
                <img alt="hotelPhoto" src={photo.original} width={120} />
              </a>
            ))}
          </div>
        </LightGallery>
      </>
    );
  }

  getLightGalleryPhotos(photos) {
    return photos.map((photo, index) => {
      return {
        id: index,
        src: photo.original,
      }
    });
  }

  openGallery(index) {
    lightGallery.current.openGallery();
  }

  renderImages(photo, width) {
    return (
      <a href={photo.original} onClick={(event) => {
        event.preventDefault();
        this.openGallery(2);
      }}>
        <img alt="hotelPhoto" src={photo.original} width={width} />
      </a>
    );
  }

  close(event) {
    if (event.keyCode === 27) {
      document.getElementsByClassName("image-gallery-fullscreen-button active")[0].click();
    }
  }

  onScreenChange(fullScreenElement) {
    this.setState({ fullscreen: fullScreenElement });
  }

  enterFullScreen() {
    this.imageGallery.fullScreen();
  }

  getFormattedPhotos(photos) {
    if (photos) {
      const formattedPhotos = photos.map((photo) => {
        const { imgSrc, preview } = photo;
        return { original: imgSrc, thumbnail: preview };
      });

      return formattedPhotos;
    }

    return null;
  }
}

RightDetailRow.propTypes = {
  tourDetail: PropTypes.any,
  term: PropTypes.any
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail
});

export default connect(mapStateToProps)(Radium(RightDetailRow));