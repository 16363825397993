import cookie from 'react-cookie';

const createFilterRequest = (filter, page, itemsPerPage, currentMin, currentMax, optional = {}) => {
  const {
    countrySortedByName,
    location,
    transport,
    diet,
    dateFrom,
    dateTo,
    days,
    isLastMinuteOrCustom,
    showTermList,
    sortBy,
    capacity,
  } = filter;
  const {
    changedShowTermList,
    changedLastMinute,
    settingPage,
    changedDateTo,
    changedDateFrom,
    changedSortBy,
    changedItemsPerPage,
  } = optional;

  let countrySortedByNameCopy = countrySortedByName;

  if (countrySortedByNameCopy[0] === '') {
    countrySortedByNameCopy = [];
  }

  const request = {
    page: page - 1,
    user: cookie.load('user'),
    minPrice: currentMin - 1,
    maxPrice: currentMax + 1,
    showTermList: typeof changedShowTermList !== 'undefined' ? changedShowTermList : showTermList,
    itemsPerPage: typeof changedItemsPerPage !== 'undefined' ? changedItemsPerPage : itemsPerPage,
    filter: {
      country: countrySortedByNameCopy,
      sortBy: typeof changedSortBy !== 'undefined' ? changedSortBy : sortBy,
      location,
      transport,
      capacity,
      diet,
      dateFrom: typeof changedDateFrom !== 'undefined' ? changedDateFrom : dateFrom,
      dateTo: typeof changedDateTo !== 'undefined' ? changedDateTo : dateTo,
      days,
      isLastMinuteOrCustom:
        typeof changedLastMinute !== 'undefined' ? changedLastMinute : isLastMinuteOrCustom,
    },
  };

  if (!settingPage) {
    request.page = 0;
  }

  return request;
};

export default createFilterRequest;
