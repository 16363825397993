import Helmet from 'react-helmet';
import ToursListHead from './SpecialOfferHead';
import Radium from 'radium';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RowImageGrey from '../../../shared/Rows/RowImageGrey';
import ToursListBody from './SpecialOfferBody';
import LoaderInModalWindow from '../../../shared/Loading/LoaderInModalWindow';
import { fetchSpecialList } from '../../../../actions/TourList/fetchTourList';
import createSpecialToursRequest from '../../../shared/RequestCreator/createSpecialToursRequest';
import { offerTitle, lastMinuteTitle } from '../../../../utils/titleConfig';
import { connect } from 'react-redux';
import hero from '../../Tours/ToursList/hero.module.scss';
import heroPicture from '../../../../img/heros/hero-lists.webp';
import { withTranslation } from 'react-i18next';
import Banner from '../../../Banner/Banner';
import Usp from '../../../Usp/Usp';

export const offerType = 'offer';
export const offerHeader = 'Výhodné nabídky';

class SpecialOffer extends Component {
  state = {
    showBody: false,
    fetched: false,
  };

  componentDidMount() {
    const {
      fetchSpecialList,
      params: { type },
      paginator: { itemsPerPage },
    } = this.props;

    fetchSpecialList(createSpecialToursRequest(1, type, itemsPerPage));
  }

  render() {
    const { type } = this.props.params;
    const {t} = this.props;
    const {
      fetchState: { specialListFetched },
      filterSettings: { filterName },
    } = this.props;

    if (!specialListFetched)
      return (
        <div>
          <Helmet title={type === offerType ? offerTitle : lastMinuteTitle} />
          <ToursListHead title={type === offerType ? offerHeader : filterName} />
          <RowImageGrey>
            <LoaderInModalWindow />
          </RowImageGrey>
        </div>
      );

    return (
      <>
        <Helmet title={offerTitle} />
        <section className={hero.hero}>
          <picture className={hero.hero__picture}>
						<img src={heroPicture} alt="Pro vaše nejlepší zážitky z cestování" />
					</picture>
          <div className={hero.hero__content}>
            <div className="container">
              <div className="grid gap">
                <div className="cell cell--12 cell--6:lg">
                  <article className={hero.hero__article}>
                    <h1 className="title margin--bottom-1 color--white">{t('specialOfferTitle')}</h1>
                    <p className="lead margin--bottom-2 color--white">{t('specialOfferDesc')}</p>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>      
        <ToursListBody type={type} />
        <section className="section section--global section--banner">
          <div className="container">
            <div className="grid">
              <div className="cell">
                <Banner />
              </div>
            </div>
          </div>
        </section>
        <section className='section section--global' style={{paddingTop: 0}}>
          <div className="container">
            <div className="grid">
              <div className="cell">
                <Usp/>
              </div>
            </div>
          </div>
        </section>        
        {/* <div>
          <Helmet title={offerTitle} />
          <ToursListHead title={type === offerType ? offerHeader : filterName} />
          <RowImageGrey>
            <ToursListBody type={type} />
          </RowImageGrey>
        </div>       */}
      </>
    );
  }
}

SpecialOffer.propTypes = {
  fetchSpecialList: PropTypes.any,
  fetchState: PropTypes.any,
  paginator: PropTypes.any,
  params: PropTypes.any,
  closeTabs: PropTypes.any,
};

const mapStateToProps = (state) => ({
  fetchState: state.fetchState,
  paginator: state.paginator,
  filterSettings: state.filterSettings,
  specialTourList: state.specialTourList,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSpecialList: (pagePropertie) => dispatch(fetchSpecialList(pagePropertie)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Radium(SpecialOffer)));
