import PropTypes from 'prop-types';
import React, { Component } from 'react';
import card from './card.module.scss';
import {Link} from 'react-router';

class ContactCard extends Component {

	render(){
		const {contactPerson: {type, photo, name, countries, phone, mobile, email}} = this.props;
  	const emailLink = 'mailto:' + email + '?Subject=Zpráva z webu ČD Travel';

		return (
			<div className="cell cell--6:md cell--4:lg align--center margin--bottom-24">
				<div className={card.card}>
					<picture className={card.card__image}>
						<img src={photo} alt={name} width={64} height={64} />
					</picture>
					<div className={card.card__content}>
						<h2 className="title">{name}</h2>
						<p className="text--14 color--gray-900 margin--bottom-0 align--left" style={{marginBottom: '0.5rem'}}>{countries}</p>
						<Link className="link link--underline text--14 color--primary" href={emailLink}>{email}</Link>
						<div className={card.card__phone}>
							<Link className="link link--short text--14 color--primary" to={phone}>{phone}</Link>
							<span className="color--primary text--14 color--primary">,&nbsp;</span>
							<Link className="link link--short text--14 color--primary" to={mobile}>{mobile}</Link>
						</div>
					</div>
				</div>
			</div>
		)
	};
}

ContactCard.propTypes = {
	contactPerson: PropTypes.shape ({
		type: PropTypes.any.isRequired,
  	photo: PropTypes.any.isRequired,
  	name: PropTypes.string.isRequired,
  	countries: PropTypes.string,
  	phone: PropTypes.string,
  	mobile: PropTypes.string,
  	email: PropTypes.string,
	})
};

export default ContactCard;
