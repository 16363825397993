import MenuItems from './MenuItems';
import constants from '../../../styles/constants.js';
import Radium from 'radium';
import React, { Component } from 'react';

class MenuDesktop extends Component {
  render() {
    return (
      <nav id="nav" className="nav">
        <MenuItems type="desktop" />
      </nav>
    );
  }
}

export default Radium(MenuDesktop);
