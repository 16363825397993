import constants from '../../../../../styles/constants';
import cookie from 'react-cookie';
import { employee } from '../../../../../actions/HP/defaultTourlist';
import Instructions from '../Panels/Instructions';
import Description from '../Panels/Informations/Description';
import Occupation from '../Panels/Occupation/Occupation';
import { pickTour } from '../../../../../actions/TourDetail/tourDetail';
import Radium from 'radium';
import Departure from '../Panels/Departure/Departure';
import crosLineImg from '../../../../../img/crossLine.png'; // eslint-disable-line
import Reviews from '../Panels/Reviews/Reviews';
import WeatherForecast from '../Panels/WeatherForecast/WeatherForecast';
import Measure from 'react-measure';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import windowDimensions from 'react-window-dimensions';
import { hashHistory } from 'react-router';
import styles from './tabs.module.scss';
const user = cookie.load('user');

const indexes = {
  title: 0,
  weather: 1,
  review: 2,
  instructions: 3,
  transport: 4,
};

class HotelTabs extends Component {
  render() {
    const {
      tourDetail: {
        rating: { reviewsCount },
      },
      term,
      params,
    } = this.props;
    const currentLocation = hashHistory.getCurrentLocation();
    const paramsReal = currentLocation.query;
    let defaultIndex = 0;

    if (paramsReal.tab) {
      defaultIndex = indexes[paramsReal.tab];
    }

    return (
      <Tabs defaultIndex={defaultIndex} className={styles.tabs}>
        <TabList className={styles.tabs__nav}>
          {this.renderTab('Popis', 'title')}
          {/*{this.renderTab('Obsazenost')}*/}
          {this.renderTab('Počasí', 'weather')}
          {this.renderTab('Recenze (' + reviewsCount + ')', 'review')}
          {this.renderTab('Pokyny', 'instructions')}
          {this.renderTab('Doprava', 'transport')}
        </TabList>
        <TabPanel>
          <Description term={term}/>
        </TabPanel>
        {/*<TabPanel>*/}
        {/*  <Occupation />*/}
        {/*</TabPanel>*/}
        <TabPanel>
          <WeatherForecast />
        </TabPanel>
        <TabPanel>
          <Reviews />
        </TabPanel>
        <TabPanel>
          <Instructions dataFetched={Boolean(true)} params={params} />
        </TabPanel>
        <TabPanel>
          <Departure term={term} />
        </TabPanel>
      </Tabs>
    );
  }

  getWrapperStyle() {
    const { width } = this.props;

    if (width < 571) {
      return styles.tabsWrapper.phone;
    } else if (width < 750) {
      return styles.tabsWrapper.tablet;
    } else {
      return styles.tabsWrapper.desktop;
    }
  }

  renderTab(title, key) {
    return (
      <Tab className={styles.tabs__item} selected={key === 'weather'}>
        <a className={styles.tabs__link} onClick={() => this.handleTabSelect(key)} title={title} key={key}>
          {title}
        </a>
        {/* <div onClick={() => this.handleTabSelect(key)}>
          <div style={styles.tabsWrapper.tab.text} key={key}>
            {title}
          </div>
        </div> */}
      </Tab>
    );
  }

  handleTabSelect(key) {
    const currentLocation = hashHistory.getCurrentLocation();

    const query = currentLocation.query;
    query.tab = key;

    hashHistory.push({
      pathname: currentLocation.pathname,
      query,
    });
  }
}

HotelTabs.propTypes = {
  tourDetail: PropTypes.any,
  term: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => ({
  pickTour: (idTour) => dispatch(pickTour(idTour)),
});

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
});

export default connect(mapStateToProps, mapDispatchToProps)(windowDimensions()(Radium(HotelTabs)));
