import logoDefault from '../../img/cd-travel_logo.svg';
import logoLight from '../../img/cd-travel_logo-white.svg'; // eslint-disable-line
import React, { Component } from 'react';
import {Link} from 'react-router';
import logo from './logo.module.scss';

export default class Logo extends Component {
  render() {
    const { isLight } = this.props;
    return (
      <>
        <Link className={logo.logo} to="/zajezdy/vypis/">
          {isLight ?
            <img src={logoLight} width={120} height={43} loading="lazy" alt="ČD Travel - cestovní kancelář" />
            :
            <img src={logoDefault} width={160} height={58} loading="lazy" alt="ČD Travel - cestovní kancelář" />
          }
        </Link>
      </>
    );
  }
}
