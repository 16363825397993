import projectColors from '../../../../styles/projectColors';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const styles = {
  h2: {
    fontSize: '1em',
    fontWeight: '600',
    marginTop: '0',
    padding: '6px 8px',
    textAlign: 'left',
    background: [projectColors.mainBlue],
    color: 'white',
    marginBottom: 0,
  },
};

export default class TourTitle extends Component {
  render() {
    const { children } = this.props;

    return <h2 className="h2 color--primary-highdark">{children}</h2>;
  }
}

TourTitle.propTypes = {
  children: PropTypes.any.isRequired,
};
