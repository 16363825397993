import constants from '../../../../../styles/constants';
import Radium from 'radium';
import selectArrow from '../../../../../img/select-arrow2.png'; // eslint-disable-line
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeTabs, selecting, toggle } from '../../../../../actions/TourList/filterOpenStatus';
import createFilterRequest from '../../../../shared/RequestCreator/createFilterRequest';
import {
  resetFilter,
  resetLocations,
  toggleResetingLocations,
  toggleSelected,
} from '../../../../../actions/TourList/tourFilter';
import { fetchFilteredList } from '../../../../../actions/TourList/fetchTourList';
import { toggleTiles } from '../../../../../actions/TourList/paginator';
import { saveFilter } from '../../../../../actions/TourList/filter';
import PropTypes from 'prop-types';
import arrowDown from '../../../../../img/down-arrow.svg';

import components from './components.module.scss';
import sprite from '../../../../../img/icons/sprite.svg';

export const styles = {};

const typeCountry = 'countrySortedByName';
const typeLocation = 'location';

class MultiCheckbox extends Component {
  state = {
    text: '',
    loading: true,
  };

  componentDidMount() {
    this.checkChange(this.props);
  }

  componentWillReceiveProps(props) {
    this.checkChange(props);
  }

  render() {
    const { defaultText, data, filterOpen, type, box, selectIcon } = this.props;
    const { text } = this.state;

    return (
      <>
        <div className={components.select}>
          <div onClick={() => this.toggleOpen(type)} className={components.select__field}>
            <span>{text || defaultText}</span>
            <svg className="icon" role="presentation" width="24" height="24">
              <use xlinkHref={sprite + '#sprite-' + selectIcon}/>
            </svg>                
          </div>
          <div className={components.select__dropdown}>
            {filterOpen[type] && data.map((items) => this.renderBox(items, box))}
          </div>          
        </div>
      </>

    );
  }

  toggleOpen(designation) {
    const { toggle, filterOpen, type, closeTabs } = this.props;
    if (!filterOpen[type]) {
      toggle(designation);
    } else {
      closeTabs();
    }
  }

  reset() {
    const { resetFilter, type, resetLocations, saveFilter } = this.props;
    const { text } = this.state;

    if (type === typeCountry) {
      resetLocations(text);
      saveFilter([], typeLocation);
    }

    resetFilter(type);
  }

  renderBox(items, box) {
    const { value, code, selected } = items;

    if (box === 'textbox') {
      return (
        <div

          value={code}
          onClick={() => this.toggleCheckbox(code, false)}
        >
          {value}
        </div>
      );
    } else {
      return (
        <div
          onClick={this.toggleCheckbox.bind(this, code, true)}
        >
          <input type="checkbox" value={code} checked={selected} />
          <label>{value}</label>
        </div>
      );
    }
  }

  checkChange(props) {
    const { defaultText } = this.state;
    const {
      data,
      type,
      filter,
      saveFilter,
      paginator: { itemsPerPage, page, minCost, maxCost },
      fetchFilteredList,
    } = props;
    let checkboxText = '';
    const propertyFilter = [];

    try {
      data.forEach((data) => {
        if (data.selected) {
          checkboxText = checkboxText ? checkboxText + ' ' + data.value : data.value;
          propertyFilter.push(data.code);
        }
      });
      if (checkboxText === '' || checkboxText === '---') {
        checkboxText = defaultText;
      }
    } catch (err) {
      checkboxText = defaultText;
    }
    this.setState({ text: checkboxText });
    if (JSON.stringify(propertyFilter) !== JSON.stringify(filter[type])) {
      filter[type] = propertyFilter;

      if (checkboxText === defaultText) {
        filter[type] = [];
      }

      fetchFilteredList(createFilterRequest(filter, page, itemsPerPage, minCost, maxCost));
      saveFilter(propertyFilter, type);
    }
  }

  toggleCheckbox(code, checkbox) {
    const { type, toggleSelected, selecting, toggleTiles, toggleResetingLocations } = this.props;
    const {
      paginator: { resetingLocations },
      onSelect,
    } = this.props;
    onSelect && onSelect();
    toggleSelected(code, type, checkbox);
    if (checkbox) {
      selecting();
    }
    if (!resetingLocations) {
      toggleResetingLocations(true);
    }
    toggleTiles(false);
  }
}

MultiCheckbox.propTypes = {
  defaultText: PropTypes.any,
  saveFilter: PropTypes.any,
  resetLocations: PropTypes.any,
  data: PropTypes.any,
  filterOpen: PropTypes.any,
  type: PropTypes.any,
  box: PropTypes.any,
  toggle: PropTypes.any,
  closeTabs: PropTypes.any,
  toggleSelected: PropTypes.any,
  toggleResetingLocations: PropTypes.any,
  paginator: PropTypes.any,
  resetFilter: PropTypes.any,
  selecting: PropTypes.any,
  toggleTiles: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => ({
  toggle: (designation) => dispatch(toggle(designation)),
  selecting: () => dispatch(selecting()),
  resetFilter: (designation) => dispatch(resetFilter(designation)),
  resetLocations: (designation) => dispatch(resetLocations(designation)),
  toggleResetingLocations: (status) => dispatch(toggleResetingLocations(status)),
  fetchFilteredList: (filters) => dispatch(fetchFilteredList(filters)),
  toggleTiles: (nextState) => dispatch(toggleTiles(nextState)),
  closeTabs: () => dispatch(closeTabs()),
  saveFilter: (filter, designation) => dispatch(saveFilter(filter, designation)),
  toggleSelected: (code, designation, checkbox) =>
    dispatch(toggleSelected(code, designation, checkbox)),
});

const mapStateToProps = (state) => ({
  filter: state.filter,
  paginator: state.paginator,
  filterOpen: state.filterOpen,
  tourList: state.tourList,
  tourFilter: state.tourFilter,
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(MultiCheckbox));
