import ToursListHead from "./ToursListHead";
import Radium from "radium";
import React, { Component } from "react";
import PropTypes from "prop-types";
import RowImageGrey from "../../../shared/Rows/RowImageGrey";
import ToursListBody from "./components/ToursListBody";
import HotelsMap from "./HotelsMap/HotelsMap";
import { vypisTitle } from "../../../../utils/titleConfig";
import LoaderInModalWindow from "../../../shared/Loading/LoaderInModalWindow";
import { fetchFilteredList, fetchTourList } from "../../../../actions/TourList/fetchTourList";
import windowDimensions from "react-window-dimensions";
import { Link } from "react-router";
import {
  toggleSelected,
  fetchTourFilter,
  saveIncluded,
  saveIncludedSelectedLocations,
  toggleResetingLocations,
  setSelected
} from "../../../../actions/TourList/tourFilter";
import { fetchEssentialData } from "../../../../actions/TourList/Map/hotelsMap";
import { closeTabs } from "../../../../actions/TourList/filterOpenStatus";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import Tiles from "./Tiles";
import Banner from "../../../Banner/Banner";
import Usp from "../../../Usp/Usp";
import Widget from "../../../Widget/Widget";
import widgetImgSoot from "../../../../img/banners/special-offer-own-transport.webp";
import widgetImgSoot2 from "../../../../img/banners/special-offer-plane-transport.webp";
import { withTranslation } from "react-i18next";
import createFilterRequest from "../../../shared/RequestCreator/createFilterRequest";
import { saveFilter } from "../../../../actions/TourList/filter";
import { toggleTiles } from "../../../../actions/TourList/paginator";

const byAir = [
  "A2",
  "A3",
  "A1",
  "B1"
];

const ownTransport = [
  "VLD"
];

const CountriesWithSea = [
  55,
  23,
  53,
  51,
  52,
  65,
  18,
  27,
  116
];

class ToursList extends Component {
  componentDidMount() {
    const {
      fetchState: { filterFetched, mapDataFetched },
      fetchTourFilter,
      fetchEssentialData,
      toggleResetingLocations
    } = this.props;

    if (!filterFetched) {
      fetchTourFilter();
    }

    if (!mapDataFetched) {
      fetchEssentialData();
    }

    toggleResetingLocations(false);
  }

  render() {
    const {
      paginator: { tilesVisible },
      fetchState: { filterFetched, mapDataFetched },
      params,
      setSelected,
      tourFilter: { countrySortedByName },
      toggleTiles,
    } = this.props;
    const { t } = this.props;

    const selectedCountry = sessionStorage.getItem('country');

    if (selectedCountry) {
      if (countrySortedByName) {
        const country = countrySortedByName.find(x => x.value === selectedCountry);

        if (country) {
          setSelected(country.code, 'countrySortedByName')
          toggleTiles(false)

          sessionStorage.removeItem('country');
        }
      }
    }

    if (!filterFetched || !mapDataFetched)
      return (
        <>
          <Helmet title={vypisTitle} />
          <ToursListHead />

          <RowImageGrey>
            <LoaderInModalWindow />
          </RowImageGrey>
        </>
      );

    return (
      <div onClick={() => this.closeAllTabs()}>
        <Helmet title={vypisTitle} />

        <ToursListHead type={params.type} />

        {tilesVisible ?
          <>
            <section className="section section--medium" id="ubytovani">
              <div className="container">
                <div className="grid">
                  <div className="cell">
                    <h2 className="title margin--bottom-2">
                      {t("specialOfferTitle")}
                    </h2>
                  </div>
                </div>
                <div className="grid gap">
                  <div className="cell cell--6:md margin--bottom-2">
                    <Widget
                      onClick={() => this.filter(CountriesWithSea, byAir)}
                      title={t('travelByBus')}
                      desc={t('travelByBusDesc')}
                      link="#"
                      image={widgetImgSoot2}
                    />
                  </div>
                  <div className="cell cell--6:md margin--bottom-2">
                    <Widget
                      onClick={() => this.filter([], ownTransport)}
                      title={t('travelByOwn')}
                      desc={t('travelByOwnDesc')}
                      link="#"
                      image={widgetImgSoot}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="section">
              <div className="container">
                <div className="grid">
                  <div className="cell">
                    <h2 className="title margin--bottom-2">
                      {t("ourDestination")}
                    </h2>
                  </div>
                </div>
                <div className="grid gap margin--bottom-1:lg">
                  <Tiles />
                </div>
                {/* <div className="grid">
                  <div className="cell align--center">
                    <Link to="" className="button button--primary" style={{ width: 230 }}>{t("loadMore")}</Link>
                  </div>
                </div> */}
              </div>
            </section>
          </>
          :
          <ToursListBody />
        }

        {/* {this.renderMap()} */}

        <section className="section section--global section--banner">
          <div className="container">
            <div className="grid">
              <div className="cell">
                <Banner />
              </div>
            </div>
          </div>
        </section>
        <section className="section section--global" style={{ paddingTop: 0 }}>
          <div className="container">
            <div className="grid">
              <div className="cell">
                <Usp />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  filter(countryFilter, travelFilter) {
    const {
      toggleTiles
    } = this.props;
    const {
      tourFilter: { countrySortedByName, transport, diet, days, capacity },
      fetchState: { filterFetched }
    } = this.props;

    console.log(countrySortedByName)
    countrySortedByName.forEach((country) => {
      if (countryFilter.includes(country.code)) {
        country.selected = 1;
      }
    });

    transport.forEach((transport) => {
      if (travelFilter.includes(transport.code)) {
        transport.selected = 1;
      }
    });

    toggleTiles(false);
  }

  renderMap() {
    const { width } = this.props;

    return null;

    if (width < 1024) {
      return null;
    }

    return <HotelsMap />;
  }

  closeAllTabs() {
    const { closeTabs } = this.props;
    closeTabs();
  }
}

ToursList.propTypes = {
  fetchState: PropTypes.any,
  fetchTourFilter: PropTypes.any,
  fetchLocations: PropTypes.any,
  toggleResetingLocations: PropTypes.any,
  paginator: PropTypes.any,
  params: PropTypes.any,
  closeTabs: PropTypes.any
};

const mapStateToProps = (state) => ({
  filter: state.filter,
  fetchState: state.fetchState,
  paginator: state.paginator,
  tourList: state.tourList,
  tourFilter: state.tourFilter,
  selectedLocations: state.selectedLocations,
  defaultTourListFilter: state.defaultTourListFilter
});

const mapDispatchToProps = (dispatch) => ({
  fetchTourFilter: () => dispatch(fetchTourFilter()),
  toggleTiles: (nextState) => dispatch(toggleTiles(nextState)),
  fetchEssentialData: () => dispatch(fetchEssentialData()),
  saveIncluded: (desingation, filter) => dispatch(saveIncluded(desingation, filter)),
  saveIncludedSelectedLocations: (result) => dispatch(saveIncludedSelectedLocations(result)),
  toggleResetingLocations: (status) => dispatch(toggleResetingLocations(status)),
  fetchTourList: () => dispatch(fetchTourList()),
  toggleSelected: (code, designation, checkbox) =>
    dispatch(toggleSelected(code, designation, checkbox)),
  closeTabs: () => dispatch(closeTabs()),
  fetchFilteredList: (filters) => dispatch(fetchFilteredList(filters)),
  saveFilter: (filter, designation) => dispatch(saveFilter(filter, designation)),
  setSelected: (code, designation) =>
    dispatch(setSelected(code, designation)),
});

export default connect(mapStateToProps, mapDispatchToProps)(windowDimensions()(withTranslation()(Radium(ToursList))));
