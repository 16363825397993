import React, { Component } from 'react';
import { Link } from 'react-router';
import Radium from 'radium';

import constants from '../../styles/constants';
import mailIcon from '../../img/mail-icon-blue.png'; // eslint-disable-line
import facebook from '../../img/facebook.svg'; // eslint-disable-line
import phoneIcon from '../../img/phone.png'; // eslint-disable-line
import { cdtravelFb } from '../Pages/FrontCrossRoad/CrossRoadLinks';

const styles = {
  wrapper: {
    right: 0,
    top: '2px',
    display: 'block',
    position: 'absolute',

    [constants.breakpoint600]: {
      display: 'none',
    },
  },
  facebookButton: {
    marginRight: 15,
    width: 22,
    height: 22,
  },
  phoneWrapper: {
    width: '311px',
    position: 'absolute',
    right: '0',
    top: '9px',
    marginLeft: '7px',

    [constants.breakpointLargeTablet]: {
      width: '283px',
    },

    [constants.breakpoint600]: {
      marginLeft: '5px',
    },
  },
  mailWrapper: {
    float: 'left',

    desc: {
      position: 'absolute',
      width: 'auto',
      left: '-118px',
      marginTop: '-2px',
      padding: '3px 7px 4px',
      background: '#E3E3E3',
      fontSize: '0.9em',
      color: '#386696',
      display: 'block',

      [constants.breakpoint850]: {
        display: 'none',
      },

      arrow: {
        display: 'inline-block',
        width: '0',
        height: '0',
        marginRight: '-44px',
        transform: 'rotate(-90deg)',
        marginTop: '-1px',
        verticalAlign: 'middle',
        borderTop: '8px dashed #E3E3E3',
        borderRight: '8px solid transparent',
        borderLeft: '8px solid transparent',
      },
    },
    icon: {
      width: '21px',
      marginTop: 0,
      height: 'auto',
      marginRight: '4px',
    },
  },
  phoneNumber: {
    fontSize: '14px',
    fontWeight: '400',
    color: '#000',

    icon: {
      width: '19px',
      marginRight: '8px',
      marginLeft: '12px',
      float: 'left',
    },
    box: {
      float: 'left',
      position: 'relative',
      marginLeft: '25px',
      top: '2px',
      lineHeight: '15px',

      block: {
        paddingLeft: '25px',
        display: 'inline-block',
      },
      bold: {
        fontWeight: '600',
        paddingLeft: 4,
        color: '#386696',
      },
      title: {
        color: '#386696',
        float: 'left',
        display: 'inline',
      },
    },
  },
};

class HeaderContacts extends Component {
  state = {
    hoveredMail: false,
  };

  render() {
    const { hoveredMail } = this.state;

    return (
      <div style={styles.wrapper}>
        <div style={styles.phoneWrapper}>
          <a href={cdtravelFb} target="_empty" style={styles.mailWrapper}>
            <img style={styles.facebookButton} src={facebook} />
          </a>
          <div
            style={styles.mailWrapper}
            onMouseEnter={() => this.setState({ hoveredMail: true })}
            onMouseLeave={() => this.setState({ hoveredMail: false })}
          >
            {hoveredMail && this.renderMailDesc()}
            <a href="mailto: ckobch@cdtravel.cz">
              <img style={styles.mailWrapper.icon} src={mailIcon} alt="phone" />
            </a>
          </div>

          <div style={styles.phoneNumber}>
            <div style={styles.phoneNumber.box}>
              <span style={styles.phoneNumber.box.block}>
                <Link to="zajezdy/vypis">
                  <div style={styles.phoneNumber.box.title}>
                    Kontakt |<span style={styles.phoneNumber.box.bold}>972 243 051-5</span>
                  </div>
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderMailDesc() {
    return (
      <div style={styles.mailWrapper.desc}>
        Napište nám
        <div style={styles.mailWrapper.desc.arrow} />
      </div>
    );
  }
}

export default Radium(HeaderContacts);
