import constants from '../../../../../../styles/constants.js';
import Radium from 'radium';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './description.module.scss';
import HotelInformations from "../HotelInformations";
import { withTranslation } from 'react-i18next';

class Description extends Component {
  render() {
    const {
      term,
      tourDetail: { description },
    } = this.props;
    const {t} = this.props;

    return (
      <div className={styles.description}>
        <h2 className="title">{t('tripDetails')}</h2>
        <HotelInformations term={term}/>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    );
  }
}

Description.propTypes = {
  tourDetail: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
});

export default connect(mapStateToProps)(withTranslation()(Radium(Description)));
