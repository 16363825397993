import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Radium from 'radium';
import constants from '../../../../../styles/constants';
import {
  fetchEssentialData,
  fetchAdditionalData,
} from '../../../../../actions/TourList/Map/hotelsMap';
import { Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react';
import StyledCircleLoader from '../../../../shared/Loading/StyledCircleLoader';
import Map from './Map';

const styles = {
  wrapper: {
    textAlign: 'center',
  },
  mapBox: {
    top: '95%',
    position: 'relative',
    width: '100%',
    zIndex: '9',
    color: '#fff',
    textShadow: '0 0 3px black',
    fontSize: '18px',
  },
  linkBox: {
    marginBottom: '40px',
    link: {
      color: '#444',

      ':hover': {
        color: [constants.mainBlue],
      },
    },
  },
  map: {
    width: '100%',
    height: '800px',
    position: 'relative',
  },
  mapLoading: {
    position: 'relative',
    width: '100px',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
};

class HotelsMap extends Component {
  state = {
    selectedPlace: {
      data: {},
    },
    selectedImage: 0,
    fetchingAdditionalData: false,
  };

  componentDidMount() {
    const { fetchAdditionalData } = this.props;

    fetchAdditionalData();
  }

  renderMap() {
    const {
      hotelsMap: { data },
    } = this.props;

    if (!Object.keys(data).length) {
      return (
        <>
        {/* <div style={styles.mapLoading}>
          <StyledCircleLoader />
        </div>         */}
        </>
      );
    }
    return <Map />;
  }

  render() {
    return (
      <div id="map">
        <div style={styles.mapBox}>{this.renderMap()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  hotelsMap: state.hotelsMap,
});

const mapDispatchToProps = (dispatch) => ({
  fetchEssentialData: () => dispatch(fetchEssentialData()),
  fetchAdditionalData: () => dispatch(fetchAdditionalData()),
});

const mapsConnected = connect(mapStateToProps, mapDispatchToProps)(HotelsMap);

export default GoogleApiWrapper({
  apiKey: 'AIzaSyD0YEKaHNBpZm8U2VC5rmm0TFSgEvSksgM',
})(Radium(mapsConnected));
