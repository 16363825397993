import styles from '../../../../shared/TourListStyles/TourListItemStyles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import format from '../../../../shared/FormatDescription';
import TourSticker from '../components/TourSticker';
import { connect } from 'react-redux';
import { hashHistory, Link } from 'react-router';
import { fetchTourDetail } from '../../../../../actions/TourDetail/fetchTourDetail';
import TermPicker from '../components/TermPicker';
import formatCurrency from '../../../../shared/formatCurrency';
import { zajezdyDetail } from '../../../../../utils/routeConfig';
import { withTranslation } from 'react-i18next';

export const soldOut = 'soldOut';
const typeOffer = 'offer';

class ToursListItem extends Component {
  state = {
    openModalTerms: false,
    pickerOpened: false,
  };

  render() {
    const {
      title,
      description,
      destination,
      image,
      tour,
      tour: { specialState, lowestPrice },
    } = this.props;
    const { pickerOpened } = this.state;
    const termSoldOut = lowestPrice === soldOut;
    const { t } = this.props;

    return (
      <>
        <div className="cell cell--12 cell--6:md cell--4:lg margin--bottom-24">
          <Link className="object" onClick={() => this.handleOpenTour(tour)}>
            {specialState && this.renderSticker(specialState)}
            <div className="object__header">
              <picture>
                <img src={image} alt={title} />
              </picture>
            </div>
            <div className="object__body">
              <h3 className="title">{title}</h3>
              <ul className="list list--breadcrumbs">
                <li className="text--14 color--primary-highdark">
                  <strong className="text--semi">{destination}</strong>
                </li>
                <li className="text--14 color--primary-highdark">
                  <strong className="text--semi">{tour.country}</strong>
                </li>
              </ul>
              <p
                className="text--14 color--primary-highdark"
                dangerouslySetInnerHTML={{ __html: format(description) }}
              />
            </div>
            <div className="object__footer">
              <div className="button button--small button--primary">{t('availableOptions')}</div>
              <div className="object__price">
                {lowestPrice !== soldOut ? (
                  <>
                    <span className="text--16 color--primary-highdark">{t('od')}</span>
                    <strong className="color--secondary">{formatCurrency(lowestPrice)}</strong>
                  </>
                ) : (
                  <>
                    <strong className="color--secondary">{t('soldOut')}</strong>
                  </>
                )}
              </div>
            </div>
          </Link>
        </div>
      </>
    );
  }
  handleOpenTour(tour) {
    hashHistory.push(zajezdyDetail + tour.idTour + '?onlyHotel=1');
  }

  renderSticker(specialState) {
    const { type, value } = specialState;
    const {
      filterSettings: { filterName, primaryColor, secondaryColor },
    } = this.props;

    return (
      <TourSticker
        type={styles.stickerTypes.hotel}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
      >
        {type === typeOffer ? 'Výhodná nabídka' : filterName}
      </TourSticker>
    );
  }

  isAvailableRowItems(isSpecialOffer, tourLength) {
    const { t } = this.props;

    return (
      <div style={styles.descriptionBox.termItem.termText.length}>
        {isSpecialOffer ? (
          <span style={styles.descriptionBox.termItem.termSpecialOffer}>{t('favorableOffer')}</span>
        ) : (
          <span>
            <strong>{t('length')}</strong> {tourLength}
          </span>
        )}
      </div>
    );
  }
}

ToursListItem.propTypes = {
  title: PropTypes.any.isRequired,
  link: PropTypes.string.isRequired,
  description: PropTypes.string,
  destination: PropTypes.string,
  tour: PropTypes.string,
  image: PropTypes.string,
  id: PropTypes.string,
  termList: PropTypes.array,
  filter: PropTypes.any,
  stickerPriority: PropTypes.any,
  filterSettings: PropTypes.any,
};

const mapStateToProps = (state) => ({
  filter: state.filter,
  filterSettings: state.filterSettings,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTourDetail: (id) => dispatch(fetchTourDetail(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Radium(ToursListItem)));
