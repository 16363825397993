import React, { Component } from 'react';
import { Link } from 'react-router';
import Radium from 'radium';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import { formatCurrency } from '../../calculations';
import { toggleAdditionalPrice } from '../../../../../actions/Calculator/calculator';
import input from '../../../../shared/forms/input.module.scss';
import { withTranslation } from 'react-i18next';

class AdditionalPrices extends Component {
  render() {
    const {
      calculator: {
        settings: { discount, surcharge, optionalSurcharge },
      },
    } = this.props;
    const {
      calculator: { occupation },
    } = this.props;

    return (
      <>
        {discount.map((discountObject) => this.renderAdditionalPrice(discountObject, 'discount'))}

        {surcharge.map((surchargeObject) =>
          this.renderAdditionalPrice(surchargeObject, 'surcharge'),
        )}

        {optionalSurcharge.map((optionalSurchargeObject) =>
          this.renderAdditionalPrice(optionalSurchargeObject, 'optionalSurcharge'),
        )}
      </>
    );
  }

  renderAdditionalPrice(additionalPrice, additionalpriceType) {
    const { index, toggleAdditionalPrice, type } = this.props;
    const {
      calculator: { occupation },
      isSubsidy,
    } = this.props;
    const { t } = this.props;
    const setting = occupation[type][index][additionalpriceType];    

    if (additionalPrice.type !== 2) {
      return null;
    }

    return (
      <>
        <div className={`${input.form__group} ${input['form__group--inline']}`}>
          <input
            type="checkbox"
            className={input.checkbox}
            checked={setting.checked}
            onClick={() => toggleAdditionalPrice(type, index, additionalPrice, additionalpriceType)}
          />
          <label className={input.label}>
            {additionalPrice.title} ({formatCurrency(additionalPrice.price)})
          </label>          
        </div>      
        {/* <div>
        <label className={'custom-checkbox' + (isSubsidy ? ' subsidy' : ' ')}>
          {additionalPrice.title} ({formatCurrency(additionalPrice.price)})
          <input
            type="checkbox"
            checked={setting.checked}
            onClick={() => toggleAdditionalPrice(type, index, additionalPrice, additionalpriceType)}
          />
          <span className="checkmark" />
        </label>
      </div>         */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  calculator: state.calculator,
  isSubsidy: state.calculator.settings.isSubsidy,
});

const mapDispatchToProps = (dispatch) => ({
  toggleAdditionalPrice: (type, index, additionalPrice, additionalPriceType) =>
    dispatch(toggleAdditionalPrice(type, index, additionalPrice, additionalPriceType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Radium(AdditionalPrices)));
