import constants from "../../../../../../styles/constants";
import Radium from "radium";
import React, { Component } from "react";
import PropTypes from "prop-types";
import OrangeButton from "../../../../../shared/OrangeButton";
import { connect } from "react-redux";
import { loadSeznamScripts } from "../../../../../shared/loadMap";
// import './Maps.scss';
import styles from "./maps.module.scss";
import Dialog from "material-ui/Dialog";
import { togetherTravelingReply } from "../../../../TogetherTravelling/AdLine";
import InputModalAlert from "../../../../../shared/InputModalAlert";
import { postSucceeded } from "../../../../../../actions/TogetherTraveling/postPassengers";
import MapDetail from "./MapDetail";


// const styles = {
//   wrapper: {
//     textAlign: 'center',
//     width: '100%',
//     height: '500px',
//     overflow: 'hidden',
//     position: 'relative',

//     [constants.breakpointTablet]: {
//       marginTop: '0',
//     },
//   },
// };

class Maps extends Component {
  state = {
    opened: false
  };
  componentDidMount() {
    loadSeznamScripts(this);
  }

  componentDidUpdate() {
    const { SMap } = window;
    const { mapLoaded } = this.state;
    const {
      tourDetail: { gps }
    } = this.props;

    if (mapLoaded) {
      this.createMap();
    }
  }

  createMap() {
    const { SMap, JAK } = window;
    const { mapInstance } = this.state;
    const {
      tourDetail: { gps }
    } = this.props;

    if (mapInstance) {
      return mapInstance;
    }

    const center = SMap.Coords.fromWGS84(gps[1], gps[0]);
    const map = new SMap(JAK.gel("map"), center, 17);

    map.addDefaultLayer(SMap.DEF_BASE).enable();
    map.addDefaultControls();

    const layer = new SMap.Layer.Marker();
    map.addLayer(layer);
    layer.enable();

    var options = {};
    var marker = new SMap.Marker(center, "myMarker", options);
    layer.addMarker(marker);

    this.setState({ mapInstance: map });

    return map;
  }

  openMap(){
    this.setState({opened: true})
  }

  render() {
    const { isSmall } = this.props;
    return (
      <>
        {isSmall &&
          <>
            <div className={`${styles.map} margin--bottom-4`}>
              <div id="map" className={styles.map__small} />
              <button className="button button--small button--primary"
              onClick={() => this.openMap()}>
                Zobrazit na mapě</button>
            </div>
          </>
        }

        <Dialog
          title="Mapa"
          actions={this.getActions()}
          modal={false}
          open={this.state.opened}
          autoScrollBodyContent="true"
          titleStyle={{ borderBottom: "none" }}
          bodyStyle={{
            borderBottom: "3px solid #fff",
            bottom: "-1px",
            position: "relative"
          }}
          style={{ borderTop: "none" }}
          contentClassName="dialog-box"
          actionsContainerClassName="modal__control"
          bodyClassName="content-box"
          titleClassName="title-box"
          className="modal"
        >
         <MapDetail/>
        </Dialog>
      </>
    );
  }

  close(){
    this.setState({opened: false})
  }

  getActions() {
    const { status } = this.state;
    const { t } = this.props;

      return (
        <div style={styles.buttonsWrapper}>
          <OrangeButton onClick={() => this.close()} submit="submit" type="inversion">
            Zavřít
          </OrangeButton>
        </div>
      );
  }
}

Maps.propTypes = {
  tourDetail: PropTypes.any
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail
});

export default connect(mapStateToProps)(Radium(Maps));
