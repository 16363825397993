import constants from '../../styles/constants';
import selectArrow from '../../img/select-arrow2.png'; // eslint-disable-line
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import input from '../shared/forms/input.module.scss'

// const styles = {
//   selectBox: {
//     width: 'calc(100%)',
//     height: 40,
//     overflow: 'hidden',
//     padding: '0 42px 0 11px',
//     lineHeight: '40px',
//     border: 'none',
//     display: 'inline-block',
//     backgroundColor: '#fff',
//     backgroundImage: 'url(' + selectArrow + ')',
//     backgroundPosition: 'right center',
//     backgroundRepeat: 'no-repeat',
//     color: [constants.black],
//     fontSize: '0.9em',
//     fontWeight: '500',
//     cursor: 'pointer',
//   },
// };

class FilterSelect extends Component {
  render() {
    const { renderData, defaultValue, name } = this.props;

    return (
      <select
        onChange={this.handleChange.bind(this)}
        id="month"
        name={name}
        className={input.select}
      >
        <option value="">{defaultValue}</option>
        {renderData && renderData.map((item) => this.renderOption(item))}
      </select>
    );
  }

  handleChange(event) {
    const { name } = this.props;
    const value = event.target.value;

    this.props.onClick(value, name);
  }

  renderOption(item) {
    const { code, value } = item;

    return <option value={code}>{value}</option>;
  }
}

FilterSelect.propTypes = {
  renderData: PropTypes.any.isRequired,
  defaultValue: PropTypes.any.isRequired,
  name: PropTypes.any.isRequired,
  onClick: PropTypes.any,
};

export default FilterSelect;
