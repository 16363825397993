import getLengthInDaysAndNights from "../../../../shared/getLengthInDaysAndNights";
import constants from "../../../../../styles/constants.js";
import formatDateLong from "../../../../shared/Moment/formatDateLong";
import crosLineImg from "../../../../../img/crossLine.png"; // eslint-disable-line
import formatDateShort from "../../../../shared/Moment/formatDateShort";
import formatCurrency from "../../../../shared/formatCurrency";
import { employee, szEmployee } from "../../../../../actions/HP/defaultTourlist";
import { zajezdyDetail } from "../../../../../utils/routeConfig";
import { hashHistory } from "react-router";
import StyledCircleLoader from "../../../../shared/Loading/StyledCircleLoader";
import createFilterRequest from "../../../../shared/RequestCreator/createFilterRequest";
import { fetchTerms } from "../../../../../actions/TourList/fetchTourList";
import { fetchTourDetail } from "../../../../../actions/TourDetail/fetchTourDetail";
import { generatePDF } from "../../../../../actions/PDF/generatePDF";
import cookie from "react-cookie";
import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";

const customStyles = {
  tourHeader: {
    justifyContent: "center"
  },
  floatRight: {
    float: "right",
    marginBottom: 20,
    marginRight: 0
  },
  loading: {
    display: "block",
    width: "110px",
    margin: "0 auto",
    justifyContent: "center",
    marginTop: 120,
    marginBottom: 120
  },
  buttonMarged: {
    margin: "20px 10px 0px 10px",
    [constants.breakpoint360]: {
      position: "relative",
      left: "50%",
      transform: "translateX(-50%)"
    }
  },
  wrapper: {
    clear: "both",
    width: "99%",
    maxWidth: "600px",
    marginTop: "10px"
  },
  table: {
    borderCollapse: "collapse",
    fontSize: "1em",
    margin: "0 auto",
    marginTop: "5px",
    width: "100%",
    centered: {
      textAlign: "center"
    },
    [constants.breakpointMobile]: {
      fontSize: "0.8em"
    },
    tbody: {
      backgroundColor: "white",
      color: [constants.mainBlue]
    },
    head: {
      textAlign: "left",
      backgroundColor: [constants.mainBlue],
      color: "#fff"
    },
    subsidyHead: {
      textAlign: "left",
      backgroundColor: [constants.green],
      color: "#fff",
      fontWeight: "bold",
      paddingBottom: "10px"
    },
    subsidyTitleHead: {
      color: "white",
      backgroundColor: [constants.green],
      fontSize: "1.1em",
      fontWeight: "500",
      position: "fixed",
      boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)",
      top: "30px",
      left: "-10",
      zIndex: 999,
      textAlign: "center",
      padding: "20px"
    },
    subsidyTitle: {
      fontSize: "13px",
      textTransform: "uppercase",
      color: "black"
    },
    line: {
      width: "780"
    },
    description: {},
    priceFor: {
      textAlign: "right",
      minWidth: "60px"
    },
    crossLine: {
      backgroundImage: `url(${crosLineImg})`,
      backgroundPositionY: "50%",
      backgroundRepeat: "repeat-x",
      color: "#999"
    },
    price: {
      textAlign: "right",
      minWidth: "80px"
    },
    tourPrice: {
      textAlign: "center",
      fontWeight: "bold",
      color: [constants.mainOrange]
    },
    tagWrapper: {
      textAlign: "center",
      marginTop: -2
    },
    tagLastMinuteWapper: {
      width: 87,
      position: "relative",
      right: 10
    },
    tagSpecialOfferWapper: {
      width: 115,
      position: "relative",
      right: 25
    },
    tagBothWrapper: {
      width: 200,
      position: "relative",
      right: 65
    },
    tagLastMinute: {
      position: "relative",
      transform: "rotate(-3deg)",
      display: "inline-block",
      margin: 1,
      marginRight: 5
    },
    tagSpecialOffer: {
      position: "relative",
      transform: "rotate(-3deg)",
      display: "inline-block"
    },
    infoBottom: {
      marginTop: "5px",
      width: "700px",
      margin: "0 auto"
    },
    infoBottomWrapper: {
      marginTop: "20px"
    },
    tr: {
      border: "1px solid black"
    }
  },
  floatLeft: {
    float: "left",
    marginRight: 10,
    marginBottom: 10
  },
  stick: {
    padding: "3px 6px",
    margin: 2,
    fontSize: "0.8em",
    color: "#fff",
    display: "inline-block",

    lastMinute: {
      background: "#e21a22",
      marginBottom: "1px"
    },
    soldOut: {
      background: "#000",
      marginBottom: "1px"
    },

    specialOffer: {
      background: [constants.green]
    }
  }
};

const specialOffer = "Výhodná nabídka";
const soldOut = "Vyprodáno";
export const adultType = "DOSP";

class TourPicker extends Component {
  state = {
    fetched: false
  };

  componentDidMount() {
    this.initialize();
  }

  componentWillUpdate(props) {
    const { termList } = props;

    if (termList && termList.terms) {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 10);
    }
  }

  render() {
    const {
      tour: { title },
      termList
    } = this.props;
    const { printing } = this.state;

    if (!termList || !termList.terms || termList.name !== title) {
      return (
        <>
          <div style={customStyles.loading}>
            <StyledCircleLoader />
          </div>        
        </>
      );
    }

    const {
      termList: { terms }
    } = this.props;

    const hasSpecialStatus = this.getSpecialStatus(terms);

    return (
      <div className="pricelist-div-line">
        <p style={customStyles.floatRight}>Klikem vyberte termín</p>

        {printing && <h style={customStyles.tourHeader}>{title}</h>}

        <table style={customStyles.table} id="printed" ref="printed">
          <tbody style={customStyles.table.tbody}>
          {terms.length && this.renderPriceListHead(hasSpecialStatus)}

          {terms.map((term) => this.renderPrices(term, hasSpecialStatus))}
          </tbody>
        </table>
      </div>
    );
  }

  handleOpenAlert(alertOpened) {
    this.setState({ alertOpened });
  }

  renderPriceListHead(hasSpecialStatus) {
    return (
      <tr style={customStyles.table.head}>
        <th id="header" style={customStyles.table.centered}>
          Datum
        </th>
        <th id="header" style={customStyles.table.centered}>
          Délka
        </th>
        <th id="header" style={customStyles.table.centered}>
          Doprava
        </th>
        <th id="header" style={customStyles.table.centered}>
          Dospělá osoba
        </th>
        {hasSpecialStatus && <th id="header" style={customStyles.table.centered} />}
      </tr>
    );
  }

  getSpecialStatus(terms) {
    let hasSpecialStatus = false;
    terms.forEach((term) => {
      const { isLastMinuteOrCustom, isSoldOut, isSpecialOffer } = term;
      if (isSpecialOffer || isSoldOut || isLastMinuteOrCustom) {
        hasSpecialStatus = true;
      }
    });

    return hasSpecialStatus;
  }

  renderPrices(term, hasSpecialStatus) {
    const {
      dateFrom,
      dateTo,
      lengthInDays,
      lengthInNights,
      transport,
      prices,
      idTour,
      isSoldOut
    } = term;

    const user = cookie.load("user");

    let adultPrice;

    prices.forEach((price) => {
      if (price.code === adultType) {
        if (user === szEmployee) {
          adultPrice = price.price - price.szSubsidy;
        } else {
          adultPrice = user === employee ? price.price - price.subsidy : price.price;
        }
      }
    });

    return (
      <tr
        onClick={() => {
          this.handleChoose(idTour);
        }}
      >
        <th style={customStyles.table.centered}>
          {formatDateShort(dateFrom) + " - " + formatDateLong(dateTo)}{" "}
        </th>
        <th style={customStyles.table.centered}>
          {getLengthInDaysAndNights(lengthInDays, lengthInNights)}
        </th>
        <th style={customStyles.table.centered}>{this.getFormatedTransports(transport)}</th>
        <th style={customStyles.table.tourPrice}>{formatCurrency(adultPrice)}</th>
        {(hasSpecialStatus || isSoldOut) && (
          <th style={customStyles.table.tagWrapper}>{this.getMessage(term)}</th>
        )}
      </tr>
    );
  }

  getMessage(term) {
    const { isSoldOut, isSpecialOffer, isLastMinuteOrCustom } = term;

    const {
      filterSettings: { filterName }
    } = this.props;

    if (isSoldOut) {
      return (
        <div style={customStyles.table.tagLastMinute}>{this.renderSticker(soldOut, "soldOut")}</div>
      );
    } else if (isLastMinuteOrCustom) {
      return (
        <div style={customStyles.table.tagLastMinute}>
          {this.renderSticker(filterName, "lastMinute")}
        </div>
      );
    } else if (isSpecialOffer) {
      return (
        <div style={customStyles.table.tagSpecialOffer}>
          {this.renderSticker(specialOffer, "specialOffer")}
        </div>
      );
    }

    return "";
  }

  initialize() {
    const {
      fetchTerms,
      filter,
      termList,
      tour: { idTour, title },
      paginator: { itemsPerPage, minCost, maxCost, page }
    } = this.props;
    const properties = createFilterRequest(filter, page, itemsPerPage, minCost, maxCost, {
      changedShowTermList: true
    });
    properties.idTour = idTour;
    properties.type = "list";

    if (termList.name !== title || termList.filterChanged) {
      fetchTerms(properties);
    }
  }

  handleChoose(idTour) {
    this.props.onClick(false);
    hashHistory.push(zajezdyDetail + idTour);
  }

  getFormatedTransports(transport) {
    let transportMessage = "";

    transport.forEach((transportItem, i) => {
      transportMessage += transportItem.title + (i !== transport.length - 1 ? ", " : "");
    });

    return transportMessage;
  }

  renderSticker(text, type) {
    return <span style={{ ...customStyles.stick, ...customStyles.stick[type] }}>{text}</span>;
  }
}

TourPicker.propTypes = {
  tour: PropTypes.any,
  postNewBooking: PropTypes.any,
  filter: PropTypes.any,
  filterSettings: PropTypes.any,
  paginator: PropTypes.any,
  fetchTerms: PropTypes.any,
  termList: PropTypes.any,
  pickTour: PropTypes.any,
  withoutAction: PropTypes.any,
  invertedShow: PropTypes.any,
  fetchTourDetail: PropTypes.any,
  onClick: PropTypes.any,
  location: PropTypes.any,
  extraStyle: PropTypes.any,
  dataFetched: PropTypes.any,
  defaultState: PropTypes.any
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
  paginator: state.paginator,
  filter: state.filter,
  filterSettings: state.filterSettings,
  termList: state.termList,
  postFormStatus: state.postFormStatus
});

const mapDispatchToProps = (dispatch) => ({
  fetchTourDetail: (id) => dispatch(fetchTourDetail(id)),
  fetchTerms: (properties) => dispatch(fetchTerms(properties)),
  generatePDF: (params, name) => dispatch(generatePDF(params, name))
});

export default connect(mapStateToProps, mapDispatchToProps)(TourPicker);
