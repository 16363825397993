import React, { useState} from 'react';
import faq from './faq.module.scss';
import App from './App';


const Accordion = ({title, content}) => {
	const[isActive, setIsActive] = useState(false);

	return (
		<li className={faq['accordion__item']}>
			<div className={faq['accordion__title'] + (isActive? ' ' + faq['is-active']:'')} onClick={() => setIsActive(!isActive)}>
				<div className="text--18">{title}</div>
				<span className={faq.icon + (isActive? ' ' + faq['is-active']:'')}></span>
			</div>
		{isActive &&
			<div className={faq['accordion__content']}>
				<p className="color--gray-900 margin--bottom-24">{content}</p>
			</div>
			}
		</li>
	);
};

export default Accordion;