//import jisovaPhoto from '../../../img/contact/photos/jisova.jpg'; // eslint-disable-line
//import vrsovskyPhoto from '../../../img/contact/photos/vrsovsky.jpg'; // eslint-disable-line
//import zajickovaPhoto from '../../../img/contact/photos/zajickova.jpg'; // eslint-disable-line
//import holcakovaPhoto from '../../../img/contact/photos/holcakova.jpg'; // eslint-disable-line
//import contactSectionImage from '../../../img/section-headers/contact.jpg'; // eslint-disable-line
import LoaderInModalWindow from '../../shared/Loading/LoaderInModalWindow';
import { fetchContacts } from '../../../actions/Contacts/fetchContacts';
import ContactTile from './ContactTile';
import greyBg from '../../../img/grey-bg.png'; // eslint-disable-line
import constants from '../../../styles/constants.js';
import PropTypes from 'prop-types';
import SectionImage from '../../shared/SectionImage';
import React, { Component } from 'react';
import Map from './Map';
import { Container } from '../../../libs/radium-grid'; // eslint-disable-line
import Helmet from 'react-helmet';
import { contactTitle } from '../../../utils/titleConfig';
import { connect } from 'react-redux';
import Radium from 'radium';
import Usp from "../../Usp/Usp";
import Banner from "../../Banner/Banner";
import ContactCard from "./ContactCard";
import hero from '../../Pages/Tours/ToursList/hero.module.scss';
import heroPicture from '../../../img/heros/hero-lists.webp';
import card from './card.module.scss';
import { withTranslation } from 'react-i18next';

{/*
const styles = {
  sectionHead: {
    width: '100%',
  },
  contentWrapper: {
    overflow: 'hidden',
    zIndex: 6,
    position: 'relative',
    padding: '50px 0 50px 0',
    backgroundImage: 'url(' + greyBg + ')',
    boxShadow: '0 8px 12px rgba(0, 0, 0, .15)',
  },
  contentBox: {
    width: '100%',
    maxWidth: '800px',
    position: 'relative',
    margin: '0 auto',
    fontSize: '1.1em',
    overflow: 'auto',
  },
  sectionTitle: {
    fontSize: '1.2em',
    color: [constants.mainBlue],
    padding: '0 20px 14px',
    lineHeight: '1.4em',
    textAlign: 'center',
    margin: '60px 0 0 0',
    fontWeight: '500',
    clear: 'both',

    first: {
      margin: 0,
    },
  },
  openTimeBox: {
    width: 'calc(95% - 30px)',
    maxWidth: '650px',
    position: 'relative',
    margin: '80px auto 40px auto',
    clear: 'both',
    padding: '15px 15px 50px',
    background: '#fff',
    textAlign: 'center',

    title: {
      fontSize: '1.4em',
      fontWeight: '500',
      padding: '10px',
      margin: 0,
      color: [constants.mainBlue],
    },
    date: {
      fontSize: '1.2em',
      paddingTop: '10px',
      fontWeight: '500',
    },
    table: {
      width: '100%',
      maxWidth: '450px',
      margin: '0 auto',
      textAlign: 'left',
      fontSize: '1.1em',
      borderCollapse: 'collapse',
      borderSpacing: '2px',

      cell: {
        padding: '10px 0',
      },

      rightAlign: {
        textAlign: 'right',
      },

      line: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
      },
    },
  },
};

const head = {
  photo: jisovaPhoto,
  name: 'Mgr. Blanka Jíšová',
  phone: '972 243 066',
  phone2: '602 473 285',
  email: 'jisova@cdtravel.cz',
};

const shopingcenter = [
  {
    photo: vrsovskyPhoto,
    name: 'Miroslav Vršovský',
    phone: '972 241 861',
    phone2: '724 994 718',
    email: 'vrsovsky@cdtravel.cz',
  },
  {
    photo: zajickovaPhoto,
    name: 'Davaceceg Zajíčková',
    phone: '972 241 861',
    phone2: '724 994 718',
    email: 'zajickova@cdtravel.cz',
  },
  {
    photo: holcakovaPhoto,
    name: 'Lenka Holčáková',
    phone: '972 241 861',
    phone2: '724 994 718',
    email: 'holcakova@cdtravel.cz',
  },
];
*/}

class Contact extends Component {
  componentDidMount() {
    const {
      fetchState: { contactsFetched },
      fetchContacts,
    } = this.props;
    !contactsFetched && fetchContacts();
  }

  render() {
    const {
      fetchState: { contactsFetched },
    } = this.props;
    const {t} = this.props;

    return (
      <div>
        <Helmet title={contactTitle} />
				<section className={hero.hero}>
					<picture className={hero.hero__picture}>
						<img src={heroPicture} alt="Pro vaše nejlepší zážitky z cestování" />
					</picture>
					<div className={hero.hero__content}>
						<div className="container">
							<div className="grid gap">
								<div className="cell cell--12 cell--6:lg">
									<article className={hero.hero__article}>
										<h1 className="title margin--bottom-1 color--white">{t('contactTitle')}</h1>
										<p className="lead margin--bottom-2 color--white">{t('contactDesc1')}<br/>{t('contactDesc2')}</p>
									</article>
								</div>
							</div>
						</div>
					</div>
				</section>
        <section className="section">
          <div className="container">
            <div className={card.block}>
						  <div className="grid gap">
                {contactsFetched ? this.renderContacts() : <LoaderInModalWindow />}
	    	  		</div>
						</div>
	    		</div>
        </section>

        <section className="section section--global section--banner">
          <div className="container">
            <div className="grid">
              <div className="cell">
                <Banner />
              </div>
            </div>
          </div>
        </section>

        <section className='section section--global' style={{paddingTop: 0}}>
          <div className="container">
            <div className="grid">
              <div className="cell">
                <Usp />
              </div>
            </div>
          </div>
        </section>

      </div>
    );
  }


  renderContacts(){
    const { contacts } = this.props;

    return contacts.map((person) => (
    <ContactCard contactPerson = {person}/>
    )
    );
  }

  /*
  renderContacts() {
    const { contacts } = this.props;

    return contacts.map((person) => (
      <ContactTile
        type="classic"
        photo={person.photo}
        name={person.name}
        destinations={person.countries}
        phone={person.phone}
        phone2={person.mobile}
        email={person.email}
      />
    )
    );
  }
  */

  renderTableLine(day, openTime) {
    return (
      <tr style={styles.openTimeBox.table.line}>
        <td style={styles.openTimeBox.table.cell}>{day}</td>
        <td style={[styles.openTimeBox.table.rightAlign, styles.openTimeBox.table.cell]}>
          {openTime}
        </td>
      </tr>
    );
  }
}

Contact.propTypes = {
  fetchState: PropTypes.any,
  fetchContacts: PropTypes.any,
  contacts: PropTypes.any,
};

const mapStateToProps = (state) => ({
  contacts: state.contacts,
  fetchState: state.fetchState,
});

const mapDispatchToProps = (dispatch) => ({
  fetchContacts: () => dispatch(fetchContacts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Radium(Contact)));
