import constants from '../../../../styles/constants.js';
import Radium from 'radium';
import leftArrow from '../../../../img/icons/back-arrow.png'; // eslint-disable-line
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import Tile from './Tile';

const styles = {
  backLink: {
    position: 'fixed',
    top: 430,
    left: 30,
    cursor: 'pointer',

    [constants.breakpointDesktop]: { display: 'none' },

    text: {
      textDecoration: 'none',
      color: [constants.mainBlue],
      fontSize: '1.1em',
      fontWeight: '600',

      [constants.breakpointDesktop]: {
        padding: '10px',
        backgroundColor: '#fff',
      },

      ':hover': { textDecoration: 'underline' },
    },

    [constants.breakpoint850]: { display: 'none' },
  },
  tilesWrapper: { overflow: 'auto' },
  arrow: {
    width: '11px',
    height: 'auto',
    marginRight: '7px',
    position: 'relative',
    top: '6px',
  },
};

class Tiles extends Component {
  render() {
    const {
      tourFilter: { country },
    } = this.props;

    return (
      <>
        {country.map((tile, i) => this.renderTile(tile, i))}
      </>
    );
  }

  renderTile(tile, i) {
    const { value, imgSrc, code, type, link } = tile;
    let realSize;

    if (!type) {
      realSize = 'small';
    } else {
      realSize = type;
    }

    return (
      <Tile
        title={value}
        image={imgSrc}
        code={code}
        key={i}
        index={i}
        size={realSize}
        link={link}
      />
    );
  }
}

Tiles.propTypes = { tourFilter: PropTypes.any };

const mapStateToProps = (state) => ({
  tourFilter: state.tourFilter,
  tiles: state.tiles,
  tourList: state.tourList,
});

export default connect(mapStateToProps)(Radium(Tiles));
