import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import './Reservation.css';
import { hashHistory } from 'react-router';
import OrangeButton from '../../../shared/OrangeButton';
import { zajezdyDetail } from '../../../../utils/routeConfig';
import { withTranslation } from 'react-i18next';
import cms from '../../CMS/cms.module.scss';
import Stepper from './Stepper.jsx';


class ReservationComplete extends Component {
  state = {};

  render() {
    const { step } = this.state;
    const { t } = this.props;

    return (
      <>
        <div className="container">
          <div className="grid gap align--center">
            <div className="cell cell--12 margin--bottom-3">
              <div className={cms.block}>
                <div className="grid gap">
                  <div className="cell cell--12a align--center">
                    <Stepper activeStep={step}/>
                  </div>
                  <div className="cell cell--12">
                    <h2 className="title">{t('bookingSent')}</h2>
                  </div>
                  <div className="cell cell--12 margin--bottom-2">
                    <p className="lead color--primary-highdark">
                      {t('bookingSentText')}
                      <strong>{t('incommingMessages')}</strong>.
                    </p>
                    <p className="lead color--primary-highdark">
                      {t('48Hours')}
                    </p>
                  </div>
                  {this.renderButtons()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderButtons() {
    const { idDetail } = this.props;
    const { t } = this.props;

    return (
      <div className="cell cell--12 align--center">
        <button
          onClick={() => hashHistory.push(zajezdyDetail + idDetail)}
          className="button button--primary"
        >
          {t('backToTrip')}
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Radium(ReservationComplete)));
