import constants from '../../styles/constants';
import Radium from 'radium';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import sprite from '../../img/icons/sprite.svg';

const styles = {
  button: {
    width: 'auto',
    textAlign: 'center',
    position: 'relative',
    background: [constants.mainOrange],
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: [constants.mainOrange],
    fontStyle: 'italic',
    cursor: 'pointer',
    transition: '0.2s',
    margin: '0 auto',
    display: 'inline-flex',
    color: '#fff',
    height: 36,
    lineHeight: '36px',
    borderRadius: '5px',

    ':hover': {
      background: 'transparent',
      borderColor: [constants.mainOrange],
      color: [constants.mainOrange],
    },
  },
  text: {
    padding: '0px 8px',
    textDecoration: 'none',
    fontStyle: 'normal',
    fontSize: '1em',
    fontWeight: '500',
    width: '100%',
  },

  types: {
    whiteText: {
      color: 'white',
    },
    normal: {
      left: 'inherit',
      transform: 'none',
    },
    centered: {
      position: 'relative',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    wide: {
      width: '100%',
    },
    inversion: {
      background: 'transparent',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: [constants.mainOrange],
      color: [constants.mainOrange],

      ':hover': {
        background: [constants.mainOrange],
        borderColor: [constants.mainOrange],
        color: '#fff',
      },
    },
    alert: {
      background: [constants.alertRed],
      borderColor: 'white',
      color: '#fff',
      float: 'right',

      ':hover': {
        backgroundColor: 'white',
        color: [constants.alertRed],
        borderColor: [constants.alertRed],
      },
    },
  },
  submit: {
    margin: '0 3px',
  },

  icon: {
    position: 'relative',
    top: 6,
    left: -2,
    marginRight: 2,
    fontSize: 22,
  },
};

class OrangeButton extends Component {
  render() {
    const { type, submit, extraStyle, disabled, className, dataTooltip } = this.props;

    return (
      <div
        data-place="left"
        data-tip={disabled ? dataTooltip : ''}
        onClick={!disabled && this.props.onClick}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        className={(disabled ? 'disabled ' : '') + (className ? className : '')}
      >
        {this.renderLink()}
      </div>
    );
  }

  renderLink() {
    const { children, link, type, target, external, icon } = this.props;

    if (external) {
      return (
        <a href={link} className="link link--file text--14 color--primary-highdark" target={target}>
          {icon && (
            <svg className="icon margin--right-1" role="presentation" width="32" height="32">
              <use xlinkHref={sprite + '#sprite-file'}/>
            </svg>
          )}
          {children}
        </a>
      );
    }

    return (
      <Link to={link} className="link link--file text--14 color--primary-highdark" target={target}>
        {icon && (
          <svg className="icon margin--right-1" role="presentation" width="32" height="32">
            <use xlinkHref={sprite + '#sprite-file'}/>
          </svg>
        )}
        {children}
      </Link>
    );
  }
}

OrangeButton.propTypes = {
  children: PropTypes.any,
  link: PropTypes.string,
  external: PropTypes.any,
  type: PropTypes.string,
  icon: PropTypes.string,
  iconName: PropTypes.string,
  color: PropTypes.string,
  submit: PropTypes.string,
  onClick: PropTypes.any,
  target: PropTypes.any,
  onMouseEnter: PropTypes.any,
  onMouseLeave: PropTypes.any,
  extraStyle: PropTypes.any,
};

export default Radium(OrangeButton);
