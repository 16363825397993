import Radium from 'radium';
import React, { Component } from 'react';
import SectionImage from '../../shared/SectionImage';
import { Container } from '../../../libs/radium-grid'; // eslint-disable-line
import { fetchCMS } from '../../../actions/CMS/fetchCMS';
import instructions from '../../../img/rozboceni.jpg'; // eslint-disable-line
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import leftArrow from '../../../img/icons/back-arrow.png'; // eslint-disable-line
import constants from '../../../styles/constants';
import { toggleTiles } from '../../../actions/TourList/paginator';
import { Link } from 'react-router';
import StyledCircleLoader from '../../shared/Loading/StyledCircleLoader';
import { fetchEmployeeInstructions } from '../../../actions/EmployeeInstructions/fetch';

const styles = {
  wrapper: {
    padding: '50px 0 120px 0',
  },
  buttonWrapper: {
    marginTop: 70,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',

    [constants.breakpointMobile]: {
      width: '91%',
      display: 'inherit',
    },
  },

  button: {
    backgroundColor: '#afc836',
    float: 'left',
    padding: '10px 15px',
    color: 'white',
    textDecoration: 'none',
    cursor: 'pointer',
    margin: '25px',
    fontSize: '20px',

    [constants.breakpointMobile]: {
      width: '90%',
      marginLeft: '5%',
    },
  },
  loadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 80,
    marginBottom: 80,
  },
  arrow: {
    width: '11px',
    height: 'auto',
    marginRight: '7px',
    position: 'relative',
    top: '6px',
  },
  backLink: {
    position: 'fixed',
    top: 430,
    left: 30,
    cursor: 'pointer',

    [constants.breakpointDesktop]: {
      display: 'none',
    },

    text: {
      textDecoration: 'none',
      color: [constants.mainBlue],
      fontSize: '1.1em',
      fontWeight: '600',

      [constants.breakpointDesktop]: {
        padding: '10px',
        backgroundColor: '#fff',
      },

      ':hover': {
        textDecoration: 'underline',
      },
    },

    [constants.breakpoint850]: {
      display: 'none',
    },
  },
};

class CMSPage extends Component {
  componentDidMount() {
    const { fetchEmployeeInstructions } = this.props;

    fetchEmployeeInstructions();
  }

  render() {
    return (
      <div>
        <Helmet title={'instrukce'} />
        <SectionImage actualImage={instructions} actualTitle="instrukce" />
        <div style={styles.wrapper}>{this.renderContent()}</div>
      </div>
    );
  }

  renderContent() {
    const {
      employeeInstructions: { instructions, loaded },
    } = this.props;

    return (
      <Container>
        <div style={styles.backLink}>
          <Link style={styles.backLink.text} to="/zajezdy/vypis">
            <img src={leftArrow} style={styles.arrow} /> zpět na přehled zemí
          </Link>
        </div>
        <div style={styles.buttonWrapper}>
          {loaded
            ? instructions.map((instruction) => this.renderInstruction(instruction))
            : this.loading()}
        </div>
      </Container>
    );
  }

  renderInstruction(instruction) {
    const { title, documentLink } = instruction;

    return (
      <a style={styles.button} href={documentLink} target="_blank">
        {title}
      </a>
    );
  }

  loading() {
    return (
      <>
      <div style={styles.loadingWrapper}>
        <StyledCircleLoader />
      </div>      
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeInstructions: state.employeeInstructions,
});

const mapDispatchToProps = (dispatch) => ({
  fetchEmployeeInstructions: () => dispatch(fetchEmployeeInstructions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(CMSPage));
