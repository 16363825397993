import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import styles from './info.module.scss';
import cookie from "react-cookie";
import {employee, szEmployee} from "../../../../../../actions/HP/defaultTourlist";
import formatCurrency from "../../../../../shared/formatCurrency";
import getDataTip from "../../../../../shared/getDetailPriceListDataTip";
import BookingForm from "../Informations/BookingForm";
import { hashHistory } from "react-router";
import { zajezdyDetail } from "../../../../../../utils/routeConfig";

class TourInfo extends Component {
  render() {
    const {
      t,
      term
    } = this.props;

    return (
      <>
        <div className={styles.info}>
          {term && this.renderStaticPriceListRowItems(term)}
            
          <div className={styles.info__control}>
            <BookingForm term={term} />
            <a className="link link--underline text--14 color--primary-highdark " onClick={()=> this.handleOpenTour(term.idTour)}>
              <strong>
                {t('chooseTerm')}
              </strong>
            </a>            
          </div>

        </div>
      </>
    );
  }

  handleOpenTour(tour) {
    hashHistory.push(zajezdyDetail + tour + "?onlyHotel=1");
  }

  renderStaticPriceListRowItems(term) {
    const user = cookie.load('user');
    const {prices} = term;

    return (
      <ul className="list list--info" id="printed" ref="printed">
        <p>{(user === employee || user === szEmployee) && 'Ceny po dotaci'}</p>

        {prices && prices.map((priceObject) => this.renderDynamicPriceListRowItems(priceObject, user))}

        <li className="color--primary-highdark text--14" dangerouslySetInnerHTML={{ __html: getDataTip(term) }} id="printed" />
      </ul>
    );
  }

  renderDynamicPriceListRowItems(priceObject, user) {
    const {description, price, subsidy, szSubsidy, isHiddenForDetail} = priceObject;
    const realSubsidy = user === szEmployee ? szSubsidy : subsidy;

    if (isHiddenForDetail) {
      return null;
    }

    return (
      <li className="color--primary-highdark text--14">
        {description}
        <div className="text--price color--secondary text--18">
          <strong>{this.renderPrice(user, price, realSubsidy)}</strong>
        </div>
      </li>
    );
  }

  renderPrice(user, price, subsidy) {
    if (!price) {
      return 'zdarma';
    }

    return user === employee || user === szEmployee
      ? [formatCurrency(price - subsidy)]
      : [formatCurrency(price)];
  }
}

export default withTranslation()(TourInfo);

