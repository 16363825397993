import constants from '../../../styles/constants.js';
import { fetchCalculatorSettings } from '../../../actions/Calculator/calculator';
import React, { Component } from 'react';
import { Link } from 'react-router';
import Radium from 'radium';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import { visitor } from '../../../actions/HP/defaultTourlist';
import { styles } from '../../Pages/Tours/ToursList/components/MultiCheckbox';
import selectArrow from '../../../img/select-arrow2.png';
import arrowDown from '../../../img/down-arrow.svg';
import { generateUuid } from '../../shared/uuid';
import input from '../../shared/forms/input.module.scss';

// export const customStyles = {
//   selectBox: {
//     width: '100%',
//     height: 40,
//     overflow: 'hidden',
//     padding: '0 30px 0 10px',
//     lineHeight: '40px',
//     borderRadius: '5px',
//     border: '1px solid' + [constants.inputBorder],
//     display: 'inline-block',
//     backgroundColor: '#fff',
//     backgroundImage: 'url(' + arrowDown + ')',
//     backgroundSize: 20,
//     backgroundPosition: 'right center',
//     backgroundRepeat: 'no-repeat',
//     color: [constants.black],
//     fontSize: '0.9em',
//     fontWeight: '500',
//     cursor: 'pointer',
//   },
//   disable: {
//     opacity: 0.4,
//     cursor: 'default',
//   },
//   inputBox: {
//     width: 'calc(100% - 40px)',
//     margin: '0.50% 0.5%',
//     float: 'left',
//     position: 'relative',
//   },
// };

const getParents = (elem) => {
  const parents = [];

  while (elem.parentNode && elem.parentNode.nodeName.toLowerCase() != 'body') {
    elem = elem.parentNode;
    parents.push(elem);
  }

  return parents;
};

class BaseSelect extends Component {
  state = {
    opened: false,
  };

  componentDidMount() {
    this.setState({ uuid: generateUuid() });
    document.addEventListener('mousedown', this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
  }

  handleOutsideClick = (event) => {
    const parents = getParents(event.target);
    let close = true;

    parents.forEach((parent) => {
      if (parent.id === this.state.uuid) {
        close = false;
      }
    });

    if (close) {
      this.setState({ opened: false });
    }
  };

  render() {
    const { items, placeholder, isCheckBox, text, disabled } = this.props;
    const { opened } = this.state;

    if (disabled) {
      return (
        <>
          <div className={`${input.choice} ${input['choice--disabled']}`}>
            <div
              onClick={() => this.setState({ opened: !opened })}
              className={input.choice__input}
            >
              {text || placeholder}
            </div>
            <div className={input.choice__dropdown}>{opened && this.renderOpened()}</div>
          </div>        
          {/* <div style={customStyles.inputBox}>
            <div style={[customStyles.selectBox, customStyles.disable]} className="select-box">
              -
            </div>
            <div style={styles.dropDown}>{opened && this.renderOpened()}</div>
          </div>           */}
        </>
      );
    }
    return (
      <div className={input.choice}>
        <div
          onClick={() => this.setState({ opened: !opened })}
          className={input.choice__input}
        >
          {text || placeholder}
        </div>
        <div className={input.choice__dropdown}>{opened && this.renderOpened()}</div>
      </div>
    );
  }

  renderOpened() {
    const { items, placeholder, onReset } = this.props;
    const { uuid } = this.state;

    return (
      <ul id={uuid} className="list">
        {/* <div style={styles.dropDown.caret} /> */}

        <li onClick={() => this.reset()} title={placeholder}>
          {placeholder}
        </li>
        {items.map((items) => this.renderBox(items))}
      </ul>
    );
  }

  renderBox(item, box) {
    const { isCheckBox } = this.props;
    const { id, title, selected } = item;

    if (!isCheckBox) {
      return (
        <li onClick={() => this.select(id)} value={id}>
          {title}
        </li>
      );
    } else {
      return (
        <li onClick={() => this.select(id)}>
          <input type="checkbox" className={input.checkbox} value={id} checked={selected} />
          <label style={styles.dropDown.label}>{title}</label>
        </li>
      );
    }
  }

  reset() {
    const { onReset } = this.props;
    this.setState({ opened: false });

    onReset && onReset();
  }

  select(id) {
    const { onSelect } = this.props;
    this.setState({ opened: false });

    onSelect && onSelect(id);
  }
}

const mapStateToProps = (state) => ({
  calculator: state.calculator,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Radium(BaseSelect));
