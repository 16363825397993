import React, {Component} from "react";
import Radium from "radium";
import {connect} from "react-redux";


class BottomText extends Component {
  state = {};

  render() {
    const {text} = this.props;

    return (
      <div className="bottom-info-text">
        {text}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(
  Radium(BottomText)
);
