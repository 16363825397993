import React, {Component} from "react";
import Radium from "radium";
import {connect} from "react-redux";
import Eating from "./rowItems/Eating";
import AdditionalPrices from "./rowItems/AdditionalPrices";
import {
  formatCurrency,
} from "../calculations";
import {
  toggleGrant,
  setChildrenAgeByRenderIndex
} from "../../../../actions/Calculator/calculator";

import styles from './summary.module.scss';
import input from '../../../shared/forms/input.module.scss';
import { withTranslation } from 'react-i18next';

class Children extends Component {
  render() {
    const {calculator: {settings: {occupation}}} = this.props;
    const { t } = this.props;

    const children = [];

    for (let i = 0; i < occupation["children"]; i++) {
      children.push(this.renderChildrenRow(i));
    }

    return <div>{children}</div>;
  }

  renderChildrenRow(index) {
    const {
      calculator: {
        settings: {occupation, childrenAge, occupation: {adult}},
        data: {isHiddenForDetail}
      },
      setChildrenAgeByRenderIndex
    } = this.props;
    const {calculator: {occupation: {children}}} = this.props;

    const isDisabled = isHiddenForDetail && occupation.children > 1 && index === 0;

    if (typeof childrenAge[index] === "undefined") {
      setChildrenAgeByRenderIndex(index, this.getDisctinctAges()[0]);
    }

    return (
      <>
        <div className={styles.summary__item}>
          <div className="grid grid--middle gap">
            <div className="cell cell--12 cell--auto:md color--primary-highdark text--14">
              <strong>{index + 1 + adult}. Dítě</strong>
            </div>
            <div className="cell cell--12 cell--shrink:md">
              {this.renderDiscountPrice(
                children[index].price,
                children[index].discountedPrice
              )}
            </div>
          </div>
          <hr className="margin--top-1 margin--bottom-1" />
          <div className="grid">
            <div className="cell">
              {this.hiddingSubsidyCheckbox(index)}
              <AdditionalPrices type="children" index={index}/>
            </div>
          </div>
          <hr className="margin--top-1 margin--bottom-1" />
          <div className="grid">
            <div className="cell">
              <Eating index={index} type="children"/>
            </div>
          </div>
        </div>      
        {/* <div className="sum-row">
          <div>
            <div className="occupation-type-label-wrapper">
              {index + 1 + adult}. Dítě
              <select
                className="formControl text-select-input"
                disabled={isDisabled}
                value={childrenAge[index]}
                onChange={event =>
                  setChildrenAgeByRenderIndex(index, event.target.value)
                }
              >
                {this.renderChildrenOptions()}
              </select>
            </div>
          </div>

          {this.hiddingSubsidyCheckbox(index)}

          <AdditionalPrices type="children" index={index}/>

          <Eating index={index} type="children"/>

          {this.renderDiscountPrice(
            children[index].price,
            children[index].discountedPrice
          )}
        </div>       */}
      </>
    );
  }

  hiddingSubsidyCheckbox(index) {
    const {
      calculator: {occupation: {children}},
      toggleGrant,
      isSubsidy
    } = this.props;
    const { t } = this.props;

    if (isSubsidy) {
      return (
        <>
          <div className={`${input.form__group} ${input['form__group--inline']}`}>
            <input
              type="checkbox"
              className={input.checkbox}
              checked={children[index].grantUsed}
              onClick={() => toggleGrant("children", index)}
              id="childrenSubsidyEntitlement"
            />
            <label className={input.label} for="childrenSubsidyEntitlement">
              {t('subsidyEntitlement')}
            </label>          
          </div>       
          {/* <div className="subsidy-claim-wrapper">
            <label className="custom-checkbox subsidy">
              nárok na dotaci
              <input
                type="checkbox"
                checked={children[index].grantUsed}
                onClick={() => toggleGrant("children", index)}
              />
              <span className="checkmark"/>
            </label>
          </div>            */}
        </>

      );
    }

    return null;
  }

  renderChildrenOptions() {
    const uniqueAges = this.getDisctinctAges();

    const options = uniqueAges.map(age => {
      return <option value={age}>Dítě do {age + 1}</option>;
    });

    return options;
  }

  getDisctinctAges() {
    const {
      calculator: {data: {availableCustomers}, settings: {childrenAge}}
    } = this.props;
    const children = availableCustomers.filter(customer => {
      return customer.type === "children" && customer.maxAge;
    });

    const uniqueAges = children
      .map(child => {
        if (child.fullCalculation) {
          return child.maxAge - 1;
        }
      })
      .filter((v, i, a) => a.indexOf(v) === i)
      .filter(x => x != null);

    return uniqueAges;
  }

  renderDiscountPrice(price, discountPrice) {
    if (!discountPrice) {
      return (
        <>
          <div className="text--price color--secondary text--18">
            <strong>
              {formatCurrency(price)}
            </strong>
          </div>
          {/* <div className="person-price-wrapper">
            <span className="label">cena</span>
            <span className="price">{formatCurrency(price)}</span>
          </div>*/}
        </>

      );
    }

    return (
      <div className="person-price-wrapper">
        <span className="label">cena</span>

        <span className="row">
          <span className="crossed">{formatCurrency(price)}</span>
          <span className="price"> {formatCurrency(discountPrice)}</span>
        </span>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  calculator: state.calculator,
  isSubsidy: state.calculator.settings.isSubsidy
});

const mapDispatchToProps = dispatch => ({
  toggleGrant: (occupation, index) => dispatch(toggleGrant(occupation, index)),
  setChildrenAgeByRenderIndex: (index, age) =>
    dispatch(setChildrenAgeByRenderIndex(index, age))
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Radium(Children)));
