import Helmet from 'react-helmet';
import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import Radium from 'radium';
import faq from './faq.module.scss';
import App from './App';
import Banner from '../../Banner/Banner';
import Usp from '../../Usp/Usp';
import hero from '../Tours/ToursList/hero.module.scss';
import heroPicture from '../../../img/heros/hero-lists.webp';
import cms from '../CMS/cms.module.scss';
import { withTranslation } from 'react-i18next';

class Faq extends Component {

  render() {
		const {t} = this.props;

    return (

			<>
				<Helmet title="FAQ" />
				<section className={hero.hero}>
					<picture className={hero.hero__picture}>
						<img src={heroPicture} alt="Pro vaše nejlepší zážitky z cestování" />
					</picture>
					<div className={hero.hero__content}>
						<div className="container">
							<div className="grid gap">
								<div className="cell cell--12 cell--6:lg">
									<article className={hero.hero__article}>
										<h1 className="title margin--bottom-1 color--white">{t('faq')}</h1>
										<p className="lead margin--bottom-2 color--white">{t('faqDesc')}</p>
									</article>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section">
					<div className="container">
						<div className="grid">
	    	  		<div className="cell">
								<div className={cms.block}>
									<App/>
								</div>
	    	  		</div>
						</div>
	    		</div>
				</section>
				<section className="section section--global section--banner">
	    	  <div className="container">
	    	    <div className="grid">
	    	      <div className="cell">
	    	        <Banner />
	    	      </div>
	    	    </div>
	    	  </div>
	    	</section>
	    	<section className='section section--global' style={{paddingTop: 0}}>
	    	  <div className="container">
	    	    <div className="grid">
	    	      <div className="cell">
	    	        <Usp/>
	    	      </div>
	    	    </div>
	    	  </div>
	    	</section>
			</>
    )
  }
}

const mapStateToProps = (state) => ({
  tourList: state.tourList,
  advertising: state.advertising,
  tourFilter: state.tourFilter,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTotalToursCount: () => dispatch(fetchTotalToursCount()),
  fetchTile: () => dispatch(fetchTile()),
  saveIncluded: (desingation, filter) => dispatch(saveIncluded(desingation, filter)),
  fetchLocations: () => dispatch(fetchLocations()),
  fetchTourList: () => dispatch(fetchTourList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Radium(Faq)));
