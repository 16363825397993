import constants from '../../../styles/constants.js';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Column } from '../../../libs/radium-grid'; // eslint-disable-line
import styles from './input.module.scss';
// const styles = {
//   wrapper: {
//     borderBottom: 'none',
//     clear: 'both',
//     padding: '1px 0',
//     display: 'block',
//     color: '#333',

//     [constants.breakpointTablet]: {
//       padding: '0',
//     },
//   },
//   form: {
//     titleBox: {
//       fontWeight: '500',
//       fontSize: '0.9em',
//       float: 'left',
//       display: 'inline-block',
//       padding: '11px 0 0 0',

//       [constants.breakpointTablet]: {
//         padding: '10px 0 5px 0',
//       },
//     },
//     inputBox: {
//       float: 'left',
//       display: 'inline-block',
//       padding: '3px 0',
//     },
//   },
// };

export default class FormGroup extends Component {
  render() {
    const { children, title } = this.props;

    return (
      <>
        <div className={styles.form__group}>
          {title ? <label className={styles.label} htmlFor="">{title}</label> : null }
          {children}
        </div>
        {/* <div style={styles.wrapper}>
          <Column xs={12} sm={4} style={styles.form.titleBox}>
            {title}
          </Column>
          <Column xs={12} sm={8} style={styles.form.inputBox}>
            {children}
          </Column>
        </div>       */}
      </>
    );
  }
}

FormGroup.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string,
};
