import React, { Component } from "react";
import stepper from "./stepper.module.scss";
import { withTranslation } from "react-i18next";
import { act } from "react-dom/test-utils";


class Stepper extends Component {
  render() {
    const { t, activeStep } = this.props;

    return (
      <ul className={stepper.stepper}>
        <li className={this.getClass(1)} onClick={() => goFirst()}>
          <a className={stepper.stepper__link}>1</a>
        </li>
        <li className={this.getClass(2)}>
          <a className={stepper.stepper__link}>2</a>
        </li>
        <li className={this.getClass(3)}>
          <a className={stepper.stepper__link}>3</a>
        </li>
        <li className={this.getClass(4)}>
          <a className={stepper.stepper__link}>4</a>
        </li>
      </ul>
    );
  }

  getClass(step) {
    const { activeStep } = this.props;

    if ((activeStep + 1) === step) {
      return stepper["is-active"];
    }

    if ((activeStep + 1) > step) {
      return stepper["is-done"];
    }

    return;
  }
}

export default (withTranslation()(Stepper));
