import getLengthInDaysAndNights from '../../../../shared/getLengthInDaysAndNights';
import constants from '../../../../../styles/constants.js';
import formatDateLong from '../../../../shared/Moment/formatDateLong';
import crosLineImg from '../../../../../img/crossLine.png'; // eslint-disable-line
import formatDateShort from '../../../../shared/Moment/formatDateShort';
import formatCurrency from '../../../../shared/formatCurrency';
import createFilterRequest from '../../../../shared/RequestCreator/createFilterRequest';
import { fetchTerms } from '../../../../../actions/TourList/fetchTourList';
import { pickTour } from '../../../../../actions/TourDetail/tourDetail';
import { employee, szEmployee } from "../../../../../actions/HP/defaultTourlist";
import StyledCircleLoader from '../../../../shared/Loading/StyledCircleLoader';
import { fetchTourDetail } from '../../../../../actions/TourDetail/fetchTourDetail';
import { generatePDF } from '../../../../../actions/PDF/generatePDF';
import cookie from 'react-cookie';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { hashHistory } from 'react-router';
import PropTypes from 'prop-types';
import Radium from 'radium';
import prices from './prices.module.scss';

const customStyles = {
  tourHeader: {
    justifyContent: 'center',
  },
  loading: {
    display: 'block',
    width: '200px',
    margin: '0 auto',
    justifyContent: 'center',
    marginTop: 120,
  },
  buttonMarged: {
    margin: '20px 10px 0px 10px',
    [constants.breakpoint360]: {
      position: 'relative',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  wrapper: {
    clear: 'both',
    width: '99%',
    maxWidth: '600px',
    marginTop: '10px',
  },
  table: {
    borderCollapse: 'collapse',
    fontSize: '0.9em',
    margin: '0 auto',
    marginTop: '5px',
    width: '100%',
    cursor: 'pointer',

    centered: {
      textAlign: 'center',
      padding: '8px 0',
    },
    [constants.breakpointMobile]: {
      fontSize: '0.8em',
    },
    tbody: {
      backgroundColor: 'white',
      color: [constants.mainBlue],
    },
    head: {
      textAlign: 'left',
      backgroundColor: [constants.mainBlue],
      color: '#fff',
    },
    subsidyHead: {
      textAlign: 'left',
      backgroundColor: [constants.green],
      color: '#fff',
      fontWeight: 'bold',
      paddingBottom: '10px',
    },
    subsidyTitleHead: {
      color: 'white',
      backgroundColor: [constants.green],
      fontSize: '1.1em',
      fontWeight: '500',
      position: 'fixed',
      boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
      top: '30px',
      left: '-10',
      zIndex: 999,
      textAlign: 'center',
      padding: '20px',
    },
    subsidyTitle: {
      fontSize: '13px',
      textTransform: 'uppercase',
      color: 'black',
    },
    line: {
      width: '780',
    },
    description: {},
    priceFor: {
      textAlign: 'right',
      minWidth: '60px',
    },
    crossLine: {
      backgroundImage: `url(${crosLineImg})`,
      backgroundPositionY: '50%',
      backgroundRepeat: 'repeat-x',
      color: '#999',
    },
    price: {
      textAlign: 'right',
      minWidth: '80px',
    },
    tourPrice: {
      textAlign: 'center',
      fontWeight: 'bold',
      color: [constants.mainOrange],
    },
    tagWrapper: {
      textAlign: 'center',
      marginTop: -2,
    },
    tagLastMinuteWapper: {
      width: 87,
      position: 'relative',
      right: 10,
    },
    tagSpecialOfferWapper: {
      width: 115,
      position: 'relative',
      right: 25,
    },
    tagBothWrapper: {
      width: 200,
      position: 'relative',
      right: 65,
    },
    tagLastMinute: {
      position: 'relative',
      transform: 'rotate(-3deg)',
      display: 'inline-block',
      margin: 1,
      marginRight: 5,
    },
    tagSpecialOffer: {
      position: 'relative',
      transform: 'rotate(-3deg)',
      display: 'inline-block',
    },
    infoBottom: {
      marginTop: '5px',
      width: '700px',
      margin: '0 auto',
    },
    infoBottomWrapper: {
      marginTop: '20px',
    },
    tr: {
      border: '1px solid black',
    },
  },
  floatLeft: {
    float: 'left',
    marginRight: 10,
    marginBottom: 10,
  },
  stick: {
    fontWeight: 'normal',

    lastMinute: {
      border: 'solid 5px red',
    },
    soldOut: {
      border: 'solid 5px black',
    },
    specialOffer: {
      border: 'solid 5px' + [constants.green],
    },
  },
};

const specialOffer = 'Výhodná nabídka';
const soldOut = 'Vyprodáno';
const canceled = 'Zrušeno';

export const pickerPrintTable = 'pickerPrintTable';

class TourPicker extends Component {
  state = {
    fetched: false,
  };

  componentDidMount() {
    if (!this.props.dataFetched) {
      this.initialize();
    }
  }

  componentWillReceiveProps(props) {
    const { termList } = props;

    if (
      this.props.location &&
      this.props.location.pathname.indexOf('printSeparatedPriceList') >= 0
    ) {
      this.setState({ printing: true });
    }

    if (termList && termList.terms) {
      this.setState({ fetched: true });
    }
  }

  componentDidUpdate() {
    const { fetched, printing } = this.state;

    printing && fetched && setTimeout(() => window.print(), 1000);
  }

  render() {
    const {
      tourDetail: { hotelTitle },
      termList,
    } = this.props;
    const { printing } = this.state;

    if (!termList || !termList.terms) {
      return (
        <>
          <div style={customStyles.loading}>
            <StyledCircleLoader />
          </div>        
        </>
      );
    }

    const {
      termList: { terms },
    } = this.props;
    const hasSpecialStatus = this.getSpecialStatus(terms);

    return (
      <>
        <ul className={prices.prices} id={pickerPrintTable} ref={pickerPrintTable}>
          {terms.map((term) => this.renderPrices(term, hasSpecialStatus))}          
        </ul>
        {/* <div className="pricelist-div-line generating-pdf">
          {printing && <h style={customStyles.tourHeader}>{hotelTitle}</h>}
          <table style={customStyles.table} id={pickerPrintTable} ref={pickerPrintTable}>
            <tbody style={customStyles.table.tbody}>
              {terms.length && this.renderPriceListHead(hasSpecialStatus)}

              {terms.map((term) => this.renderPrices(term, hasSpecialStatus))}
            </tbody>
          </table>
        </div>       */}
      </>

    );
  }

  handleOpenAlert(alertOpened) {
    this.setState({ alertOpened });
  }

  initialize() {
    const {
      fetchTerms,
      filter,
      paginator: { minCost, itemsPerPage, maxCost, page },
    } = this.props;
    const properties = createFilterRequest(filter, page, itemsPerPage, minCost, maxCost, {
      changedShowTermList: true,
    });
    properties.idTour = this.props.params.idDetail;
    properties.type = 'list';

    fetchTerms(properties);
  }

  renderPriceListHead(hasSpecialStatus) {
    return (
      <tr style={customStyles.table.head}>
        <th id="header" style={customStyles.table.centered}>
          Datum
        </th>
        <th id="header" style={customStyles.table.centered}>
          Délka
        </th>
        <th id="header" style={customStyles.table.centered}>
          Doprava
        </th>
        <th id="header" style={customStyles.table.centered}>
          Dospělá osoba
        </th>
        {hasSpecialStatus && <th id="header" style={customStyles.table.centered} />}
      </tr>
    );
  }

  getSpecialStatus(terms) {
    let hasSpecialStatus = false;
    terms.forEach((term) => {
      const { isLastMinuteOrCustom, isSoldOut, isSpecialOffer, isCanceled } = term;
      if (isSpecialOffer || isCanceled || isSoldOut || isLastMinuteOrCustom) {
        hasSpecialStatus = true;
      }
    });

    return hasSpecialStatus;
  }

  renderPrices(term, hasSpecialStatus) {
    const {
      dateFrom,
      dateTo,
      isCanceled,
      lengthInDays,
      lengthInNights,
      transport,
      prices,
      idTour,
      isSoldOut,
    } = term;

    const user = cookie.load('user');

    let adultPrice;

    prices.forEach((price) => {
      if (price.code === 'DOSP') {
        if(user === szEmployee){
          adultPrice =  price.price - price.szSubsidy
        } else {
          adultPrice = user === employee ? price.price - price.subsidy : price.price;
        }
      }
    });

    return (
      <>
        <li onClick={() => this.pickTour(idTour)}>
          <div className="grid grid--middle">
            <div className="cell cell--auto">
              <div className="text--date color--primary-highdark">
                <strong>
                  {formatDateShort(dateFrom) + ' - ' + formatDateLong(dateTo)}
                </strong>
              </div>
            </div>
            <div className="cell cell--shrink">
              <div className="text--price color--secondary text--18">
                <strong>
                  {formatCurrency(adultPrice)}
                </strong>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="cell">
              <ul className="list">
                {(hasSpecialStatus || isSoldOut || isCanceled) && (
                  <li>{this.getMessage(term)}</li>
                )}
                <li className="text--14 color--primary-highdark">{getLengthInDaysAndNights(lengthInDays, lengthInNights)}</li>
                <li className="text--14 color--primary-highdark">{this.getFormatedTransports(transport)}</li>
              </ul>
            </div>
          </div>
        </li>

        {/* <tr onClick={() => this.pickTour(idTour)}>
        <td style={customStyles.table.centered}>
          {formatDateShort(dateFrom) + ' - ' + formatDateLong(dateTo)}{' '}
        </td>
        <td style={customStyles.table.centered}>
          {getLengthInDaysAndNights(lengthInDays, lengthInNights)}
        </td>
        <td style={customStyles.table.centered}>{this.getFormatedTransports(transport)}</td>
        <td style={customStyles.table.tourPrice}>{formatCurrency(adultPrice)}</td>
        {(hasSpecialStatus || isSoldOut || isCanceled) && (
          <td style={customStyles.table.tagWrapper}>{this.getMessage(term)}</td>
        )}
      </tr>         */}
      </>
    );
  }

  pickTour(idTour) {
    const { pickTour } = this.props;
    const user = cookie.load('user');

    pickTour(idTour);
    this.handleClose();

    hashHistory.push(`/zajezdy/detail/${idTour}?user=${user}`);
  }

  getMessage(term) {
    const { isSoldOut, isCanceled, isSpecialOffer, isLastMinuteOrCustom } = term;

    const {
      filterSettings: { filterName },
    } = this.props;

    if (isCanceled) {
      return (
        <div className={prices.label}>
          {this.renderSticker(canceled, 'soldOut')}
        </div>
      );
    } else if (isSoldOut) {
      return (
        <div className={prices.label}>{this.renderSticker(soldOut, 'soldOut')}</div>
      );
    } else if (isLastMinuteOrCustom) {
      return (
        <div className={prices.label}>
          {this.renderSticker(filterName, 'lastMinute')}
        </div>
      );
    } else if (isSpecialOffer) {
      return (
        <div className={prices.label}>
          {this.renderSticker(specialOffer, 'specialOffer')}
        </div>
      );
    }

    return '';
  }

  handleClose() {
    this.props.onClick(false);
  }

  getFormatedTransports(transport) {
    let transportMessage = '';

    transport.forEach((transportItem, i) => {
      transportMessage += transportItem.title + (i !== transport.length - 1 ? ', ' : '');
    });

    return transportMessage;
  }

  renderSticker(text, type) {
    return (
      <>
        {text}
      </>
    );
  }
}

TourPicker.propTypes = {
  tourDetail: PropTypes.any,
  postNewBooking: PropTypes.any,
  fetchTerms: PropTypes.any,
  filter: PropTypes.any,
  filterSettings: PropTypes.any,
  termList: PropTypes.any,
  paginator: PropTypes.any,
  pickTour: PropTypes.any,
  withoutAction: PropTypes.any,
  invertedShow: PropTypes.any,
  fetchTourDetail: PropTypes.any,
  onClick: PropTypes.any,
  params: PropTypes.any,
  location: PropTypes.any,
  extraStyle: PropTypes.any,
  dataFetched: PropTypes.any,
  defaultState: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
  filter: state.filter,
  filterSettings: state.filterSettings,
  paginator: state.paginator,
  termList: state.termList,
  postFormStatus: state.postFormStatus,
});

const mapDispatchToProps = (dispatch) => ({
  pickTour: (idTour) => dispatch(pickTour(idTour)),
  fetchTourDetail: (id) => dispatch(fetchTourDetail(id)),
  fetchTerms: (properties) => dispatch(fetchTerms(properties)),
  generatePDF: (params, name) => dispatch(generatePDF(params, name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(TourPicker));
