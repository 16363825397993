import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

const styles = {
  sticker: {
    position: 'absolute',
    left: '1rem',
    top: '-0.875rem',
    color: '#fff',
    padding: '0.25rem 0.5rem',
    lineHeight: '1.25rem',
    textAlign: 'center',
    fontWeight: '700',
    letterSpacing: '0.032rem',
    display: 'inline-flex',
    textTransform: 'uppercase',
    zIndex: 2,
    fontSize: '0.625rem',  
  },
  triangle: {
    position: 'absolute',
    top: '1.75rem',
    width: 0,
    height: 0,
    borderLeft: '0.5rem solid transparent',
    borderRight: '0.5rem solid transparent',
    borderTop: '0.5rem solid #0049C6',
  }
};

class TourSticker extends Component {
  render() {
    const { children, type, primaryColor, secondaryColor } = this.props;

    if (primaryColor !== 'defaultColor') {
      type.background = '#146AFF';
    }

    if (secondaryColor !== 'defaultColor') {
      type.background = '#146AFF';
    }

    return <div style={[styles.sticker, type]}><span style={styles.triangle}></span>{children}</div>;
  }
}

TourSticker.propTypes = {
  children: PropTypes.string.isRequired,
  type: PropTypes.string,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
};

export default Radium(TourSticker);
