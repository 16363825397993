import cookie from 'react-cookie';
import {connect} from 'react-redux';
import {hashHistory} from 'react-router';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

import HeaderContacts from './HeaderContacts';
import SectionType from './SectionType';
import constants from '../../styles/constants.js';
import MainSearchInput from './MainSearchInput';
import {fetchHeader} from '../../actions/Header/fetchHeader';
import LoaderInModalWindow from '../shared/Loading/LoaderInModalWindow';
import ComparementSideBar from './ComparementSideBar/ComparementSideBar';
import MenuDesktop from './MainMenu/MenuDesktop';
import MenuResponsive from './MainMenu/MenuResponsive';
import styles from './header.module.scss';
import Logo from '../Logo/Logo';
import sprite from '../../img/icons/sprite.svg';

class Header extends Component {  
  componentDidMount() {
    const {
      fetchHeader,
      fetchState: {headerFetched},
      location: {pathname, query},
    } = this.props;

    const user = cookie.load('user');
    const ignoreUrlUser = cookie.load('ignoreUrlUser');

    if (query.user && query.user !== user && ignoreUrlUser !== 'true') {
      cookie.save('userChangedFromMenu', false);
      cookie.save('user', query.user);
      cookie.save('userChanged', true);

      window.location.reload();
      return;
    }

    if (!user) {
      if (pathname.indexOf('katalog') > 0) {
        cookie.save('backLink', '/katalog');
      }

      hashHistory.push('/');
    } else {
      !headerFetched && fetchHeader(user);
    }
  }

  menuToggle = () => {
    const nav = document.getElementById('nav');
    nav.classList.toggle('is--active');
  };  

  render() {
    const {
      fetchState: {headerFetched},
    } = this.props;
    const user = cookie.load('user');

    const showComparementSideBar = this.props.location.pathname.indexOf('porovnani') < 0;

    return (
      <>
        <header className={styles.header}>
          <div className={styles.navbar}>
            <div className={styles.navbar__left}>
              <Logo isLight={false} />
              <MainSearchInput/>
            </div>
            <div className={styles.navbar__right}>
              <MenuDesktop />
              <button type="button" className="button button--menu" onClick={this.menuToggle}>
                <svg className="icon hide:lg" role="presentation" width="24" height="24">
                  <use xlinkHref={sprite + '#sprite-menu'}/>
                </svg>
              </button>
            </div>
          </div>
          <SectionType location={this.props.location}/>
        </header>

        <ComparementSideBar showComparementSideBar={showComparementSideBar}/>
      </>
      // <div style={styles.wrapper}>
      //   {!headerFetched && <LoaderInModalWindow/>}
      //   <Logo/>

      //   

      //   <div className="section-types-wrapper">
      //     <SectionType location={this.props.location}/>
      //   </div>

      //   <HeaderContacts/>

      //   <MenuDesktop/>

      //   <MenuResponsive/>


      // </div>
    );
  }
}

Header.propTypes = {
  fetchHeader: PropTypes.any,
  fetchState: PropTypes.any,
};

const mapStateToProps = (state) => ({
  fetchState: state.fetchState,
});

const mapDispatchToProps = (dispatch) => ({
  fetchHeader: (user) => dispatch(fetchHeader(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(Header));
