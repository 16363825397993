import Radium from 'radium';
import constants from '../../../../../styles/constants';
import selectArrow from '../../../../../img/select-arrow2.png'; // eslint-disable-line
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { saveFilter } from '../../../../../actions/TourList/filter';
import { toggleTiles } from '../../../../../actions/TourList/paginator';
import { closeTabs, toggle } from '../../../../../actions/TourList/filterOpenStatus';
import { fetchFilteredList } from '../../../../../actions/TourList/fetchTourList';
import createFilterRequest from '../../../../shared/RequestCreator/createFilterRequest';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import components from './components.module.scss';
import settings from './settings.module.scss';
import sprite from '../../../../../img/icons/sprite.svg';

const styles = {
  wrapper: {
    width: '100%',
  },
  filterArrow: {
    border: '2px',
    borderStyle: 'solid',
    borderRadius: '25px',
    textAlign: 'center',
    borderColor: [constants.grey],
    color: [constants.grey],
    selected: {
      borderColor: [constants.mainBlue],
      color: [constants.mainBlue],
    },
  },
  inputBox: {
    width: 240,
    display: 'inline-block',
    position: 'absolute',
    marginLeft: 25,
    zIndex: 2,

    [constants.breakpoint600]: {
      right: 2,

      width: 180,
    },
    first: {
      [constants.breakpointLargeTablet]: {
        marginLeft: 0,
      },

      [constants.breakpointTablet]: {
        marginTop: 0,
      },
    },
  },
  showByTerms: {
    position: 'relative',
    display: 'inline-block',
    float: 'right',
    marginRight: 10,
  },
  selectBox: {
    width: 'calc(100% - 53px)',
    height: 32,
    zIndex: '2',
    overflow: 'hidden',
    padding: '0 42px 0 11px',
    lineHeight: '34px',
    border: '1px solid' + [constants.mainBlue],
    display: 'inline-block',
    backgroundColor: '#fff',
    backgroundImage: 'url(' + selectArrow + ')',
    backgroundPosition: 'right center',
    backgroundRepeat: 'no-repeat',
    color: [constants.mainBlue],
    fontSize: '0.9em',
    fontWeight: '500',
    cursor: 'pointer',

    orderNameInSelect: {
      fontWeight: 'bold',
      color: [constants.mainBlue],
    },
  },

  /*
  orderArrowsWrapper: {
    width: 80,
    position: 'absolute',
    right: 610,
    top: 76,
    [constants.breakpoint600]: {
      right: 180,
    },
  },
  orderArrow: {
    paddingRight: '10px',
    cursor: 'pointer',
    color: [constants.mainBlue],

    [constants.breakpointLargeTablet]: {
      paddingLeft: '0',
    },

    first: {
      paddingLeft: 0,
    },
  },
  */

  dropdownWrapper: {
    background: [constants.dropDownBackground],
    width: 'calc(100% + 2px)',
    position: 'absolute',
    top: 48,
    borderTop: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.31) 1px 2px 4px',
    zIndex: 3,
  },
  dropDown: {
    background: [constants.dropDownBackground],
    position: 'absolute',
    left: 0,
    width: 'calc(100% + 2px)',
    zIndex: '2',
    fontSize: '0.9em',

    item: {
      padding: '8px 0px',
      cursor: 'pointer',
      margin: '0 25px',
      borderBottom: 'solid 1px #ccc',
    },
    checkbox: {
      marginRight: '10px',
      position: 'relative',
      top: '1px',
    },
    caret: {
      display: 'block',
      position: 'absolute',
      top: '-8px',
      left: '7px',
      width: '0',
      height: '0',
      content: '',
      verticalAlign: 'middle',
      borderBottom: '10px dashed' + [constants.dropDownBackground],
      borderRight: '10px solid transparent',
      borderLeft: '10px solid transparent',
    },
  },
};

const dateCode = 'date';

const locationCode = 'location';

export const dateSorting = {
  value: 'dle data',
  code: dateCode,
};

class TourListSettings extends Component {
  state = {
    sortType: 'ASC',
    opened: false,
    data: [
      dateSorting,
      {
        value: 'dle ceny',
        code: 'price',
      },
      {
        value: 'dle oblasti',
        code: locationCode,
      },
    ],
    text: '',
  };

  componentDidMount() {
    this.checkFilters();
  }

  componentWillReceiveProps(props) {
    this.checkFilters(props);
  }

  checkFilters(props = this.props) {
    const {
      filter: { sortBy },
    } = props;
    const { text } = this.state;

    if (sortBy && text !== sortBy.value) {
      this.setState({ text: sortBy.value, pickedSort: sortBy });
    }
  }

  render() {
    const {
      filterOpen: { sort },
    } = this.props;
    const { data, sortType, text } = this.state;
    const checkBoxIsFirst = true;
    const checkBoxIsNullable = true;
    const sortingTitle = 'Řazení';
    const sortingMessage = text;

    return (
      <>
        {this.renderCheckBox(
          sortingTitle,
          sortingMessage,
          data,
          sort,
          checkBoxIsNullable,
          !checkBoxIsFirst,
          'sort',
        )}

        {this.renderSorting(sortType)}
      </>
    );
  }

  renderSorting() {
    const { pickedSort, sortType } = this.state;

    if (!pickedSort) {
      return null;
    }

    return (
      <div className={settings['sort']}>
        <span className={settings['sort__item'] + (sortType === 'DESC'? ' ' + settings['is-active']:'')} onClick={() => this.changeRadioState('DESC')}>
          <svg className="icon material-icons" data-tip={'řadit sestupně'} role="presentation" width="24" height="24">
            <use xlinkHref={sprite + '#sprite-arrow-down'}/>
          </svg>
          {/*
          <i
            data-tip={'řadit sestupně'}
            style={[styles.orderArrow, sortType === 'DESC' && settings.arrow.selected]}
            className="material-icons"
          >
            keyboard_arrow_down
          </i>*/}
        </span>
        <span className={settings['sort__item'] + (sortType === 'ASC'? ' ' + settings['is-active']:'')} onClick={() => this.changeRadioState('ASC')}>
          <svg className="icon material-icons"  data-tip={'řadit vzestupně'} role="presentation" width="24" height="24">
            <use xlinkHref={sprite + '#sprite-arrow-up'}/>
          </svg>
          {/*
          <i
            data-tip={'řadit vzestupně'}
            style={[styles.orderArrow, sortType === 'ASC' && styles.orderArrow.selected]}
            className="material-icons"
          >
            keyboard_arrow_up
          </i>*/}
        </span>

        <ReactTooltip html={Boolean(true)} />
      </div>
    );
  }

  renderCheckBox(textTitle, textMessage, data, opened, nullable, isfirst, type) {
    return (
      <div className={components.sort}>
        <div onClick={() => this.toggleOpen(type)} className={components.sort__button}>
          {textTitle}
          <span>: {textMessage}</span>
        </div>

        <div className={components.sort__dropdown}>
          <ul className='list'>
            {opened && data.map((item) => this.renderItem(item))}
          </ul>
        </div>
      </div>
    );
  }

  renderDropDownCaret(nullable) {
    return (
      <li>
        {nullable && this.renderNoneOption()}
      </li>
    );
  }

  renderNoneOption() {
    return (
      <div className="item" style={styles.dropDown.item} onClick={() => this.reset()}>
        <i>
          <center>žádné</center>
        </i>
      </div>
    );
  }

  renderItem(item) {
    const { value, code } = item;
    const { sortType } = this.state;

    return (
      <li
        onClick={() => this.setSorting(item, sortType)}
        value={code}
      >
        {value}
      </li>
    );
  }

  changeRadioState(nextState) {
    const { pickedSort } = this.state;
    this.setState({ sortType: nextState });
    this.setSorting(pickedSort, nextState);
  }

  setSorting(sortItem, sortType) {
    this.setState({ text: sortItem.value, pickedSort: sortItem });

    if (sortItem) {
      const showTermList = sortItem.code !== locationCode;
      const { saveFilter } = this.props;
      const sortObject = {
        value: sortItem.value,
        code: sortItem.code,
        type: sortType,
      };
      const optional = {
        changedSortBy: sortObject,
        changedShowTermList: showTermList,
      };

      this.fetchFiltered(optional);
      saveFilter(sortObject, 'sortBy');
      saveFilter(showTermList, 'showTermList');
    }
  }

  sortByDate() {
    const { saveFilter } = this.props;
    const sortObject = {
      value: dateSorting.value,
      code: dateSorting.code,
      type: 'ASC',
    };
    this.setState({ text: dateSorting.value, pickedSort: dateSorting });
    saveFilter(sortObject, 'sortBy');

    return sortObject;
  }

  reset() {
    const { saveFilter } = this.props;

    saveFilter('', 'sortBy');
    saveFilter(Boolean(false), 'showTermList');
    this.setState({ text: '', opened: false, pickedSort: '' });
    const optional = { changedSortBy: '', changedShowTermList: false };

    this.fetchFiltered(optional);
  }

  fetchFiltered(optional) {
    const {
      fetchFilteredList,
      toggleTiles,
      filter,
      paginator: { minCost, itemsPerPage, maxCost, page },
    } = this.props;
    fetchFilteredList(createFilterRequest(filter, page, itemsPerPage, minCost, maxCost, optional));
    toggleTiles(false);
  }

  toggleOpen(type) {
    const { toggle, filterOpen } = this.props;
    if (!filterOpen[type]) {
      toggle(type);
    } else {
      closeTabs();
    }
  }
}

TourListSettings.propTypes = {
  filter: PropTypes.any,
  toggle: PropTypes.any,
  filterOpen: PropTypes.any,
  saveFilter: PropTypes.any,
  paginator: PropTypes.any,
  fetchFilteredList: PropTypes.any,
  toggleTiles: PropTypes.any,
};

const mapStateToProps = (state) => ({
  filter: state.filter,
  filterOpen: state.filterOpen,
  paginator: state.paginator,
});

const mapDispatchToProps = (dispatch) => ({
  toggle: (designation) => dispatch(toggle(designation)),
  closeTabs: () => dispatch(closeTabs()),
  toggleTiles: (nextState) => dispatch(toggleTiles(nextState)),
  saveFilter: (filter, designation) => dispatch(saveFilter(filter, designation)),
  fetchFilteredList: (filters) => dispatch(fetchFilteredList(filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(TourListSettings));
