import {
  setSettingsParameter,
  cleanOccupation,
  setOccupation,
} from '../../../../actions/Calculator/calculator';
import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import BaseSelect from '../../../shared/BaseSelect/BaseSelect';
import { customerCombinationParam } from '../../../../reducers/calculator';

class RoomPicker extends Component {
  render() {
    const {
      calculator: {
        data: { customerCombinations },
        settings: { customerCombination },
      },
    } = this.props;
    const selectItems = Object.keys(customerCombinations)
      .map((key) => {
        return {
          id: key,
          title: key,
        };
      })
      .sort((item) => {
        return item.id > item.id;
      });

    return (
      <>
        <BaseSelect
          isCheckBox={Boolean(false)}
          items={selectItems}
          placeholder={'-'}
          text={customerCombination}
          onReset={() => this.handleReset(null, customerCombinationParam)}
          onSelect={(count) => this.handleSelect(count, customerCombinationParam)}
        />      
        {/* <div className="picker picker-small">
          <BaseSelect
            isCheckBox={Boolean(false)}
            items={selectItems}
            placeholder={'-'}
            text={customerCombination}
            onReset={() => this.handleReset(null, customerCombinationParam)}
            onSelect={(count) => this.handleSelect(count, customerCombinationParam)}
          />
        </div>       */}
      </>
    );
  }

  handleSelect(count, customerCombinationParam) {
    const {
      calculator: { specialAdult },
      setOccupation,
      cleanOccupation,
      setSettingsParameter,
    } = this.props;

    if (specialAdult) {
      setOccupation('adult', parseInt(count));
    } else {
      cleanOccupation();
    }

    setSettingsParameter(count, customerCombinationParam);
  }

  handleReset(count, customerCombinationParam) {
    const { setSettingsParameter, cleanOccupation } = this.props;

    setSettingsParameter(count, customerCombinationParam);
    cleanOccupation();
  }
}

const mapStateToProps = (state) => ({
  calculator: state.calculator,
});

const mapDispatchToProps = (dispatch) => ({
  setOccupation: (occupationCode, count) => dispatch(setOccupation(occupationCode, count)),
  setSettingsParameter: (object, parameter) => dispatch(setSettingsParameter(object, parameter)),
  cleanOccupation: () => dispatch(cleanOccupation()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Radium(RoomPicker));
