import constants from '../../../../../../styles/constants.js';
import crosLineImg from '../../../../../../img/crossLine.png'; // eslint-disable-line
import formatDateLong from '../../../../../shared/Moment/formatDateLong';
import formatDateShort from '../../../../../shared/Moment/formatDateShort';
import getHeaders from '../../../../../shared/getUniqueHeaders';
import getPriceFromCode from '../../../../../shared/getPriceFromCode';
import { fetchCompleteTourTerms } from '../../../../../../actions/TourDetail/fetchTourDetail';
import { generatePDF } from '../../../../../../actions/PDF/generatePDF';
import getDataTip from '../../../../../shared/getDetailPriceListDataTip';
import tourPriceListTemplate from '../../../../../shared/TourDetail/tourPriceListTemplate';
import StyledCircleLoader from '../../../../../shared/Loading/StyledCircleLoader';
import PropTypes from 'prop-types';
import OrangeButton from '../../../../../shared/OrangeButton';
import { printCompletePriceList } from '../../../../../../utils/routeConfig';
import base64 from 'base-64';
import utf8 from 'utf8';
import ReactTooltip from 'react-tooltip';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Radium from 'radium';
import { employee, szEmployee } from "../../../../../../actions/HP/defaultTourlist";
import getIsSubsidy from "../../../../../shared/isSubsidy";

const styles = {
  wrapper: {
    marginBottom: '30px',
    clear: 'both',
  },
  leftAlign: {
    textAlign: 'left',
  },
  rightAlign: {
    margin: '5 0 5 0',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  sectionTitle: {
    marginTop: '0px',
    marginBottom: '40px',
    textTransform: 'uppercase',
    lineHeight: '23px',
    fontWeight: '700',
    padding: '1px 5px 1px 4px',
    color: [constants.yellow],
    display: 'inline-block',
    fontSize: '1.1em',
    borderBottom: '2px solid #ededed',
    width: '100%',
  },
  table: {
    borderCollapse: 'collapse',
    fontSize: '1em',
    maxWidth: '1000px',
    width: '100%',
    margin: '0 auto',
    marginTop: '30px',

    [constants.breakpointMobile]: {
      fontSize: '0.8em',
    },
    tbody: {
      backgroundColor: 'white',
    },
    head: {
      textAlign: 'center',
    },
    subsidyHead: {
      textAlign: 'left',
      backgroundColor: [constants.mainOrange],
      color: '#fff',
      fontWeight: 'bold',
      paddingBottom: '10px',
    },
    normalHead: {
      backgroundColor: [constants.orange],
      color: '#fff',
    },
    subsidyTitleHead: {
      color: 'white',
      backgroundColor: [constants.green],
      fontSize: '1.1em',
      fontWeight: '500',
      position: 'fixed',
      boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
      top: '30px',
      left: '-10',
      zIndex: 999,
      textAlign: 'center',
      padding: '20px',
    },
    subsidyTitle: {
      fontSize: '13px',
      textTransform: 'uppercase',
      color: 'black',
    },
    line: {
      width: '780',
    },
    crossLine: {
      backgroundImage: `url(${crosLineImg})`,
      backgroundPositionY: '50%',
      backgroundRepeat: 'repeat-x',
      color: '#999',
    },
    printingCrossLine: {
      backgroundPositionY: '50%',
      backgroundRepeat: 'repeat-x',
      color: 'rgba(216, 216, 216, 0.5)',
    },
    description: {},
    priceFor: {
      textAlign: 'right',
      minWidth: '60px',
    },
    price: {
      textAlign: 'right',
      minWidth: '80px',
    },
    infoBottom: {
      marginTop: '5px',
      width: '700px',
      margin: '0 auto',
    },
    infoBottomWrapper: {
      marginTop: '20px',
    },
  },
  tourNotices: {
    padding: ' 0 10px',
  },
  tourHeader: {
    justifyContent: 'center',
  },
  loading: {
    display: 'block',
    width: '200px',
    margin: '0 auto',
    justifyContent: 'center',
    marginTop: 120,
  },
  buttonSubsidyMarged: {
    margin: '20px 10px 0px 10px',
    background: [constants.mainOrange],
    borderColor: [constants.mainOrange],
    [constants.breakpoint360]: {
      position: 'relative',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    ':hover': {
      background: 'transparent',
      borderColor: [constants.mainOrange],
      color: [constants.mainOrange],
    },
  },
  buttonMarged: {
    margin: '20px 10px 0px 10px',
    [constants.breakpoint360]: {
      position: 'relative',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  buttons: {
    position: 'relative',
    left: '50%',
    float: 'left',
    transform: 'translateX(-50%)',
  },
};

export const subsidy = 'dotovany';
export const soldOut = 'Vyprodáno';

class PriceList extends Component {
  typesToUsableTypes = {
    'dotovany': 'employee',
    'szEmployee': 'szEmployee',
    'employee': 'employee',
    'visitor': 'visitor',
  }

  state = {
    fetched: false,
  };

  componentDidMount() {
    this.initialize();
  }

  componentWillReceiveProps(props) {
    const { tourDetail, fetchCompleteTourTerms } = props;
    const { idDetail, type, fetched } = this.state;

    if (
      idDetail !== props.params.idDetail &&
      typeof props.params.idDetail !== 'undefined' &&
      !fetched
    ) {

      fetchCompleteTourTerms(props.params.idDetail);
      if (!type && typeof this.props.params.type !== 'undefined') {
        this.setState({ type: this.typesToUsableTypes[this.props.params.type]});
      }
    }

    if (this.props.location.pathname.indexOf('printCompletePriceList') >= 0) {
      this.setState({ printing: true });
    }

    if (Object.keys(tourDetail).length !== 0) {
      this.setState({ fetched: true });
    }
  }

  componentDidUpdate() {
    const { fetched, printing } = this.state;

    printing && fetched && setTimeout(() => window.print(), 1000);
  }

  render() {
    const {
      tourDetail: { terms, id, hotelTitle, country, location },
    } = this.props;
    const { fetched, type, printing } = this.state;

    if (!fetched) {
      return (
        <>
          <div style={customStyles.loading}>
            <StyledCircleLoader />
          </div>        
        </>
      );
    }

    const { headers, headersCodes } = getHeaders(terms, type);

    typeof this.state.headersLength === 'undefined' &&
      this.setState({ headersLength: headers.length });

    return (
      <div
        className={
          'generating-pdf ' +
          (printing ? 'tour-price-list tour-price-list-print' : 'tour-price-list')
        }
        style={styles.wrapper}
      >
        {printing && (
          <h style={styles.tourHeader}>{country + ', ' + location + ', ' + hotelTitle}</h>
        )}
        <div className="pricelist-div-line" id="printed" ref="printed">
          <table style={styles.table}>
            <tbody style={styles.table.tbody}>
              {terms.length && this.renderPriceListHead(headers, type)}

              {terms.length && terms.map((term) => this.renderPrices(term, headersCodes, type))}
            </tbody>
          </table>
        </div>

        <div className="bottom-info-wrapper" />

        <ReactTooltip html={Boolean(true)} />
        {!printing && this.renderOrangeButtons(id, type, hotelTitle)}
      </div>
    );
  }

  renderOrangeButtons(id, type) {
    return (
      <div style={styles.buttons}>
        <OrangeButton
          onClick={() => this.generatePDF(document.getElementById('printed'), getIsSubsidy(type))}
          target="_blank"
          extraStyle={getIsSubsidy(type) ? styles.buttonSubsidyMarged : styles.buttonMarged}
        >
          Vygenerovat PDF
        </OrangeButton>
      </div>
    );
  }

  initialize() {
    const { fetchCompleteTourTerms, dataFetched } = this.props;

    if (!dataFetched) {
      fetchCompleteTourTerms(this.props.params.idDetail);
    } else {
      this.setState({ fetched: true });
    }
  }

  getTableLineStyle(isSoldOut) {
    const { printing } = this.state;

    if (isSoldOut) {
      return printing ? styles.table.printingCrossLine : styles.table.crossLine;
    }
    return styles.table.line;
  }

  generatePDF(html, isSubsidy) {
    const { headersLength } = this.state;
    const {
      generatePDF,
      tourDetail: { hotelTitle, location, country },
    } = this.props;
    const textColor = isSubsidy ? constants.green : constants.orange;
    const header = country + ', ' + location + ', ' + hotelTitle;

    const pdf = tourPriceListTemplate
      .replace('{header}', header)
      .replace('{content}', html.outerHTML);

    const bytes = utf8.encode(pdf);

    const encoded = base64.encode(bytes);

    const requestData = {
      base64Encoded: encoded,
    };

    const name = 'Cenik_' + hotelTitle.replace(/ /g, '_').replace(/\*/g, '');

    generatePDF(requestData, name.endsWith('_') ? name.substr(0, name.length - 1) : name);
  }

  renderPrices(term, headersCodes, type) {
    const { dateFrom, dateTo, lengthInDays, prices, isSubsidy, isSoldOut } = term;
    if (getIsSubsidy(type) && !isSubsidy) {
      return null;
    }

    return (
      <tr
        style={this.getTableLineStyle(isSoldOut)}
        className={isSoldOut ? 'term-sold-out' : ''}
        data-tip={isSoldOut ? soldOut : getDataTip(term, type)}
      >
        <td style={styles.rightAlign}>
          {formatDateShort(dateFrom) + ' - ' + formatDateLong(dateTo)}{' '}
        </td>
        <td style={styles.rightAlign}>{lengthInDays !== 0 && lengthInDays}</td>
        {headersCodes.map((code) => this.renderTable(code, prices, type, isSoldOut))}
      </tr>
    );
  }

  renderTable(code, prices, type, isSoldOut) {
    return (
      <td style={styles.rightAlign}>
        {isSoldOut ? soldOut : getPriceFromCode(code, prices, type)}
      </td>
    );
  }

  renderPriceListHead(headers, type) {
    return (
      <tr style={getIsSubsidy(type) ? styles.table.subsidyHead : styles.table.normalHead}>
        <th id="header" style={getIsSubsidy(type) ? styles.table.subsidyTitle : null}>
          {getIsSubsidy(type) ? 'Ceny po dotaci' : 'Datum'}
        </th>
        <th id="header" style={styles.rightAlign}>
          Počet dnů
        </th>
        {headers.map((item) => this.renderTableHeaderItems(item))}
      </tr>
    );
  }

  renderTableHeaderItems(item) {
    return (
      <th id="header" style={styles.table.head}>
        {item}
      </th>
    );
  }
}

PriceList.propTypes = {
  params: PropTypes.node,
  generatePDF: PropTypes.node,
  type: PropTypes.node,
  location: PropTypes.node,
  pathname: PropTypes.node,
  tourDetail: PropTypes.node,
  fetchCompleteTourTerms: PropTypes.node,
  dataFetched: PropTypes.node,
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
  filter: state.filter,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCompleteTourTerms: (id) => dispatch(fetchCompleteTourTerms(id)),
  generatePDF: (params, name) => dispatch(generatePDF(params, name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(PriceList));
