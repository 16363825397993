import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SectionImage from '../../shared/SectionImage';
import LoaderInModalWindow from '../../shared/Loading/LoaderInModalWindow';
import about from '../../../img/about.jpg'; // eslint-disable-line
import faq from '../../../img/faq.jpg'; // eslint-disable-line
import tickets from '../../../img/tickets.jpg'; // eslint-disable-line
import croatiaTravel from '../../../img/croatiaTravel.jpg'; // eslint-disable-line
import { Container } from '../../../libs/radium-grid'; // eslint-disable-line
import Radium from 'radium';
import { fetchCMS } from '../../../actions/CMS/fetchCMS';
import { faqTitle, aboutTitle, ticketsTitle, croatiaTravelTitle, faqDesc } from '../../../utils/titleConfig';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import cms from './cms.module.scss';
import hero from '../Tours/ToursList/hero.module.scss';
import heroPicture from '../../../img/heros/hero-lists.webp';
import Banner from '../../Banner/Banner';
import Usp from '../../Usp/Usp';
import { withTranslation } from 'react-i18next';


{/*const styles = {
  wrapper: {
    padding: '50px 0 120px 0',
  },
  loading: {
    display: 'block',
    width: '200px',
    margin: '0 auto',
    justifyContent: 'center',
    marginTop: 120,
  },
  innerContentBox: {
    maxWidth: '900px',
    margin: '30px auto 0 auto',
  },
};*/}

class CMSPage extends Component {
  state = {
    tickets,
    faq,
    about,
    'doprava-do-chorvatska': croatiaTravel,
  };

  componentDidMount() {
    const { fetchCMS } = this.props;

    fetchCMS();
  }

  render() {
    const { title } = this.getContent(this.props.params.idCms);
    const {t} = this.props;

    let header = '';
    let desc = '';
    switch (this.props.params.idCms) {
      case 'faq':
        header = faqTitle;
        desc = faqDesc;
        break;
      case 'about':
        header = aboutTitle;
        break;
      case 'tickets':
        header = ticketsTitle;
        break;
      case 'doprava-do-chorvatska':
        header = croatiaTravelTitle;
        break;
      default:
        header = '';
    }

    return (
      <div>
        <Helmet title={header} />
        <section className={hero.hero}>
          <picture className={hero.hero__picture}>
						<img src={heroPicture} alt="Pro vaše nejlepší zážitky z cestování" />
					</picture>
          <div className={hero.hero__content}>
            <div className="container">
              <div className="grid gap">
                <div className="cell cell--12 cell--6:lg">
                  <article className={hero.hero__article}>
                    <h1 className="title margin--bottom-1 color--white">{title}</h1>
                    {desc ? <>
                      <p className="lead margin--bottom-2 color--white">{desc}</p>
                    </> : null}
                    
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
          <div >{this.renderContent()}</div>
      </div>
    );
  }

  renderContent() {
    const { title, content } = this.getContent(this.props.params.idCms);

    if (title === '') {
      return (
        <Container>
          <div>
            <LoaderInModalWindow />
          </div>
        </Container>
      );
    }

    return (
      <>
        <section className="section">
					<div className="container">
						<div className="grid">
	    	  		<div className="cell">
                <div className={cms.block}>
                  <p dangerouslySetInnerHTML={{ __html: content }} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section section--global section--banner">
	    	  <div className="container">
	    	    <div className="grid">
	    	      <div className="cell">
	    	        <Banner />
	    	      </div>
	    	    </div>
	    	  </div>
	    	</section>
	    	<section className='section section--global' style={{paddingTop: 0}}>
	    	  <div className="container">
	    	    <div className="grid">
	    	      <div className="cell">
	    	        <Usp/>
	    	      </div>
	    	    </div>
	    	  </div>
	    	</section>
      </>
    );
  }

  getContent(url) {
    const { CMS } = this.props;
    let title = '';
    let content = '';

    CMS.forEach((item) => {
      if (item.url === url) {
        title = item.title;
        content = item.content;
      }
    });
    return { title, content };
  }
}

CMSPage.propTypes = {
  CMS: PropTypes.any,
  idCms: PropTypes.any,
  params: PropTypes.any,
  fetchCMS: PropTypes.any,
};

const mapStateToProps = (state) => ({
  CMS: state.CMS,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCMS: () => dispatch(fetchCMS()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Radium(CMSPage)));
