import constants from "../../../../../../styles/constants";
import Radium from "radium";
import React, { Component } from "react";
import PropTypes from "prop-types";
import OrangeButton from "../../../../../shared/OrangeButton";
import { connect } from "react-redux";
import { loadSeznamScripts } from "../../../../../shared/loadMap";
// import './Maps.scss';
import styles from "./maps.module.scss";
import Dialog from "material-ui/Dialog";
import { togetherTravelingReply } from "../../../../TogetherTravelling/AdLine";
import InputModalAlert from "../../../../../shared/InputModalAlert";
import { postSucceeded } from "../../../../../../actions/TogetherTraveling/postPassengers";

class Maps extends Component {
  state = {
    opened: false
  };
  componentDidMount() {
    loadSeznamScripts(this);
  }

  componentDidUpdate() {
    const { SMap } = window;
    const { mapLoaded } = this.state;
    const {
      tourDetail: { gps }
    } = this.props;

    if (mapLoaded) {
      this.createMap();
    }
  }

  createMap() {
    const { SMap, JAK } = window;
    const { mapInstance } = this.state;
    const {
      tourDetail: { gps }
    } = this.props;

    if (mapInstance) {
      return mapInstance;
    }

    const center = SMap.Coords.fromWGS84(gps[1], gps[0]);
    const map = new SMap(JAK.gel("mapBig"), center, 17);

    map.addDefaultLayer(SMap.DEF_BASE).enable();
    map.addDefaultControls();

    const layer = new SMap.Layer.Marker();
    map.addLayer(layer);
    layer.enable();

    var options = {};
    var marker = new SMap.Marker(center, "myMarkerDetail", options);

    layer.addMarker(marker);

    this.setState({ mapInstance: map });

    return map;
  }

  render() {
    const { isSmall } = this.props;
    return (
      <>
        <div className={`${styles.map} margin--bottom-1`}>
          <div id="mapBig" className={styles.map__large} />
        </div>
      </>
    );
  }
}

Maps.propTypes = {
  tourDetail: PropTypes.any
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail
});

export default connect(mapStateToProps)(Radium(Maps));
