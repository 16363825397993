import cookie from 'react-cookie';
import { employee, szEmployee } from '../actions/HP/defaultTourlist';

const user = cookie.load('user');

const constants = {
  /* Colors */
  mainBlue: '#287fb8',
  darkBlue: 'rgb(56, 102, 150)',
  grey: '#ccc',
  mainOrange: user === employee ? '#afc836' : user === szEmployee ? '#FF5200' : '#edb700',
  green: '#afc836',
  orange: '#edb700',
  mainOrangeLight: '#ffd133',
  mainOrangeDark: '#e6b200',
  szEmployeePrimary: '#FF5200',
  littleGrayScale: 'rgba(0, 0, 0, 0.07)',
  yellow: user === employee ? '#afc836' : user === szEmployee ? '#FF5200' : '#edb700',
  black: '#404040',
  dropDownBackground: '#ececec',
  dropDownText: '#000',

  /* Other */
  toursTileOverlay: user === employee ? '#afc836' : user === szEmployee ? '#FF5200' : 'rgba(237, 183, 0, 0.8)',
  inputBorder: '#ccc',
  transition: '0.3s',

  /* Media breakpoints */
  breakpoint1650: '@media (max-width: 1650px)',
  breakpointLargeDesktop: '@media (min-width: 1555px)',
  breakpointDesktop: '@media (max-width: 1555px)',
  breakpoint1400: '@media (max-width: 1400px)',
  breakpoint1300: '@media (max-width: 1300px)',
  breakpointLargeTablet: '@media (max-width: 1200px)',
  breakpoint1040: '@media (max-width: 1040px)',
  breakpoint1024: '@media (max-width: 1024px)',
  breakpoint991: '@media (max-width: 991px)',
  breakpoint850: '@media (max-width: 850px)',
  breakpoint820: '@media (max-width: 820px)',
  breakpoint768Min: '@media (min-width: 768px)',
  breakpointTablet: '@media (max-width: 766px)',
  breakpoint600: '@media (max-width: 600px)',
  breakpointMobile: '@media (max-width: 480px)',
  breakpoint400: '@media (max-width: 400px)',
  breakpoint360: '@media (max-width: 360px)',
  retina: '@media (-webkit-min-device-pixel-ratio: 2)',
};

export default constants;
