import DatePicker from 'material-ui/DatePicker';
import Dialog from 'material-ui/Dialog';
import emailVerify from '../../shared/emailVerify';
import InputModalAlert from '../../shared/InputModalAlert';
import FilterSelect from '../../shared/FilterSelect';
import FormGroup from '../../shared/forms/FormGroup';
import OnlyText from '../../shared/forms/OnlyText';
import moment from 'moment';
import 'moment/locale/cs';
import OrangeButton from '../../shared/OrangeButton';
import styles from '../../shared/forms/modalFormStyles/modalFormStyles';
import Radium from 'radium';
import { togetherTravelingReply } from './AdLine';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  replyRequest,
  postNewInsertion,
  postSucceeded,
} from '../../../actions/TogetherTraveling/postPassengers';
import {
  setNewInsertionForm,
  toggleTogetherTravelingForm,
} from '../../../actions/TogetherTraveling/passengerFilter';
import { newAdvertising } from './TogetherTravelling';
import input from '../../shared/forms/input.module.scss';
import { withTranslation } from 'react-i18next';

class TogetherTravellingForm extends Component {
  state = {
    status: '',
    alertOpened: false,
    ableToOpen: true,
    listeningToPropsStatus: false,
    opened: false,
    dateFrom: '',
    dateTo: '',
    sex: '',
    age: '',
    note: '',
    email: '',
    countryName: '',
    sexOptions: [
      {
        id: 0,
        value: 'muž',
      },
      {
        id: 1,
        value: 'žena',
      },
    ],
  };

  componentWillReceiveProps(props) {
    const {
      postFormStatus: { postTogetherTraveling },
    } = props;
    const { listeningToPropsStatus, ableToOpen } = this.state;
    if (ableToOpen) {
      this.setState({ status: '' });
    }

    if (listeningToPropsStatus) {
      this.setState({ status: postTogetherTraveling });
    }
  }

  render() {
    const { status, alertOpened } = this.state;
    const { opened, sourceOfRequest } = this.props;
    const {t} = this.props;

    return (
      <Dialog
        title={
          sourceOfRequest === togetherTravelingReply ? 'Odpověď na inzerát' : 'Přidat nový inzerát'
        }
        actions={this.getActions()}
        modal={false}
        open={opened}
        autoScrollBodyContent="true"
        titleStyle={{ borderBottom: 'none' }}
        bodyStyle={{
          borderBottom: '3px solid #fff',
          bottom: '-1px',
          position: 'relative',
        }}
        style={{ borderTop: 'none' }}
        contentClassName="dialog-box"
        actionsContainerClassName="modal__control"
        bodyClassName="content-box"
        titleClassName="title-box"
        className="modal"
      >
        <InputModalAlert
          opened={alertOpened}
          onClick={this.handleOpenAlert.bind(this)}
          message="Zkontrolujte, prosím, zda jsou všechny položky vyplněné a jsou vyplněné správně."
        />
        <div style={styles.wrapper}>
          <p style={styles.status}>{status}</p>
          {this.renderDialogItems(status)}
        </div>
      </Dialog>
    );
  }

  renderDialogItems(status) {
    const { age, email, note, sexOptions } = this.state;
    const { sourceOfRequest } = this.props;
    const {t} = this.props;

    if (status === postSucceeded) {
      return null;
    }

    return (
      <>
        <div className="grid gap">
          <div className="cell cell--12 cell--6:md margin--bottom-24">
            <FormGroup title="Pohlaví *">
              <FilterSelect
                renderData={sexOptions}
                defaultValue="Vyberte pohlaví"
                onClick={this.handleSex.bind(this)}
              />
            </FormGroup>
          </div>
          <div className="cell cell--12 cell--6:md margin--bottom-24">
            <FormGroup title="Věk *">
              <input
                className={input.input}
                value={age}
                onChange={(event) => {
                  this.setState({ age: event.target.value });
                }}
              />
            </FormGroup>            
          </div>
          <div className="cell cell--12 cell--6:md margin--bottom-24">
            <FormGroup title="Země *">
              {this.renderCountryItem(sourceOfRequest)}
            </FormGroup>
          </div>
          <div className="cell cell--12 cell--6:md margin--bottom-24">
            <FormGroup title="E-mail *">
              <input
                className={input.input}
                value={email}
                placeholder="@"
                onChange={(event) => {
                  this.setState({ email: event.target.value });
                }}
              />
            </FormGroup>            
          </div>
          <div className="cell cell--12 margin--bottom-24">
            <FormGroup title="Komentář">
              <textarea
                value={note}
                className={input.textarea}
                rows="8"
                onChange={(text) => {
                  this.setState({ note: text.target.value });
                }}
              />
            </FormGroup>
          </div>          
        </div>
        {/* <div style={styles.row}>
          <div style={styles.col}>
            <div>
              {this.renderDateItem(sourceOfRequest)}
              <FormGroup title="Pohlaví *">
                <FilterSelect
                  renderData={sexOptions}
                  defaultValue="Pohlaví"
                  onClick={this.handleSex.bind(this)}
                />
              </FormGroup>
            </div>
          </div>
          <div style={styles.col}>
            <div>
              <FormGroup title="Věk *">
                <input
                  style={styles.input}
                  value={age}
                  onChange={(event) => {
                    this.setState({ age: event.target.value });
                  }}
                />
              </FormGroup>
              <FormGroup title="Země *">{this.renderCountryItem(sourceOfRequest)}</FormGroup>
              <FormGroup title="E-Mail *">
                <input
                  style={styles.input}
                  value={email}
                  onChange={(event) => {
                    this.setState({ email: event.target.value });
                  }}
                />
              </FormGroup>
            </div>
          </div>
          <div style={styles.noticeBox}>
            <div style={styles.noticeBox.title}>Poznámka *</div>
            <div style={styles.noticeBox.inputBox}>
              <textarea
                value={note}
                style={styles.area}
                onChange={(text) => {
                  this.setState({ note: text.target.value });
                }}
              />
            </div>
          </div>
        </div>       */}
      </>
    );
  }

  renderCountryItem(sourceOfRequest) {
    if (sourceOfRequest === newAdvertising) {
      const {
        tourFilter: { country },
      } = this.props;

      const allowedCountries = country
        .map((countryItem) => {
          if (countryItem.code === 'HPTILE') {
            return null;
          }

          return countryItem;
        })
        .filter((x) => x != null);

      return (
        <FilterSelect
          renderData={allowedCountries}
          defaultValue="země"
          onClick={this.handleCountry.bind(this)}
        />
      );
    }

    const { country } = this.props;

    if (typeof country !== 'undefined') {
      return (
        <>
          <select className={input.select} disabled>
            <option value={country}>{country}</option>
          </select>
        </>
      );
    }

    return null;
  }

  renderDateItem(sourceOfRequest) {
    const { dateFrom, dateTo } = this.props;

    if (sourceOfRequest === newAdvertising) {
      return (
        <div>
          <FormGroup title="Datum od *">
            <DatePicker
              style={styles.datePicker}
              onChange={(x, event) => this.setDate(event, 'from')}
              hintText="Vybrat"
              mode="portrait"
              key="from"
              cancelLabel="Zrušit"
              className="datepicker"
              textFieldStyle={styles.datePicker.text}
              okLabel="Potvrdit"
              DateTimeFormat={Intl.DateTimeFormat}
              formatDate={this.formatDate}
              locale="cs"
              autoOk="true"
            />
          </FormGroup>
          <FormGroup title="Datum do *">
            <DatePicker
              style={styles.datePicker}
              onChange={(x, event) => this.setDate(event, 'to')}
              hintText="Vybrat"
              mode="portrait"
              key="to"
              cancelLabel="Zrušit"
              className="datepicker"
              textFieldStyle={styles.datePicker.text}
              okLabel="Potvrdit"
              DateTimeFormat={Intl.DateTimeFormat}
              formatDate={this.formatDate}
              locale="cs"
              autoOk="true"
            />
          </FormGroup>
        </div>
      );
    }

    return (
      <div>
        <FormGroup title="Datum od *">
          <OnlyText>{dateFrom}</OnlyText>
        </FormGroup>

        <FormGroup title="Datum do *">
          <OnlyText>{dateTo}</OnlyText>
        </FormGroup>
      </div>
    );
  }

  close() {
    const { toggleTogetherTravelingForm, sourceOfRequest } = this.props;

    this.setState({
      status: '',
      listeningToPropsStatus: false,
      ableToOpen: true,
    });
    toggleTogetherTravelingForm(sourceOfRequest, false);
  }

  getActions() {
    const { status } = this.state;
    const {t} = this.props;

    if (status === postSucceeded) {
      return (
        <div style={styles.buttonsWrapper}>
          <OrangeButton onClick={() => this.close()} submit="submit" type="inversion">
            Zavřít
          </OrangeButton>
        </div>
      );
    }

    return (
      <>
      <div className="grid grid--justify gap">
        <div className="cell cell--shrink">
          <button onClick={() => this.close()} submit="submit" type="inversion" className="button hollow" title={t('close')}>
            {t('close')}
          </button>
        </div>
        <div className="cell cell--shrink">
          <button onClick={() => this.postInsertion()} submit="submit" type="normal" className="button button--primary" title={t('submit')} >
            {t('submit')}
          </button>
        </div>
      </div>      
        {/* <div style={styles.buttonsWrapper}>
          <OrangeButton onClick={() => this.close()} submit="submit" type="inversion">
            Zrušit
          </OrangeButton>

          <OrangeButton onClick={() => this.postInsertion()} submit="submit" type="normal">
            Potvrdit
          </OrangeButton>
        </div>       */}
      </>
    );
  }

  handleOpenAlert(alertOpened) {
    this.setState({ alertOpened });
  }

  postInsertion() {
    const {
      postNewInsertion,
      replyRequest,
      sourceOfRequest,
      id,
      tourFilter: { country },
    } = this.props;
    const { age, sex, note, email, countryName } = this.state;

    const countryObject = country.filter((countryObject) => countryObject.value === countryName);

    let requestDateFrom;
    let requestDateTo;
    if (sourceOfRequest === newAdvertising) {
      const { dateFrom, dateTo } = this.state;
      requestDateFrom = dateFrom;
      requestDateTo = dateTo;
    } else {
      const { dateFrom, dateTo } = this.props;
      requestDateFrom = dateFrom;
      requestDateTo = dateTo;
    }

    const requestData = {
      idTravelerAdvertising: id,
      dateFrom: requestDateFrom,
      dateTo: requestDateTo,
      age,
      countryCode: countryObject.length && countryObject[0].code,
      sex,
      email,
      note,
    };

    const requiredFields = [requestDateFrom, requestDateTo, age, sex, note, email];
    sourceOfRequest === newAdvertising && requiredFields.push(this.state.countryName);

    let error = false;

    requiredFields.forEach((fieldName) => {
      if (fieldName === '') {
        error = true;
      }
    });

    if (
      error ||
      moment(requestDateFrom).isAfter(requestDateTo) ||
      isNaN(age) ||
      !emailVerify(email)
    ) {
      this.setState({ alertOpened: true });
    } else {
      this.setState({
        status: 'Odesílám formulář',
        listeningToPropsStatus: true,
        ableToOpen: false,
        dateFrom: '',
        dateTo: '',
        sex: '',
        age: '',
        note: '',
        email: '',
        countryName: '',
      });

      sourceOfRequest === newAdvertising
        ? postNewInsertion(requestData)
        : replyRequest(requestData);
    }
  }

  setDate(event, state) {
    if (state === 'to') {
      const date = moment(event).format('YYYY/MM/DD');
      this.setState({ dateTo: date });
    } else {
      const date = moment(event).format('YYYY/MM/DD');
      this.setState({ dateFrom: date });
    }
  }

  handleSex(value) {
    this.setState({ sex: value });
  }

  handleCountry(value) {
    this.setState({ countryName: value });
  }
}

TogetherTravellingForm.propTypes = {
  opened: PropTypes.object.isRequired,
  sourceOfRequest: PropTypes.object.isRequired,
  country: PropTypes.object.isRequired,
  dateFrom: PropTypes.string.isRequired,
  dateTo: PropTypes.string.isRequired,
  toggle: PropTypes.object.isRequired,
  tourFilter: PropTypes.any,
  toggleTogetherTravelingForm: PropTypes.any,
  id: PropTypes.any,
  postNewInsertion: PropTypes.any,
  replyRequest: PropTypes.any,
};

const mapStateToProps = (state) => ({
  passengersFilter: state.passengersFilter,
  postFormStatus: state.postFormStatus,
  tourFilter: state.tourFilter,
});

const mapDispatchToProps = (dispatch) => ({
  setNewInsertionForm: (status) => dispatch(setNewInsertionForm(status)),
  postNewInsertion: (requestData) => dispatch(postNewInsertion(requestData)),
  toggleTogetherTravelingForm: (formType, isFormOpened) =>
    dispatch(toggleTogetherTravelingForm(formType, isFormOpened)),
  replyRequest: (requestData) => dispatch(replyRequest(requestData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Radium(TogetherTravellingForm)));
