import DatePicker from 'material-ui/DatePicker';
import FilterSelect from '../../shared/FilterSelectCode';
import constants from '../../../styles/constants.js';
import Radium from 'radium';
import React, { Component } from 'react';
import getCountries from '../../shared/getTogetherCountiesIncluded';
import { saveFilter } from '../../../actions/TogetherTraveling/passengerFilter';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormGroup from '../../shared/forms/FormGroup';
import input from '../../shared/forms/input.module.scss';
import sprite from '../../../img/icons/sprite.svg';


const styles = {
  datepicker: {
    text: {
      color: '#000816',
      fontSize: '0.875rem',
      cursor: 'pointer',
      width: '100%',
      maxWidth: '100%',
    },
  },
};

// const styles = {
//   filterBox: {
//     background: '#282828',
//     padding: '15px',
//     maxWidth: '800px',
//     width: 'calc(100% - 30px)',
//     margin: '-10px auto 40px auto',
//     overflow: 'auto',
//   },
//   inputBox: {
//     width: '24%',
//     margin: '0.5%',
//     float: 'left',
//     fontWeight: '500',
//     display: 'inline-block',
//     position: 'relative',

//     [constants.breakpointTablet]: {
//       width: '100%',
//       margin: '1% 0',
//     },
//     resetDate: {
//       position: 'absolute',
//       top: 5,
//       right: 5,
//       cursor: 'pointer',
//     },
//   },
//   datePicker: {
//     overflow: 'hidden',
//     background: '#fff',
//     borderBottom: 'none',
//     height: 40,

//     text: {
//       color: [constants.black],
//       fontSize: '13px',
//       letterSpacing: '0px',
//       left: 10,
//       top: -4,
//       cursor: 'pointer',
//     },
//   },
// };

class Filter extends Component {
  state = {
    sex: '',
    country: '',
    dateFrom: '',
    dateTo: '',
    sexOptions: [
      {
        code: 0,
        value: 'muž',
      },
      {
        code: 1,
        value: 'žena',
      },
    ],
  };

  render() {
    const {
      tourFilter: { country },
      passengers,
    } = this.props;
    const {
      passengersFilter: { dateFrom, dateTo },
    } = this.props;
    const { sexOptions } = this.state;

    return (
      <>
        <div className="grid grid--bottom gap">
          <div className="cell cell--12 cell--6:md cell--3:lg margin--bottom-24">
            <FormGroup>
              <FilterSelect
                renderData={sexOptions}
                defaultValue="Pohlaví"
                name="sex"
                onClick={this.handleSelect.bind(this)}
              />
            </FormGroup>
          </div>
          <div className="cell cell--12 cell--6:md cell--3:lg margin--bottom-24">
            <FormGroup>
              <FilterSelect
                renderData={getCountries(country, passengers)}
                defaultValue="Země"
                name="country"
                onClick={this.handleSelect.bind(this)}
              />
            </FormGroup>
          </div>
          <div className="cell cell--12 cell--6:md margin--bottom-24">
            <div className={input.datepicker}>
              <div className={input.datepicker__field}>
                <DatePicker
                  value={dateFrom}
                  onChange={(x, event) => this.setDate(event, 'from')}
                  hintText="Datum od"
                  mode="portrait"
                  key="from"
                  cancelLabel="Zrušit"
                  className={input.datepicker__input}
                  textFieldStyle={styles.datepicker.text}
                  okLabel="Potvrdit"
                  DateTimeFormat={Intl.DateTimeFormat}
                  formatDate={this.formatDateLong}
                  locale="cs"
                  autoOk="true"
                />
                {dateFrom && this.renderResetDateButton('from')}
              </div>
              <div className={input.datepicker__field}>
                <DatePicker
                  value={dateTo}
                  minDate={dateFrom || new Date()}
                  onChange={(x, event) => this.setDate(event, 'to')}
                  style={styles.datePicker}
                  hintText="Datum do"
                  mode="portrait"
                  key="from"
                  cancelLabel="Zrušit"
                  className={input.datepicker__input}
                  textFieldStyle={styles.datepicker.text}
                  okLabel="Potvrdit"
                  DateTimeFormat={Intl.DateTimeFormat}
                  formatDate={this.formatDateLong}
                  locale="cs"
                  autoOk="true"
                />
                {dateTo && this.renderResetDateButton('to')}
              </div>
            </div>
          </div>
        </div>
        {/* <div style={styles.filterBox}>
          <div style={styles.inputBox}>
            <FilterSelect
              renderData={sexOptions}
              defaultValue="pohlaví"
              name="sex"
              onClick={this.handleSelect.bind(this)}
            />
          </div>
          <div style={styles.inputBox}>
            <FilterSelect
              renderData={getCountries(country, passengers)}
              defaultValue="země"
              name="country"
              onClick={this.handleSelect.bind(this)}
            />
          </div>
          <div style={styles.inputBox}>
            <DatePicker
              value={dateFrom}
              onChange={(x, event) => this.setDate(event, 'from')}
              style={styles.datePicker}
              hintText="datum od"
              mode="portrait"
              key="from"
              cancelLabel="Zrušit"
              className="datepicker datepicker-together-traveling"
              textFieldStyle={styles.datePicker.text}
              okLabel="Potvrdit"
              DateTimeFormat={Intl.DateTimeFormat}
              formatDate={this.formatDateLong}
              locale="cs"
              autoOk="true"
            />
            {dateFrom && this.renderResetDateButton('from')}
          </div>
          <div style={styles.inputBox}>
            <DatePicker
              value={dateTo}
              minDate={dateFrom || new Date()}
              onChange={(x, event) => this.setDate(event, 'to')}
              style={styles.datePicker}
              hintText="datum do"
              mode="portrait"
              key="from"
              cancelLabel="Zrušit"
              className="datepicker datepicker-together-traveling"
              textFieldStyle={styles.datePicker.text}
              okLabel="Potvrdit"
              DateTimeFormat={Intl.DateTimeFormat}
              formatDate={this.formatDateLong}
              locale="cs"
              autoOk="true"
            />
            {dateTo && this.renderResetDateButton('to')}
          </div>
        </div>       */}
      </>
    );
  }

  renderResetDateButton(state) {
    return (
      <>
        <i
          className={input.datepicker__reset}
          onClick={() => this.setDate(null, 'from')}
        >
          <svg className="icon" role="presentation" width="16" height="16">
            <use xlinkHref={sprite + '#sprite-cross'}/>
          </svg>
        </i>
        {/* <i
          style={styles.inputBox.resetDate}
          className="material-icons "
          onClick={() => this.setDate(null, state)}
        >
          close
        </i>               */}
      </>
    );
  }

  saveFilter(designation, state) {
    const { saveFilter } = this.props;

    saveFilter(designation, state);
  }

  setDate(event, state) {
    if (state === 'to') {
      this.saveFilter('dateTo', event);
    } else {
      this.saveFilter('dateFrom', event);
    }
  }

  handleSelect(value, name) {
    this.saveFilter([name], value);
  }
}

Filter.propTypes = {
  tourFilter: PropTypes.any,
  saveFilter: PropTypes.any,
  passengers: PropTypes.any,
  passengersFilter: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourFilter: state.tourFilter,
  passengers: state.passengers,
  passengersFilter: state.passengersFilter,
});

const mapDispatchToProps = (dispatch) => ({
  saveFilter: (designation, state) => dispatch(saveFilter(designation, state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(Filter));
