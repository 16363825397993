import constants from '../../../styles/constants.js';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './input.module.scss';

// const styles = {
//   input: {
//     padding: '8px',
//     background: '#fff',
//     borderWidth: '1px',
//     borderStyle: 'solid',
//     borderColor: [constants.inputBorder],
//     marginBottom: '1px',
//     fontSize: '0.9em',
//     width: '100%',
//   },
//   types: {
//     inline: {
//       width: 'auto',
//       margin: '0 7px',
//     },
//     small: {
//       width: 'auto',
//       margin: 0,
//     },
//   },
// };

export default class SelectBox extends Component {
  render() {
    const { name, type, options, defaultOption } = this.props;

    return (
        <select
          onChange={this.handleChange.bind(this)}
          className={styles.select}
          name= {name}
        >
          {defaultOption && <option value={0}>---</option>}
          {options.map((item) => this.renderOptions(item))}
        </select>
    );
  }

  renderOptions(item) {
    return (
      <option value={item.id}>
        {item.value}
      </option>
    );
  }

  handleChange(event) {
    const { name } = this.props;
    const value = event.target.value;

    this.props.onClick(value, name);
  }
}

SelectBox.propTypes = {
  children: PropTypes.any.isRequired,
  name: PropTypes.any.isRequired,
  type: PropTypes.string,
  options: PropTypes.object,
  defaultOption: PropTypes.any.isRequired,
  onClick: PropTypes.any,
};
