import {
  setInfantOccupation,
  resetOccupation
} from "../../../../actions/Calculator/calculator";
import React, {Component} from "react";
import Radium from "radium";
import {connect} from "react-redux";

export const getPeopleInOrder = (props) => {
  const {calculator: {occupation: {children, adult, infants}}} = props;

  const infantsUsed = infants.reduce((a, b) => a + (parseInt(b.timeUsed) || 0), 0)

  return infantsUsed + children.length + adult.length;
}

class InfantsPicker extends Component {
  render() {
    const {calculator: {data: {availableCustomers}}} = this.props;

    if (!availableCustomers) {
      return null;
    }

    const infants = availableCustomers.filter(customer => {
      return !customer.fullCalculation;

    });

    if (!infants.length) {
      return (
        <>
          {infants.map(infant => this.renderInfantPicker(infant))}
          {this.renderSpecialPeoplePriceMessage(availableCustomers)}
        </>
      )
    }

    return (
      <>
        {infants.map(infant => this.renderInfantPicker(infant))}
        {this.renderSpecialPeoplePriceMessage(availableCustomers)}
        <p className="color--primary-highdark text--14 margin--bottom-0">
          <strong className="color--primary">!&nbsp;</strong>
          <i>Infanti nezabírají plné místo</i>
        </p>
      </>
    );
  }

  renderSpecialPeoplePriceMessage() {
    const {calculator: {specialAdult}} = this.props;

    if (specialAdult) {
      return (
        <p className="color--primary-highdark text--14 margin--bottom-0">
          <strong className="color--primary">!&nbsp;</strong>
          <i>Stejné ceny u dětí i dospělých</i>
        </p>
      );
    }
  }

  renderInfantPicker(infant) {
    const {
      calculator: {settings: {infants}},
      setInfantOccupation
    } = this.props;
    const {code} = infant;

    return (
      <div className={"picker picker-small " + (getPeopleInOrder(this.props) > 6 ? 'validation-error' : '')}>
        <label>Infant</label>
        <input
          className="text-input error"
          value={infants[code]}
          onChange={event => setInfantOccupation(code, event.target.value)}
          type="number"
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  calculator: state.calculator
});

const mapDispatchToProps = dispatch => ({
  setInfantOccupation: (occupationCode, count) =>
    dispatch(setInfantOccupation(occupationCode, count)),
  resetOccupation: occupationCode => dispatch(resetOccupation(occupationCode))
});
export default connect(mapStateToProps, mapDispatchToProps)(
  Radium(InfantsPicker)
);
