import React, {Component} from "react";
import Radium from "radium";
import {connect} from "react-redux";
import "./Reservation.css";
import input from '../../../shared/forms/input.module.scss';

class Agreement extends Component {
  state = {};

  render() {
    const {trans, values, param, isSubsidy, onChange} = this.props;

    return (
      <>
        <div className={`${input.form__group} ${input['form__group--inline']}`}>
          <input
            type="checkbox"
            className={input.checkbox}
            checked={values[param]}
            onChange={() => onChange(!values[param])}
            id="agreement"
          />
          <label className={input.label} for="agreement">
            {trans[param]}
          </label>          
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(
  Radium(Agreement)
);
