import React, { Component } from 'react';
import {Link} from 'react-router';
import banner from './banner.module.scss';
import bannerImg from '../../img/banners/banner-last-minute.webp';
import { dateSorting } from "../Pages/Tours/ToursList/components/TourListSettings";
import createFilterRequest from "../shared/RequestCreator/createFilterRequest";
import { setCost, toggleTiles } from "../../actions/TourList/paginator";
import { saveFilter } from "../../actions/TourList/filter";
import { fetchFilteredList } from "../../actions/TourList/fetchTourList";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Radium from "radium";

class Banner extends Component {
  render() {
    const {
      saveFilter,
      toggleTiles,
      filter: { isLastMinuteOrCustom },
      fetchFilteredList,
      paginator: { page, itemsPerPage },
      filter,
      filter: { dateFrom, dateTo },
    } = this.props;
    const {t} = this.props;

    return (
      <>
        <div className={banner.banner}>
          <picture className={banner.banner__picture}>
            <img src={bannerImg} alt="Nabídka first nebo last minute" />
          </picture>
          <div className={banner.banner__content}>
            <h2 className='title color--white margin--bottom-24'>{t('firstOrLastMinuteTitle')}</h2>
            <p className='color--white margin--bottom-2'>{t('firstOrLastMinuteDesc')}</p>
            <button className='button button--primary' onClick={() => this.toggleLastMinute()}>
              {isLastMinuteOrCustom ? "Schovat Last Minute zájezdy" : "Prohlédnout aktuální nabídku"}
            </button>
          </div>
        </div>
      </>
    );

  }
  toggleLastMinute() {
    const {
      saveFilter,
      toggleTiles,
      filter: { isLastMinuteOrCustom },
      fetchFilteredList,
      paginator: { page, itemsPerPage },
      filter,
      filter: { dateFrom, dateTo },
    } = this.props;
    const { actualMax, actualMin } = this.state;
    const sortObject = {
      value: dateSorting.value,
      code: dateSorting.code,
      type: 'ASC',
    };

    saveFilter(!isLastMinuteOrCustom, 'isLastMinuteOrCustom');
    const optional = {
      changedLastMinute: !isLastMinuteOrCustom,
      changedSortBy: sortObject,
    };

    saveFilter(sortObject, 'sortBy');
    toggleTiles(false);

    fetchFilteredList(
      createFilterRequest(filter, page, itemsPerPage, 0, 999999999, optional),
    );
  }

}

const mapStateToProps = (state) => ({
  tourList: state.tourList,
  tourFilter: state.tourFilter,
  filter: state.filter,
  filterSettings: state.filterSettings,
  paginator: state.paginator,
  fetchState: state.fetchState,
  defaultTourListFilter: state.defaultTourListFilter,
});

const mapDispatchToProps = (dispatch) => ({
  toggleTiles: (nextState) => dispatch(toggleTiles(nextState)),
  saveFilter: (filter, designation) => dispatch(saveFilter(filter, designation)),
  fetchFilteredList: (filters) => dispatch(fetchFilteredList(filters)),
  setCost: (min, max) => dispatch(setCost(min, max)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Radium(Banner)));

