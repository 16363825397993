import Radium from 'radium';
import paymentMethodes from '../../../img/paymentMethodes.png';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import TextInput from '../../shared/forms/TextInput';
import { createNewPayment } from '../../../actions/Payment/payment';
import Agreement from '../Calculator/Reservation/Agreement';
import cookie from 'react-cookie';
import { employee } from '../../../actions/HP/defaultTourlist';
import ReactTooltip from 'react-tooltip';
import hero from '../Tours/ToursList/hero.module.scss';
import heroPicture from '../../../img/heros/hero-lists.webp';
import cms from '../CMS/cms.module.scss';
import form from './forms.module.scss';
import sprite from '../../../img/icons/sprite.svg';
import visaIcon from '../../../img/icons/visa.svg';
import styles from '../../shared/forms/input.module.scss';
import { withTranslation } from 'react-i18next';

class Payment extends Component {
  state = {
    agreementChecked: false,
    firstAndSecondName: '',
    specificSymbol: '',
    amount: '',
  };

  SPECIFIC_SYMBOL_LENGTH = 10;

  onSubmit() {
    createNewPayment(this.state);
  }

  render() {
    const {t} = this.props;
    const isSubsidy = cookie.load('user') === employee;
    return (
      <>
        <Helmet title="Online prodej"/>
				<section className={hero.hero}>
					<picture className={hero.hero__picture}>
						<img src={heroPicture} alt={t('paymentInfo')} />
					</picture>
					<div className={hero.hero__content}>
						<div className="container">
							<div className="grid gap">
								<div className="cell cell--12 cell--6:lg">
									<article className={hero.hero__article}>
										<h1 className="title margin--bottom-1 color--white">{t('paymentInfo')}</h1>
										<p className="lead margin--bottom-2 color--white">{t('paymentIntroText')}</p>
									</article>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section">
					<div className="container">
						<div className="grid">
	    	  		<div className="cell margin--bottom-3">
								<div className={cms.block}>
                  {this.renderFormInputs()}

                  <div style={{maxWidth:"28rem", marginInline:"auto"}}>
                    <div className="grid">
                      <div className="cell margin--bottom-2 margin--top-2 align--center">
                        <p className="margin--bottom-0 text--18">
                          <span className="color--primary-highdark">{t('price')}</span>
                          {'  '}
                          <strong className="color--primary">{this.getPrice()} {t('currency')}</strong>
                        </p>
                      </div>
                      <div className="cell">
                        <button
                          className="button button--primary button--full"
                          dataTooltip={this.getValidationMessage()}
                          disabled={this.isSubmitButtonDisabled()}
                          onClick={() => this.onSubmit()}
                        >
                          {t('pay')}
                        </button>
                        <ReactTooltip html={Boolean(true)} />
                        {/* {this.getValidationMessage()} */}
                      </div>
                    </div>
                  </div>
								</div>
	    	  		</div>
						</div>
	    		</div>
				</section>
      </>
    );
  }

  renderFormInputs() {
    const {t} = this.props;
    const isSubsidy = cookie.load('user') === employee;

    return (
      <div style={{maxWidth:"28rem", marginInline:"auto"}}>
        <div className="grid color--primary-highdark">
          <div className="cell align--center margin--bottom-2">
            <p className="margin--bottom-0" dangerouslySetInnerHTML={{__html: t('paymentLead')}}>
            </p>
          </div>

          <div className="cell margin--bottom-24">
            {this.renderTextInput('firstAndSecondName', 'Jméno a příjmení')}
          </div>

          <div className="cell margin--bottom-24">
            {this.renderTextInput('specificSymbol', 'Variabilní symbol')}
            <p style={{marginTop: '0.5rem'}} className="text--14 color--primary-highdark margin--bottom-0"><i>{t('vsHint')}</i></p>
          </div>

          <div className="cell margin--bottom-24">
            {this.renderNumberInput('amount', 'Částka')}
          </div>

          <div className="cell margin--bottom-24">
            <Agreement
              isSubsidy={isSubsidy}
              trans={{
                agreementChecked:
                  t('pipAgreement'),
              }}
              param="agreementChecked"
              values={this.state}
              onChange={(value) => this.setState({ agreementChecked: value })}
            />
          </div>

          <div className="cell">
            <div className={form.form__payment}>
              <svg className="icon" role="presentation" width="40" height="32">
                <use xlinkHref={sprite + '#sprite-master-card'}/>
              </svg>
              <svg className="icon" role="presentation" width="120" height="32">
                <use xlinkHref={sprite + '#sprite-master-pass'}/>
              </svg>
              <img src={visaIcon} className="icon" role="presentation" width="70" height="43"/>
              <svg className="icon" role="presentation" width="100" height="43">
                <use xlinkHref={sprite + '#sprite-diners-club'}/>
              </svg>
              <svg className="icon" role="presentation" width="70" height="43">
                <use xlinkHref={sprite + '#sprite-gpay'}/>
              </svg>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderTextInput(param, label) {
    return (
      <TextInput
        className= {styles.input}
        name={param}
        label={label}
        value={this.state[param]}
        onChange={(value) => this.setState({ [param]: value })}
      />
    );
  }

  renderNumberInput(param, label) {
    const handleOnChange = (value) => {
      if (value.match(/^[0-9]+$/) || value === '') {
        this.setState({ [param]: value });
      }
    };

    return (
      <TextInput
        className= {styles.input}
        name={param}
        label={label}
        value={this.state[param]}
        onChange={(value) => handleOnChange(value)}
      />
    );
  }

  getPrice() {
    const price = parseInt(this.state.amount);

    return isNaN(price) ? '0' : price.toLocaleString();
  }

  isSpecificSymbolValid() {
    const { specificSymbol } = this.state;

    return specificSymbol.length === this.SPECIFIC_SYMBOL_LENGTH;
  }

  isSubmitButtonDisabled() {
    const { amount, agreementChecked, firstAndSecondName } = this.state;

    return !(amount && agreementChecked && firstAndSecondName && this.isSpecificSymbolValid());
  }

  getValidationMessage() {
    const { agreementChecked } = this.state;

    if (!this.isSpecificSymbolValid()) {
      return 'VS musí být desetimístný';
    }

    if (!agreementChecked) {
      return 'Pro pokračování musíte souhlasit<br> se zpracováním os. údajú.';
    }

    return 'Vyplňte prosím všechny údaje';
  }
}

export default (withTranslation()(Radium(Payment)));
