import constants from '../../../../../../styles/constants.js';
import { format } from 'money-formatter';
import Radium from 'radium';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Column } from '../../../../../../libs/radium-grid'; // eslint-disable-line
import styles from './weather.module.scss';

// const styles = {
//   wrapper: {
//     padding: 0,
//     textAlign: 'center',
//     marginBottom: '0px',
//   },
//   mainBox: {
//     padding: '0 20px',
//     minHeight: '150px',

//     [constants.breakpointMobile]: {
//       minHeight: '200px',
//     },
//   },
//   col: {
//     padding: 0,
//   },
//   bigger: {
//     fontSize: '1.1em',

//     [constants.breakpointMobile]: {
//       fontSize: '1em',
//     },
//   },
//   date: {
//     padding: '0 5px',
//     borderBottom: '1px solid #ccc',
//     fontSize: '1.2em',
//     textAlign: 'left',
//     fontWeight: 500,
//     color: [constants.mainBlue],
//   },
//   temperature: {
//     height: '30px',
//   },
//   strongText: {
//     fontWeight: '500',
//     color: [constants.mainBlue],
//   },
//   bottomLine: {
//     paddingTop: '5px',
//     paddingBottom: '20px',
//     overflow: 'auto',
//     fontSize: '0.9em',
//   },
// };

class DayBox extends Component {
  render() {
    const { date, image, tempMax, tempMin, humidity, wind } = this.props;

    return (
      <div className="cell cell--12 cell--4:md margin--bottom-24">
        <div className={styles.weather__item}>
          <img className="margin--right-1" src={image} alt={date} width={50} height={50} />
          <ul className="list">
            <li>
              <h3 className="title color--primary-highdark" style={{marginBottom: '0.5rem'}}>{date}</h3>
            </li>
            <li className="text--14 color--primary" style={{marginBottom: '0.5rem'}}>
              <strong className="">{format('', tempMax, 1)} °C</strong>
              {' / '}
              <strong className="text--14 color--primary">{format('', tempMin, 1)} °C</strong>   
              <hr />
            </li>
            <li className="text--14 color--primary-highdark">
              Vlhkost: <strong>{humidity}%</strong>
            </li>
            <li className="text--14 color--primary-highdark">
              Vítr: <strong>{format('', wind, 1)} m/s</strong>
            </li>            
          </ul>
        </div>
        {/* <Column xs={12} sm={4} style={styles.wrapper}>
          <div style={styles.mainBox}>
            <h2 style={styles.date}>{date}</h2>
            <img src={image} alt="" />
            <div style={styles.temperature}>
              <span style={{ ...styles.strongText, ...styles.bigger }}>
                {format('', tempMax, 1)} °C
              </span>{' '}
              / {format('', tempMin, 1)} °C
            </div>
            <div style={styles.bottomLine}>
              <Column xs={12} md={12} style={styles.col}>
                Vlhkost: <span style={styles.strongText}>{humidity}%</span>
              </Column>
              <Column xs={12} md={12} style={styles.col}>
                Vítr: <span style={styles.strongText}>{format('', wind, 1)} m/s</span>
              </Column>
            </div>
          </div>
        </Column>       */}
      </div>
    );
  }
}

DayBox.propTypes = {
  day: PropTypes.string,
  image: PropTypes.string,
  tempMax: PropTypes.string,
  tempMin: PropTypes.string,
  humidity: PropTypes.string,
  wind: PropTypes.string,
  date: PropTypes.string,
};

export default Radium(DayBox);
