import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { setParam } from '../../../../actions/Calculator/reservation';
import './Reservation.css';
import OrangeButton from '../../../shared/OrangeButton';
import UsersDetail from './UsersDetail';
import ReservationComplete from './ReservationComplete';
import Step1 from './CalculatorStep1';
import Agreement from './Agreement';
import ReactTooltip from 'react-tooltip';
import ReservationSummary from './ReservationSummary';
//import BottomText from "./BottomText";
import sprite from '../../../../img/icons/sprite.svg';
import FormGroup from '../../../shared/forms/FormGroup';
import input from '../../../shared/forms/input.module.scss';
import { Link } from 'react-router';
import { withTranslation } from 'react-i18next';
import Stepper from './Stepper.jsx';
import CalculatorStep1 from './CalculatorStep1';
import cms from '../../CMS/cms.module.scss';

const isSelectedCustomersOkByCombinations = (props) => {
  const {
    calculator: {
      settings: { customerCombination, occupation },
      data: { customerCombinations },
    },
  } = props;

  if (!customerCombination) {
    return null;
  }

  const combinationExist = customerCombinations[customerCombination].findIndex((combination) => {
    return (
      combination.childCount === (occupation['children'] || 0) &&
      combination.adultsCount === (occupation['adult'] || 0)
    );
  });

  return combinationExist > -1;
};

const modal = {
  overlay: {
    position: 'fixed',
    zIndex: 120,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 22, 61, 0.6)',
    overflow: 'auto',
  },
  content: {
    width: '64.5rem',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    borderRadius: '0.5rem',
    padding: '2rem 1rem',
    transform: 'translate(-50%, -50%)',
    border: 'none',
  },
};

class Reservation extends Component {
  state = {
    step: 0,
  };

  render() {


    return (
      <>


        {this.renderModalContent()}
      </>
    );
  }

  renderInputs() {
    const {
      isSubsidy,
      isReadyToSubmit,
      onClose,
      reservation: { values, trans },
      setParam,
    } = this.props;
    const { t } = this.props;
    const combinationAllowed = isSelectedCustomersOkByCombinations(this.props);
    const { step } = this.state;

    return (
      <>
        <div className="container">
          <div className="grid">
            <div className="cell cell--12 margin--bottom-3">
              <div className={cms.block}>
                <div className="grid grid--middle margin--bottom-2 margin--bottom-3:md">
                  <div className="cell cell--auto:md">
                    <h2 className="title color--primary-highdark margin--bottom-0:md">{t('basicInfo')}</h2>
                  </div>
                  <div className="cell cell--shrink:md align--center">
                    <Stepper activeStep={step} />
                  </div>
                </div>

                <div className="grid gap">
                  <div className="cell cell--12 cell--2:md margin--bottom-24">
                    {this.renderDataInput('title')}
                  </div>
                  <div className="cell cell--12 cell--5:md margin--bottom-24">
                    {this.renderDataInput('name')}
                  </div>
                  <div className="cell cell--12 cell--5:md margin--bottom-24">
                    {this.renderDataInput('surname')}
                  </div>
                  <div className="cell cell--12 cell--3:md margin--bottom-24">
                    {this.renderDataInput('street')}
                  </div>
                  <div className="cell cell--12 cell--3:md margin--bottom-24">
                    {this.renderDataInput('streetNum')}
                  </div>
                  <div className="cell cell--12 cell--3:md margin--bottom-24">
                    {this.renderDataInput('city')}
                  </div>
                  <div className="cell cell--12 cell--3:md margin--bottom-24">
                    {this.renderDataInput('zipCode')}
                  </div>
                  <div className="cell cell--12 cell--4:md margin--bottom-24">
                    {this.renderDataInput('birthDate')}
                  </div>
                  <div className="cell cell--12 cell--4:md margin--bottom-24">
                    {this.renderDataInput('email')}
                  </div>
                  <div className="cell cell--12 cell--4:md margin--bottom-24">
                    {this.renderDataInput('phone')}
                  </div>
                  <div className="cell cell--12 margin--bottom-24">
                    {this.renderDataInput('note', 'textArea')}
                  </div>
                  <div className="cell cell--12">
                    <Agreement
                      onChange={(value) => setParam('agrees', value)}
                      values={values}
                      param={'agrees'}
                      trans={trans}
                      isSubsidy={isSubsidy}
                    />
                  </div>
                </div>

                <hr className="margin--bottom-2 margin--top-2" />

                <div className="grid grid--middle">
                  <div className="cell cell--12 cell--6:md margin--bottom-24">
                    <button
                      className="link link--underline text--14 color--primary-highdark"
                      onClick={() => this.setState({ step: 0 })}
                    >
                      <strong>{t('backToCalculator')}</strong>
                    </button>
                  </div>
                  <div className="cell cell--12 cell--6:md align--right margin--bottom-24">
                    <button
                      dataTooltip="Vyplňte všechna pole označná znakem * a zaškrtněte souhlas se zpracováním osobních údajů"
                      disabled={!combinationAllowed || !isReadyToSubmit}
                      onClick={() => this.setState({ step: 2 })}
                      className="button button--primary"
                    >
                      {t('next')}
                    </button>
                  </div>
                  <div className="cell cell--12">
                    <p className="color--primary-highdark text--14 margin--bottom-0">
                      <i>{t('backToCalculatorNote')}</i>
                    </p>
                    {/* <BottomText text="*Pokud se vrátíte zpět na kalkulačku, nepřijdete tím o vyplněné údaje"/> */}
                  </div>
                </div>

                <ReactTooltip html={Boolean(true)} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderModalContent() {
    const {
      calculator: { reservationSent },
      idDetail,
      combinationAllowed,
      onClose,
    } = this.props;
    const { step } = this.state;

    if (reservationSent === 'ok') {
      return <ReservationComplete idDetail={idDetail} />;
    }

    if (step === 0) {
      return (
        <>
          <CalculatorStep1
            idDetail={this.props.idDetail}
            goNext={() => this.setState({ step: 1 })}
          />
        </>
      );
    }

    if (step === 1) {
      return <>{this.renderInputs()}</>;
    }

    if (step === 2) {
      return (
        <UsersDetail
          idDetail={idDetail}
          step={step}
          closeModal={() => onClose()}
          goFirst={() => this.setState({ step: 1 })}
          goNext={() => this.setState({ step: 3 })}
        />
      );
    }

    if (step === 3) {
      return (
        <ReservationSummary
          idDetail={idDetail}
          step={step}
          closeModal={() => onClose()}
          goSecond={() => this.setState({ step: 2 })}
          goFirst={() => this.setState({ step: 0 })}
          goBack={() => this.setState({ step: 2 })}
        />
      );
    }
  }

  renderDataInput(param, type = 'input') {
    const {
      reservation: { values, trans, required, requiredValidation },
      setParam,
    } = this.props;
    const regex = requiredValidation[param];
    const isInvalid = values[param] && regex && !new RegExp(regex).test(values[param]);

    return (
      <>
        <FormGroup title={trans[param] + (required.includes(param) ? ' *' : '')}>
          {type === 'input'
            ? this.renderInput(param, values, setParam)
            : this.renderTextArea(param, values, setParam)}
        </FormGroup>
      </>
    );
  }

  renderInput(param, values, setParam) {
    return (
      <input
        name={param}
        className={input.input}
        autocomplete="on"
        value={values[param]}
        onChange={(event) => setParam(param, event.target.value)}
      />
    );
  }

  renderTextArea(param, values, setParam) {
    return (
      <textarea
        className={input.textarea}
        rows={6}
        onChange={(event) => setParam(param, event.target.value)}
      >
        {values[param]}
      </textarea>
    );
  }
}

let isReadyToSubmit = function (state) {
  return (
    state.reservation.required.findIndex((key) => {
      const regex = state.reservation.requiredValidation[key];
      const value = state.reservation.values[key];

      if (value && regex && !new RegExp(regex).test(value)) {
        return key;
      }

      return !value;
    }) === -1
  );
};

const mapStateToProps = (state) => {
  return {
    reservation: state.reservation,
    calculator: state.calculator,
    isSubsidy: state.calculator.settings.isSubsidy,
    isReadyToSubmit: isReadyToSubmit(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setParam: (param, value) => dispatch(setParam(param, value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Radium(Reservation)));
