import { connect } from 'react-redux';
import Radium from 'radium';
import { saveIncluded } from '../../../actions/TourList/tourFilter';
import { fetchTile, fetchTotalToursCount } from '../../../actions/HP/defaultTourlist';
import { fetchTourList, fetchLocations } from '../../../actions/TourList/fetchTourList';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CDNameTitle } from '../../../utils/titleConfig';
import CrossRoadLinks from './CrossRoadLinks';
import Helmet from 'react-helmet';

class HomePage extends Component {
  state = {
    showBody: false,
    fetched: false,
    defaultFilterChecked: false,
    defaultChecked: false,
    includedChecked: false,
    checkIncluded: ['country', 'transport', 'type', 'apartment'],
  };

  componentDidMount() {
    const { fetchTotalToursCount, fetchTile } = this.props;

    fetchTile();
    fetchTotalToursCount();
  }

  render() {
    return (
      <>
        <Helmet title={CDNameTitle} />
        <CrossRoadLinks />
      </>
    );
  }
}

HomePage.propTypes = {
  fetchTotalToursCount: PropTypes.func,
  fetchTile: PropTypes.func,
};

const mapStateToProps = (state) => ({
  tourList: state.tourList,
  advertising: state.advertising,
  tourFilter: state.tourFilter,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTotalToursCount: () => dispatch(fetchTotalToursCount()),
  fetchTile: () => dispatch(fetchTile()),
  saveIncluded: (designation, filter) => dispatch(saveIncluded(designation, filter)),
  fetchLocations: () => dispatch(fetchLocations()),
  fetchTourList: () => dispatch(fetchTourList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(HomePage));
