import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addTourForComparement } from '../../../actions/TourDetail/fetchTourDetail';
import LoaderInModalWindow from '../../shared/Loading/LoaderInModalWindow';
import OrangeButton from '../../shared/OrangeButton';
import { Link, hashHistory } from 'react-router';
import formatDateShort from '../../shared/Moment/formatDateShort';
import formatDateLong from '../../shared/Moment/formatDateLong';
import formatCurrency from '../../shared/formatCurrency';
import { withTranslation } from 'react-i18next';
import hero from '../Tours/ToursList/hero.module.scss';
import heroPicture from '../../../img/heros/hero-lists.webp';
import cms from '../CMS/cms.module.scss';
import './Comparement.scss';
import Radium from "radium";
import Helmet from 'react-helmet';
import sprite from '../../../img/icons/sprite.svg';

class Comparement extends Component {
  state = {
    allToursAreFetched: false,
  };

  componentDidMount() {
    const {
      params: { r },
    } = this.props;

    if (!r) {
      hashHistory.push('/porovnani/1');
      window.location.reload();
    }

    this.checkLoadedTourDetails();
    this.loadAllTourDetails();
  }

  componentWillReceiveProps(props) {
    this.checkLoadedTourDetails(props);
    this.loadAllTourDetails(props);
  }

  loadAllTourDetails(props = this.props) {
    const {
      tourComparement: { tourDetails, tours },
      addTourForComparement,
    } = props;

    tours.forEach((tour) => {
      let tourDetailExists = false;

      tourDetails.forEach((tourDetail) => {
        if (tour.idTour === tourDetail.id) {
          tourDetailExists = true;
        }
      });

      if (!tourDetailExists) {
        addTourForComparement(tour.idTour);
      }
    });
  }

  checkLoadedTourDetails(props = this.props) {
    const {
      tourComparement: { tourDetails, tours },
    } = props;
    let tourDetailsLoaded = true;

    tours.forEach((tour) => {
      let tourDetailExists = false;

      tourDetails.forEach((tourDetail) => {
        if (tour.idTour === tourDetail.id) {
          tourDetailExists = true;
        }
      });

      if (!tourDetailExists) {
        tourDetailsLoaded = false;
      }
    });

    this.setState({ allToursAreFetched: tourDetailsLoaded });
  }

  render() {
    const {
      tourComparement: { tourDetails },
    } = this.props;
    const { allToursAreFetched } = this.state;
    const {t} = this.props;

    if (!allToursAreFetched) {
      return <LoaderInModalWindow />;
    }

    return (
      <>
        <Helmet title={t('compareTitle')} />
				<section className={hero.hero}>
					<picture className={hero.hero__picture}>
						<img src={heroPicture} alt={t('compareTitle')} />
					</picture>
					<div className={hero.hero__content}>
						<div className="container">
							<div className="grid gap">
								<div className="cell cell--12 cell--6:lg">
									<article className={hero.hero__article}>
										<h1 className="title margin--bottom-1 color--white">{t('compareTitle')}</h1>
										<p className="lead margin--bottom-2 color--white">{t('compareDesc')}</p>
									</article>
								</div>
							</div>
						</div>
					</div>
				</section>
        <section className="section">
					<div className="container">
						<div className="grid">
	    	  		<div className="cell margin--bottom-3">
								<div className={cms.block}>
                  <div className="grid">
                    <div className="cell">
                      <div className="table-scroll">
                        <table className="table table--compare">
                          <tbody>
                            <tr>
                              <td className="color--white">
                                {t('tableName')}
                              </td>
                              {tourDetails.map((tour) => this.renderHeaderLink(tour))}
                            </tr>
                            <tr>
                              <td className="color--white">
                                {t('tablePrice')}
                              </td>
                              {tourDetails.map((tour) => {
                                const price = tour.isSoldOut ? 'Vyprodáno' : formatCurrency(tour.price);
                                return this.renderComparementRow(price);
                              })}
                            </tr>
                            <tr>
                              <td className="text--14 color--white">
                                {t('tableDate')}
                              </td>
                              {tourDetails.map((tour) => this.renderDates(tour))}
                            </tr>
                            <tr>
                              <td className="text--14 color--white">
                                {t('tableLastminute')}
                              </td>
                              {tourDetails.map((tour) => this.renderComparementRowBoolean(tour.isLastMinute))}
                            </tr>
                            <tr>
                              <td className="text--14 color--white">
                                {t('tableTransport')}
                              </td>
                              {tourDetails.map((tour) => this.renderTransport(tour.transport))}
                            </tr>
                            <tr>
                              <td className="text--14 color--white">
                                {t('tableCapacity')}
                              </td>
                              {tourDetails.map((tour) => this.renderIcons(tour.capacities))}
                            </tr>
                            <tr>
                              <td className="text--14 color--white">
                                {t('tableFood')}
                              </td>
                              {tourDetails.map((tour) => this.renderFood(tour.foodDetail))}
                            </tr>
                            <tr>
                              <td className="text--14 color--white">
                                {t('tableNote')}
                              </td>
                              {tourDetails.map((tour) => this.renderTourNotes(tour.tourNotes))}
                            </tr>
                            <tr>
                              <td className="text--14 color--white">
                                {t('tableRooms')}
                              </td>
                              {tourDetails.map((tour) => this.renderRooms(tour))}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="grid">
                    <div className="cell align--center">
                      <Link className="button button--primary" to={'/zajezdy/vypis'}>{t('backToList')}</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  renderTransport(transport) {
    return (
      <td>
        {this.getFormattedTransportText(transport)}
      </td>
    );
  }

  getFormattedTransportText(transport) {
    let formattedText = '';
    transport.forEach((trans, i) => {
      if (transport.length - 1 !== i) {
        formattedText += trans.title + ', ';
      } else {
        formattedText += trans.title;
      }
    });

    return formattedText;
  }

  renderDates(tour) {
    const { dateFrom, dateTo } = tour;

    return (
      <td>
        {formatDateShort(dateFrom) + ' - ' + formatDateLong(dateTo)}
      </td>
    );
  }

  renderTourNotes(notes) {
    return <td>{notes.map((note) => this.renderNote(note))}</td>;
  }

  renderNote(note) {
    return <>{note.description}</>;
  }

  renderFood(foodDetail) {
    return (
      <td>
        {foodDetail
          .map((food) => {
            if (food.type === 'children') {
              return null;
            }

            return food.description;
          })
          .filter((x) => x != null)}
      </td>
    );
  }

  renderIcons(items) {
    return (
      <td>
        <ul className="list list--inline">
          {items.map((trans) => this.renderIcon(trans))}
        </ul>
      </td>
    );
  }

  renderIcon(transportItem) {
    return (
      <li className="margin--right-1">
        <img src={transportItem.imgSrc} loading="lazy" style={{height: '1.5rem', objectFit: 'contain'}} />
      </li>
    );
  }

  renderRooms(tour) {
    const {
      occupation: { tours, headers },
      pickedTour,
    } = tour;

    const pickedTourOccupation = tours
      .map((tour) => {
        if (tour.tourId === pickedTour) {
          return tour;
        }

        return null;
      })
      .filter((x) => x != null)
      .shift();

    return (
      <td>
        <ul className="list">
          {pickedTourOccupation.codes.occupied.map((occupied, index) =>
            this.renderSigleOccupation(occupied, headers, index),
          )}
        </ul>
      </td>
    );
  }

  renderSigleOccupation(occupied, headers, index) {
    const value = !occupied ? 'check' : 'close';

    return (
      <li>
        {headers[index]}
      </li>
    );
  }

  renderComparementRowBoolean(parameter) {
    const value = parameter ? 'check' : 'close';

    return (
      <td>
        <svg className="icon" role="presentation" width="24" height="24">
          <use xlinkHref={sprite + '#sprite-' + value}/>
        </svg>
      </td>
    );
  }

  renderHeaderLink(tour, additionalClass = '') {
    return (
      <td>
        <a href={`#/zajezdy/detail/${tour.id}`} className="link color--secondary">
          <strong>
            {tour.title}
          </strong>
        </a>
      </td>
    );
  }

  renderComparementRow(parameter, additionalClass = '') {
    return <td><strong className="text--16">{parameter}</strong></td>;
  }
}

const mapStateToProps = (state) => ({
  tourComparement: state.tourComparement,
});

const mapDispatchToProps = (dispatch) => ({
  addTourForComparement: (idTour) => dispatch(addTourForComparement(idTour)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Radium(Comparement)));
