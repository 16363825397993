import FormGroup from '../../../../../shared/forms/FormGroup';
import SelectBox from '../../../../../shared/forms/SelectBox';
import Dialog from 'material-ui/Dialog';
import emailVerify from '../../../../../shared/emailVerify';
import InputModalAlert from '../../../../../shared/InputModalAlert';
import { connect } from 'react-redux';
import { postNewBooking, postSucceeded } from '../../../../../../actions/TourDetail/postTourDetail';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Radium from 'radium';
import input from '../../../../../shared/forms/input.module.scss';
import { withTranslation } from 'react-i18next';

//const customStyles = {
//  floatLeft: {
//    float: 'left',
//    marginRight: 10,
//    marginBottom: 10,
//  },
//};

class BookingForm extends Component {
  state = {
    status: '',
    alertOpened: false,
    opened: false,
    personCount: 1,
    name: '',
    email: '',
    phone: '',
    address: '',
    note: '',
    options: [
      {
        id: 1,
        value: 1,
      },
      {
        id: 2,
        value: 2,
      },
      {
        id: 3,
        value: 3,
      },
      {
        id: 4,
        value: 4,
      },
      {
        id: 5,
        value: 5,
      },
    ],
  };

  componentWillReceiveProps(props) {
    const {
      postFormStatus: { postNewBooking },
    } = props;

    this.setState({ status: postNewBooking });
  }

  render() {
    const { alertOpened } = this.state;
    const {
      tourDetail: { pickedTour },
    } = this.props;
    const {t} = this.props;

    return (
      <div>
        <InputModalAlert
          opened={alertOpened}
          onClick={this.handleOpenAlert.bind(this)}
          message="Zkontrolujte, prosím, zda jsou všechny položky vyplněné a jsou vyplněné správně."
        />

        {pickedTour && (
          <button
            onClick={() => this.setState({ opened: true })}
            className="button button--primary"
          >
            {t('inquiry')}
          </button>
        )}

        {this.renderForm()}
      </div>
    );
  }

  handleOpenAlert(alertOpened) {
    this.setState({ alertOpened });
  }

  renderForm() {
    const { opened, status } = this.state;

    return (
      <Dialog
        title="Přidat novou poptávku"
        actions={this.getActions()}
        modal={false}
        open={opened}
        autoScrollBodyContent="true"
        className="modal"
      >
        <div>
          <p className="color--primary-highdark">{status}</p>
          {this.renderDialogItems(status)}
        </div>
      </Dialog>
    );
  }

  renderDialogItems(status) {
    const { options, name, email, phone, address, note } = this.state;
    const {
      tourDetail: { hotelTitle },
    } = this.props;
    const {t} = this.props;

    if (status === postSucceeded) {
      return null;
    }

    return (
      <div className="grid gap">
        <div className="cell cell--12 margin--bottom-3">
          <p className="color--primary-highdark">{t('bookingDesc')}</p>
        </div>
        <div className="cell cell--12 cell--6:md margin--bottom-24">
          <FormGroup title="Hotel">
            <p className="color--primary">{hotelTitle}</p>
          </FormGroup>
        </div>
        <div className="cell cell--12 cell--6:md margin--bottom-24">
          <FormGroup title="Počet osob *">
            <SelectBox
              name="personCount"
              options={options}
              type={'small'}
              onClick={this.handleSelect.bind(this)}
            />
          </FormGroup>
        </div>
        <div className="cell cell--12 cell--6:md margin--bottom-24">
          <FormGroup title="Jméno a příjmení *">
            <input
              className={input.input}
              value={name}
              onChange={(event) => {
                this.setState({ name: event.target.value });
              }}
            />
          </FormGroup>
        </div>
        <div className="cell cell--12 cell--6:md margin--bottom-24">
          <FormGroup title="E-Mail *">
            <input
              className={input.input}
              value={email}
              onChange={(event) => {
                this.setState({ email: event.target.value });
              }}
            />
          </FormGroup>
        </div>
        <div className="cell cell--12 cell--6:md margin--bottom-24">
          <FormGroup title="Telefon *">
            <input
              className={input.input}
              value={phone}
              onChange={(event) => {
                this.setState({ phone: event.target.value });
              }}
            />
          </FormGroup>
        </div>
        <div className="cell cell--12 cell--6:md margin--bottom-24">
          <FormGroup title="Adresa *">
            <input
              className={input.input}
              value={address}
              onChange={(event) => {
                this.setState({ address: event.target.value });
              }}
            />
          </FormGroup>
        </div>
        <div className="cell cell--12 margin--bottom-24">
          <FormGroup title="Poznámka *">
            <textarea
              value={note}
              className={input.textarea}
              rows="8"
              onChange={(text) => this.setState({ note: text.target.value })}
            />
          </FormGroup>
        </div>
      </div>
    );
  }

  handleSelect(value, name) {
    this.setState({ [name]: value });
  }

  postInsertion() {
    const {
      postNewBooking,
      term: { idTour },
    } = this.props;
    const { personCount, name, email, phone, address, note } = this.state;
    const requestData = {
      idTour,
      personCount,
      name,
      email,
      phone,
      address,
      note,
    };

    const requiredFields = [name, email, phone, address, note, personCount];

    const error = requiredFields.filter((fieldName) => fieldName === '');

    if (error.length || !emailVerify(email)) {
      this.setState({ alertOpened: true });
    } else {
      this.setState({
        status: 'Odesílám formulář',
        id: '',
        personCount: '',
        name: '',
        email: '',
        phone: '',
        address: '',
        note: '',
      });
      postNewBooking(requestData);
    }
  }

  close() {
    this.setState({ status: '', opened: false });
  }

  getActions() {
    const { status } = this.state;
    const {t} = this.props;

    if (status === postSucceeded) {
      return (
        <button onClick={() => this.close()} submit="submit" type="inversion">
          {t('close')}
        </button>
      );
    }

    return (
      <div className="grid grid--justify gap">
        <div className="cell cell--shrink">
          <button onClick={() => this.close()} submit="submit" type="inversion" className="button hollow" title={t('close')}>
            {t('close')}
          </button>
        </div>
        <div className="cell cell--shrink">
          <button onClick={() => this.postInsertion()} submit="submit" type="normal" className="button button--primary" title={t('submit')} >
            {t('submit')}
          </button>
        </div>
      </div>
    );
  }
}

BookingForm.propTypes = {
  tourDetail: PropTypes.any,
  term: PropTypes.any,
  postNewBooking: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
  postFormStatus: state.postFormStatus,
});

const mapDispatchToProps = (dispatch) => ({
  postNewBooking: (requestData) => dispatch(postNewBooking(requestData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Radium(BookingForm)));
