import Guarantee from './Guarantee';
import constants from '../../../../../styles/constants.js';
import Radium from 'radium';
import React, { Component } from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import styles from './hotelInformations.module.scss';

class HotelInformations extends Component {
  render() {
    const {
      tourDetail: { pickedTour },
    } = this.props;

    if (!pickedTour) {
      return;
    }

    return <div className="margin--bottom-2">{pickedTour && this.renderBasicInfoTable()}</div>;
  }

  renderBasicInfoTable() {
    const { term } = this.props;

    if (!term) {
      return;
    }

    const {
      term: { transport, tripTypes, capacities, tripType },
      tourDetail: { title, gps, location },
    } = this.props;
    let gpsFinal = '';

    if (gps.length > 0) {
      gpsFinal = gps[0] + ', ' + gps[1];
    }

    const renderList = [
      {
        title: 'Hotel',
        value: title,
      },
      {
        title: 'GPS hotelu',
        value: gpsFinal,
      },
      {
        title: 'Lokalita',
        value: location,
      },
      {
        title: 'Typ zájezdu',
        value: tripType,
      },
    ];

    return (
      <div className="grid">
        <div className="cell cell--12 margin--bottom-2">
          <h3 className="title color--gray-900 margin--bottom-1">Kapacita hotelu</h3>
          <ul className="list list--inline">
            {capacities && capacities.map((icon) => this.renderTravelIcon(icon))}
          </ul>
        </div>
        <div className="cell cell--12">
          <div className="grid gap">
            {/*<table className={styles.infoTable}>*/}
            {/*  <tbody>{renderList.map((info) => this.renderTableRow(info.title, info.value))}</tbody>*/}
            {/*</table>*/}

            {/*{this.renderIcons(tripTypes)}*/}
            {/*{this.renderIcons(transport)}*/}
          </div>
        </div>
      </div>
    );
  }

  renderIcons(items) {
    if (!items) {
      return null;
    }

    return items.map((trans) => this.renderIcon(trans));
  }

  renderIcon(transportItem) {
    return (
      <div className="cell cell--shrink margin--bottom-24">
        <div className="link link--file link--disabled text--14 color--gray-900" style={{fontWeight:"normal"}}>
          <img src={transportItem.imgSrc} className="icon margin--right-1" width={20} height={20} />
          {transportItem.title}
        </div>
      </div>
    );
  }

  renderTravelIcon(icon) {
    const { title, imgSrc } = icon;

    return <li><img src={imgSrc} alt={title} key={title} width={28} height={28} style={{ width: 'auto', height: '1.75rem', marginRight: '0.5rem' }} /></li>;
  }

  //renderTableRow(title, value) {
  //  return (
  //    <tr key={title}>
  //      <td className={styles.infoTable.title}>{title}:</td>
  //      <td className={styles.infoTable.value}>{value}</td>
  //    </tr>
  //  );
  //}
}

HotelInformations.propTypes = {
  tourDetail: PropTypes.any,
  term: PropTypes.any,
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
});

export default connect(mapStateToProps)(Radium(HotelInformations));
