import constants from '../../../styles/constants';
import mobileBg from '../../../img/bcg-mobile.jpg'; // eslint-disable-line
import logo from '../../../img/cd-travel_logo-white.svg'; // eslint-disable-line
import facebook from '../../../img/facebook.svg'; // eslint-disable-line
import sprite from '../../../img/icons/sprite.svg';
import {toggleTiles} from '../../../actions/TourList/paginator';
import cookie from 'react-cookie';
import {fetchHeader} from '../../../actions/Header/fetchHeader';
import {employee, visitor, szEmployee} from '../../../actions/HP/defaultTourlist';
import {resetAllFilters} from '../../../actions/TourList/tourFilter';
import {Link, hashHistory} from 'react-router';
import Radium from 'radium';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import app from '../../../styles/app.scss';
import crossroad from './crossroad.module.scss';

import placeholderCd from '../../../img/placeholders/placeholder-zamestnanec-cd.webp'; // eslint-disable-line
import placeholderCustomer from '../../../img/placeholders/placeholder-bezny-uzivatel.webp'; // eslint-disable-line
import placeholderSz from '../../../img/placeholders/placeholder-zamestnanec-sz.webp'; // eslint-disable-line

import { withTranslation } from 'react-i18next';

export const cdtravelFb = 'https://www.facebook.com/cdtravel.cz/';

class CrossRoadLinks extends Component {
  componentDidMount() {
    const userChanged = cookie.load('userChanged');
    const userChangedFromMenu = cookie.load('userChangedFromMenu');
    const backLink = cookie.load('backLink');

    if (userChangedFromMenu !== 'false') {
      cookie.save('userChangedFromMenu', false);
      cookie.save('user', userChangedFromMenu);
      window.location.reload();
    }

    if (userChanged === 'true') {
      cookie.save('userChanged', false);
      if (backLink) {
        hashHistory.push(backLink);
      } else {
        hashHistory.push('/zajezdy/vypis/');
      }
    }
  }

  render() {
    const {t} = this.props;
    return (
      <>
        <div className={crossroad.crossroad}>
          <Link className={crossroad.logo} to="/">
            <img src={logo} width="192"/>
          </Link>
          <Link className={crossroad.crossroad__link} onClick={() => this.showTiles(visitor)}>
            <picture className={crossroad.crossroad__picture}>
              <img src={placeholderCustomer} alt={t('crossroadTitle1')} width={740} height={1080} />
            </picture>
            <div className={crossroad.crossroad__content}>
              <svg className="icon margin--bottom-1 margin--bottom-2:lg" role="presentation" width="54" height="80">
                <use xlinkHref={sprite + '#sprite-customer'}/>
              </svg>
              <h2 className="title color--white margin--bottom-24">
                {t('crossroadTitle1')}
                <br/>
                {t('withoutSubsidy')}
              </h2>
              <p className="hide visible:lg color--white">{t('crossroadDesc1')}</p>
              <div className='button button--primary color--white'>{t('crossroadCta')}</div>
            </div>
          </Link>
          <Link className={crossroad.crossroad__link} onClick={() => this.showTiles(employee)}>
            <picture className={crossroad.crossroad__picture}>
              <img src={placeholderCd} alt={t('crossroadTitle2')} width={740} height={1080} />
            </picture>
            <div className={crossroad.crossroad__content}>
              <svg className="icon margin--bottom-1 margin--bottom-2:lg" role="presentation" width="132" height="80">
                <use xlinkHref={sprite + '#sprite-customer-cd'}/>
              </svg>
              <h2 className="title color--white margin--bottom-24">
                {t('crossroadTitle2')}
                <br/>
                {t('withSubsidy')}
              </h2>
              <p className="hide visible:lg color--white">{t('crossroadDesc2')}</p>
              <div className='button button--primary color--white'>{t('crossroadCta')}</div>
            </div>
          </Link>
          <Link className={crossroad.crossroad__link} onClick={() => this.showTiles(szEmployee)} style={{ backgroundColor: 'darkBlue'}}>
            <picture className={crossroad.crossroad__picture}>
              <img src={placeholderSz} alt={t('crossroadTitle3')} width={740} height={1080} />
            </picture>
            <div className={crossroad.crossroad__content}>
              <svg className="icon margin--bottom-1 margin--bottom-2:lg" role="presentation" width="68" height="80">
                <use xlinkHref={sprite + '#sprite-customer-sz'}/>
              </svg>
              <h2 className="title color--white margin--bottom-24">
                {t('crossroadTitle3')}
                <br/>
                {t('withSubsidy')}
              </h2>
              <p className="hide visible:lg color--white">{t('crossroadDesc3')}</p>
              <div className='button button--primary color--white'>{t('crossroadCta')}</div>
            </div>
          </Link>
        </div>
      </>
    );
  }

  showTiles(user) {
    const {toggleTiles, resetAllFilters, fetchHeader} = this.props;

    resetAllFilters();
    toggleTiles(true);
    fetchHeader(user);
    cookie.save('user', user);
    cookie.save('userChanged', true);
    window.location.reload();
  }
}

CrossRoadLinks.propTypes = {
  toggleTiles: PropTypes.any,
  fetchHeader: PropTypes.any,
  resetAllFilters: PropTypes.any,
};

const mapStateToProps = (state) => ({
  paginator: state.paginator,
});

const mapDispatchToProps = (dispatch) => ({
  toggleTiles: (nextState) => dispatch(toggleTiles(nextState)),
  resetAllFilters: () => dispatch(resetAllFilters()),
  fetchHeader: (user) => dispatch(fetchHeader(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Radium(CrossRoadLinks)));
