import Radium from 'radium';
import failedIcon from '../../../img/icons/error.svg';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import hero from '../Tours/ToursList/hero.module.scss';
import heroPicture from '../../../img/heros/hero-lists.webp';
import cms from '../CMS/cms.module.scss';

{/*const styles = {
  sectionHead: {
    width: '100%',
  },
  contentWrapper: {
    overflow: 'hidden',
    background: '#fff',
    zIndex: 6,
    position: 'relative',
    padding: '50px 0',
  },
  contentBox: {
    margin: '0 auto',
    padding: '0 15px',
    maxWidth: '500px',
    textAlign: 'center',
  },
  image: {
    marginTop: '25px',
    width: '100%',
  },
  icon: {
    width: '90px',
    heignht: '90px',
    marginTop: '50px',
    opacity: 0.7,
  },
};*/}

class PaymentError extends Component {
  render() {
    return (
      <>
      <Helmet title="Online prodej"/>
      <section className={hero.hero}>
        <picture className={hero.hero__picture}>
          <img src={heroPicture} alt="Pro vaše nejlepší zážitky z cestování" />
        </picture>
        <div className={hero.hero__content}>
          <div className="container">
            <div className="grid gap">
              <div className="cell cell--12 cell--6:lg">
                <article className={hero.hero__article}>
                  <h1 className="title margin--bottom-1 color--white">Stav platby</h1>
                  <p className="lead margin--bottom-2 color--white">Lorem ipsum dolor sit amet, consectetuer elit dal adipiscing elit. Lorem ipsum dolor sit amet, consectetuer elit dal adipiscing elit.</p>
                </article>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="grid">
            <div className="cell margin--bottom-3">
              <div className={cms.block}>
                <div className="align--center" style={{paddingBlock:"3rem"}}>
                  <h2 className="h3 title">
                    Litujeme, ale platba neproběhla správně. Pro úspěšné zaplacení prosím platbu zopakujte znovu.
                  </h2>
                  <picture>
                    <img className="icon" src={failedIcon} alt="Payment failed" width="50" height="50"/>
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
    );
  }
}

export default Radium(PaymentError);
