// import styles from '../../../shared/TourListStyles/TourListPaginatorStyles';
import Radium from 'radium';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getLimitedPages from '../../../shared/dynamicPaginator/getLimitedPages';
import getNextPage from '../../../shared/dynamicPaginator/getNextPage';
import TourListSettings from './components/TourListSettings';
import createFilterRequest from '../../../shared/RequestCreator/createFilterRequest';
import { loading } from '../../../../reducers/filteredTours';
import createSpecialToursRequest from '../../../shared/RequestCreator/createSpecialToursRequest';
import { fetchFilteredList, fetchSpecialList } from '../../../../actions/TourList/fetchTourList';
import { savePage } from '../../../../actions/TourList/paginator';
import { connect } from 'react-redux';
import styles from './pagination.module.scss';

class ToursPaginator extends Component {
  state = {
    toggle: false,
  };

  render() {
    const { showTourSettings, tripCount, pages, actualPage, filterLoading } = this.props;
    const {
      filterSettings: { filterName },
      filter: { isLastMinuteOrCustom, showTermList },
    } = this.props;

    return (
      <div className="grid grid--middle grid--center grid--justify:md margin--top-24">
        <div className="cell--shrink hide visible:md">
          {filterLoading !== loading ? this.pageCounter(actualPage, pages) : filterLoading}
        </div>

        {showTourSettings && <TourListSettings />}

        <div className="cell--shrink">
          <ul className={styles.pagination}>
            {filterLoading !== loading &&
              getLimitedPages(pages, actualPage).map((pagination__item) => this.renderPaginator(pagination__item))}
          </ul>
        </div>

        {/* <div style={styles.lastMinuteSearchText}>
          {isLastMinuteOrCustom && <p style={styles.lastMinuteInfo}>{filterName}</p>}
        </div> */}
      </div>
    );
  }

  getloading(showTermList, tripCount) {
    return (
      <div>
        {showTermList ? 'Celkem termínů: ' : 'Celkem hotelů: '}
        <strong id="up">{tripCount}</strong>
      </div>
    );
  }

  pageCounter(actualPage, pages) {
    return (
      <div className="color--primary-highdark text--14">
        <strong>{actualPage} <span>z</span> {pages.length}</strong>
      </div>
    )
  }

  selectPage(page) {
    const {
      savePage,
      actualPage,
      paginatorType,
      filter,
      paginator: { minCost, maxCost, itemsPerPage },
      fetchFilteredList,
      specialOffer,
      fetchSpecialList,
      type,
    } = this.props;
    const nextPage = getNextPage(page, actualPage);
    const optional = { settingPage: true };
    savePage(nextPage, paginatorType);
    specialOffer
      ? fetchSpecialList(createSpecialToursRequest(nextPage, type, itemsPerPage))
      : fetchFilteredList(
          createFilterRequest(filter, nextPage, itemsPerPage, minCost, maxCost, optional),
          true,
        );
  }

  renderPaginator(pagination__item) {
    const { actualPage } = this.props;

    return (
      <li className={styles['pagination__item'] + (pagination__item === actualPage ? ' ' + styles['pagination__item','is-active'] : '')} onClick={() => this.selectPage(pagination__item)}>
        <span>{pagination__item}</span>
      </li>
    );
  }
}

ToursPaginator.propTypes = {
  tripCount: PropTypes.any.isRequired,
  showTourSettings: PropTypes.any,
  filterLoading: PropTypes.any.isRequired,
  pages: PropTypes.any.isRequired,
  actualPage: PropTypes.any.isRequired,
  paginatorType: PropTypes.any.isRequired,
  specialOffer: PropTypes.any.isRequired,
  savePage: PropTypes.any,
  filter: PropTypes.any,
  type: PropTypes.any,
  paginator: PropTypes.any,
  fetchFilteredList: PropTypes.any,
  fetchSpecialList: PropTypes.any,
  filterSettings: PropTypes.any,
};

const mapStateToProps = (state) => ({
  filter: state.filter,
  filterSettings: state.filterSettings,
  paginator: state.paginator,
});

const mapDispatchToProps = (dispatch) => ({
  savePage: (page, paginatorType) => dispatch(savePage(page, paginatorType)),
  fetchFilteredList: (filters, settingPage) => dispatch(fetchFilteredList(filters, settingPage)),
  fetchSpecialList: (pageProperties) => dispatch(fetchSpecialList(pageProperties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(ToursPaginator));
