import React, { Component } from 'react';
import widget from './widget.module.scss';

export default class Widget extends Component {
    render() {
        const { title, desc, link, image, onClick } = this.props;

        return (
            <>
                <div className={widget.widget} onClick={onClick}>
                    <picture className={widget.widget__picture}>
                        <img src={image} alt={title} />
                    </picture>
                    <div className={widget.widget__content}>
                        <h3 className='title color--white' style={{ marginBottom: (desc ? '1rem' : '0') }}>
                            {title}
                        </h3>
                        {desc ?
                            <>
                                <p className='color--white margin--bottom-0'>{desc}</p>
                            </>
                            : null
                        }
                    </div>
                </div>
            </>
        );
    }
}
