import constants from "../../../../../styles/constants.js";
import Radium from "radium";
import ReactDOMServer from "react-dom/server";
import { Provider } from "react-redux";
import OrangeButton from "../../../../shared/OrangeButton";
import { apiUrl } from "../../../../../utils/config.local";
import tourTemplate from "../../../../shared/TourDetail/tourPDFTemplate";
import tourPriceListTemplate from "../../../../shared/TourDetail/tourPriceListTemplate";
import { renderPriceList } from "../../../../shared/TourDetail/renderPriceList";
import { renderPDFHeaderIcons, getPDFContent } from "../../../../shared/TourDetail/pdfHeaderUtils";
import { generatePDF, generatePdfWithCatch } from "../../../../../actions/PDF/generatePDF";
import PickerPriceList from "./PickerPriceList";
import React, { Component } from "react"; // eslint-disable-line
import PropTypes from "prop-types";
import { connect } from "react-redux";
import base64 from "base-64";
import utf8 from "utf8";

import "./HotelPreview.scss";

const url = apiUrl.substring(0, apiUrl.indexOf("www/api/v1/"));

const getFormattedPdfHtml = (params, fontSize = 13) => {
  const {
    hotelTitle,
    description,
    guarantee: { name, mobile },
    country,
    photos,
    capacities,
    transport,
    tripTypes,
    pdfProps: { strippedInstructions, croppedImage },
    terms
  } = params;

  const headerIcons = renderPDFHeaderIcons(capacities, transport, tripTypes);

  const content = getPDFContent(photos, strippedInstructions, `hotel-pdf-description`);
  const priceList = ReactDOMServer.renderToString(renderPriceList(terms, hotelTitle));
  const pdf = tourTemplate
    .replace("{country}", country)
    .replace("{hotel}", hotelTitle)
    .replace("{mainImage}", croppedImage ? croppedImage : photos[0] ? photos[0].imgSrc : "")
    .replace("{availabilityIcons}", headerIcons)
    .replace("{guaranteeName}", name)
    .replace("{guaranteePhone}", mobile)
    .replace("{content}", content)
    .replace("{dynamicFontSize}", fontSize)
    .replace("{priceList}", priceList);

  const saveName = "Zajezd_" + hotelTitle.replace(/ /g, "_").replace(/\*/g, "");

  return { pdf, saveName };
};

class HotelPreview extends Component {
  render() {
    const {
      tourDetail: {
        pdfProps: { strippedInstructions }
      }
    } = this.props;

    return (
      <div id="hotel-pdf-description">
        {this.renderOrangeButtons()}
      </div>
    );
  }

  renderOrangeButtons() {
    const {
      tourDetail: { id }
    } = this.props;
    const link = url + "front/catalogue/entity-pdf?idHotel=" + id;

    return (
      <OrangeButton
        onClick={() => this.generatePDF(id)}
        external={Boolean(true)}
        target="_blank"
        icon="picture_as_pdf"
      >
        Informace o hotelu si můžete stáhnout v PDF
      </OrangeButton>
    );
  }

  generatePDF(id) {
    const {
    } = this.props;
    const {
      generatePDF,
      tourDetail: { hotelTitle },
    } = this.props;

    generatePDF(id, hotelTitle + ' instrukce');
    this.setState({ generated: true });
  }

  unescapeHtml(html) {
    var el = document.createElement("div");
    return html.replace(/\&[#0-9a-z]+;/gi, function(enc) {
      el.innerHTML = enc;
      return el.innerText;
    });
  }

  strip(html) {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  generatePDFWithTemplate() {
    const {
      generatePdfWithCatch,
      tourDetail: {
        hotelTitle,
        description,
        guarantee: { name, mobile },
        country,
        photos,
        capacities,
        transport,
        tripTypes,
        pdfProps: { strippedInstructions, croppedImage }
      },
      termList: { terms }
    } = this.props;

    const params = {
      hotelTitle,
      description,
      guarantee: { name, mobile },
      country,
      photos,
      capacities,
      transport,
      tripTypes,
      pdfProps: { strippedInstructions, croppedImage },
      terms
    };

    generatePdfWithCatch(getFormattedPdfHtml, params);
  }
}

HotelPreview.propTypes = {
  tourDetail: PropTypes.any
};

const mapStateToProps = (state) => ({
  tourDetail: state.tourDetail,
  termList: state.termList,
  filterSettings: state.filterSettings
});

const mapDispatchToProps = (dispatch) => ({
  generatePdfWithCatch: (func, params) => dispatch(generatePdfWithCatch(func, params)),
  generatePDF: (params, name) => dispatch(generatePDF(params, name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Radium(HotelPreview));
